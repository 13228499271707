import React, {createContext, useContext, useState, useCallback} from 'react';
import {isEqual, isFunction} from 'lodash';
import {initialFilter} from './ProcessAutomationUIHelpers';

const ProcessAutomationUIContext = createContext();

export function useProcessAutomationUIContext() {
  return useContext(ProcessAutomationUIContext);
}

export const ProcessAutomationUIConsumer = ProcessAutomationUIContext.Consumer;

export function ProcessAutomationUIProvider({
  processAutomationUIEvents,
  children,
}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initProcessAutomation = {
    id: undefined,
    title: '',
    actionType: '',
    points: '',
    maxAttempt: '',
    enabled: '',
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initProcessAutomation,
    newProcessAutomationButtonClick:
      processAutomationUIEvents.newProcessAutomationButtonClick,
    openEditProcessAutomationDialog:
      processAutomationUIEvents.openEditProcessAutomationDialog,
    openDeleteProcessAutomationDialog:
      processAutomationUIEvents.openDeleteProcessAutomationDialog,
    // openDeleteCustomersDialog: customersUIEvents.openDeleteCustomersDialog,
    // openFetchCustomersDialog: customersUIEvents.openFetchCustomersDialog,
    // openUpdateCustomersStatusDialog:
    //   customersUIEvents.openUpdateCustomersStatusDialog,
  };

  return (
    <ProcessAutomationUIContext.Provider value={value}>
      {children}
    </ProcessAutomationUIContext.Provider>
  );
}
