import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {getRetailerData} from '../_redux/action';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {CSVExport} from 'react-bootstrap-table2-toolkit';
const {ExportCSVButton} = CSVExport;

const ListComponent = ({retailerName, startDate, endDate, getRetailerData}) => {
  const [result, setResult] = useState([]);
  useEffect(() => {
    const newQuery = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      storeName: retailerName,
      type: 'List',
    };
    getRetailerData(newQuery)
      .then((res) => {
        setResult(res.length ? res : []);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, [startDate, endDate]);
  const columns = [
    {
      dataField: 'productName',
      text: 'productName',
    },
    {
      dataField: 'upc',
      text: 'UPC',
    },
    {
      dataField: 'count',
      text: 'Count',
    },
  ];

  // console.log('result', result);

  return (
    <>
      {' '}
      {/* <BootstrapTable
        keyField="upc"
        data={result}
        bootstrap4
        remote
        columns={columns}
        // pagination={paginationFactory(options)}
        bordered={false}
      /> */}
      <div className={`card card-custom gutter-b`}>
        {/* begin::Header */}

        <ToolkitProvider
          keyField="upc"
          data={result}
          columns={columns}
          exportCSV>
          {(props) => (
            <>
              <div className="card-header" bis_skin_checked="1">
                <div className="card-title" bis_skin_checked="1">
                  <h3 className="card-label">{retailerName}</h3>
                </div>
                <ExportCSVButton
                  className="btn btn-primary"
                  style={{height: '40px', margin: 'auto 0'}}
                  {...props.csvProps}>
                  Export to CSV
                </ExportCSVButton>
              </div>

              <hr />
              <BootstrapTable
                bootstrap4
                remote
                bordered={false}
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default connect(null, {getRetailerData})(IsLoadingHOC(ListComponent));
