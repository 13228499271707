import * as Yup from 'yup';
const AddRolevalidationSchema = Yup.object({
  roleName: Yup.string()
    .min(2, 'Role name should be greater than 2 letters')
    .max(100, 'Role should be less than 100 letters')
    .required('Required'),
  roles: Yup.array().required('Please select atleast one policy'),
});

export default AddRolevalidationSchema;
