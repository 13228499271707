import React, {useEffect, useRef} from 'react';
import {Formik, ErrorMessage, FieldArray} from 'formik';
import {FormHelperText, Switch, Typography, Tooltip} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import {get} from 'lodash';
import {toast} from 'react-toastify';
import {connect, useSelector, useDispatch, shallowEqual} from 'react-redux';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {addRuleItem, updateRule, fetchRule} from '../_redux/action';
import AddRulevalidationSchema from '../Schema/AddRule.Schema';
import {useHistory, useParams} from 'react-router';
import ErrorBoundary from '../../../../_metronic/_helpers/ErrorBoundary';
import '../styles.css';

const AddRule = ({setLoading, addRuleItem, updateRule}) => {
  const initialValues = {
    ruleName: '',
    ruleCode: '',
    description: '',
    issueType: '',
    tag: '',
    status: 'inactive',
    isStoreRequired: false,
    isProductRequired: false,
    isUserDataRequired: false,
    isUserPointsRequired: false,
    isUserRewardsRequired: false,
    isUserActivityAttemptsRequired: false,
    isUserPurchaseHistoryRequired: false,
    isUserRewardClaimTokensRequired: false,
    isUserPackageCodeClaimRequired: false,
    panelRuleConfigurations: {
      enabled: false,
      requiredTotalQualifyingQuantity: '',
      requiredTotalQualifyingAmount: '',
      panelCheckReceiptDate: {
        enabled: false,
        // purchaseStartDate: null,
        // purchaseEndDate: null,
        checkApplicableStatuses: ['READY'],
        failedReceiptStatus: 'PENDING',
      },
      panelValidateStore: {
        enabled: false,
        validStoreNames: '',
        excludedStoreNames: '',
        checkApplicableStatuses: ['READY'],
        failedReceiptStatus: 'PENDING',
      },
      panelCheckReceiptForDiscount: {
        enabled: false,
        failedReceiptStatus: 'PENDING',
      },
      panelCheckRewardAlreadyClaimed: {
        enabled: false,
        maxRewardLimit: '',
        checkApplicableStatuses: ['READY'],
        failedReceiptStatus: 'PENDING',
      },
      panelCheckReceiptLocation: {
        enabled: false,
        validLocationRegex: [''],
        checkApplicableStatuses: ['READY'],
        failedReceiptStatus: 'PENDING',
      },
      panelCheckReceiptKeywords: {
        enabled: false,
        keywords: '',
        passedReceiptStatus: 'PENDING', // required options will be radio with values => PENDING or REJECTED,
      },
      panelSpecialProducts: {
        enabled: false,
        productUpcs: '', // required comma separated upcs ids
        minimumQuantities: '', // required,
        maximumQuantities: '', // optional
      },
    },
  };
  //const [code, setCode] = useState();
  const inputRef = useRef(null);
  const {id} = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchRule(id));
  }, [id, dispatch]);
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const {ruleForEdit, listLoading} = useSelector(
    (state) => ({
      ruleForEdit: state.rule.ruleForEdit,
      listLoading: state.rule.listLoading,
    }),
    shallowEqual,
  );

  // server request for save
  const saveRule = (rule, resetForm) => {
    if (!id) {
      setLoading(true);
      addRuleItem(rule)
        .then((res) => {
          setLoading(false);
          resetForm(initialValues);
          toast.success('Rule Added.');
          history.push('/rule/list');
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      updateRule(id, rule)
        .then((res) => {
          setLoading(false);
          resetForm(initialValues);
          toast.success('Updated Rule');
          history.push('/rule/list');
        })
        .catch((err) => {
          setLoading(false);
          // err.forEach((item) => {
          //   toast.error(item.message);
          // });
        });
    }
  };

  const handleChangeForCheckbox = (event, value, setFieldValue) => {
    const cpValue = Array.isArray(value) ? [...value] : [];
    if (event.target.checked) {
      cpValue.push(event.target.value);
    } else {
      const index = cpValue.indexOf(event.target.value);
      if (index > -1) {
        cpValue.splice(index, 1);
      }
    }
    setFieldValue(event.target.name, cpValue);
  };

  let history = useHistory();
  const ToolTipsCustom = (title) => {
    return (
      <Tooltip
        title={
          <Typography variant="subtitle1" component="span">
            {title}
          </Typography>
        }>
        <IconButton>
          <HelpIcon />
        </IconButton>
      </Tooltip>
    );
  };
  if (listLoading) {
    return (
      <Typography variant="h3" component="div">
        Loading....
      </Typography>
    );
  }
  return (
    <ErrorBoundary>
      <Formik
        initialValues={
          id
            ? ruleForEdit
              ? {
                  ...ruleForEdit,
                  panelRuleConfigurations: {
                    ...(ruleForEdit?.panelRuleConfigurations || {
                      enabled: false,
                      requiredTotalQualifyingQuantity: '',
                      requiredTotalQualifyingAmount: '',
                      panelCheckReceiptDate: {
                        enabled: false,
                        // purchaseStartDate: null,
                        // purchaseEndDate: null,
                        checkApplicableStatuses: ['READY'],
                        failedReceiptStatus: 'PENDING',
                      },
                      panelValidateStore: {
                        enabled: false,
                        validStoreNames: '',
                        excludedStoreNames: '',
                        checkApplicableStatuses: ['READY'],
                        failedReceiptStatus: 'PENDING',
                      },
                      panelCheckReceiptForDiscount: {
                        enabled: false,
                        failedReceiptStatus: 'PENDING',
                      },
                      panelCheckRewardAlreadyClaimed: {
                        enabled: false,
                        maxRewardLimit: '',
                        checkApplicableStatuses: ['READY'],
                        failedReceiptStatus: 'PENDING',
                      },
                      panelCheckReceiptLocation: {
                        enabled: false,
                        validLocationRegex: [''],
                        checkApplicableStatuses: ['READY'],
                        failedReceiptStatus: 'PENDING',
                      },
                    }),
                  },
                }
              : initialValues
            : initialValues
        }
        enableReinitialize
        onSubmit={(values, {resetForm}) => {
          const newValues = {
            ...values,
            //ruleCode: code,
            status:
              values.status === true || values.status === 'active'
                ? 'active'
                : 'inactive',
          };
          saveRule(newValues, resetForm);
          //console.log(newValues);
        }}
        validationSchema={AddRulevalidationSchema}
        onReset={() => {}}>
        {({
          values,
          handleReset,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          errors,
        }) => (
          <>
            {console.log(errors)}
            <div className="card card-custom role-form">
              <div className="card-header card-header-tabs-line">
                <ul
                  className="nav nav-dark nav-bold nav-tabs nav-tabs-line"
                  data-remember-tab="tab_id"
                  role="tablist">
                  <li className="nav-item">
                    <span className={`nav-link active}`} data-toggle="tab">
                      {id ? 'Update' : 'Add'} Rule
                    </span>
                  </li>
                </ul>
                <button
                  type="text"
                  style={{margin: '16px'}}
                  onClick={() => history.push('/rule/list')}
                  className={`btn btn-info font-weight-bold mr-2`}>
                  Back
                </button>
              </div>
              <div className="form">
                <div className="card-body">
                  <div className="row form-group">
                    <div className="col-12 col-lg-6">
                      <div className="row">
                        <label className="col-lg-4 col-form-label text-lg-left">
                          Rule Name:
                        </label>
                        <div className="col-lg-8">
                          <input
                            ref={inputRef}
                            className="form-control form-control-lg form-control-solid"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="ruleName"
                            value={values?.ruleName}
                          />

                          <FormHelperText>
                            <ErrorMessage
                              name="ruleName"
                              render={(msg) => (
                                <span className="text-danger">{msg}</span>
                              )}
                            />
                          </FormHelperText>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="row">
                        <label className="col-lg-4 col-form-label text-lg-left">
                          Tag:
                        </label>
                        <div className="col-lg-8">
                          <input
                            className="form-control form-control-lg form-control-solid "
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="tag"
                            value={values?.tag}
                          />

                          <FormHelperText>
                            <ErrorMessage
                              name="tag"
                              render={(msg) => (
                                <span className="text-danger">{msg}</span>
                              )}
                            />
                          </FormHelperText>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-lg-2 col-form-label text-lg-left">
                      Description:
                    </label>
                    <div className="col-lg-10">
                      <textarea
                        cols="40"
                        rows="3"
                        className="form-control form-control-lg form-control-solid"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="description"
                        value={values.description}
                      />
                      <FormHelperText>
                        <ErrorMessage
                          name="description"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-6 form-group row">
                      <label className="col-12 col-lg-6 col-sm-8 col-form-label text-lg-left">
                        Is Store Required
                      </label>
                      <div className="col-lg-6 col-sm-4">
                        <Switch
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="isStoreRequired"
                          checked={!!get(values, 'isStoreRequired')}
                        />
                        <FormHelperText>
                          <ErrorMessage
                            name="isStoreRequired"
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 form-group row">
                      <label className="col-lg-6 col-sm-8 col-form-label text-lg-left">
                        Is Product Required
                      </label>
                      <div className="col-lg-6  col-sm-4">
                        <Switch
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="isProductRequired"
                          checked={!!get(values, 'isProductRequired')}
                        />
                        <FormHelperText>
                          <ErrorMessage
                            name="isProductRequired"
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 form-group row">
                      <label className="col-lg-6 col-sm-8 col-form-label text-lg-left">
                        Is User Data Required
                      </label>
                      <div className="col-12  col-sm-4 col-lg-6">
                        <Switch
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="isUserDataRequired"
                          checked={!!get(values, 'isUserDataRequired')}
                        />
                        <FormHelperText>
                          <ErrorMessage
                            name="isUserDataRequired"
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 form-group row">
                      <label className="col-lg-6 col-sm-8 col-form-label text-lg-left">
                        Is User Activity Attempts Required
                      </label>
                      <div className="col-12  col-sm-4 col-lg-6">
                        <Switch
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="isUserActivityAttemptsRequired"
                          checked={
                            !!get(values, 'isUserActivityAttemptsRequired')
                          }
                        />
                        <FormHelperText>
                          <ErrorMessage
                            name="isUserActivityAttemptsRequired"
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 form-group row">
                      <label className="col-lg-6 col-sm-8 col-form-label text-lg-left">
                        Is User Points Required
                      </label>
                      <div className="col-12  col-sm-4 col-lg-6">
                        <Switch
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="isUserPointsRequired"
                          checked={!!get(values, 'isUserPointsRequired')}
                        />
                        <FormHelperText>
                          <ErrorMessage
                            name="isUserPointsRequired"
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 form-group row">
                      <label className="col-lg-6 col-sm-8 col-form-label text-lg-left">
                        Is User Rewards Required
                      </label>
                      <div className="col-12  col-sm-4 col-lg-6">
                        <Switch
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="isUserRewardsRequired"
                          checked={!!get(values, 'isUserRewardsRequired')}
                        />
                        <FormHelperText>
                          <ErrorMessage
                            name="isUserRewardsRequired"
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 form-group row">
                      <label className="col-lg-6 col-sm-8 col-form-label text-lg-left">
                        Is User Purchase History Required
                      </label>
                      <div className="col-12  col-sm-4 col-lg-6">
                        <Switch
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="isUserPurchaseHistoryRequired"
                          checked={
                            !!get(values, 'isUserPurchaseHistoryRequired')
                          }
                        />
                        <FormHelperText>
                          <ErrorMessage
                            name="isUserPurchaseHistoryRequired"
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 form-group row">
                      <label className="col-lg-6 col-sm-8 col-form-label text-lg-left">
                        Is User Reward Claim Tokens Required
                      </label>
                      <div className="col-12  col-sm-4 col-lg-6">
                        <Switch
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="isUserRewardClaimTokensRequired"
                          checked={
                            !!get(values, 'isUserRewardClaimTokensRequired')
                          }
                        />
                        <FormHelperText>
                          <ErrorMessage
                            name="isUserRewardClaimTokensRequired"
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 form-group row">
                      <label className="col-lg-6 col-sm-8 col-form-label text-lg-left">
                        Is User Package Code Claim Required
                      </label>
                      <div className="col-12  col-sm-4 col-lg-6">
                        <Switch
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="isUserPackageCodeClaimRequired"
                          checked={
                            !!get(values, 'isUserPackageCodeClaimRequired')
                          }
                        />
                        <FormHelperText>
                          <ErrorMessage
                            name="isUserPackageCodeClaimRequired"
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 form-group row">
                      <label className="col-lg-6 col-sm-8 col-form-label text-lg-left">
                        Status
                      </label>
                      <div className="col-lg-6 col-sm-4">
                        <Switch
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="status"
                          checked={
                            values.status === 'active' || values.status === true
                              ? true
                              : false
                          }
                          // checked={!!get(values, 'status')}
                        />
                        {values.status === true || values.status === 'active'
                          ? 'active'
                          : 'inactive'}
                        <FormHelperText>
                          <ErrorMessage
                            name="status"
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-6 form-group row">
                      <label className="col-12 col-lg-6 col-form-label text-lg-left">
                        Rule Configuration
                      </label>
                      <div className="col-12 col-sm-4 col-lg-6">
                        <Switch
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="panelRuleConfigurations.enabled"
                          checked={
                            !!get(values, 'panelRuleConfigurations.enabled')
                          }
                        />
                        <FormHelperText>
                          <ErrorMessage
                            name="panelRuleConfigurations.enabled"
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </div>
                    <div className="col-12 form-group">
                      <div className="row">
                        {values?.panelRuleConfigurations?.enabled && (
                          <fieldset className="border p-5">
                            <legend className="w-auto">
                              Rule Configuration
                            </legend>
                            <div className="row form-group">
                              <div className="col-12 col-lg-6 form-group row">
                                <label className="col-lg-4 col-form-label text-lg-left pr-0">
                                  Required Total Qualifying Amount :
                                </label>
                                <div className="col-lg-8">
                                  <input
                                    className="form-control form-control-lg form-control-solid "
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="number"
                                    name="panelRuleConfigurations.requiredTotalQualifyingAmount"
                                    value={
                                      values?.panelRuleConfigurations
                                        ?.requiredTotalQualifyingAmount
                                    }
                                  />

                                  <FormHelperText>
                                    <ErrorMessage
                                      name="panelRuleConfigurations.requiredTotalQualifyingAmount"
                                      render={(msg) => (
                                        <span className="text-danger">
                                          {msg}
                                        </span>
                                      )}
                                    />
                                  </FormHelperText>
                                </div>
                              </div>
                              <div className="col-12 col-lg-6 form-group row">
                                <label className="col-lg-4 col-form-label text-lg-left pr-0">
                                  Required Total Qualifying Quantity :
                                </label>
                                <div className="col-lg-8">
                                  <input
                                    className="form-control form-control-lg form-control-solid"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="number"
                                    name="panelRuleConfigurations.requiredTotalQualifyingQuantity"
                                    value={
                                      values?.panelRuleConfigurations
                                        ?.requiredTotalQualifyingQuantity
                                    }
                                  />

                                  <FormHelperText>
                                    <ErrorMessage
                                      name="panelRuleConfigurations.requiredTotalQualifyingQuantity"
                                      render={(msg) => (
                                        <span className="text-danger">
                                          {msg}
                                        </span>
                                      )}
                                    />
                                  </FormHelperText>
                                </div>
                              </div>
                              <div className="col-12 col-lg-6 form-group row">
                                <label className="col-12 col-lg-6 col-form-label text-lg-left">
                                  Receipt Date
                                </label>
                                <div className="col-12 col-sm-4 col-lg-6">
                                  <Switch
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="panelRuleConfigurations.panelCheckReceiptDate.enabled"
                                    checked={
                                      !!get(
                                        values,
                                        'panelRuleConfigurations.panelCheckReceiptDate.enabled',
                                      )
                                    }
                                  />
                                </div>
                                {values?.panelRuleConfigurations
                                  ?.panelCheckReceiptDate?.enabled && (
                                  <fieldset className="border p-3 m-2 w-100">
                                    <legend
                                      className="w-auto"
                                      style={{fontSize: 'small'}}>
                                      Receipt Date Configuration
                                    </legend>
                                    <div className="col-12 row">
                                      {/* <div className="form-group col-12 col-lg-6">
                                      <label className="form-label ">
                                        Start Date:
                                      </label>
                                      <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        className={`form-control form-control-lg form-control-solid`}
                                        autocomplete="off"
                                        selected={
                                          values?.panelRuleConfigurations
                                            ?.panelCheckReceiptDate
                                            ?.purchaseStartDate
                                            ? moment(
                                                values?.panelRuleConfigurations
                                                  .panelCheckReceiptDate
                                                  .purchaseStartDate,
                                              ).toDate()
                                            : null
                                        }
                                        name="panelRuleConfigurations.panelCheckReceiptDate.purchaseStartDate"
                                        onChange={(date) => {
                                          handleChange({
                                            target: {
                                              name: 'panelRuleConfigurations.panelCheckReceiptDate.purchaseStartDate',
                                              value: date,
                                            },
                                          });
                                        }} //only when value has changed
                                        showYearDropdown
                                        dropdownMode="select"
                                        enableTabLoop
                                      />
                                      <FormHelperText>
                                        <ErrorMessage
                                          name="panelRuleConfigurations.panelCheckReceiptDate.purchaseStartDate"
                                          render={(msg) => (
                                            <span className="text-danger">
                                              {msg}
                                            </span>
                                          )}
                                        />
                                      </FormHelperText>
                                    </div>
                                    <div className="form-group col-12 col-lg-6">
                                      <label className="form-label ">
                                        End Date:
                                      </label>
                                      <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        className={`form-control form-control-lg form-control-solid`}
                                        autocomplete="off"
                                        selected={
                                          values?.panelRuleConfigurations
                                            ?.panelCheckReceiptDate
                                            ?.purchaseEndDate
                                            ? moment(
                                                values?.panelRuleConfigurations
                                                  .panelCheckReceiptDate
                                                  .purchaseEndDate,
                                              ).toDate()
                                            : null
                                        }
                                        name="panelRuleConfigurations.panelCheckReceiptDate.purchaseEndDate"
                                        onChange={(date) => {
                                          handleChange({
                                            target: {
                                              name: 'panelRuleConfigurations.panelCheckReceiptDate.purchaseEndDate',
                                              value: date,
                                            },
                                          });
                                        }} //only when value has changed
                                        showYearDropdown
                                        dropdownMode="select"
                                        enableTabLoop
                                      />
                                      <FormHelperText>
                                        <ErrorMessage
                                          name="panelRuleConfigurations.panelCheckReceiptDate.purchaseEndDate"
                                          render={(msg) => (
                                            <span className="text-danger">
                                              {msg}
                                            </span>
                                          )}
                                        />
                                      </FormHelperText>
                                    </div> */}
                                      <div className="form-group col-12 col-lg-6">
                                        <label className="form-label ">
                                          Applicable Status
                                        </label>
                                        <div className="d-flex">
                                          <div className="form-check form-check-custom">
                                            <input
                                              type="checkbox"
                                              id="READY"
                                              name="panelRuleConfigurations.panelCheckReceiptDate.checkApplicableStatuses"
                                              checked={values?.panelRuleConfigurations?.panelCheckReceiptDate?.checkApplicableStatuses.includes(
                                                'READY',
                                              )}
                                              className="form-check-input"
                                              value="READY"
                                              disabled
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="READY">
                                              Ready
                                            </label>
                                          </div>
                                          <div className="form-check form-check-custom ml-3">
                                            <input
                                              type="checkbox"
                                              id="APPROVED"
                                              name="panelRuleConfigurations.panelCheckReceiptDate.checkApplicableStatuses"
                                              checked={values?.panelRuleConfigurations?.panelCheckReceiptDate?.checkApplicableStatuses.includes(
                                                'APPROVED',
                                              )}
                                              className="form-check-input"
                                              onChange={(e) =>
                                                handleChangeForCheckbox(
                                                  e,
                                                  values
                                                    ?.panelRuleConfigurations
                                                    ?.panelCheckReceiptDate
                                                    ?.checkApplicableStatuses ||
                                                    [],
                                                  setFieldValue,
                                                )
                                              }
                                              value="APPROVED"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="APPROVED">
                                              Approved
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group col-12 col-lg-6">
                                        <label className="form-label ">
                                          Receipt Status
                                        </label>
                                        <div className="d-flex">
                                          <div className="form-check form-check-custom mr-3">
                                            <input
                                              type="radio"
                                              id="pending"
                                              name="panelRuleConfigurations.panelCheckReceiptDate.failedReceiptStatus"
                                              checked={
                                                values?.panelRuleConfigurations
                                                  ?.panelCheckReceiptDate
                                                  ?.failedReceiptStatus ===
                                                'PENDING'
                                              }
                                              className="form-check-input"
                                              value="PENDING"
                                              onChange={handleChange}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="pending">
                                              Pending
                                            </label>
                                          </div>
                                          <div className="form-check form-check-custom">
                                            <input
                                              type="radio"
                                              id="rejected"
                                              name="panelRuleConfigurations.panelCheckReceiptDate.failedReceiptStatus"
                                              checked={
                                                values?.panelRuleConfigurations
                                                  ?.panelCheckReceiptDate
                                                  ?.failedReceiptStatus ===
                                                'REJECTED'
                                              }
                                              className="form-check-input"
                                              onChange={handleChange}
                                              value="REJECTED"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="rejected">
                                              Rejected
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </fieldset>
                                )}
                              </div>

                              <div className="col-12 col-lg-6 form-group row">
                                <label className="col-12 col-lg-6 col-form-label text-lg-left">
                                  Receipt For Discount
                                </label>
                                <div className="col-12 col-sm-4 col-lg-6">
                                  <Switch
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="panelRuleConfigurations.panelCheckReceiptForDiscount.enabled"
                                    checked={
                                      !!get(
                                        values,
                                        'panelRuleConfigurations.panelCheckReceiptForDiscount.enabled',
                                      )
                                    }
                                  />
                                </div>
                                {values?.panelRuleConfigurations
                                  ?.panelCheckReceiptForDiscount?.enabled && (
                                  <fieldset className="border p-3 m-2 w-100">
                                    <legend
                                      className="w-auto"
                                      style={{fontSize: 'small'}}>
                                      Receipt For Discount Configuration
                                    </legend>
                                    <div className="col-12 row">
                                      <div className="form-group col-12 col-lg-6">
                                        <label className="form-label">
                                          Receipt Status
                                        </label>
                                        <div className="d-flex">
                                          <div className="form-check mr-3 form-check-custom">
                                            <input
                                              type="radio"
                                              id="panelCheckReceiptForDiscount.pending"
                                              name="panelRuleConfigurations.panelCheckReceiptForDiscount.failedReceiptStatus"
                                              checked={
                                                values?.panelRuleConfigurations
                                                  ?.panelCheckReceiptForDiscount
                                                  ?.failedReceiptStatus ===
                                                'PENDING'
                                              }
                                              className="form-check-input"
                                              value="PENDING"
                                              onChange={handleChange}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="panelCheckReceiptForDiscount.pending">
                                              Pending
                                            </label>
                                          </div>
                                          <div className="form-check form-check-custom">
                                            <input
                                              type="radio"
                                              id="panelCheckReceiptForDiscount.rejected"
                                              name="panelRuleConfigurations.panelCheckReceiptForDiscount.failedReceiptStatus"
                                              checked={
                                                values?.panelRuleConfigurations
                                                  ?.panelCheckReceiptForDiscount
                                                  ?.failedReceiptStatus ===
                                                'REJECTED'
                                              }
                                              className="form-check-input"
                                              onChange={handleChange}
                                              value="REJECTED"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="panelCheckReceiptForDiscount.rejected">
                                              Rejected
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </fieldset>
                                )}
                              </div>
                              <div className="col-12 col-lg-6 form-group row">
                                <label className="col-12 col-lg-6 col-form-label text-lg-left">
                                  Validate Store
                                </label>
                                <div className="col-12 col-sm-4 col-lg-6">
                                  <Switch
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="panelRuleConfigurations.panelValidateStore.enabled"
                                    checked={
                                      !!get(
                                        values,
                                        'panelRuleConfigurations.panelValidateStore.enabled',
                                      )
                                    }
                                  />
                                </div>
                                {values?.panelRuleConfigurations
                                  ?.panelValidateStore?.enabled && (
                                  <fieldset className="border p-3 m-2 w-100">
                                    <legend
                                      className="w-auto"
                                      style={{fontSize: 'small'}}>
                                      Validate Store Configuration
                                    </legend>

                                    <div className="row col-12">
                                      <div className="form-group col-12 col-lg-6">
                                        <label className="form-label ">
                                          Store Names:
                                        </label>
                                        <input
                                          className="form-control form-control-lg form-control-solid"
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          name="panelRuleConfigurations.panelValidateStore.validStoreNames"
                                          placeholder="Comma separated store names"
                                          value={
                                            values?.panelRuleConfigurations
                                              ?.panelValidateStore
                                              ?.validStoreNames || ''
                                          }
                                        />

                                        <FormHelperText>
                                          <ErrorMessage
                                            name="panelRuleConfigurations.panelValidateStore.validStoreNames"
                                            render={(msg) => (
                                              <span className="text-danger">
                                                {msg}
                                              </span>
                                            )}
                                          />
                                        </FormHelperText>
                                      </div>
                                      <div className="form-group col-12 col-lg-6">
                                        <label className="form-label  ">
                                          Excluded Store Names:
                                        </label>
                                        <input
                                          className="form-control form-control-lg form-control-solid"
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          name="panelRuleConfigurations.panelValidateStore.excludedStoreNames"
                                          placeholder="Walmart"
                                          value={
                                            values?.panelRuleConfigurations
                                              ?.panelValidateStore
                                              ?.excludedStoreNames || ''
                                          }
                                        />

                                        <FormHelperText>
                                          <ErrorMessage
                                            name="panelRuleConfigurations.panelValidateStore.excludedStoreNames"
                                            render={(msg) => (
                                              <span className="text-danger">
                                                {msg}
                                              </span>
                                            )}
                                          />
                                        </FormHelperText>
                                      </div>

                                      <div className="form-group col-12 col-lg-6">
                                        <label className="form-label  ">
                                          Applicable Status
                                        </label>
                                        <div className="d-flex">
                                          <div className="form-check form-check-custom">
                                            <input
                                              type="checkbox"
                                              id="panelValidateStore.READY"
                                              name="panelRuleConfigurations.panelValidateStore.checkApplicableStatuses"
                                              checked={
                                                values?.panelRuleConfigurations?.panelValidateStore?.checkApplicableStatuses?.includes(
                                                  'READY',
                                                ) || false
                                              }
                                              className="form-check-input"
                                              disabled
                                              value="READY"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="panelValidateStore.READY">
                                              Ready
                                            </label>
                                          </div>

                                          <div className="form-check form-check-custom ml-3">
                                            <input
                                              type="checkbox"
                                              id="panelValidateStore.APPROVED"
                                              name="panelRuleConfigurations.panelValidateStore.checkApplicableStatuses"
                                              checked={
                                                values?.panelRuleConfigurations?.panelValidateStore?.checkApplicableStatuses?.includes(
                                                  'APPROVED',
                                                ) || false
                                              }
                                              className="form-check-input"
                                              onChange={(e) =>
                                                handleChangeForCheckbox(
                                                  e,
                                                  values
                                                    ?.panelRuleConfigurations
                                                    ?.panelValidateStore
                                                    ?.checkApplicableStatuses ||
                                                    [],
                                                  setFieldValue,
                                                )
                                              }
                                              value="APPROVED"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="panelValidateStore.APPROVED">
                                              Approved
                                            </label>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="form-group col-12 col-lg-6">
                                        <label className="form-label  ">
                                          Receipt Status
                                        </label>
                                        <div className="d-flex">
                                          <div className="form-check form-check-custom mr-3">
                                            <input
                                              type="radio"
                                              id="panelValidateStore.pending"
                                              name="panelRuleConfigurations.panelValidateStore.failedReceiptStatus"
                                              checked={
                                                values?.panelRuleConfigurations
                                                  ?.panelValidateStore
                                                  ?.failedReceiptStatus ===
                                                'PENDING'
                                              }
                                              className="form-check-input"
                                              value="PENDING"
                                              onChange={handleChange}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="panelValidateStore.pending">
                                              Pending
                                            </label>
                                          </div>
                                          <div className="form-check form-check-custom">
                                            <input
                                              type="radio"
                                              id="panelValidateStore.rejected"
                                              name="panelRuleConfigurations.panelValidateStore.failedReceiptStatus"
                                              checked={
                                                values?.panelRuleConfigurations
                                                  ?.panelValidateStore
                                                  ?.failedReceiptStatus ===
                                                'REJECTED'
                                              }
                                              className="form-check-input"
                                              onChange={handleChange}
                                              value="REJECTED"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="panelValidateStore.rejected">
                                              Rejected
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </fieldset>
                                )}
                              </div>
                              <div className="col-12 col-lg-6 form-group row">
                                <label className="col-12 col-lg-6 col-form-label text-lg-left">
                                  Reward Already Claimed
                                </label>
                                <div className="col-12 col-sm-4 col-lg-6">
                                  <Switch
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="panelRuleConfigurations.panelCheckRewardAlreadyClaimed.enabled"
                                    checked={
                                      !!get(
                                        values,
                                        'panelRuleConfigurations.panelCheckRewardAlreadyClaimed.enabled',
                                      )
                                    }
                                  />
                                </div>
                                {values?.panelRuleConfigurations
                                  ?.panelCheckRewardAlreadyClaimed?.enabled && (
                                  <fieldset className="border p-3 m-2 w-100">
                                    <legend
                                      className="w-auto"
                                      style={{fontSize: 'small'}}>
                                      Reward Already Claimed Configuration
                                    </legend>
                                    <div className="col-12 row">
                                      <div className="form-group col-12 col-lg-6">
                                        <label className="form-label">
                                          Max Reward Limit :
                                        </label>
                                        <input
                                          className="form-control form-control-lg form-control-solid"
                                          onBlur={handleBlur}
                                          type="number"
                                          onChange={handleChange}
                                          name="panelRuleConfigurations.panelCheckRewardAlreadyClaimed.maxRewardLimit"
                                          placeholder="Max Reward Limit"
                                          value={
                                            values?.panelRuleConfigurations
                                              ?.panelCheckRewardAlreadyClaimed
                                              ?.maxRewardLimit || ''
                                          }
                                        />

                                        <FormHelperText>
                                          <ErrorMessage
                                            name="panelRuleConfigurations.panelCheckRewardAlreadyClaimed.maxRewardLimit"
                                            render={(msg) => (
                                              <span className="text-danger">
                                                {msg}
                                              </span>
                                            )}
                                          />
                                        </FormHelperText>
                                      </div>

                                      <div className="form-group col-12 col-lg-6">
                                        <label className="form-label">
                                          Applicable Status
                                        </label>
                                        <div className="d-flex">
                                          <div className="form-check form-check-custom">
                                            <input
                                              type="checkbox"
                                              id="panelCheckRewardAlreadyClaimed.READY"
                                              name="panelRuleConfigurations.panelCheckRewardAlreadyClaimed.checkApplicableStatuses"
                                              checked={
                                                values?.panelRuleConfigurations?.panelCheckRewardAlreadyClaimed?.checkApplicableStatuses?.includes(
                                                  'READY',
                                                ) || false
                                              }
                                              className="form-check-input"
                                              disabled
                                              value="READY"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="panelCheckRewardAlreadyClaimed.READY">
                                              Ready
                                            </label>
                                          </div>

                                          <div className="form-check form-check-custom ml-3">
                                            <input
                                              type="checkbox"
                                              id="panelCheckRewardAlreadyClaimed.APPROVED"
                                              name="panelRuleConfigurations.panelCheckRewardAlreadyClaimed.checkApplicableStatuses"
                                              checked={
                                                values?.panelRuleConfigurations?.panelCheckRewardAlreadyClaimed?.checkApplicableStatuses?.includes(
                                                  'APPROVED',
                                                ) || false
                                              }
                                              className="form-check-input"
                                              onChange={(e) =>
                                                handleChangeForCheckbox(
                                                  e,
                                                  values
                                                    ?.panelRuleConfigurations
                                                    ?.panelCheckRewardAlreadyClaimed
                                                    ?.checkApplicableStatuses ||
                                                    [],
                                                  setFieldValue,
                                                )
                                              }
                                              value="APPROVED"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="panelCheckRewardAlreadyClaimed.APPROVED">
                                              Approved
                                            </label>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="form-group col-12 col-lg-6">
                                        <label className="form-label">
                                          Receipt Status
                                        </label>
                                        <div className="d-flex">
                                          <div className="form-check form-check-custom mr-3">
                                            <input
                                              type="radio"
                                              id="panelCheckRewardAlreadyClaimed.pending"
                                              name="panelRuleConfigurations.panelCheckRewardAlreadyClaimed.failedReceiptStatus"
                                              checked={
                                                values?.panelRuleConfigurations
                                                  ?.panelCheckRewardAlreadyClaimed
                                                  ?.failedReceiptStatus ===
                                                'PENDING'
                                              }
                                              className="form-check-input"
                                              value="PENDING"
                                              onChange={handleChange}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="panelCheckRewardAlreadyClaimed.pending">
                                              Pending
                                            </label>
                                          </div>
                                          <div className="form-check form-check-custom">
                                            <input
                                              type="radio"
                                              id="panelCheckRewardAlreadyClaimed.rejected"
                                              name="panelRuleConfigurations.panelCheckRewardAlreadyClaimed.failedReceiptStatus"
                                              checked={
                                                values?.panelRuleConfigurations
                                                  ?.panelCheckRewardAlreadyClaimed
                                                  ?.failedReceiptStatus ===
                                                'REJECTED'
                                              }
                                              className="form-check-input"
                                              onChange={handleChange}
                                              value="REJECTED"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="panelCheckRewardAlreadyClaimed.rejected">
                                              Rejected
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </fieldset>
                                )}
                              </div>
                              <div className="col-12 col-lg-6 form-group row">
                                <label className="col-12 col-lg-6 col-form-label text-lg-left">
                                  Receipt Location(Postal Code)
                                </label>
                                <div className="col-12 col-sm-4 col-lg-6">
                                  <Switch
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="panelRuleConfigurations.panelCheckReceiptLocation.enabled"
                                    checked={
                                      !!get(
                                        values,
                                        'panelRuleConfigurations.panelCheckReceiptLocation.enabled',
                                      )
                                    }
                                  />
                                </div>
                                {values?.panelRuleConfigurations
                                  ?.panelCheckReceiptLocation?.enabled && (
                                  <fieldset className="border p-3 m-2 w-100">
                                    <legend
                                      className="w-auto"
                                      style={{fontSize: 'small'}}>
                                      Receipt Location(Postal Code)
                                      Configuration
                                    </legend>
                                    <div className="col-12 row">
                                      <div className="form-group col-12 col-lg-6">
                                        <label className="form-label">
                                          Applicable Status
                                        </label>
                                        <div className="d-flex">
                                          <div className="form-check form-check-custom">
                                            <input
                                              type="checkbox"
                                              id="panelCheckReceiptLocation.READY"
                                              name="panelRuleConfigurations.panelCheckReceiptLocation.checkApplicableStatuses"
                                              checked={
                                                values?.panelRuleConfigurations?.panelCheckReceiptLocation?.checkApplicableStatuses?.includes(
                                                  'READY',
                                                ) || false
                                              }
                                              className="form-check-input"
                                              disabled
                                              value="READY"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="panelCheckReceiptLocation.READY">
                                              Ready
                                            </label>
                                          </div>

                                          <div className="form-check form-check-custom ml-3">
                                            <input
                                              type="checkbox"
                                              id="panelCheckReceiptLocation.APPROVED"
                                              name="panelRuleConfigurations.panelCheckReceiptLocation.checkApplicableStatuses"
                                              checked={
                                                values?.panelRuleConfigurations?.panelCheckReceiptLocation?.checkApplicableStatuses?.includes(
                                                  'APPROVED',
                                                ) || false
                                              }
                                              className="form-check-input"
                                              onChange={(e) =>
                                                handleChangeForCheckbox(
                                                  e,
                                                  values
                                                    ?.panelRuleConfigurations
                                                    ?.panelCheckReceiptLocation
                                                    ?.checkApplicableStatuses ||
                                                    [],
                                                  setFieldValue,
                                                )
                                              }
                                              value="APPROVED"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="panelCheckReceiptLocation.APPROVED">
                                              Approved
                                            </label>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="form-group col-12 col-lg-6">
                                        <label className="form-label">
                                          Receipt Status
                                        </label>
                                        <div className="d-flex">
                                          <div className="form-check form-check-custom mr-3">
                                            <input
                                              type="radio"
                                              id="panelCheckReceiptLocation.pending"
                                              name="panelRuleConfigurations.panelCheckReceiptLocation.failedReceiptStatus"
                                              checked={
                                                values?.panelRuleConfigurations
                                                  ?.panelCheckReceiptLocation
                                                  ?.failedReceiptStatus ===
                                                'PENDING'
                                              }
                                              className="form-check-input"
                                              value="PENDING"
                                              onChange={handleChange}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="panelCheckReceiptLocation.pending">
                                              Pending
                                            </label>
                                          </div>
                                          <div className="form-check form-check-custom">
                                            <input
                                              type="radio"
                                              id="panelCheckReceiptLocation.rejected"
                                              name="panelRuleConfigurations.panelCheckReceiptLocation.failedReceiptStatus"
                                              checked={
                                                values?.panelRuleConfigurations
                                                  ?.panelCheckReceiptLocation
                                                  ?.failedReceiptStatus ===
                                                'REJECTED'
                                              }
                                              className="form-check-input"
                                              onChange={handleChange}
                                              value="REJECTED"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="panelCheckReceiptLocation.rejected">
                                              Rejected
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <FieldArray name="panelRuleConfigurations.panelCheckReceiptLocation.validLocationRegex">
                                        {({insert, remove, push}) => {
                                          const rexValues =
                                            values?.panelRuleConfigurations
                                              ?.panelCheckReceiptLocation
                                              ?.validLocationRegex || [];
                                          return (
                                            rexValues.length > 0 &&
                                            rexValues.map((item, index) => (
                                              <div
                                                className="col-12 form-group row"
                                                key={index}>
                                                <div className="col-10">
                                                  <label
                                                    className="form-label"
                                                    htmlFor={`panelRuleConfigurations.panelCheckReceiptLocation.validLocationRegex[${index}]`}>
                                                    Location(Postal Code) Regex{' '}
                                                    {index + 1} :
                                                  </label>
                                                  <div className="">
                                                    <input
                                                      className="form-control form-control-lg form-control-solid mr-1"
                                                      onBlur={handleBlur}
                                                      onChange={handleChange}
                                                      id={`panelRuleConfigurations.panelCheckReceiptLocation.validLocationRegex[${index}]`}
                                                      name={`panelRuleConfigurations.panelCheckReceiptLocation.validLocationRegex[${index}]`}
                                                      placeholder="Valid Location(Postal Code) Regex"
                                                      value={item}
                                                    />
                                                    <FormHelperText>
                                                      <ErrorMessage
                                                        name={`panelRuleConfigurations.panelCheckReceiptLocation.validLocationRegex[${index}]`}
                                                        render={(msg) => (
                                                          <span className="text-danger">
                                                            {msg}
                                                          </span>
                                                        )}
                                                      />
                                                    </FormHelperText>
                                                  </div>
                                                </div>
                                                <div className="col-2 pt-5">
                                                  {rexValues.length > 1 ? (
                                                    <div className="d-flex pt-3">
                                                      <IconButton
                                                        color="secondary"
                                                        onClick={() => {
                                                          remove(index);
                                                        }}
                                                        aria-label="upload picture"
                                                        className={`btn-icon btn-red font-weight-bold mr-1`}
                                                        component="span">
                                                        <i className="fa fa-minus text-white" />
                                                      </IconButton>
                                                      {rexValues.length ===
                                                        index + 1 && (
                                                        <IconButton
                                                          type="submit"
                                                          onClick={() => {
                                                            push('');
                                                          }}
                                                          className={`btn-success btn-icon font-weight-bold`}>
                                                          <i className="fa fa-plus text-white" />
                                                        </IconButton>
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <div className="pt-3">
                                                      <IconButton
                                                        type="submit"
                                                        onClick={() => {
                                                          push('');
                                                        }}
                                                        className={`btn-success btn-icon font-weight-bold`}>
                                                        <i className="fa fa-plus text-white" />
                                                      </IconButton>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            ))
                                          );
                                        }}
                                      </FieldArray>
                                    </div>
                                  </fieldset>
                                )}
                              </div>
                              <div className="col-12 col-lg-6 form-group row">
                                <label className="col-12 col-lg-6 col-form-label text-lg-left">
                                  Receipt Keywords :
                                </label>
                                <div className="col-12 col-sm-4 col-lg-6">
                                  <Switch
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="panelRuleConfigurations.panelCheckReceiptKeywords.enabled"
                                    checked={
                                      !!get(
                                        values,
                                        'panelRuleConfigurations.panelCheckReceiptKeywords.enabled',
                                      )
                                    }
                                  />
                                </div>
                                {values?.panelRuleConfigurations
                                  ?.panelCheckReceiptKeywords?.enabled && (
                                  <fieldset className="border p-3 m-2 w-100">
                                    <legend
                                      className="w-auto"
                                      style={{fontSize: 'small'}}>
                                      Receipt Keywords Configuration
                                    </legend>
                                    <div className="row col-12">
                                      <div className="form-group col-12 col-lg-6">
                                        <label className="form-label ">
                                          Keywords:
                                        </label>
                                        <input
                                          className="form-control form-control-lg form-control-solid"
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          name="panelRuleConfigurations.panelCheckReceiptKeywords.keywords"
                                          placeholder="Comma separated keywords"
                                          value={
                                            values?.panelRuleConfigurations
                                              ?.panelCheckReceiptKeywords
                                              ?.keywords || ''
                                          }
                                        />

                                        <FormHelperText>
                                          <ErrorMessage
                                            name="panelRuleConfigurations.panelCheckReceiptKeywords.keywords"
                                            render={(msg) => (
                                              <span className="text-danger">
                                                {msg}
                                              </span>
                                            )}
                                          />
                                        </FormHelperText>
                                      </div>

                                      <div className="form-group col-12 col-lg-6">
                                        <label className="form-label  ">
                                          Receipt Status
                                        </label>
                                        <div className="d-flex">
                                          <div className="form-check form-check-custom mr-3">
                                            <input
                                              type="radio"
                                              id="panelCheckReceiptKeywords.pending"
                                              name="panelRuleConfigurations.panelCheckReceiptKeywords.passedReceiptStatus"
                                              checked={
                                                values?.panelRuleConfigurations
                                                  ?.panelCheckReceiptKeywords
                                                  ?.passedReceiptStatus ===
                                                'PENDING'
                                              }
                                              className="form-check-input"
                                              value="PENDING"
                                              onChange={handleChange}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="panelCheckReceiptKeywords.pending">
                                              Pending
                                            </label>
                                          </div>
                                          <div className="form-check form-check-custom">
                                            <input
                                              type="radio"
                                              id="panelCheckReceiptKeywords.rejected"
                                              name="panelRuleConfigurations.panelCheckReceiptKeywords.passedReceiptStatus"
                                              checked={
                                                values?.panelRuleConfigurations
                                                  ?.panelCheckReceiptKeywords
                                                  ?.passedReceiptStatus ===
                                                'REJECTED'
                                              }
                                              className="form-check-input"
                                              onChange={handleChange}
                                              value="REJECTED"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="panelCheckReceiptKeywords.rejected">
                                              Rejected
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </fieldset>
                                )}
                              </div>
                              <div className="col-12 col-lg-6 form-group row">
                                <label className="col-12 col-lg-6 col-form-label text-lg-left">
                                  Special Products
                                </label>
                                <div className="col-12 col-sm-4 col-lg-6">
                                  <Switch
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="panelRuleConfigurations.panelSpecialProducts.enabled"
                                    checked={
                                      !!get(
                                        values,
                                        'panelRuleConfigurations.panelSpecialProducts.enabled',
                                      )
                                    }
                                  />
                                </div>
                                {values?.panelRuleConfigurations
                                  ?.panelSpecialProducts?.enabled && (
                                  <fieldset className="border p-3 m-2 w-100">
                                    <legend
                                      className="w-auto"
                                      style={{fontSize: 'small'}}>
                                      Special Products Configuration
                                    </legend>
                                    <div className="row col-12">
                                      <div className="form-group col-12 col-lg-6">
                                        <label className="form-label ">
                                          Product UPCS:
                                        </label>
                                        <input
                                          className="form-control form-control-lg form-control-solid"
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          name="panelRuleConfigurations.panelSpecialProducts.productUpcs"
                                          placeholder="Comma separated productUpcs"
                                          value={
                                            values?.panelRuleConfigurations
                                              ?.panelSpecialProducts
                                              ?.productUpcs || ''
                                          }
                                        />

                                        <FormHelperText>
                                          <ErrorMessage
                                            name="panelRuleConfigurations.panelSpecialProducts.productUpcs"
                                            render={(msg) => (
                                              <span className="text-danger">
                                                {msg}
                                              </span>
                                            )}
                                          />
                                        </FormHelperText>
                                      </div>

                                      <div className="form-group col-12 col-lg-6">
                                        <label className="form-label ">
                                          Minimum Quantities:
                                        </label>
                                        <input
                                          className="form-control form-control-lg form-control-solid"
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          type="number"
                                          name="panelRuleConfigurations.panelSpecialProducts.minimumQuantities"
                                          placeholder="Comma separated minimum quantities"
                                          value={
                                            values?.panelRuleConfigurations
                                              ?.panelSpecialProducts
                                              ?.minimumQuantities || ''
                                          }
                                        />

                                        <FormHelperText>
                                          <ErrorMessage
                                            name="panelRuleConfigurations.panelSpecialProducts.minimumQuantities"
                                            render={(msg) => (
                                              <span className="text-danger">
                                                {msg}
                                              </span>
                                            )}
                                          />
                                        </FormHelperText>
                                      </div>

                                      <div className="form-group col-12 col-lg-6">
                                        <label className="form-label ">
                                          Maximum Quantities:
                                        </label>
                                        <input
                                          className="form-control form-control-lg form-control-solid"
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          type="number"
                                          name="panelRuleConfigurations.panelSpecialProducts.maximumQuantities"
                                          placeholder="Comma separated maximum quantities"
                                          value={
                                            values?.panelRuleConfigurations
                                              ?.panelSpecialProducts
                                              ?.maximumQuantities || ''
                                          }
                                        />

                                        <FormHelperText>
                                          <ErrorMessage
                                            name="panelRuleConfigurations.panelSpecialProducts.maximumQuantities"
                                            render={(msg) => (
                                              <span className="text-danger">
                                                {msg}
                                              </span>
                                            )}
                                          />
                                        </FormHelperText>
                                      </div>
                                    </div>
                                  </fieldset>
                                )}
                              </div>
                            </div>
                          </fieldset>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-2 col-form-label text-lg-left">
                      Rule Code:
                    </label>
                    <div className="col-lg-10">
                      <textarea
                        cols="40"
                        rows="10"
                        style={{whiteSpace: 'nowrap'}}
                        className="form-control form-control-lg form-control-solid"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="ruleCode"
                        value={values?.ruleCode}
                      />

                      <FormHelperText>
                        <ErrorMessage
                          name="ruleCode"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                </div>

                <div className="card-footer">
                  <div className="row">
                    <div className="col-lg-12 d-flex justify-content-center align-items-center">
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className={`btn btn-primary font-weight-bold mr-2`}>
                        <i className="fa fa-eye" /> {id ? 'Update' : 'Submit'}
                      </button>{' '}
                      {!id && (
                        <button
                          type="button"
                          onClick={handleReset}
                          className={`btn btn-clean font-weight-bold mr-2`}>
                          <i className="fa fa-recycle" /> Reset
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </ErrorBoundary>
  );
};

export default connect(null, {addRuleItem, updateRule})(IsLoadingHOC(AddRule));
