import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';

import {useSubheader} from '../../../_metronic/layout';
import RequestedReportsList from './pages/RequestedReportsList';
import {IsLoadingHOC} from '../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {RequestedReportsUIProvider} from './RequestedReportsUIContext';

const RequestedReportsPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle('');
  const requestedReportsUIEvents = {
    openDeleteRequestedReportsDialog: (id) => {},
  };

  return (
    <RequestedReportsUIProvider
      requestedReportsUIEvents={requestedReportsUIEvents}>
      <Switch>
        <Redirect
          from="/requestedreports"
          exact={true}
          to="/requestedreports/list"
        />
        <Route path="/requestedreports/list" component={RequestedReportsList} />
      </Switch>
    </RequestedReportsUIProvider>
  );
};

export default connect(null, {})(IsLoadingHOC(RequestedReportsPage));
