import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import {ReceiptSummaryFilter} from '../component/ReceiptSummaryFilter';
import ReceiptSummaryTable from '../component/ReceiptSummaryTable';

const ReceiptSummaryList = () => {
  return (
    <>
      <Card>
        <CardHeader title="Receipt Summary">
          <CardHeaderToolbar>
            {/* <button
              className="btn btn-primary"
              type="button"
              onClick={exportToCsv}>
              Export to CSV
            </button> */}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {/* <ReceiptSummaryFilter /> */}
          <ReceiptSummaryTable />
        </CardBody>
      </Card>
    </>
  );
};

export default ReceiptSummaryList;
