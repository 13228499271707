import React, {useState} from 'react';
import {Tabs, Tab} from 'react-bootstrap/';
import './NodeProperties.css';
import FileManagerModel from '../../../FileManager/index';
import fmService from '../../../FileManager/FileManager.Service';
import AlertPopup from '../../../../../_metronic/_partials/PopupModel/AlertPopup';
export default function DataExporterNodeProperties(props) {
  let nodeProperties = props.nodeProperty;
  let [activeTabs, setaActiveTabs] = useState('inputTab');
  let [alertMessage, setAlertMessage] = useState(null);
  let [showFileManager, setShowFileManager] = useState(false);
  let [filename, setFilename] = useState(nodeProperties.inputParam.filename);
  let [filepath, setFilepath] = useState(nodeProperties.inputParam.filepath);
  let [inputParamMapping, setInputParamMapping] = useState(
    nodeProperties.inputParam.mapping ? nodeProperties.inputParam.mapping : [],
  );
  let exportTypeList = [
    {key: 'json', name: 'JSON File'},
    {key: 'csv', name: 'CSV File'},
  ];
  let inputVariablelist = nodeProperties.inputParam.variables
    ? nodeProperties.inputParam.variables
    : {};
  let nodeKeysName = Object.keys(inputVariablelist);
  for (let i in nodeKeysName) {
    let nodeID = nodeKeysName[i];
    inputVariablelist[nodeID] = inputVariablelist[nodeID].map((v) => {
      let selected = false;
      if (inputParamMapping.length > 0) {
        let findSelectedVal = inputParamMapping.find(
          (m) => m.input_key.key === v.key && m.input_key.nodeID === nodeID,
        );
        if (findSelectedVal) {
          selected = findSelectedVal.is_checked;
        }
      }
      return {...v, selected: selected};
    });
  }
  let [inputNodeVariableField, setInputNodeVariableField] =
    useState(inputVariablelist);
  let export_type = React.createRef();
  let updateSelection = (e, ind, key) => {
    let TempinputNodeVariableField = Object.assign({}, inputNodeVariableField);
    TempinputNodeVariableField[key][ind]['selected'] =
      !TempinputNodeVariableField[key][ind]['selected'];
    setInputNodeVariableField(TempinputNodeVariableField);
  };
  let prepareOutput = (event) => {
    event.preventDefault();
    let mappingList = [];
    for (let i in nodeKeysName) {
      let nodeID = nodeKeysName[i];
      inputNodeVariableField[nodeID].map((v) => {
        if (v.selected) {
          mappingList.push({
            is_checked: true,
            input_key: {
              key: v.key,
              nodeID: nodeID,
            },
          });
        }
      });
    }
    if (export_type.current.value === '' || !export_type.current.value) {
      setAlertMessage('Please select Export Type');
      return true;
    } else if (filename === '') {
      setAlertMessage('Please enter valid File Name');
      return true;
    } else if (filepath === '') {
      setAlertMessage('Invalid S3 location');
      return true;
    } else if (mappingList.length === 0) {
      setAlertMessage('Please select at least one variable');
      return true;
    }
    nodeProperties.inputParam.mapping = mappingList;
    nodeProperties.inputParam['export_type'] = export_type.current.value;
    nodeProperties.inputParam['filepath'] = filepath;
    nodeProperties.inputParam['filename'] = filename;
    nodeProperties.outputParam.variables = Object.assign(
      {},
      nodeProperties.inputParam.variables,
    );
    setInputParamMapping(mappingList);
    setaActiveTabs('outputTab');
    props.parentCallback(nodeProperties, 1);
  };
  let handleAlertPopupMsg = () => {
    setAlertMessage(null);
  };
  let inputOnchange = (e) => {
    setFilename(e.target.value);
  };
  let selectedFolderPath = (param) => {
    let {path} = param;
    setFilepath(fmService.getPathWithoutTenentID(path));
    setShowFileManager(false);
  };
  let openFileManager = () => {
    setShowFileManager(true);
  };
  let counter = 1;
  return (
    <>
      {alertMessage ? (
        <AlertPopup
          parentCallback={handleAlertPopupMsg}
          message={alertMessage}
        />
      ) : (
        ''
      )}
      <h2 className="leftToolBar">
        Object Properties
        <br />
        <span>Data Exporter Node</span>
      </h2>
      <div className="leftToolBarContainer">
        <Tabs
          activeKey={activeTabs}
          transition={false}
          id="noanim-tab-example"
          onSelect={(key) => {
            setaActiveTabs(key);
          }}>
          <Tab eventKey={'inputTab'} title="Input">
            <table className="table" id="templateNodeTable">
              <tbody>
                <tr>
                  <td width="50%">
                    <strong>
                      {' '}
                      Export Type <span className="requiredfield">*</span>{' '}
                    </strong>
                  </td>
                  <td>
                    <select
                      className="form-select customselect"
                      id="export_type"
                      ref={export_type}
                      defaultValue={nodeProperties.inputParam.export_type}>
                      <option value="">Select Export Type</option>
                      {exportTypeList.map((val, ind) => {
                        return (
                          <option key={'tem' + ind} value={val.key}>
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      {' '}
                      S3 Folder <span className="requiredfield">*</span>{' '}
                    </strong>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control custominput"
                      onClick={openFileManager}
                      id="filepath"
                      value={filepath}
                    />
                    {showFileManager ? (
                      <FileManagerModel
                        folderOnly={1}
                        parentCallback={() => setShowFileManager(false)}
                        getSelectedFilesOnParent={selectedFolderPath}
                      />
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      {' '}
                      FileName <span className="requiredfield">*</span>{' '}
                    </strong>
                  </td>
                  <td>
                    <input
                      type="text"
                      onChange={inputOnchange}
                      className="form-control custominput"
                      maxLength="100"
                      id="filename"
                      value={filename}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" style={{padding: '2px'}}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th colSpan="2" width="70%">
                            Variables List
                          </th>
                          <th width="30%">Export</th>
                        </tr>
                      </thead>
                      <tbody>
                        {nodeKeysName.map((key, ind) => {
                          return (
                            <>
                              <tr key={ind + key + 'tr'}>
                                <td
                                  colSpan="3"
                                  align="center"
                                  style={{
                                    backgroundColor: '#ebedf3',
                                    textTransform: 'capitalize',
                                  }}>
                                  <strong>{key} node</strong>
                                </td>
                              </tr>
                              {inputNodeVariableField[key].map((val, ind2) => {
                                return (
                                  <tr key={ind2 + 'tr'}>
                                    <td width="10%">{counter++}.</td>
                                    <td>
                                      <label
                                        className="form-check-label"
                                        htmlFor={'key' + ind2}>
                                        {' '}
                                        {val.key}
                                      </label>
                                    </td>
                                    <td>
                                      <div className="form-check" key={ind2}>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={val.selected}
                                          onChange={(e) =>
                                            updateSelection(e, ind2, key)
                                          }
                                          id={'key' + ind2}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                    <p align="center">
                      <button
                        type="button"
                        className="custombtn btn btn-dark"
                        title="Prepare Output"
                        onClick={prepareOutput}>
                        Select Column & Prepare Output
                      </button>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </Tab>
          <Tab
            eventKey={'outputTab'}
            title="Output"
            disabled={inputParamMapping.length > 0 ? false : 'disabled'}>
            <h5>Selected Columns</h5>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Node</th>
                  <th>Variable</th>
                </tr>
              </thead>
              <tbody>
                {inputParamMapping.map((val, ind) => {
                  return (
                    <tr key={ind}>
                      <td>{val.input_key.nodeID}</td>
                      <td>{val.input_key.key}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
