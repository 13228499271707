import React, {useEffect, useState} from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import {useHistory, useParams} from 'react-router-dom';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {ErrorMessage, Form, Formik} from 'formik';
import AddEditRBACvalidationSchema from '../Schema/RBAC.Schema';
import {FormHelperText, Switch} from '@material-ui/core';
import {toast} from 'react-toastify';
import {addEditRoleAction, getRoleDetailsAction} from '../_redux/action';
import {getClientList} from '../../../../app/modules/Auth/_redux/authCrud';
import {useSelector} from 'react-redux';
const AddEdit = (props) => {
  const {id} = useParams();
  const history = useHistory();
  const [clientList, setClientList] = useState([]);
  const [tokenData, setTokenData] = useState({});
  const {campaign, accessToken} = useSelector((state) => state.auth);

  const [initialValues, setInitialValues] = useState({
    roleType: '',
    name: '',
    description: '',
    status: 'active',
    clientId: '',
    campaignId: '',
  });
  useEffect(() => {
    if (accessToken) {
      try {
        let jsData = JSON.parse(window.atob(accessToken.split('.')[1]));
        jsData.isSuperAdmin = jsData?.roles?.indexOf('ADMIN') >= 0 || false;
        jsData.isClient = jsData?.roles?.indexOf('CLIENT') >= 0 || false;
        if (!jsData.isSuperAdmin) {
          setInitialValues({
            ...initialValues,
            clientId: jsData?.clId || '',
          });
        }
        setTokenData(jsData);
      } catch (e) {
        setTokenData({});
      }
    }
  }, [accessToken]);
  useEffect(() => {
    async function fetchRoleData(id) {
      try {
        if (id) {
          props.setLoading(true);
          const response = await getRoleDetailsAction(id);
          if (response.httpCode == 200) {
            const {
              name = '',
              description = '',
              status = 'active',
              roleType = 'global',
              clientId = '',
              campaignId = '',
            } = response.data?.content[0];
            setInitialValues({
              name,
              description,
              status,
              roleType,
              clientId,
              campaignId,
            });
            props.setLoading(false);
          }
        }
      } catch (error) {
        props.setLoading(false);
      }
    }
    fetchRoleData(id);
  }, [id]);
  useEffect(() => {
    getClientList().then((res) => {
      if (res?.content?.length) {
        setClientList(res?.content || []);
      }
    });
  }, []);
  const handleChange = (e, values, setValues) => {
    let {name, type, value, checked} = e.target;
    let formData = {...values};
    if (type == 'checkbox') {
      value = checked ? 'active' : 'inactive';
    }
    if (name == 'name') {
      value = value.toUpperCase();
    }
    if (name == 'roleType') {
      if (value === 'global') {
        formData['clientId'] = '';
        formData['campaignId'] = '';
      } else {
        if (value === 'client') {
          formData['campaignId'] = '';
        }
      }
    }
    if (name == 'clientId') {
      formData['campaignId'] = '';
    }
    formData[name] = value;
    setValues(formData);
  };
  const handleSubmit = async (values, {resetForm}) => {
    try {
      props.setLoading(true);
      const response = await addEditRoleAction(values, id);
      if (response.httpCode == 200) {
        toast.success(response.message);
        resetForm(initialValues);
        props.setLoading(false);
        history.push('/rbac/roles/list');
      } else {
        const error = response.errors;
        error.forEach((item) => {
          toast.error(item.message);
        });
        props.setLoading(false);
      }
    } catch (error) {
      console.log(error);
      props.setLoading(false);
    }
  };
  const renderCampaignList = (clientId) => {
    let content = campaign && campaign?.content ? campaign?.content : [];
    content = content.sort((a, b) => a.name.localeCompare(b.name));
    content = content.filter((v) => v.clientId === clientId);
    return content.map((item) => {
      return (
        <option value={`${item.id}`} key={item.id}>
          {item.name}
        </option>
      );
    });
  };
  return (
    <>
      <Card>
        <CardHeader title={id ? 'Edit Role' : 'Add Role'}>
          <CardHeaderToolbar>
            <button
              className="btn btn-info"
              type="button"
              onClick={() => history.push('/rbac/roles/list')}>
              Back
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleSubmit}
            validationSchema={AddEditRBACvalidationSchema}>
            {({values, errors, setValues}) => (
              <Form>
                {console.log(errors, values)}
                <div className="form-group row">
                  <label className="col-lg-2 col-form-label text-lg-left">
                    Role Type:
                  </label>
                  <div className="col-lg-4 col-xl-4 my-3">
                    <select
                      name="roleType"
                      value={values.roleType}
                      disabled={id}
                      className="form-control form-control-lg text-uppercases"
                      onChange={(e) => handleChange(e, values, setValues)}>
                      <option value="">Select Role Type</option>
                      {tokenData?.isSuperAdmin && (
                        <option value="global">GLOBAL</option>
                      )}
                      {(tokenData?.isSuperAdmin || tokenData?.isClient) && (
                        <option value="client">CLIENT</option>
                      )}
                      <option value="campaign">CAMPAIGN</option>
                    </select>
                    <FormHelperText>
                      <ErrorMessage
                        name="roleType"
                        render={(msg) => (
                          <span className="text-danger">{msg}</span>
                        )}
                      />
                    </FormHelperText>
                  </div>
                  {(values.roleType === 'client' ||
                    values.roleType === 'campaign') && (
                    <>
                      <label className="col-lg-2 col-form-label text-lg-left">
                        Select client
                      </label>
                      <div className="col-lg-4 col-xl-4 my-3">
                        <select
                          style={{marginRight: '5px'}}
                          onChange={(e) => handleChange(e, values, setValues)}
                          value={values?.clientId}
                          disabled={id}
                          name="clientId"
                          className="form-control"
                          id="sel1">
                          <option value="">Select Client</option>
                          {clientList.map((v) => (
                            <option value={v.id}>{`${v.email}`}</option>
                          ))}
                        </select>

                        <FormHelperText>
                          <ErrorMessage
                            name={'clientId'}
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </>
                  )}
                  {values.roleType === 'campaign' && (
                    <>
                      <label className="col-lg-2 col-form-label text-lg-left">
                        Select Campaign
                      </label>
                      <div className="col-lg-4 col-xl-4 my-3">
                        <select
                          style={{marginRight: '5px'}}
                          onChange={(e) => handleChange(e, values, setValues)}
                          value={values?.campaignId}
                          disabled={id}
                          name="campaignId"
                          className="form-control"
                          id="sel1">
                          <option value="">Select Site</option>
                          {renderCampaignList(values.clientId)}
                        </select>

                        <FormHelperText>
                          <ErrorMessage
                            name={'campaignId'}
                            render={(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          />
                        </FormHelperText>
                      </div>
                    </>
                  )}
                  <label className="col-lg-2 col-form-label text-lg-left">
                    Role:
                  </label>
                  <div className="col-lg-4 col-xl-4 my-3">
                    <input
                      className="form-control form-control-lg text-uppercases"
                      onChange={(e) => handleChange(e, values, setValues)}
                      name="name"
                      value={values.name}
                    />
                    <FormHelperText>
                      <ErrorMessage
                        name="name"
                        render={(msg) => (
                          <span className="text-danger">{msg}</span>
                        )}
                      />
                    </FormHelperText>
                  </div>
                  <label className="col-lg-2 col-form-label text-lg-left">
                    Description:
                  </label>
                  <div className="col-lg-4 col-xl-4 my-3">
                    <textarea
                      cols="40"
                      rows="5"
                      className="form-control form-control-lg"
                      onChange={(e) => handleChange(e, values, setValues)}
                      name="description"
                      value={values.description}
                    />
                    <FormHelperText>
                      <ErrorMessage
                        name="description"
                        render={(msg) => (
                          <span className="text-danger">{msg}</span>
                        )}
                      />
                    </FormHelperText>
                  </div>

                  <label className="col-lg-2 col-form-label text-lg-left">
                    Status
                  </label>
                  <div className="col-lg-4 col-xl-4 my-3">
                    <Switch
                      onChange={(e) => handleChange(e, values, setValues)}
                      name="status"
                      checked={
                        values.status === 'active' || values.status === true
                          ? true
                          : false
                      }
                      // checked={!!get(values, 'status')}
                    />
                    {values.status === true || values.status === 'active'
                      ? 'active'
                      : 'inactive'}
                    <FormHelperText>
                      <ErrorMessage
                        name="status"
                        render={(msg) => (
                          <span className="text-danger">{msg}</span>
                        )}
                      />
                    </FormHelperText>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-3"></div>
                  <div className="col-lg-9">
                    <button
                      type="submit"
                      className="btn btn-primary font-weight-bold mr-2">
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </>
  );
};

export default IsLoadingHOC(AddEdit, '..');
