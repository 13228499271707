import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Table, Button} from 'react-bootstrap';
import {Button as ButtonN} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Viewer from 'react-viewer';
import PdfReader from './PdfReader';
import {useSelector} from 'react-redux';
import moment from 'moment';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import Slider from 'react-slick';
import {saveAs} from 'file-saver';
export default function RightContainer({data}) {
  // console.log('right container', data);
  const [visible, setVisible] = useState(false);
  const [fullSize, setFullSize] = useState(false);
  // for slider
  const [zoomSlider, setZoomSlider] = useState(false);
  const [similarData, setSimilardata] = useState([
    {
      id: '123',
      imageUrl:
        'https://dev.userportal-terra.rsi-rnd.com/public/receipts/1651047750632_k1-1.jpg',
    },
    {
      id: '1223',
      imageUrl:
        'https://dev.userportal-terra.rsi-rnd.com/public/receipts/1647969802310_zoomreceipt_41.png',
    },
    {
      id: '1213',
      imageUrl:
        'https://dev.userportal-terra.rsi-rnd.com/public/receipts/1651047750632_k1-1.jpg',
    },
    {
      id: '1213',
      imageUrl:
        'https://dev.userportal-terra.rsi-rnd.com/public/receipts/1647969802310_zoomreceipt_41.png',
    },
    {
      id: '1213',
      imageUrl:
        'https://dev.userportal-terra.rsi-rnd.com/public/receipts/1651047750632_k1-1.jpg',
    },
  ]);
  const [selectedSlider, setSelectSlider] = useState([]);
  const [duplicateIds, setDuplicateIds] = useState([]);
  const {campaignId, tenantConfig} = useSelector((state) => state.auth);

  useEffect(() => {
    if (data?.similarReceipts) {
      setDuplicateIds(Object.keys(data.similarReceipts));
    }
  }, [data?.similarReceipts]);

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    setVisible(true);
  }, []);
  const selectSlider = (id, imageUrl) => {
    setZoomSlider(true);
    setSelectSlider([
      {
        src: imageUrl,
        alt: id,
      },
    ]);
  };
  const renderSimilarList = (similardata) => {
    for (let key in similardata) {
      return (
        <Paper
          key={key}
          onClick={() => selectSlider(key, similardata[key])}
          elevation={3}
          border={0}
          className={`rounded-0  d-flex flex-column justify-content-between align-items-center 
        }`}>
          <img
            src={similardata[key]}
            style={{height: '100px', cursor: 'pointer'}}
            className="w-100"
          />
        </Paper>
      );
    }
  };
  const download = (fileUrl, fileName) => {
    // window.location.href = fileUrl;
    saveAs(fileUrl, fileName);
  };
  const checkExcel =
    data?.imageUrl &&
    (data?.imageUrl.indexOf('.xlxs') != -1 ||
      data?.imageUrl.indexOf('.xls') != -1);
  // console.log('fadsf dfdsafadsf', data);
  return (
    <Container>
      <Paper
        elevation={3}
        border={0}
        className="p-6 mb-6 rounded-0 justify-content-center text-center ">
        <Row>
          <Col>
            <h3>Status: {data?.status} </h3>
          </Col>
        </Row>
        {data?.status === 'REJECTED' ? (
          <>
            {
              data.declineReason
                ? data.declineReason
                : data?.error?.length > 0 && (
                    // data?.error?.map((item) => {
                    //   return (
                    <Row>
                      <Col>
                        <h6>{data?.error[data.error.length - 1]?.message}</h6>
                      </Col>
                    </Row>
                  )
              //   );
              // })
            }
          </>
        ) : (
          <>
            {
              data?.status !== 'APPROVED' && data?.error?.length > 0 && (
                // data?.error?.map((item) => {
                //   return (
                <Row>
                  <Col>
                    <h6>{data?.error[data.error.length - 1]?.message}</h6>
                  </Col>
                </Row>
              )
              //   );
              // })
            }
          </>
        )}
      </Paper>

      <Paper elevation={3} border={0} className="rounded-0 show">
        <div id="inline-container-new" style={{position: 'relative'}}>
          <div
            className="react-viewer-fullscreen"
            onClick={() => {
              if (data?.imageUrl && data?.imageUrl.indexOf('.pdf') != -1) {
                window.open(data?.imageUrl, '_blank', 'fullscreen=yes');
              } else {
                setFullSize(true);
              }
            }}>
            <FullscreenIcon style={{color: '#000', fontSize: '50px'}} />
          </div>

          {/* <button
                  type="submit"
                  onClick={handleSubmit}
                  className="btn btn-primary mx-3">
                  Search
                </button> */}
        </div>
        {data?.imageUrl && data?.imageUrl.indexOf('.pdf') != -1 ? (
          <PdfReader filePath={data?.imageUrl} />
        ) : checkExcel ? (
          <Container style={{padding: '20px'}}>
            <h3>
              {`You can't view XLXS files here. Please Download from below button`}
            </h3>
            <div>
              {data?.imageUrl && (
                <ButtonN
                  variant="contained"
                  size="large"
                  className="rounded-0 ml-2"
                  onClick={() => download(data?.imageUrl, 'ReceiptFile')}
                  color="primary">
                  Export Receipt
                </ButtonN>
              )}
            </div>
          </Container>
        ) : fullSize ? (
          <Viewer
            zIndex={1100}
            visible={visible}
            onClose={() => {
              setFullSize(false);
            }}
            images={[
              {
                src: data?.imageUrl,
                alt: '',
              },
            ]}
          />
        ) : (
          <Viewer
            zIndex={90}
            noClose={true}
            fullscreen={true}
            container={document.getElementById('inline-container-new')}
            visible={visible}
            // onClose={() => {
            //   setVisible(false);
            // }}
            images={[
              {
                src: data?.imageUrl,
                alt: '',
              },
            ]}
          />
        )}

        {/* <button
          onClick={() => {
            setVisible(true);
          }}>
          show viewer
        </button> */}
      </Paper>
      <Paper
        elevation={3}
        border={0}
        className="p-6 pb-10 mt-6 rounded-0 d-flex ">
        <Container>
          <h3 className="align-self-center mb-3">Similar receipt</h3>
          <Slider
            {...settings}
            className="inner-class-div-sk"
            // afterChange={(index) => onChangeIndex(index)}
          >
            {data?.similarReceipts &&
              Object.keys(data.similarReceipts).map((key) => {
                return (
                  <Paper
                    key={key}
                    onClick={() => selectSlider(key, data.similarReceipts[key])}
                    elevation={3}
                    border={0}
                    className={`rounded-0  d-flex flex-column justify-content-between align-items-center 
                }`}>
                    <img
                      src={data.similarReceipts[key]}
                      style={{height: '100px', cursor: 'pointer'}}
                      className="w-100"
                      alt={key}
                    />
                  </Paper>
                );
              })}
            {/* {similarData?.map((item) => (
              <Paper
                key={item.id}
                onClick={() => selectSlider(item.id, item.imageUrl)}
                elevation={3}
                border={0}
                className={`rounded-0  d-flex flex-column justify-content-between align-items-center 
                }`}>
                <img
                  src={item.imageUrl}
                  style={{height: '100px', cursor: 'pointer'}}
                  className="w-100"
                />
              </Paper>
            ))} */}
          </Slider>
          {/* <p align="center" className='align-self-center'>
            {duplicateIds.join(", ")}
          </p> */}

          {zoomSlider && (
            <Viewer
              zIndex={1100}
              visible={visible}
              onClose={() => {
                setZoomSlider(false);
              }}
              images={selectedSlider}
            />
          )}
        </Container>
      </Paper>
      <Paper elevation={3} border={0} className="p-6 mt-6 rounded-0 d-flex ">
        <Container>
          <h3 className="align-self-center mb-3">Campaign Details</h3>
          {campaignId && (
            <Table striped bordered>
              <tbody>
                <tr>
                  <td>Campaign Name</td>
                  <td>{tenantConfig?.name}</td>
                </tr>
                <tr>
                  <td>Program Start Date</td>
                  <td>
                    {moment(tenantConfig?.activationStart).format('YYYY-MM-DD')}
                  </td>
                </tr>
                <tr>
                  <td>Program End Date</td>
                  <td>
                    {moment(tenantConfig?.activationEnd).format('YYYY-MM-DD')}
                  </td>
                </tr>
                <tr>
                  <td>Submission Dead Line</td>
                  <td>
                    {moment(tenantConfig?.completeEnd).format('YYYY-MM-DD')}
                  </td>
                </tr>
                <tr>
                  <td>Domain</td>
                  <td>{tenantConfig?.domain}</td>
                </tr>
                <tr>
                  <td>Receipt Id</td>
                  <td>{data?.id}</td>
                </tr>
                <tr>
                  <td>Validated By</td>
                  <td>{data?.validatedBy}</td>
                </tr>
                <tr>
                  <td>Validated On</td>
                  <td>
                    {moment(data?.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
                  </td>
                </tr>
                <tr>
                  <td>Submitted On</td>
                  <td>
                    {moment(data?.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
        </Container>
      </Paper>
    </Container>
  );
}
