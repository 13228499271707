import React, {useEffect, useState} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import {getPincodeReport} from '../_redux/action';
import {connect} from 'react-redux';
import filterFactory from 'react-bootstrap-table2-filter';
import filterFun from '../../../../_metronic/_helpers/FilterHelper';
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  headerSortingClasses,
  getHandlerTableChange,
} from '../../../../_metronic/_helpers';
import {Pagination} from '../../../../_metronic/_partials/controls';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';

const PincodeSummaryTable = ({
  getPincodeReport,
  setLoading,
  campaignId,
  packageCode,
}) => {
  const initialFilter = {
    page: 1,
    limit: 10,
    campaignId: campaignId,
    packageCode: packageCode,
  };
  const defaultSorted = [{dataField: 'id', order: 'asc'}];
  const sizePerPageList = [
    {text: '5', value: 5},
    {text: '10', value: 10},
    {text: '25', value: 25},
    {text: '50', value: 50},
    {text: '100', value: 100},
  ];
  const [queryParams, setQueryParams] = useState(initialFilter);
  const [responseData, setResponseData] = useState({
    totalCount: 0,
    entities: [],
  });
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      campaignId: campaignId,
      packageCode: packageCode,
    });
  }, [campaignId, packageCode]);
  useEffect(() => {
    if (queryParams.campaignId && queryParams.packageCode) {
      setLoading(true);
      getPincodeReport(queryParams)
        .then((res) => {
          setLoading(false);
          setResponseData({
            totalCount: res?.metaData?.totalRecs || 0,
            entities: res?.content || [],
          });
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  }, [queryParams]);

  // Table columns
  let columns = [
    {
      dataField: 'packageCode',
      text: 'PackageCode',
      headerSortingClasses,
      filter: filterFun(),
    },
    {
      dataField: 'firstName',
      text: 'FirstName',
    },
    {
      dataField: 'lastName',
      text: 'LastName',
    },
    {
      dataField: 'userName',
      text: 'UserName',
    },
    {
      dataField: 'redemptionEmail',
      text: 'Email',
      headerSortingClasses,
    },
    {
      dataField: 'redemptionDate',
      text: 'RedemptionDate',
      formatter: (data, row) => {
        return new Date(row.redemptionDate).toLocaleDateString();
      },
      headerSortingClasses,
    },
    {
      dataField: 'points',
      text: 'Points',
      headerSortingClasses,
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: responseData.totalCount || 0,
    sizePerPageList: sizePerPageList,
    sizePerPage: queryParams.limit,
    page: queryParams.page,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({paginationProps, paginationTableProps}) => {
          return (
            <Pagination
              // isLoading={listLoading}
              paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive text-nowrap"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="rewardCode"
                data={
                  !responseData.entities
                    ? []
                    : responseData.entities.map((item) => {
                        item.redeemed = item.redeemed ? 'Yes' : 'No';

                        return item;
                      })
                }
                columns={columns}
                filter={filterFactory()}
                onTableChange={getHandlerTableChange(setQueryParams)}
                defaultSorted={defaultSorted}
                {...paginationTableProps}>
                <PleaseWaitMessage entities={responseData.entities} />
                <NoRecordsFoundMessage entities={responseData.entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};
export default connect(null, {getPincodeReport})(
  IsLoadingHOC(PincodeSummaryTable),
);
