const initialValues = {
  id: '',
  clientId: '',
  accuracy: 0,
  date: '',
  userId: '',
  status: '',
  imageUrl: '',
  imageName: '',
  callbackURL: '',
  declineReason: '',
  processedBy: '',
  md5Hash: '',
  adminUserId: '',
  campaignId: '',
  callbackSent: 0,
  uploadedBy: '',
  batchId: '',
  jobId: '',
  isJobProcessed: 1,
  userInfo: {
    userId: null,
    firstName: '',
    lastName: '',
    email: '',
    receiptUserId: '',
  },
  receiptUploadEmail: '',
  document: {
    id: null,
    receiptId: '',
    storeName: '',
    amount: 0,
    memberId: '',
    receiptDate: new Date(),
    qualifyingAmount: 0,
    postal: '',
    lines: '',
    products: [],
  },
  active: true,
  additionalInfo: {
    submissionType: ''
  }
};

const initialValuesForSearch = {
  receiptId: '',
  firstName: '',
  lastName: '',
  email: '',
  storeName: '',
  status: '',
  amount: {
    from: '',
    to: '',
  },
  submissionDateFrom: '',
  submissionDateTo: '',
  receiptDateFrom: '',
  receiptDateTo: '',
  amountFrom: '',
  amountTo: '',
  uuid: '',
};

const updateValuesFilter = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  campaignId: '',
  imageUrl: null,
  declineReason: null,
  storeName: null,
  receiptId: '',
  amount: 0,
  qualifyingAmount: 0.0,
  postal: null,
  tax: 0.0,
  receiptDate: 'null',
  date: 1645525605628,
  products: [],
  ocrData: '',
  batchId: null,
  error: [],
};

const limit = 8;

export { initialValues, initialValuesForSearch, limit, updateValuesFilter };
