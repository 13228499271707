import React, {useMemo} from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import {TemplateFilter} from '../component/TemplateFilter';
// import { CustomersFilter } from "./customers-filter/CustomersFilter";
import TemplateTable from '../component/TemplateTable';
// import { CustomersGrouping } from "./customers-grouping/CustomersGrouping";
import {useTemplateUIContext} from '../TemplateUIContext';

export function TemplateList() {
  const templateUIContext = useTemplateUIContext();
  //console.log('user context', customersUIContext);
  const templateUIProps = useMemo(() => {
    return {
      ids: templateUIContext.ids,
      newTemplateButtonClick: templateUIContext.newTemplateButtonClick,
    };
  }, [templateUIContext]);

  return (
    <Card>
      <CardHeader title="Email Templates">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={templateUIContext.newTemplateButtonClick}>
            New Template
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <TemplateFilter /> */}
        {/* <CustomersFilter />
        {templateUIProps.ids.length > 0 && <CustomersGrouping />} */}
        <TemplateTable />
      </CardBody>
    </Card>
  );
}
