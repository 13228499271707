import api from '../../../../api';
import {SAVE_REWARDSUMMARY_LIST, LOADING} from './type';
import {toast} from 'react-toastify';
export const getRewardSummaryList = (values) => async (dispatch) => {
  // console.log('values', values);
  const newQuery = {...values, page: values.page - 1};
  let queryString = Object.keys(newQuery)
    .map((key) => key + '=' + newQuery[key])
    .join('&');
  // console.log('reward summary page isssd==================>');
  return new Promise(async (resolve, reject) => {
    let data = await api.get(`/s1/reward/summary?${queryString}`);
    if (data.httpCode === 200) {
      if (typeof data.data === 'object') {
        dispatch({type: SAVE_REWARDSUMMARY_LIST, payload: data.data});
      }
      resolve(data.data);
    } else {
      // reject('error');
      dispatch({type: LOADING, payload: false});
      dispatch({
        type: SAVE_REWARDSUMMARY_LIST,
        payload: {
          content: [],
          metaData: {
            currentPageNo: 0,
            limit: 10,
            nextPageNo: 0,
            totalRecs: 0,
            tokenAvailable: false,
          },
        },
      });
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }

      reject(data?.message);
    }
  });
  // });
};

export const getPincodeReport = (values) => async (dispatch) => {
  const newQuery = {...values, page: values.page - 1};
  let queryString = Object.keys(newQuery)
    .map((key) => key + '=' + newQuery[key])
    .join('&');

  return new Promise(async (resolve, reject) => {
    let data = await api.get(`/s1/pincode/summary?${queryString}`);
    if (data.httpCode === 200) {
      resolve(data.data);
      dispatch({type: LOADING, payload: false});
    } else {
      reject(data.message);
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }
      dispatch({type: LOADING, payload: false});
    }
  });
  // });
};
