import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import ErrorBoundary from '../../../../../_metronic/_helpers/ErrorBoundary';

export default function Logs({show, onHide, events}) {
  // const [show, setShow] = useState(false);
  const logs = (item) => {
    let string = '';
    item.forEach((item) => {
      string +=
        ' \n String Name : ' +
        item.logStreamName +
        '\n' +
        'Message : ' +
        item.message +
        '\n' +
        'Time Stamp : ' +
        item.timestamp;
    });
    // console.log('string', string);
    return string;
  };
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title">
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Execution Logs
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="console">
          <div className="console-inner">
            <div id="outputs"></div>
            <div className="output-cmd">
              <ErrorBoundary>
                {/* <textarea
                  autoFocus
                  className="console-input"
                  placeholder="Type command...">
                  {events.length > 0 && logs(events)}
                </textarea> */}
                {events.length > 0 && logs(events)}
              </ErrorBoundary>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          onClick={onHide}
          className="btn btn-light btn-elevate">
          Close
        </button>
        <> </>
      </Modal.Footer>
    </Modal>
  );
}
