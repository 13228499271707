import api from '../../../../api';
import {
  SAVE_RULE_LIST,
  LOADING,
  RULE_FOR_EDIT,
  UPDATE_RULE,
  RULE_DELETE,
} from './type';
import {toast} from 'react-toastify';

export const getRuleList = (query) => async (dispatch) => {
  const newQuery = {...query, page: query.page - 1};
  let queryString = Object.keys(newQuery)
    .map((key) => key + '=' + newQuery[key])
    .join('&');

  //console.log('query string', queryString, newQuery);
  //return new Promise(async (resolve, reject) => {
  dispatch({type: LOADING, payload: true});
  let data = await api.get(`/s1/rule?${queryString}`);
  if (data.httpCode === 200) {
    // resolve(data.message);
    dispatch({type: LOADING, payload: false});
    dispatch({type: SAVE_RULE_LIST, payload: data.data});
  } else {
    // reject(data.message);
    dispatch({type: SAVE_RULE_LIST, payload: []});
    if (data?.errors) {
      if (Array.isArray(data.errors)) {
        data.errors.map((item) => {
          toast.error(item?.message);
        });
      }
    }

    dispatch({type: LOADING, payload: false});
  }
  // });
};

export const addRuleItem = (values) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.post('/s1/rule', values);
    if (data.httpCode === 201) {
      resolve(data.message);
      //dispatch({type: SAVE_RULE_LIST, payload: data.data});
    } else {
      reject(data?.errors);
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }
    }
  });
};

export const updateRule = (id, rule) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.put(`/s1/rule/${id}`, rule);
    if (data.httpCode === 200) {
      resolve(data.message);
      dispatch({type: UPDATE_RULE, payload: rule});
    } else {
      reject(data?.errors);
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }
    }
  });
};

export const fetchRule = (id) => async (dispatch) => {
  if (!id) {
    return dispatch({type: RULE_FOR_EDIT, payload: undefined});
  }
  dispatch({type: LOADING, payload: true});
  return new Promise(async (resolve, reject) => {
    let data = await api.get(`/s1/rule/${id}`);
    if (data.httpCode === 200) {
      resolve(data.message);
      // console.log('data.data', data.data);
      dispatch({type: LOADING, payload: false});
      dispatch({type: RULE_FOR_EDIT, payload: data.data});
    } else {
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }
      dispatch({type: LOADING, payload: false});
    }
  });
};

export const deleteRuleById = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.delete(`/s1/rule/${id}`);
    if (data.httpCode === 200) {
      resolve(data.message);
      dispatch({type: RULE_DELETE, payload: id});
    } else {
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }
    }
  });
};
