import React, {createContext, useContext, useState, useCallback} from 'react';
import {isEqual, isFunction} from 'lodash';
import {initialFilter} from './ReceiptSummaryUIHelpers';

const ReceiptSummaryUIContext = createContext();

export function useReceiptSummaryUIContext() {
  console.log(
    'useContext(ReceiptSummaryUIContext);',
    useContext(ReceiptSummaryUIContext),
  );
  return useContext(ReceiptSummaryUIContext);
}

export const ReceiptSummaryUIConsumer = ReceiptSummaryUIContext.Consumer;

export function ReceiptSummaryUIProvider({receiptSummaryUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
        // console.log('nextQueryparams', nextQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initReceiptSummary = {
    id: undefined,
    title: '',
    actionType: '',
    points: '',
    maxAttempt: '',
    enabled: '',
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initReceiptSummary,
    openDeleteReceiptSummaryDialog:
      receiptSummaryUIEvents.openDeleteReceiptSummaryDialog,
  };

  //console.log('value', value);

  return (
    <ReceiptSummaryUIContext.Provider value={value}>
      {children}
    </ReceiptSummaryUIContext.Provider>
  );
}
