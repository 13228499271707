import React, {useState} from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import RetailersTable from '../component/RetailersTable';
import {Row, Col} from 'react-bootstrap';
import {useSelector, shallowEqual, connect} from 'react-redux';
import ChartComponent from '../component/ChartComponent';
import ListComponent from '../component/ListComponent';
import DatePicker from 'react-datepicker';

import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {getRetailersExportData} from '../_redux/action';
import useFormatDate from '../../../../_metronic/hooks/useFormatDate';
import {RetailersFilters} from '../component/RetailersFilter';

const RetailersList = ({getRetailersExportData, setLoading}) => {
  const {activationEnd, activationStart} = useSelector(
    (state) => state.auth.tenantConfig,
  );
  const [startDate, setStartDate] = useState(new Date(activationStart));
  const [endDate, setEndDate] = useState(new Date(activationEnd));
  const {currentState} = useSelector(
    (state) => ({currentState: state.retailers}),
    shallowEqual,
  );
  const {entities} = currentState;
  const listArray = entities?.length ? entities.slice(0, 5) : [];
  const startD = useFormatDate(startDate);
  const endD = useFormatDate(endDate);
  const downloadFile = ({data, fileName, fileType}) => {
    const blob = new Blob([data], {type: fileType});

    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = async (e) => {
    e.preventDefault();

    // Headers for each column
    let headers = [
      'RETAILER NAME, BASKET SIZE AMOUNT,  QUALIFYING AMOUNT, COUNT',
    ];
    setLoading(true);

    const users = await getRetailersExportData({
      startDate: startD,
      endDate: endD,
      type: 'download',
    });

    setLoading(false);
    if (users?.length > 0) {
      let usersCsv = users?.reduce((acc, user) => {
        const {_id, basketSizeAmount, qualifyingAmount, count} = user;
        acc.push([_id, basketSizeAmount, qualifyingAmount, count].join(','));
        return acc;
      }, []);

      downloadFile({
        data: [...headers, ...usersCsv].join('\n'),
        fileName: 'retailers.csv',
        fileType: 'text/csv',
      });
    }
  };

  return (
    <>
      <Row>
        <Col>
          <div className={`card card-custom gutter-b`}>
            {/* begin::Header */}
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  Please Select the Date Range
                </span>
              </h3>
              <div className="d-flex  pl-5 flex-row">
                <div className="mr-3">
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    className="form-control form-control-md form-control-solid "
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    showYearDropdown
                    enableTabLoop
                    dropdownMode="select"
                  />
                </div>
                <div className="mr-3">
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    className="form-control form-control-md form-control-solid"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    showYearDropdown
                    enableTabLoop
                    dropdownMode="select"
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Card>
        <CardHeader title="Retailers">
          <CardHeaderToolbar>
            <button
              className="btn btn-primary"
              type="button"
              onClick={exportToCsv}>
              Export to CSV
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <RetailersFilters />
          {/* <CustomersFilter />
        {RetailersUIProps.ids.length > 0 && <CustomersGrouping />} */}
          <RetailersTable startDate={startDate} endDate={endDate} />
        </CardBody>
      </Card>
      {listArray?.map((item) => {
        return (
          <Row key={item.count + item.retailerName}>
            <Col>
              <div className={`card card-custom gutter-b`}>
                {/* begin::Header */}
                <div className="card-header" bis_skin_checked="1">
                  <div className="card-title" bis_skin_checked="1">
                    <h3 className="card-label">{item.retailerName}</h3>
                  </div>
                </div>
                <ChartComponent
                  startDate={startDate}
                  endDate={endDate}
                  retailerName={item.retailerName}
                />
              </div>
            </Col>
            <Col>
              <ListComponent
                startDate={startDate}
                endDate={endDate}
                retailerName={item.retailerName}
              />
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default connect(null, {getRetailersExportData})(
  IsLoadingHOC(RetailersList),
);
