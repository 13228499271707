import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import CommanChart from '../../../../_metronic/_partials/charts/CommanChart';
import {getRetailerData} from '../_redux/action';
import moment from 'moment';

const ChartComponent = ({
  retailerName,
  startDate,
  endDate,
  getRetailerData,
}) => {
  const [result, setResult] = useState();
  useEffect(() => {
    const newQuery = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      storeName: retailerName,
      type: 'Chart',
    };
    getRetailerData(newQuery)
      .then((res) => {
        setResult(res);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, [startDate, endDate]);

  return (
    <>
      {result?.type ? (
        <CommanChart
          type={result.type}
          data={result.data}
          options={result?.options}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default connect(null, {getRetailerData})(IsLoadingHOC(ChartComponent));
