import React, {useMemo, useEffect} from 'react';
import objectPath from 'object-path';
import {useHtmlClassService} from '../../layout';
// import {Demo1Dashboard} from './Demo1Dashboard';
// import {Demo2Dashboard} from './Demo2Dashboard';
// import {Demo3Dashboard} from './Demo3Dashboard';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Typography} from '@material-ui/core';
import {ReportingList} from './ReportingList';
import ChartsWidget from './ChartsWidget';
import {useSelector} from 'react-redux';
import SummeryData from './component/SummeryData';
import TrafficGoogleAnalytics from './component/TrafficGoogleAnalytics';
import EarnBarn from './component/EarnBarn';
import './styles.css';
import jwt_decode from 'jwt-decode';

function TabPanel(props) {
  const {children, value, index, ...other} = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export function Dashboard() {
  const uiService = useHtmlClassService();
  const [value, setValue] = React.useState(0);
  const {campaignId, accessToken} = useSelector((state) => state.auth);
  let decoded = jwt_decode(accessToken);
  const renderError = () => {
    if (campaignId === '' || campaignId === null || campaignId === undefined) {
      return (
        <Typography variant="h5" component="div" className="m-5">
          Please select campaign first.
        </Typography>
      );
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const layoutProps = useMemo(() => {
    return {
      demo: objectPath.get(uiService.config, 'demo'),
    };
  }, [uiService]);

  return (
    <>
      <Box sx={{width: '100%', typography: 'body1'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example">
          <Tab label="Summary" {...a11yProps(0)} />
          {!decoded?.roles?.includes('DASHBOARD') && (
            <Tab label="Charts" {...a11yProps(1)} />
          )}
          {!decoded?.roles?.includes('DASHBOARD') && (
            <Tab label="Campaign Reporting" {...a11yProps(2)} />
          )}
          {!decoded?.roles?.includes('DASHBOARD') && (
            <Tab label="Traffic" {...a11yProps(3)} />
          )}
          {!decoded?.roles?.includes('DASHBOARD') && (
            <Tab label="Earn/Burn" {...a11yProps(4)} />
          )}
          {decoded?.roles?.includes('ADMIN') && (
            <Tab label="Reporting Details" {...a11yProps(5)} />
          )}
        </Tabs>
        {renderError()}
        {campaignId && (
          <>
            <TabPanel value={value} index={0}>
              <SummeryData />
            </TabPanel>
            {!decoded?.roles?.includes('DASHBOARD') && (
              <>
                <TabPanel value={value} index={1}>
                  <ChartsWidget />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <ReportingList url="report" />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <TrafficGoogleAnalytics />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <EarnBarn />
                </TabPanel>
                {decoded?.roles?.includes('ADMIN') && (
                  <TabPanel value={value} index={5}>
                    <ReportingList url="admin-report" />
                  </TabPanel>
                )}
              </>
            )}
          </>
        )}
      </Box>

      {/* {layoutProps.demo === 'demo1' && <h2>Coming Soon...</h2>} */}
      {/* {layoutProps.demo === 'demo1' && <Demo1Dashboard />} */}
      {/* {layoutProps.demo === 'demo2' && <Demo2Dashboard />}
        {layoutProps.demo === 'demo3' && <Demo3Dashboard />}
        {layoutProps.demo === 'demo4' && <Demo4Dashboard />}
        {layoutProps.demo === 'demo5' && <Demo5Dashboard />}
        {layoutProps.demo === 'demo6' && <Demo6Dashboard />}
        {layoutProps.demo === 'demo7' && <Demo7Dashboard />} */}
    </>
  );
}
