import React, {useState} from 'react';
import {useFormik} from 'formik';
import {connect} from 'react-redux';
import {Link, Redirect, useLocation, useHistory} from 'react-router-dom';
import * as Yup from 'yup';
import {injectIntl} from 'react-intl';
import * as auth from '../_redux/authRedux';
import {resetPassword} from '../_redux/authCrud';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {toast} from 'react-toastify';
const initialValues = {
  email: '',
};

const ResetPassword = ({intl, setLoading, resetPassword}) => {
  const [isRequested, setIsRequested] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const token = new URLSearchParams(location.search).get('token');

  const ForgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(),
    passwordConfirm: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match',
    ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      // console.log("set askjjdslfjasd", values);
      setLoading(true);
      resetPassword(token, values.password)
        .then((res) => {
          setLoading(false);
          toast.success('Password is changed successfully');

          history.push('/auth/login');
        })
        .catch((errors) => {
          //setIsRequested(false);
          setSubmitting(false);
          setLoading(false);
          if (errors) {
            if (Array.isArray(errors)) {
              errors.map((item) => {
                toast.error(item?.message);
              });
            }
          }
          // setStatus(
          //   intl.formatMessage(
          //     {id: 'AUTH.VALIDATION.NOT_FOUND'},
          //     {name: values.email},
          //   ),
          // );
        });
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{display: 'block'}}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Reset Password ?</h3>
            <div className="text-muted font-weight-bold">
              Enter your new password
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp">
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <label htmlFor="password">Enter Password</label>
              <input
                type="password"
                id="password"
                className={`form-control form-control-solid h-auto py-5 px-6 `}
                name="password"
                {...formik.getFieldProps('password')}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <label htmlFor="passwordConfirm">Confirm Password</label>
              <input
                type="password"
                id="passwordConfirm"
                className={`form-control form-control-solid h-auto py-5 px-6 `}
                name="passwordConfirm"
                {...formik.getFieldProps('passwordConfirm')}
              />
              {formik.touched.passwordConfirm &&
              formik.errors.passwordConfirm ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.passwordConfirm}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}>
                Submit
              </button>
              {/* <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">
                  Cancel
                </button>
              </Link> */}
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default injectIntl(
  connect(null, {resetPassword})(IsLoadingHOC(ResetPassword)),
);
