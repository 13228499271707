import React, {createContext, useContext, useState, useCallback} from 'react';
import {isEqual, isFunction} from 'lodash';
import {initialFilter} from './RetailersUIHelpers';

const RetailersUIContext = createContext();

export function useRetailersUIContext() {
  console.log(
    'useContext(RetailersUIContext);',
    useContext(RetailersUIContext),
  );
  return useContext(RetailersUIContext);
}

export const RetailersUIConsumer = RetailersUIContext.Consumer;

export function RetailersUIProvider({retailersUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
        // console.log('nextQueryparams', nextQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initRetailers = {
    id: undefined,
    title: '',
    actionType: '',
    points: '',
    maxAttempt: '',
    enabled: '',
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initRetailers,
    newRetailersButtonClick: retailersUIEvents.newRetailersButtonClick,
    openEditRetailersDialog: retailersUIEvents.openEditRetailersDialog,
    openDeleteRetailersDialog: retailersUIEvents.openDeleteRetailersDialog,
    // openDeleteCustomersDialog: customersUIEvents.openDeleteCustomersDialog,
    // openFetchCustomersDialog: customersUIEvents.openFetchCustomersDialog,
    // openUpdateCustomersStatusDialog:
    //   customersUIEvents.openUpdateCustomersStatusDialog,
  };

  return (
    <RetailersUIContext.Provider value={value}>
      {children}
    </RetailersUIContext.Provider>
  );
}
