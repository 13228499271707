import {textFilter} from 'react-bootstrap-table2-filter';
const filterFun = function (object) {
  return textFilter({
    placeholder: object?.placeholder || 'Search', // custom the input placeholder
    //className: 'my-custom-text-filter', // custom classname on input
    defaultValue: object?.defaultValue || '', // default filtering value
    // comparator: Comparator.EQ, // default is Comparator.LIKE
    caseSensitive: object?.delay || true, // default is false, and true will only work when comparator is LIKE
    //style: {backgroundColor: 'yellow'}, // your custom inline styles on input
    delay: object?.delay || 1000, // how long will trigger filtering after user typing, default is 500 ms
    // onClick: (e) => console.log(e),
    // getFilter: (filter) => {
    // nameFilter was assigned once the component has been mounted.
    // nameFilter = filter;
    // },
    // onFilter: (filterValue) => {
    //   userUIProps.queryParams = {...userUIProps, username: filterValue};
    //   console.log('filter values', filterValue);
    // },
  });
};

export default filterFun;
