export const RuleStatusCssClasses = ['danger', 'success', 'info', ''];
export const RuleStatusTitles = ['Suspended', 'Active', 'Pending', ''];
export const RuleTypeCssClasses = ['success', 'primary', ''];
export const RuleTypeTitles = ['Business', 'Individual', ''];
export const defaultSorted = [{dataField: '_id', order: 'asc'}];
export const sizePerPageList = [
  {text: '5', value: 5},
  {text: '10', value: 10},
  {text: '25', value: 25},
  {text: '50', value: 50},
  {text: '100', value: 100},
];
export const initialFilter = {
  // filter: {
  //   title: '',
  //   actionType: '',
  //   points: '',
  //   enabled: '',
  // },
  // sortOrder: 'asc', // asc||desc
  // sortField: '_id',
  page: 1,
  limit: 10,
};
