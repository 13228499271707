import React from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { injectIntl } from 'react-intl';
import IsLoadingHOC from '../../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import { toast } from 'react-toastify';
import ErrorBoundary from '../../../../../_metronic/_helpers/ErrorBoundary';
import { changePasswordAction } from '../../_redux/action';
const initialValues = {
  email: '',

};

export const ChangePassword = ({ intl, setLoading, changePasswordAction }) => {
  const history = useHistory();

  const ChangePasswordSchema = Yup.object().shape({
    passwordOld: Yup.string()
      .min(8, 'Minimum 8 letters or symbols')
      .required("please provide valid New password"),
    passwordNew: Yup.string()
      .min(8, 'Minimum 8 letters or symbols')
      .required("please provide valid New password"),
    passwordConfirm: Yup.string().oneOf(
      [Yup.ref('passwordNew'), null],
      'new password and confirm password must match',
    ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ChangePasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      changePasswordAction(values)
        .then((res) => {
          if (res) {
            toast.success(`you have changed password successfully`);
            setLoading(false);
            history.push('/dashboard');
          }

        })
        .catch((errors) => {
          setSubmitting(false);
          setLoading(false);
          if (errors) {
            if (Array.isArray(errors)) {
              errors.map((item) => {
                toast.error(item?.message);
              });
            }
          }
        });
    },
  });

  return (
    <>
      <ErrorBoundary>
        <div className="login-form login-forgot" style={{ display: 'block' }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Change Password ?</h3>
            <div className="text-muted font-weight-bold">
              Enter your new password
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp">
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <label htmlFor="passwordOld">Enter Old Password</label>
              <input
                type="password"
                id="passwordOld"
                className={`form-control form-control-solid h-auto py-5 px-6 `}
                name="passwordOld"
                {...formik.getFieldProps('passwordOld')}
              />
              {formik.touched.passwordOld && formik.errors.passwordOld ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.passwordOld}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <label htmlFor="passwordNew">Enter New Password</label>
              <input
                type="password"
                id="passwordNew"
                className={`form-control form-control-solid h-auto py-5 px-6 `}
                name="passwordNew"
                {...formik.getFieldProps('passwordNew')}
              />
              {formik.touched.passwordNew && formik.errors.passwordNew ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.passwordNew}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <label htmlFor="passwordConfirm">Confirm Password</label>
              <input
                type="password"
                id="passwordConfirm"
                className={`form-control form-control-solid h-auto py-5 px-6 `}
                name="passwordConfirm"
                {...formik.getFieldProps('passwordConfirm')}
              />
              {formik.touched.passwordConfirm &&
                formik.errors.passwordConfirm ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.passwordConfirm}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </ErrorBoundary>
    </>
  );
};

export default injectIntl(
  connect(null, { changePasswordAction })(IsLoadingHOC(ChangePassword)),
);
