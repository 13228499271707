import api from '../../../../api';
import {
  SAVE_ROLE_LIST,
  LOADING,
  ROLE_FOR_EDIT,
  UPDATE_ROLE,
  ROLE_DELETE,
} from './type';
import {toast} from 'react-toastify';

export const getRoleList = (query) => async (dispatch) => {
  const newQuery = {...query, page: query.page - 1};
  let queryString = Object.keys(newQuery)
    .map((key) => key + '=' + newQuery[key])
    .join('&');

  //console.log('query string', queryString, newQuery);
  //return new Promise(async (resolve, reject) => {
  dispatch({type: LOADING, payload: true});
  let data = await api.get(`/s1/iam/list-roles?${queryString}`);
  if (data.httpCode === 200) {
    // resolve(data.message);
    dispatch({type: LOADING, payload: false});
    dispatch({type: SAVE_ROLE_LIST, payload: data.data});
  } else {
    // reject(data.message);
    dispatch({type: SAVE_ROLE_LIST, payload: []});
    if (data?.errors) {
      if (Array.isArray(data.errors)) {
        data.errors.map((item) => {
          toast.error(item?.message);
        });
      }
    }

    dispatch({type: LOADING, payload: false});
  }
  // });
};

export const getIAMPoliciesList = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.get('/s1/iam/list-policies');
    if (data.httpCode === 200) {
      resolve(data.data);
      // dispatch({type: LOADING, payload: false});
      // dispatch({type: SAVE_ROLE_LIST, payload: data.data});
    } else {
      reject(data?.errors);
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }
    }
  });

  // });
};

export const addRoleItem = (values) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.post('/s1/iam/create-role', values);
    if (data.httpCode === 201) {
      resolve(data.message);
      //dispatch({type: SAVE_ROLE_LIST, payload: data.data});
    } else {
      reject(data?.errors);
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }
    }
  });
};

export const updateRole = (role) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.post(`/s1/iam/attach-detach-policy`, role);
    if (data.httpCode === 200) {
      resolve(data.message);
      dispatch({type: UPDATE_ROLE, payload: role});
    } else {
      reject(data?.errors);
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }
    }
  });
};

export const fetchRole = (name) => async (dispatch) => {
  if (!name) {
    return dispatch({type: ROLE_FOR_EDIT, payload: undefined});
  }
  dispatch({type: LOADING, payload: true});
  return new Promise(async (resolve, reject) => {
    let data = await api.post('/s1/iam/role-attached-policies', {
      roleName: name,
    });
    if (data.httpCode === 200) {
      resolve(data.data);
      // console.log('data.data', data.data);
      // dispatch({type: LOADING, payload: false});
      // dispatch({type: ROLE_FOR_EDIT, payload: data.data});
    } else {
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }
      dispatch({type: LOADING, payload: false});
    }
  });
};

export const deleteRoleById = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.delete(`/s1/role/${id}`);
    if (data.httpCode === 200) {
      resolve(data.message);
      dispatch({type: ROLE_DELETE, payload: id});
    } else {
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }
    }
  });
};
