import {combineReducers} from 'redux';

const loading = (state = false, action) => {
  switch (action.type) {
    case 'LOADING': {
      return action.payload || false;
    }
    case 'SET_QUESTION':
      return false;
    default:
      return state;
  }
};

const initialState = {
  entities: [],
  meta: {limit: 10, currentPageNo: 0, totalRecs: 10, nextPageNo: 1},
};

const list = (
  state = {
    ...initialState,
  },
  action,
) => {
  switch (action.type) {
    case 'INIT_TAB': {
      return {...initialState, entities: []};
    }
    case 'SET_QUESTION':
      return {
        entities: action.entities,
        meta: action.meta,
      };
    default:
      return state;
  }
};

export default combineReducers({
  loading,
  list,
});
