import api from '../../../../api';

export const changePasswordAction = (values) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      let data = await api.post(`/s1/identities/change-user-password`, {
        currentPassword:values.passwordOld,
        newPassword:values.passwordNew,
        newPasswordConfirm:values.passwordConfirm
      });
      if ( data.httpCode >= 200 && data.httpCode <= 299) {
        resolve(data.message);
      } else { 
        reject(data.errors);
      }
    });
  };