import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Formik, Form, Field, ErrorMessage, FieldArray} from 'formik';
import {Modal} from 'react-bootstrap';
import * as Yup from 'yup';
import {useLocation} from 'react-router';
import {Spinner} from 'react-bootstrap';

import {Input} from '../../../../../_metronic/_partials/controls';
import FileManagerModel from '../../../FileManager/index';
import fmService from '../../../FileManager/FileManager.Service';
import {executeProcess} from '../../_redux/action';
import {toast} from 'react-toastify';
// Validation schema
const ExcuteProcessModelSchema = Yup.object({
  params: Yup.array().of(
    Yup.object().shape({
      value: Yup.string().required('Value is required'),
    }),
  ),
});
const ExcecuteForm = ({executeProcess, id, onHide}) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showTextFM, setShowTextFM] = useState(false);
  const [initValues, setInitValues] = useState({
    params: location.state?.params ? location.state?.params : [],
  });

  const showPopupFM = () => {
    setShowTextFM(!showTextFM);
  };

  const getStateFromChild = () => {
    // setShowFM(false);
    setShowTextFM(false);
  };
  const elechange = (e) => {
    // setFilepath(e.target.value);
    console.log('e.target.value', e.target.value);
  };
  const handleFiles = (param, field, values, setValues, key) => {
    // update dynamic form
    // console.log('values', values, param.path);
    // console.log('key', key);
    const updatedValues = values.params.map((item) => {
      if (item.key === key) {
        return {...item, value: fmService.getPathWithoutTenentID(param.path)};
      }
      return item;
    });

    setValues({...values, params: updatedValues});

    // call formik onChange method
    // field.onChange(e);
  };

  const executeThis = (values) => {
    //console.log('values', values);
    const finalRequest = {processId: id, ...values};
    setLoading(true);
    //  dispatch(
    executeProcess(finalRequest)
      .then((res) => {
        setLoading(false);
        toast.success('Process completed');
      })
      .catch((err) => {
        console.error('err', err);
        setLoading(false);
      });
    // );
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initValues}
      validationSchema={ExcuteProcessModelSchema}
      onSubmit={(values) => {
        executeThis(values);
      }}>
      {({handleSubmit, values, errors, touched, setValues, field}) => (
        <>
          <Modal.Body className="overlay overlay-block cursor-default">
            <FieldArray name="params">
              {() =>
                values?.params.length > 0 &&
                values.params.map((paramsField, i) => {
                  const paramsFieldErrors =
                    (errors.params?.length && errors.params[i]) || {};
                  const paramsFieldTouched =
                    (touched.params?.length && touched.params[i]) || {};
                  if (paramsField.type === 'file_upload') {
                    return (
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-left key={i}">
                          {paramsField.key}:
                        </label>
                        <div className="col-lg-9 col-xl-4">
                          <Field
                            className={
                              'form-control' +
                              (paramsFieldErrors.value &&
                              paramsFieldTouched.value
                                ? ' is-invalid'
                                : '')
                            }
                            name={`params[${i}].value`}
                            // component={Input}
                            placeholder={paramsField.value}
                            // label={paramsField.key}
                            //  value={paramsField.value}
                            onFocus={showPopupFM}
                            onChange={elechange}
                            disabled={
                              paramsField.type === 'constant' ? true : false
                            }
                          />
                          {showTextFM ? (
                            <FileManagerModel
                              parentCallback={getStateFromChild}
                              // getSelectedFilesOnParent={selectedFile}
                              getSelectedFilesOnParent={(param) =>
                                handleFiles(
                                  param,
                                  field,
                                  values,
                                  setValues,
                                  paramsField.key,
                                )
                              }
                            />
                          ) : (
                            ''
                          )}
                          <ErrorMessage
                            name={`params.${i}.value`}
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                    );
                  }
                  return (
                    <div className="form-group row" key={i}>
                      <label className="col-lg-3 col-form-label text-lg-left key={i}">
                        {paramsField.key}:
                      </label>
                      <div className="col-lg-9 col-xl-4">
                        <Field
                          className={
                            'form-control' +
                            (paramsFieldErrors.value && paramsFieldTouched.value
                              ? ' is-invalid'
                              : '')
                          }
                          name={`params.${i}.value`}
                          //component={Input}
                          placeholder={paramsField.value}
                          // label={paramsField.key}
                          //  value={paramsField.value}
                          disabled={
                            paramsField.type === 'constant' ? true : false
                          }
                        />
                        <ErrorMessage
                          name={`params.${i}.value`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  );
                })
              }
            </FieldArray>

            {/* </Form> */}
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate">
              Cancel
            </button>
            <> </>
            <button
              type="submit"
              onClick={handleSubmit}
              className={`btn btn-primary font-weight-bold mr-2`}
              disabled={loading}>
              {loading && (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {loading ? 'Executing ...' : 'Execute'}
            </button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
};

export default connect(null, {executeProcess})(ExcecuteForm);
