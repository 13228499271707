// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, {useEffect, useMemo} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
//import * as actions from '../../../_redux/customers/customersActions';
import {getRetailersList} from '../_redux/action';
import {connect} from 'react-redux';
import {Row, Col} from 'react-bootstrap';
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from '../../../../_metronic/_helpers';
import * as uiHelpers from '../RetailersUIHelpers';
//import * as columnFormatters from './ActionsColumnFormatter';
import {Pagination} from '../../../../_metronic/_partials/controls';
// import {entities} from '../_mock';
import {useRetailersUIContext} from '../RetailersUIContext';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import useFormatDate from '../../../../_metronic/hooks/useFormatDate';

const RetailersTable = ({getRetailersList, setLoading, startDate, endDate}) => {
  // Retailers UI Context
  const retailersUIContext = useRetailersUIContext();
  //console.log('redfjadsl ', retailersUIContext);

  const retailersUIProps = useMemo(() => {
    return {
      ids: retailersUIContext.ids,
      setIds: retailersUIContext.setIds,
      queryParams: retailersUIContext.queryParams,
      setQueryParams: retailersUIContext.setQueryParams,
    };
  }, [retailersUIContext]);

  // Getting curret state of retailers list from store (Redux)
  const {currentState} = useSelector(
    (state) => ({currentState: state.retailers}),
    shallowEqual,
  );
  const {totalCount, entities, listLoading} = currentState;
  const startD = useFormatDate(startDate);
  const endD = useFormatDate(endDate);
  // Customers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    retailersUIProps.setIds([]);

    async function callList() {
      try {
        setLoading(true);
        await getRetailersList(retailersUIProps.queryParams, startD, endD);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }

    callList();
    // }, [customersUIProps.queryParams, dispatch]);
  }, [dispatch, retailersUIProps.queryParams, startDate, endDate]);
  // Table columns
  const columns = [
    {
      dataField: 'retailerName',
      text: 'Retailer Name',
      headerSortingClasses,
    },
    {
      dataField: 'count',
      text: 'Total Receipt Submitted',
    },

    {
      dataField: 'qualifyingAmount',
      text: 'Qualifying Product Amount',
      headerSortingClasses,
    },
    {
      dataField: 'basketSizeAmount',
      text: 'Basket Size Amount',
      headerSortingClasses,
    },

    // {
    //   dataField: 'action',
    //   text: 'Actions',

    //   classes: 'text-right pr-0',
    //   headerClasses: 'text-right pr-3',
    //   style: {
    //     minWidth: '100px',
    //   },
    // },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: retailersUIProps.queryParams.limit,
    page: retailersUIProps.queryParams.page,
    // onPageChange: function (page, sizePerPage) {
    //   console.log(page);
    //   console.log(sizePerPage);
    // },
    // onSizePerPageChange: function (page, sizePerPage) {
    //   console.log(page);
    //   console.log(sizePerPage);
    // },
  };
  // const onTableChange = (type, newState) => {
  //   // handle any data change here
  //   console.log(type);
  //   console.log(newState);
  // };
  //console.log('retailers ui props', startDate, endDate);
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({paginationProps, paginationTableProps}) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="retailerName"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  retailersUIProps.setQueryParams,
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: retailersUIProps.ids,
                //   setIds: retailersUIProps.setIds,
                // })}
                {...paginationTableProps}>
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default connect(null, {getRetailersList})(IsLoadingHOC(RetailersTable));
