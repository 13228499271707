import axios from 'axios';
import store from '../redux/store';
import {Logout, SetUser} from '../app/modules/Auth/_redux/types';
import {checkErrorResponse} from './util';

const baseUrl = process.env.REACT_APP_API_URL;
//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
  (config) => {
    const {accessToken} = store.getState('state').auth;

    if (accessToken) {
      config.headers['Authorization'] = accessToken;
    }
    return config;
  },
  (error) => {
    //console.log('error  --->....', error);
    Promise.reject(error);
  },
);

//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    let {refreshToken} = store.getState('state').auth;
    if (
      refreshToken &&
      error.config.url === '/api/s2/auth/refreshtoken' &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      store.dispatch({type: Logout});
    }
    if (
      refreshToken &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      return axios
        .post(`${baseUrl}/s2/auth/refreshtoken`, {refreshToken: refreshToken})
        .then((res) => {
          if (res['data']) {
            if (res.data.httpCode === 200) {
              store.dispatch({type: SetUser, payload: res.data.data});
              return axios(originalRequest);
            }
          } else {
            store.dispatch({type: Logout});
          }
        })
        .catch((error) => {
          console.log('Refresh Token expire', error);
          store.dispatch({type: Logout});
        });
    }
    return Promise.reject(error);
  },
);

class Api {
  post(path, data = null) {
    return this.send(path, 'post', data);
  }

  put(path, data = null) {
    return this.send(path, 'put', data);
  }

  get(path, data = null) {
    return this.send(path, 'get', data);
  }

  patch(path, data = null) {
    return this.send(path, 'patch', data);
  }

  delete(path, data = null) {
    return this.send(path, 'delete', data);
  }

  send(url, method, data) {
    let uri = `${baseUrl}${url}`;
    const pathname = window.location.pathname;
    const {menu} = store.getState('state').auth;
    let allRoutes = [];
    if (menu?.adminMenu) {
      for (let i in menu.adminMenu) {
        allRoutes.push({
          id: menu.adminMenu[i].id,
          route: menu.adminMenu[i].route,
        });
      }
    }
    if (menu?.campaignMenu) {
      for (let i in menu.campaignMenu) {
        allRoutes.push({
          id: menu.campaignMenu[i].id,
          route: menu.campaignMenu[i].route,
        });
      }
    }
    let menuId = null;
    let row = allRoutes.find((v) => v.route === pathname);
    if (row) {
      menuId = row.id;
    } else {
      if (pathname.split('/').length > 4 && !menuId) {
        let newPath = '/' + pathname.split('/').slice(1, 4).join('/');
        let rows = allRoutes.filter((v) => v.route === newPath);
        if (rows.length === 1) {
          menuId = rows[0].id;
        }
      }
      if (pathname.split('/').length > 3 && !menuId) {
        let newPath = '/' + pathname.split('/').slice(1, 3).join('/');
        let rows = allRoutes.filter((v) => v.route === newPath);
        if (rows.length === 1) {
          menuId = rows[0].id;
        }
      }
      if (pathname.split('/').length > 2 && !menuId) {
        let newPath = '/' + pathname.split('/').slice(1, 2).join('/');
        let rows = allRoutes.filter((v) => v.route === newPath);
        if (rows.length === 1) {
          menuId = rows[0].id;
        }
      }
    }
    if (uri.indexOf('?') >= 0 && menuId) {
      uri = `${uri}&menuId=${menuId}`;
    } else if (menuId) {
      uri = `${uri}?menuId=${menuId}`;
    }
    //console.log('.......', menuId, allRoutes);
    return new Promise((resolve, reject) => {
      let option = {method, url: uri, data: data};
      axios(option)
        .then((response) => {
          if (response.headers['content-disposition']) {
            let params = JSON.parse(response.config.data);
            var fileName = response.headers['content-disposition']
              .split('filename=')[1]
              .split(';')[0];
            if (params?.outputType === 'json') {
              const url = window.URL.createObjectURL(
                new Blob([JSON.stringify(response.data)]),
              );
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
            }
            if (params?.outputType === 'csv') {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
            }
          }
          return response.data;
        })
        .then((responseData) => {
          resolve(responseData);
        })
        .catch((error) => {
          const varifyError = checkErrorResponse(error);
          resolve(varifyError);
        });
    });
  }
}

const api = new Api();
export default api;
//export default axios;
