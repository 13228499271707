import React, {useState, useEffect} from 'react';
export default function SelectBox(props) {
  let inputNodeVariableField = props.inputNodeVariableField;
  let inputVariabltTypes = Object.keys(inputNodeVariableField);
  let selectionValue = props.selectedVal['nodeID']
    ? props.selectedVal.nodeID + '|' + props.selectedVal.key
    : '';
  let [selectedVal, setSelectedVal] = useState(selectionValue);
  useEffect(() => {
    setSelectedVal(selectionValue);
  }, [props.selectedVal]);
  return (
    <select
      className="form-select customselect"
      onChange={(e) => {
        setSelectedVal(e.target.value);
        props.callBack(props.key_name, props.key_ind, e);
      }}
      value={selectedVal}>
      <option value=""> None </option>
      {inputVariabltTypes.map((v) => {
        return (
          <optgroup key={v} label={'From ' + v}>
            {inputNodeVariableField[v].map((val2, ind2) => {
              return (
                <option key={'opt' + v + ind2} value={v + '|' + val2.key}>
                  {val2.key}
                </option>
              );
            })}
          </optgroup>
        );
      })}
    </select>
  );
}
