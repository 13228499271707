import * as utils from "../../_helpers/LocalStorageHelpers";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import UsersList from "./UsersList";
const instance = axios.create();
const BaseUrl = process.env.REACT_APP_API_URL;
export function UsersMgmt() {
  const AuthStr = utils.getStorage('accessToken')

  const [users, setUsers] = useState([]);
  useEffect(() => {
    instance.get(`${BaseUrl}/s2/identities`, { headers: { Authorization: AuthStr } })
      .then((res) => {
        setUsers(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <div>
      <div className="card card-custom card-stretch gutter-b">
        <div class="card-header border-0 pt-5">

          <h1 className="font-size-sm-100 font-weight-boldest text-dark-75 mt-2">
            Users
          </h1>
        </div>

        <div className="card-body pt-3 pb-2">
          {users && users.length <= 0 && <span>Loading...</span>}

          {users && users.length > 0 && (
            <UsersList users={users} />
          )}
        </div>
      </div>
    </div>
  );
}