import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {LayoutSplashScreen} from '../../../../_metronic/layout';
import {logout} from '../_redux/authCrud';

const Logout = ({logout, hasAuthToken}) => {
  useEffect(() => {
    logout();
  });

  return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
};

export default connect(
  ({auth}) => ({hasAuthToken: Boolean(auth.accessToken)}),
  {logout},
)(Logout);
