import {
  SAVE_CONTEST_LIST,
  LOADING,
  CONTEST_FOR_EDIT,
  UPDATE_CONTEST,
  CONTEST_DELETE,
  SAVE_CONTEST_USER_LIST,
} from './type';

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  metaData: {limit: 10, currentPageNo: 0, totalRecs: 10, nextPageNo: 1},
  contestUserEntities: null,
  contestForEdit: undefined,
  lastError: null,
};
const ContestReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CONTEST_LIST: {
      const {content, metaData} = action.payload;
      return {
        ...state,
        entities: content ? content : null,
        totalCount: metaData?.totalRecs,
        metaData: metaData,
      };
    }
    case SAVE_CONTEST_USER_LIST: {
      const {content, metaData} = action.payload;
      return {
        ...state,
        contestUserEntities: content ? content : null,
        totalCount: metaData?.totalRecs,
        metaData: metaData,
      };
    }
    case LOADING: {
      return {...state, listLoading: action.payload};
    }
    case CONTEST_FOR_EDIT: {
      return {...state, contestForEdit: action.payload};
    }

    case CONTEST_DELETE: {
      const entities = state.entities.filter(
        (item) => item.id != action.payload,
      );
      return {...state, entities: entities, totalCount: state.totalCount - 1};
    }

    case UPDATE_CONTEST: {
      let entities = state.entities.map((entity) => {
        if (entity.id === action.payload.id) {
          return action.payload;
        }
        return entity;
      });
      return {...state, entities: entities};
    }
    case 'CLEAR_ALL': {
      return initialState;
    }
    default:
      return state;
  }
};

export default ContestReducer;
