import {SAVE_RECEIPTSUMMARY_LIST, LOADING} from './type';

const initialState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  lastError: null,
};
const ReceiptSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_RECEIPTSUMMARY_LIST: {
      return {
        ...state,
        entities: action.payload,
      };
    }
    case LOADING: {
      return {...state, listLoading: action.payload};
    }

    case 'CLEAR_ALL': {
      return initialState;
    }
    default:
      return state;
  }
};

export default ReceiptSummaryReducer;
