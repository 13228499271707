import React, {useEffect, useState} from 'react';
import {get} from 'lodash';
import {Formik, FieldArray, Field, ErrorMessage} from 'formik';
import {FormHelperText, Switch} from '@material-ui/core';
import {Form, Row, Col} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {connect, useSelector, useDispatch, shallowEqual} from 'react-redux';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {addTemplateItem, updateTemplate, fetchTemplate} from '../_redux/action';
import AddTemplatevalidationSchema from '../Schema/AddTemplate.Schema';
import {useHistory, useParams} from 'react-router';
import ErrorBoundary from '../../../../_metronic/_helpers/ErrorBoundary';

const AddTemplate = ({setLoading, addTemplateItem, updateTemplate}) => {
  const initialValues = {
    templateName: '',
    templateTag: '',
    body: '',
    status: 'inactive',
    templateFieldsDef: [],
    shortMessage: '',
    subject: '',
  };

  let history = useHistory();
  const {id} = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    // server call for getting Customer by id

    dispatch(fetchTemplate(id));
  }, [id, dispatch]);

  const {templateForEdit} = useSelector(
    (state) => ({
      templateForEdit: state.template.templateForEdit,
    }),
    shallowEqual,
  );

  // server request for save
  const saveTemplate = (template, resetForm) => {
    if (!id) {
      setLoading(true);
      addTemplateItem(template)
        .then((res) => {
          setLoading(false);
          resetForm(initialValues);
          toast.success('Template Added.');
          history.push('/template/list');
        })
        .catch((err) => {
          setLoading(false);

          err.forEach((item) => {
            toast.error(item.message);
          });
        });
    } else {
      setLoading(true);
      updateTemplate(id, template)
        .then((res) => {
          setLoading(false);
          resetForm(initialValues);
          toast.success('Updated Template');
          history.push('/template/list');
        })
        .catch((err) => {
          setLoading(false);
          err.forEach((item) => {
            toast.error(item.message);
          });
        });
    }
  };

  const onAddfield = (e, field, values, setValues) => {
    // update dynamic form
    const templateFieldsDef = [...values.templateFieldsDef];

    templateFieldsDef.push({
      id: templateFieldsDef.length + 1,
      fieldType: '',
      required: true,
      name: '',
      default: '',
    });
    setValues({...values, templateFieldsDef});

    // call formik onChange method
    // field.onChange(e);
  };

  const onRemove = (id, values, setValues) => {
    // update dynamic form

    const templateFieldsDef = values.templateFieldsDef.filter(
      (item, i) => i !== id,
    );

    setValues({...values, templateFieldsDef: templateFieldsDef});
  };

  return (
    <ErrorBoundary>
      <Formik
        initialValues={templateForEdit || initialValues}
        enableReinitialize
        onSubmit={(values, {resetForm}) => {
          const newValues = {
            ...values,
            status:
              values.status === true || values.status === 'active'
                ? 'active'
                : 'inactive',
          };
          saveTemplate(newValues, resetForm);
        }}
        validationSchema={AddTemplatevalidationSchema}
        onReset={() => {}}>
        {({
          values,
          handleReset,
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          errors,
          field,
          setValues,
        }) => (
          <>
            <div className="card card-custom">
              {/*Header*/}
              <div className="card-header card-header-tabs-line">
                <ul
                  className="nav nav-dark nav-bold nav-tabs nav-tabs-line"
                  data-remember-tab="tab_id"
                  role="tablist">
                  <li className="nav-item">
                    <span className={`nav-link active}`} data-toggle="tab">
                      {id ? 'Update' : 'Add'} Template
                    </span>
                  </li>
                </ul>
                <button
                  type="text"
                  style={{margin: '16px'}}
                  onClick={() => history.push('/template/list')}
                  className={`btn btn-info font-weight-bold mr-2`}>
                  Back
                </button>
              </div>

              <div className="form">
                <div className="card-body">
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Template Name:
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <input
                        className="form-control form-control-lg form-control-solid"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="templateName"
                        value={values.templateName}
                      />

                      <FormHelperText>
                        <ErrorMessage
                          name="templateName"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Template Tag:
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <input
                        className="form-control form-control-lg form-control-solid"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="templateTag"
                        value={values.templateTag}
                      />
                      <FormHelperText>
                        <ErrorMessage
                          name="templateTag"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Email Body:
                    </label>
                    <div className="col-lg-9 col-xl-9">
                      <textarea
                        className="form-control form-control-lg form-control-solid"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="body"
                        rows="10"
                        value={values.body}
                      />
                      <FormHelperText>
                        <ErrorMessage
                          name="body"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Template Fields:
                    </label>
                    <div className="col-lg-9 col-xl-9">
                      <button
                        type="button"
                        className="btn btn-success mr-2"
                        onClick={(e) =>
                          onAddfield(e, field, values, setValues)
                        }>
                        Add New
                      </button>

                      <FieldArray name="templateFieldsDef">
                        {() =>
                          values?.templateFieldsDef.length > 0 &&
                          values.templateFieldsDef.map((templateField, i) => {
                            const templateFieldErrors =
                              (errors.templateFieldsDef?.length &&
                                errors.templateFieldsDef[i]) ||
                              {};
                            const templateFieldTouched =
                              (touched.templateFieldsDef?.length &&
                                touched.templateFieldsDef[i]) ||
                              {};
                            return (
                              <div
                                key={i}
                                className="list-group list-group-flush">
                                <div className="list-group-item">
                                  <div className="form-row">
                                    <div className="form-group col-3">
                                      <label>Field Type</label>

                                      <Field
                                        as="select"
                                        name={`templateFieldsDef.${i}.fieldType`}
                                        className={
                                          'form-control' +
                                          (templateFieldErrors.fieldType &&
                                          templateFieldTouched.fieldType
                                            ? ' is-invalid'
                                            : '')
                                        }>
                                        <option value="">Select Type</option>
                                        <option value="string">String</option>
                                        <option value="number">Number</option>
                                      </Field>
                                      <ErrorMessage
                                        name={`templateFieldsDef.${i}.fieldType`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                    <div className="form-group col-3">
                                      <label>Name</label>
                                      <Field
                                        name={`templateFieldsDef.${i}.name`}
                                        type="text"
                                        className={
                                          'form-control' +
                                          (templateFieldErrors.name &&
                                          templateFieldTouched.name
                                            ? ' is-invalid'
                                            : '')
                                        }
                                      />
                                      <ErrorMessage
                                        name={`templateFieldsDef.${i}.name`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>

                                    <div className="form-group col-3">
                                      <label>Default</label>
                                      <Field
                                        name={`templateFieldsDef.${i}.default`}
                                        type="text"
                                        className={
                                          'form-control' +
                                          (templateFieldErrors.default &&
                                          templateFieldTouched.default
                                            ? ' is-invalid'
                                            : '')
                                        }
                                      />
                                      <ErrorMessage
                                        name={`templateFieldsDef.${i}.default`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                    <div className="form-group col-3">
                                      <label>Required</label>
                                      <div>
                                        <Switch
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          name={`templateFieldsDef.${i}.required`}
                                          checked={
                                            !!get(
                                              values,
                                              `templateFieldsDef.${i}.required`,
                                            )
                                          }
                                        />
                                        <button
                                          type="button"
                                          className="btn btn-danger mr-2"
                                          onClick={() =>
                                            onRemove(i, values, setValues)
                                          }>
                                          Remove
                                        </button>
                                      </div>

                                      <ErrorMessage
                                        name={`templateFieldsDef.${i}.required`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        }
                      </FieldArray>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Status
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <Switch
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="status"
                        // checked={!!get(values, 'status')}
                        checked={
                          values.status === 'active' || values.status === true
                            ? true
                            : false
                        }
                      />
                      {values.status === true || values.status === 'active'
                        ? 'active'
                        : 'inactive'}
                      <FormHelperText>
                        <ErrorMessage
                          name="status"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                  {id ? (
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label text-lg-left">
                        Master Template
                      </label>
                      <div className="col-lg-9 col-xl-4 align-self-center pl-5">
                        {values?.masterTemplate ? 'true' : 'false'}
                      </div>
                    </div>
                  ) : null}

                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Short Message:
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <input
                        className="form-control form-control-lg form-control-solid"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="shortMessage"
                        value={values?.shortMessage}
                      />
                      <FormHelperText>
                        <ErrorMessage
                          name="shortMessage"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Email Subject:
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <input
                        className="form-control form-control-lg form-control-solid"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="subject"
                        value={values?.subject}
                      />
                      <FormHelperText>
                        {errors?.subject ? (
                          <div className="text-danger">{errors?.subject}</div>
                        ) : null}
                      </FormHelperText>
                    </div>
                  </div>
                </div>

                <div className="card-footer">
                  <div className="row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-9">
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className={`btn btn-primary font-weight-bold mr-2`}>
                        <i className="fa fa-eye" /> {id ? 'Update' : 'Submit'}
                      </button>{' '}
                      {!id && (
                        <button
                          type="button"
                          onClick={handleReset}
                          className={`btn btn-clean font-weight-bold mr-2`}>
                          <i className="fa fa-recycle" /> Reset
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </ErrorBoundary>
  );
};

export default connect(null, {addTemplateItem, updateTemplate})(
  IsLoadingHOC(AddTemplate),
);
