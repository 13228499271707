import React from 'react';
import './NodeProperties.css';

export default function EndNodeProperties(props) {
  let nodeProperties = props.nodeProperty;
  let inputNodeVariableField = nodeProperties.inputParam.variables
    ? nodeProperties.inputParam.variables
    : {};
  let inputVariabltTypes = Object.keys(inputNodeVariableField);

  return (
    <>
      <h2 className="leftToolBar">
        Object Properties
        <br />
        <span>End Node</span>
      </h2>
      <div className="leftToolBarContainer">
        <h5 style={{textAlign: 'center'}}> All Variable List</h5>
        <table className="table table-striped">
          <thead>
            <tr>
              <th colSpan="2" width="40%">
                Variable
              </th>
              <th width="25%">Type</th>
              <th width="35%">value</th>
            </tr>
          </thead>
          <tbody>
            {inputVariabltTypes.map((v) => {
              return (
                <>
                  <tr>
                    <td colSpan="4">
                      <h5 style={{textAlign: 'center', fontSize: '0.8rem'}}>
                        Form {v} Node
                      </h5>
                    </td>
                  </tr>
                  {inputNodeVariableField[v].map((val, ind2) => {
                    return (
                      <tr key={ind2 + 'tr'}>
                        <td>{ind2 + 1}.</td>
                        <td>{val.key}</td>
                        <td>{val.type}</td>
                        <td>{val.value}</td>
                      </tr>
                    );
                  })}
                </>
              );
            })}
          </tbody>
        </table>

        <div className="notesdiv">
          <h5>Notes</h5>
          <div className="success">
            <p>
              <strong> End Node!</strong> This is Process Model destination end
              point. All decalred variables is listed above and type of that
              variable also.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
