import React from "react";
import dbicon from '../../_assets/images/db.png';
import pdficon from '../../_assets/images/pdf.png';
import emailicon from '../../_assets/images/email.png';
import templateicon from '../../_assets/images/template.png';
import eventBusicon from '../../_assets/images/eventBus.png';
import jsCodeicon from '../../_assets/images/jscode.png';
import dbImporticon from '../../_assets/images/db_import.png';
import csvicon from '../../_assets/images/csv.png';
import dataExporterIcon from '../../_assets/images/dataExport.png';
let iconList = {
    dbicon: dbicon,
    pdficon: pdficon,
    emailicon: emailicon,
    templateicon: templateicon,
    jsCodeicon: jsCodeicon,
    eventBusicon: eventBusicon,
    dbImporticon: dbImporticon,
    csvicon: csvicon,
    dataExporterIcon: dataExporterIcon
}
let toolBoxList = [
    { enable: true, nodetType: "db", title: "Database Node", icon: iconList.dbicon },
    { enable: true, nodetType: "template", title: "Template Engine", icon: iconList.templateicon },
    { enable: true, nodetType: "notification", title: "Notification", icon: iconList.emailicon },
    { enable: true, nodetType: "csv", title: "CSV", icon: iconList.csvicon },
    { enable: true, nodetType: "dbloader", title: "DB Loader", icon: iconList.dbImporticon },
    { enable: true, nodetType: "dataexport", title: "Data Exporter", icon: iconList.dataExporterIcon },
    { enable: false, nodetType: "sqs", title: "SQS Event Bus", icon: iconList.eventBusicon },
    { enable: false, nodetType: "pdf", title: "PDF", icon: iconList.pdficon },
    { enable: false, nodetType: "jscode", title: "JavaScript Write", icon: iconList.jsCodeicon },
];
export default function ToolBox(props) {
    return (
        <>
            <div className="row">
                {
                    toolBoxList.map(tool => {
                        return (
                            <div className="col-md-12">
                                <div className={"objectToolshape" + (tool.enable ? "" : " objectToolshapeDisable")} title={tool.title} onClick={() => (tool.enable ? props.parentCallback(tool.nodetType) : "")}><img alt={tool.title} src={tool.icon} /> </div>
                            </div>
                        );
                    })
                }
            </div>
        </>
    )
}
