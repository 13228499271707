import React, {useMemo} from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import {ProcessAutomationFilter} from '../component/ProcessAutomationFilter';
// import { CustomersFilter } from "./customers-filter/CustomersFilter";
import ProcessAutomationTable from '../component/ProcessAutomationTable';
// import { CustomersGrouping } from "./customers-grouping/CustomersGrouping";
import {useProcessAutomationUIContext} from '../ProcessAutomationUIContext';

export function ProcessAutomationList() {
  const processAutomationUIContext = useProcessAutomationUIContext();
  //console.log('user context', customersUIContext);
  const processAutomationUIProps = useMemo(() => {
    return {
      ids: processAutomationUIContext.ids,
      newProcessAutomationButtonClick:
        processAutomationUIContext.newProcessAutomationButtonClick,
    };
  }, [processAutomationUIContext]);

  return (
    <Card>
      <CardHeader title="Process Automation">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={
              processAutomationUIContext.newProcessAutomationButtonClick
            }>
            New Process
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <ProcessAutomationFilter /> */}
        {/* <CustomersFilter />
        {processAutomationUIProps.ids.length > 0 && <CustomersGrouping />} */}
        <ProcessAutomationTable />
      </CardBody>
    </Card>
  );
}
