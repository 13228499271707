import api from '../../../../api';
import {
  SAVE_TEMPLATE_LIST,
  LOADING,
  TEMPLATE_FOR_EDIT,
  TEMPLATE_UPDATE,
  TEMPLATE_DELETE,
} from './type';
import {toast} from 'react-toastify';
export const getTemplateList = (query) => async (dispatch) => {
  const newQuery = {...query, page: query.page - 1};
  let queryString = Object.keys(newQuery)
    .map((key) => key + '=' + newQuery[key])
    .join('&');
  dispatch({type: LOADING, payload: true});
  let data = await api.get(`/s1/template?${queryString}`);
  if (data.httpCode === 200) {
    dispatch({type: LOADING, payload: false});
    dispatch({type: SAVE_TEMPLATE_LIST, payload: data.data});
  } else {
    dispatch({type: LOADING, payload: false});
    if (data?.errors) {
      if (Array.isArray(data.errors)) {
        data.errors.map((item) => {
          toast.error(item?.message);
        });
      }
    }
    dispatch({type: SAVE_TEMPLATE_LIST, payload: []});
  }
};

export const addTemplateItem = (values) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.post('/s1/template', values);
    if (data.httpCode === 201) {
      resolve(data.message);
    } else {
      reject(data.errors);
    }
  });
};

export const updateTemplate = (id, entity) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.put(`/s1/template/${id}`, entity);
    if (data.httpCode === 200) {
      resolve(data.message);
      dispatch({type: TEMPLATE_UPDATE, payload: entity});
    } else {
      reject(data.errors);
    }
  });
};

export const fetchTemplate = (id) => async (dispatch) => {
  if (!id) {
    return dispatch({type: TEMPLATE_FOR_EDIT, payload: undefined});
  }
  return new Promise(async (resolve, reject) => {
    let data = await api.get(`/s1/template/${id}`);
    if (data.httpCode === 200) {
      resolve(data.message);
      dispatch({type: TEMPLATE_FOR_EDIT, payload: data.data});
    } else {
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }
      reject(data.errors);
    }
  });
};

export const deleteTemplateById = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.delete(`/s1/template/${id}`);
    if (data.httpCode === 200) {
      resolve(data.message);
      dispatch({type: TEMPLATE_DELETE, payload: id});
    } else {
      reject(data.errors);
    }
  });
};
