import React, {useState, useEffect} from 'react';
import {Button, Table} from 'react-bootstrap';
import Logs from './Logs';
import {useParams} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';
import {
  getHistory,
  getProcessLogs,
  refreshHistory,
  stopExecution,
} from '../../_redux/action';
// import RefreshIcon from '@material-ui/icons/RefreshIcon';
import './styles.css';
import {Refresh, HighlightOff} from '@material-ui/icons';
import {IsLoadingHOC} from '../../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import ErrorBoundary from '../../../../../_metronic/_helpers/ErrorBoundary';
import HistoryTable from './HistoryTable';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const History = ({
  getHistory,
  refreshHistory,
  setLoading,
  stopExecution,
  getProcessLogs,
}) => {
  const params = useParams();

  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pagePerSize, setPagePerSize] = useState(3);
  const columns = [
    {
      dataField: 'startTime',
      text: 'Start Time',
      formatter: (cellContent) => {
        return <>{moment(cellContent).format('YYYY-MM-DD HH:mm:ss')}</>;
      },
    },
    {
      dataField: 'endTime',
      text: 'End time',
      formatter: (cellContent) => {
        return <>{moment(cellContent).format('YYYY-MM-DD HH:mm:ss')}</>;
      },
    },
    {
      dataField: 'processStatus',
      text: 'Status',
    },
    {
      dataField: 'action',
      text: 'Actions',
      formatter: (cellContent, row) => {
        return (
          <>
            <Button
              variant="info"
              onClick={() => showModel(row?.id, row?.startTime)}>
              Show Logs
            </Button>
            {row.processStatus === 'running' && (
              <Button
                variant="link"
                title="Refresh"
                onClick={() => handleRefresh(row.id)}>
                <Refresh />
              </Button>
            )}
            {row.processStatus === 'running' && (
              <Button
                variant="link"
                title="Stop Process"
                onClick={() => handleStop(row?.processUuid, row?.id)}>
                <HighlightOff />
              </Button>
            )}
          </>
        );
      },

      classes: 'text-right pr-0',
      headerClasses: 'text-right pr-3',
      // style: {
      //   minWidth: '100px',
      // },
    },
  ];
  useEffect(() => {
    setLoading(true);
    getHistoryList(page, options.sizePerPage);
  }, [pagePerSize, page]);
  const getHistoryList = (page, sizePerPage) => {
    getHistory(params.id, page, sizePerPage)
      .then((res) => {
        setLoading(false);
        setPage(page);
        setTotalCount(res?.metaData?.totalRecs ? res?.metaData?.totalRecs : 0);
        setList(res?.content ? res?.content : []);
      })
      .catch((error) => {
        setLoading(false);
        console.log('error', error);
      });
  };
  // console.log('page total', page, totalCount);
  const options = {
    totalSize: totalCount,
    sizePerPage: pagePerSize,
    sizePerPageList: [
      {text: '3', value: 3},
      {text: '5', value: 5},
      {text: '10', value: 10},
    ],
    page: page,
    onSizePerPageChange: (sizePerPage, page) => {
      setPagePerSize(sizePerPage);
      setPage(1);
    },
    onPageChange: (page, sizePerPage) => {
      setLoading(true);
      setPage(page);
      // getHistoryList(page, sizePerPage);
    },
  };

  const onHide = () => {
    setShow(false);
  };
  const showModel = (id, startTime) => {
    const timeInUtc = moment.utc(startTime).valueOf();
    setLoading(true);
    getProcessLogs(id, timeInUtc)
      .then((res) => {
        setShow(true);
        setEvents(res.events);
        setLoading(false);
      })
      .catch((err) => {
        setShow(true);
        setLoading(false);
      });
  };
  const handleRefresh = (id) => {
    setLoading(true);
    refreshHistory(id)
      .then((res) => {
        setLoading(false);
        const newList = list?.map((item) => {
          if (res._id === item.id) {
            return {...item, processStatus: res.processStatus};
          }
          return item;
        });
        setList(newList);
      })
      .catch(() => {
        setLoading(false);
      });
    //console.log('wokring', id);
  };
  const handleStop = (pId, id) => {
    setLoading(true);
    stopExecution(pId)
      .then(() => {
        setLoading(false);
        const newList = list.map((item) => {
          if (item?.id === id) {
            return {...item, processStatus: 'stopped'};
          }
          return item;
        });
        setList(newList);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <ErrorBoundary>
      <BootstrapTable
        keyField="id"
        data={list}
        bootstrap4
        remote
        columns={columns}
        pagination={paginationFactory(options)}
        bordered={false}
      />
      <Logs onHide={onHide} show={show} events={events} />
    </ErrorBoundary>
  );
};

export default connect(null, {
  getHistory,
  refreshHistory,
  stopExecution,
  getProcessLogs,
})(IsLoadingHOC(History));
