import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {
  Card,
  CardBody,
  CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
  getAllModulesAction,
  getAllRolesAction,
  getRolePermissionAction,
  updateRolePermissionAction,
} from '../_redux/action';

const Permission = (props) => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [modules, setModules] = useState([]);
  const [rolePermission, setRolePermission] = useState([]);

  useEffect(() => {
    async function fetchRoleData() {
      try {
        props.setLoading(true);
        const response = await getAllRolesAction('rbac-role-permissions');
        const resModules = await getAllModulesAction();
        if (response.httpCode == 200) {
          setRoles(response.data?.content || []);
        }
        if (resModules.httpCode == 200) {
          setModules(resModules.data.filter(({status}) => status === 'active'));
        }
        props.setLoading(false);
      } catch (error) {
        props.setLoading(false);
      }
    }
    fetchRoleData();
  }, []);

  useEffect(() => {
    async function fetchRoleData(id) {
      try {
        if (id) {
          props.setLoading(true);
          const response = await getRolePermissionAction(id);
          if (response.httpCode === 200) {
            let resources = [];
            if (response?.data?.length > 0) {
              if (response?.data[0]?.resources) {
                resources = response.data[0].resources;
              }
            }
            const updatedModules = modules.map((module) => {
              let moduleObj = {};
              const foundModule = resources.find(
                (row) => row.resId === module._id,
              );
              moduleObj = {
                moduleId: module._id,
                name: module.name,
                permissions: foundModule?.permissions || {
                  view: 0,
                  create: 0,
                  update: 0,
                },
              };
              if (foundModule && foundModule?.priv) {
                moduleObj['priv'] = foundModule.priv;
              }
              return moduleObj;
            });
            setRolePermission(updatedModules);
            props.setLoading(false);
          } else {
            props.setLoading(false);
            toast.error(response.message);
          }
        }
      } catch (error) {
        props.setLoading(false);
      }
    }
    fetchRoleData(selectedRole);
  }, [selectedRole]);

  const handleChange = (e) => {
    setSelectedRole(e.target.value);
  };
  const handleChangePermission = (e, moduleId) => {
    const {name, checked} = e.target;
    const updatedModules = rolePermission.map((module) => {
      if (module.moduleId === moduleId) {
        return {
          ...module,
          permissions: {
            ...module.permissions,
            [name]: +checked,
          },
        };
      }
      return module;
    });
    setRolePermission(updatedModules);
  };

  const savePermission = async () => {
    try {
      let validateChk = rolePermission.filter(
        (v) =>
          v?.permissions?.view === 0 &&
          (v?.permissions?.create === 1 || v?.permissions?.update === 1),
      );
      if (validateChk.length > 0) {
        let modules = validateChk.map((v) => v.name);
        toast.error(
          `Role must have view access on these modules: ${modules.join(', ')}`,
        );
        return;
      }
      props.setLoading(true);
      let payload = {
        roleId: selectedRole,
        resources: [],
      };
      payload.resources = rolePermission.map((module) => {
        let obj = {
          resId: module.moduleId,
          permissions: module.permissions,
        };
        if (module?.priv) {
          obj['priv'] = module?.priv;
        }
        return obj;
      });
      const response = await updateRolePermissionAction(payload);
      if (response.httpCode === 200) {
        toast.success(response.message);
        setSelectedRole('');
        props.setLoading(false);
      } else {
        props.setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      props.setLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      <Card>
        <CardHeader title={'Role Permissions'}>
          {/* <CardHeaderToolbar>
            <button className="btn btn-info" type="button">
              Back
            </button>
          </CardHeaderToolbar> */}
        </CardHeader>
        <CardBody>
          <div className="row justify-content-center">
            <div className="col-lg-3">
              <select
                className="form-control form-control-lg"
                name="role"
                onChange={(e) => handleChange(e)}
                value={selectedRole}>
                <option value="">Select Role</option>
                {roles
                  .filter(({status}) => status === 'active')
                  .map((val, i) => (
                    <option value={val._id} key={i}>
                      {val.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          {selectedRole && (
            <div className="row mt-3">
              <div className="col-lg-9">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Module</th>
                      <th scope="col">View</th>
                      <th scope="col">Create</th>
                      <th scope="col">Update</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rolePermission.map((module, i) => (
                      <tr key={module.moduleId}>
                        <th scope="row">{module.name}</th>
                        <td>
                          <input
                            name="view"
                            type={'checkbox'}
                            checked={Boolean(module.permissions.view)}
                            value={module.permissions.view}
                            onClick={(e) => {
                              handleChangePermission(e, module.moduleId);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            name="create"
                            type={'checkbox'}
                            value={module.permissions.create}
                            checked={Boolean(module.permissions.create)}
                            onClick={(e) => {
                              handleChangePermission(e, module.moduleId);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            name="update"
                            type={'checkbox'}
                            checked={Boolean(module.permissions.update)}
                            value={module.permissions.update}
                            onClick={(e) => {
                              handleChangePermission(e, module.moduleId);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {rolePermission.length > 0 && (
            <div className="row justify-content-center mt-3">
              <div className="col-lg-3">
                <button
                  className="btn btn-info"
                  type="button"
                  onClick={() => {
                    savePermission();
                  }}>
                  Save
                </button>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
};
export default IsLoadingHOC(Permission, '..');
