import React from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {Button, Modal} from 'react-bootstrap';

const EditProduct = ({productDetails, handleClose, handleFormSubmit, show}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      {Object.keys(productDetails).length <= 0 && (
        <Modal.Header>
          <div>Loading...</div>
        </Modal.Header>
      )}

      {Object.keys(productDetails).length > 0 && (
        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={{
              productName: productDetails.productName,
              shortDesc: productDetails.shortDesc,
              longDesc: productDetails.longDesc,
              image1: productDetails.image1,
              videoUrl: productDetails.videoUrl,
              pointsEarned: productDetails.pointsEarned,
              UPC: productDetails.UPC,
            }}
            onSubmit={(values, {setSubmitting}) => {
              handleFormSubmit(values);
              handleClose();
            }}>
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <h2 className="pb-8" style={{textTransform: 'uppercase'}}>
                  {productDetails.productName}
                </h2>

                <div className="form-group row">
                  <label
                    className="col-sm-3 col-form-label"
                    htmlFor="productName">
                    Product Name
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control form-control-lg"
                      id="productName"
                      type="text"
                      {...formik.getFieldProps('productName')}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    className="col-sm-3 col-form-label"
                    htmlFor="shortDesc">
                    Short Description
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control form-control-lg"
                      id="shortDesc"
                      type="text"
                      {...formik.getFieldProps('shortDesc')}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="longDesc">
                    Long Description
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control form-control-lg"
                      id="longDesc"
                      type="text"
                      {...formik.getFieldProps('longDesc')}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="">
                    Product Image
                  </label>
                  <div className="col-sm-9">
                    <img
                      width="150"
                      src={`https://dev-content-management.s3.us-west-2.amazonaws.com/${productDetails.image1}`}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="UPC">
                    UPC
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control form-control-lg"
                      id="UPC"
                      type="text"
                      {...formik.getFieldProps('UPC')}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" htmlFor="videoUrl">
                    Video Url
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control form-control-lg"
                      id="videoUrl"
                      type="text"
                      {...formik.getFieldProps('videoUrl')}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    className="col-sm-3 col-form-label"
                    htmlFor="pointsEarned">
                    Points
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control form-control-lg"
                      id="pointsEarned"
                      type="text"
                      {...formik.getFieldProps('pointsEarned')}
                    />
                  </div>
                </div>

                <div style={{textAlign: 'center'}}>
                  <Button type="submit" variant="primary">
                    Submit
                  </Button>
                  &nbsp;&nbsp;
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default EditProduct;
