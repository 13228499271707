/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo, useLayoutEffect, useEffect} from 'react';
import objectPath from 'object-path';
import {useHistory, useLocation} from 'react-router-dom';
import {BreadCrumbs} from './components/BreadCrumbs';
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from '../../_core/MetronicSubheader';
import {useHtmlClassService} from '../../_core/MetronicLayout';
import {Form} from 'react-bootstrap';
import * as utils from '../../../_helpers/LocalStorageHelpers';
import {shallowEqual, useSelector, useDispatch} from 'react-redux';
import {
  getCampaignList,
  regenerateCampaignToken,
  getLookUpData,
  getMenu,
} from '../../../../app/modules/Auth/_redux/authCrud';
import {connect} from 'react-redux';
import IsLoadingHOC from '../HOC/IsLoadingHOC';

const SubHeader = ({
  getMenu,
  getCampaignList,
  regenerateCampaignToken,
  setLoading,
  getLookUpData,
}) => {
  //const AuthStr = utils.getStorage('accessToken')
  const state = useSelector((state) => state);
  const {campaign, campaignId, menu} = useSelector((state) => state.auth);
  const history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    getMenu()
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (menu?.adminMenu?.length > 0 || menu?.campaignMenu?.length > 0) {
      async function fetchData() {
        getCampaignList()
          .then((response) => {
            //  console.log(response);
          })
          .catch((error) => {
            console.log('error', error);
          });
      }
      async function lookUp() {
        getLookUpData({
          lookups: ['POINT_ACTIVITIES', 'ACTIVITY_TAGS_LIST'],
        });
      }
      fetchData();
      lookUp();
    }
  }, [state.auth.accessToken, menu]);

  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();

  const today = new Date();

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        'subheader.mobile-toggle',
      ),
      subheaderCssClasses: uiService.getClasses('subheader', true),
      subheaderContainerCssClasses: uiService.getClasses(
        'subheader_container',
        true,
      ),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle('kt_aside_menu', location.pathname);
    const header = getBreadcrumbsAndTitle('kt_header_menu', location.pathname);
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title,
    );
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)

  const selectCampaign = (e) => {
    // console.log(e.target.value);
    const {value} = e.target;
    if (value) {
      setLoading(true);
      const arr = value.split('-');

      //console.log(arr);
      regenerateCampaignToken(arr[0], arr[1])
        .then((response) => {
          setLoading(false);
          dispatch({type: 'SAVE_CAMPAIGN_ID', payload: value});
          // window.location.reload(false);
          // console.log('response check kdkfkd', response);
          history.push('/campaign');
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  };

  const campaignName = () => {
    const selectedCampaign = campaign?.content?.find(
      (item) => `${item.id}-${item.clientId}` == campaignId,
    );
    //console.log("selectCajdfajsdlf", selectedCampaign);
    // return selectCampaign?.name
    return selectedCampaign?.name;
  };
  return (
    <div
      id="kt_subheader"
      // style={{zIndex: 1010}}
      className={`subheader py-2 py-lg-4  ${layoutProps.subheaderCssClasses}`}>
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}>
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle">
              <span />
            </button>
          )}
          <BreadCrumbs items={subheader.breadcrumbs} />
        </div>

        {/* <div className="d-flex align-items-right">
       
        </div>  */}
        {/* Toolbar */}

        <div className="d-flex align-items-center">
          {/* <div className="d-flex align-items-baseline mr-5">
            {campaignName()}
          </div>
          <select
            style={{marginRight: '5px', width: 'auto'}}
            onChange={selectCampaign}
            value={campaignId}
            className="form-control"
            id="sel1">
            <option value="">Select Campaign</option>
            {campaign?.content &&
              campaign?.content.map((item) => {
                return (
                  <option value={`${item.id}-${item.clientId}`} key={item.id}>
                    {item.name}
                  </option>
                );
              })}
          </select> */}

          <a
            href="#"
            style={{width: '235px'}}
            className="btn btn-light btn-sm font-weight-bold"
            id="kt_dashboard_daterangepicker"
            data-toggle="tooltip"
            title="Select dashboard daterange"
            data-placement="left">
            {/* <span
              className="text-muted font-weight-bold mr-2"
              id="kt_dashboard_daterangepicker_title">
              Today
            </span> */}
            <span
              className="text-primary font-weight-bold"
              id="kt_dashboard_daterangepicker_date">
              {today.getDate() +
                ' ' +
                today.toLocaleString('en-US', {month: 'long'})}
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  getMenu,
  getCampaignList,
  regenerateCampaignToken,
  getLookUpData,
})(IsLoadingHOC(SubHeader));
