import React, {useMemo} from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
// import { CustomersFilter } from "./customers-filter/CustomersFilter";
import RoleTable from '../component/RoleTable';
// import { CustomersGrouping } from "./customers-grouping/CustomersGrouping";
import {useRoleUIContext} from '../RoleUIContext';

export function RoleList() {
  const roleUIContext = useRoleUIContext();
  //console.log('user context', customersUIContext);
  const roleUIProps = useMemo(() => {
    return {
      ids: roleUIContext.ids,
      newRoleButtonClick: roleUIContext.newRoleButtonClick,
    };
  }, [roleUIContext]);

  return (
    <Card>
      <CardHeader title="IAM policies">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={roleUIContext.newRoleButtonClick}>
            New Role
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <CustomersFilter />
        {RoleUIProps.ids.length > 0 && <CustomersGrouping />} */}
        <RoleTable />
      </CardBody>
    </Card>
  );
}
