import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';

import {useSubheader} from '../../../_metronic/layout';
import ReceiptSummaryList from './pages/ReceiptSummaryList';
import {IsLoadingHOC} from '../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {ReceiptSummaryUIProvider} from './ReceiptSummaryUIContext';

const ReceiptSummaryPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle('');
  const receiptSummaryUIEvents = {
    openDeleteReceiptSummaryDialog: (id) => {},
  };

  return (
    <ReceiptSummaryUIProvider receiptSummaryUIEvents={receiptSummaryUIEvents}>
      <Switch>
        <Redirect
          from="/receiptsummary"
          exact={true}
          to="/receiptsummary/list"
        />
        <Route path="/receiptsummary/list" component={ReceiptSummaryList} />
      </Switch>
    </ReceiptSummaryUIProvider>
  );
};

export default connect(null, {})(IsLoadingHOC(ReceiptSummaryPage));
