import axios from 'axios';
import store from '../../redux/store';
const instance = axios.create();
const apiUrl = process.env.REACT_APP_API_URL;
const errorParser = (err) => {
  let msg = 'Error! 404 Service API not found';
  if (typeof err === 'object') {
    if (err['response']) {
      if (err.response.data['errors']) {
        try {
          let errarArr = err.response.data.errors.map((v) => v.message);
          msg = `Error! ${err.response.status} : ${errarArr.toString()}`;
        } catch (e) {
          msg = `Error! ${err.response.status} : something went wrong`;
        }
      } else {
        msg = `Error! ${err.response.status} : something went wrong `;
      }
    }
  }
  return msg;
};
const addMenuIdInURl = (url) => {
  const pathname = window.location.pathname;
  const {menu} = store.getState('state').auth;
  let allRoutes = [];
  if (menu?.adminMenu) {
    for (let i in menu.adminMenu) {
      allRoutes.push({
        id: menu.adminMenu[i].id,
        route: menu.adminMenu[i].route,
      });
    }
  }
  if (menu?.campaignMenu) {
    for (let i in menu.campaignMenu) {
      allRoutes.push({
        id: menu.campaignMenu[i].id,
        route: menu.campaignMenu[i].route,
      });
    }
  }
  let menuId = null;
  let row = allRoutes.find((v) => v.route === pathname);
  if (row) {
    menuId = row.id;
  } else {
    if (pathname.split('/').length > 4 && !menuId) {
      let newPath = '/' + pathname.split('/').slice(1, 4).join('/');
      let rows = allRoutes.filter((v) => v.route === newPath);
      if (rows.length === 1) {
        menuId = rows[0].id;
      }
    }
    if (pathname.split('/').length > 3 && !menuId) {
      let newPath = '/' + pathname.split('/').slice(1, 3).join('/');
      let rows = allRoutes.filter((v) => v.route === newPath);
      if (rows.length === 1) {
        menuId = rows[0].id;
      }
    }
    if (pathname.split('/').length > 2 && !menuId) {
      let newPath = '/' + pathname.split('/').slice(1, 2).join('/');
      let rows = allRoutes.filter((v) => v.route === newPath);
      if (rows.length === 1) {
        menuId = rows[0].id;
      }
    }
  }
  if (url.indexOf('?') >= 0 && menuId) {
    url = `${url}&menuId=${menuId}`;
  } else if (menuId) {
    url = `${url}?menuId=${menuId}`;
  }
  return url;
};
export const getRequest = (url, callback) => {
  let {auth} = store.getState();
  let token = auth.accessToken;
  let headers = {
    Authorization: token,
  };
  instance
    .get(addMenuIdInURl(apiUrl + url), {
      headers: headers,
    })
    .then((response) => {
      callback(response, null);
    })
    .catch((err) => {
      let msg = errorParser(err);
      callback(null, msg);
    });
};

export const postRequest = (url, body, callback) => {
  let {auth} = store.getState();
  let token = auth.accessToken;
  let headers = {
    Authorization: token,
  };

  instance
    .post(addMenuIdInURl(apiUrl + url), body, {
      headers: headers,
    })
    .then((response) => {
      callback(response, null);
    })
    .catch((err) => {
      let msg = errorParser(err);
      callback(null, msg);
    });
};

export const putRequest = (url, body, callback) => {
  let {auth} = store.getState();
  let token = auth.accessToken;
  let headers = {
    Authorization: token,
  };

  instance
    .put(addMenuIdInURl(apiUrl + url), body, {
      headers: headers,
    })
    .then((response) => {
      callback(response, null);
    })
    .catch((err) => {
      let msg = errorParser(err);
      callback(null, msg);
    });
};
export const fetchRequest = (url, method, body = {}) => {
  let {auth} = store.getState();
  let token = auth.accessToken;
  let opt = {
    method: method, // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  if (Object.keys(body).length > 0) {
    opt['body'] = JSON.stringify(body); // body data type must match "Content-Type" header
  }
  return fetch(addMenuIdInURl(apiUrl + url), opt)
    .then((res) => res.json())
    .then((data) => data.data)
    .catch((e) => {
      console.log('Error', e);
      throw errorParser(e);
    });
};

export const downloadFileRequest = (url) => {
  let {auth} = store.getState();
  let token = auth.accessToken;
  let opt = {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return fetch(addMenuIdInURl(apiUrl + url), opt)
    .then((res) => res)
    .catch((e) => {
      console.log('Error', e);
      throw errorParser(e);
    });
};
