import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import {RequestedReportsFilter} from '../component/RequestedReportsFilter';
import RequestedReportsTable from '../component/RequestedReportsTable';

const RequestedReportsList = () => {
  return (
    <>
      <Card>
        <CardHeader title="Requested Report (SQS)">
          <CardHeaderToolbar>
            {/* <button
              className="btn btn-primary"
              type="button"
              onClick={exportToCsv}>
              Export to CSV
            </button> */}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {/* <RequestedReportsFilter /> */}
          <RequestedReportsTable />
        </CardBody>
      </Card>
    </>
  );
};

export default RequestedReportsList;
