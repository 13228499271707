import React, {useEffect, useState, useRef} from 'react';
import {Formik, ErrorMessage, FieldArray, Field} from 'formik';
import {FormHelperText, Switch, Typography, Tooltip} from '@material-ui/core';
import {toast} from 'react-toastify';
import {connect, useSelector, useDispatch, shallowEqual} from 'react-redux';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {
  addRoleItem,
  updateRole,
  fetchRole,
  getRoleLevelList,
} from '../_redux/action';
import AddRolevalidationSchema from '../Schema/AddRole.Schema';
import {useHistory, useParams} from 'react-router';
import ErrorBoundary from '../../../../_metronic/_helpers/ErrorBoundary';
import '../styles.css';
import DragAndDrop from '../component/DragAndDrop';
import {useLocation} from 'react-router';

import {
  getMenu,
  getCampaignList,
  regenerateCampaignToken,
} from '../../../../app/modules/Auth/_redux/authCrud';

import {Form, Row, Col, Button} from 'react-bootstrap';
const initialPermission = {
  name: '',
  priv: {
    read: false,
    write: false,
    update: false,
    delete: false,
    report: false,
  },
};
const AddRole = ({setLoading, addRoleItem, updateRole, getCampaignList}) => {
  const initialValues = {
    roleName: '',
    roles: [],
  };

  const inputRef = useRef(null);
  const {id} = useParams();
  const location = useLocation();
  const state = useSelector((state) => state);
  const {menu} = useSelector((state) => state.auth);
  const [accessLevelList, setaccessLevelList] = useState();
  const [permission, setPermission] = useState([]);

  useEffect(() => {
    if (menu?.adminMenu?.length > 0 || menu?.campaignMenu?.length > 0) {
      async function fetchData() {
        getCampaignList()
          .then((response) => {
            //  console.log(response);
          })
          .catch((error) => {
            console.log('error', error);
          });
      }

      fetchData();
    }
  }, [state.auth.accessToken, menu]);

  const renderCampaignMenu = () => {
    let content =
      menu?.campaignMenu && menu?.campaignMenu ? menu?.campaignMenu : [];
    content = content.sort((a, b) => a.name.localeCompare(b.name));
    setaccessLevelList('');
    return content.map((item) => {
      return (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      );
    });
  };

  const saveRole = (role) => {
    // console.log(role)
    if (!id) {
      setLoading(true);
      addRoleItem(role)
        .then((res) => {
          // console.log(res)
          setLoading(false);
          toast.success('Role Added.');
          history.push('/role/list');
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      updateRole(role)
        .then((res) => {
          // console.log(res)
          setLoading(false);
          toast.success('Updated Role');
          history.push('/role/list');
        })
        .catch((err) => {
          setLoading(false);
          // err.forEach((item) => {
          //   toast.error(item.message);
          // });
        });
    }
  };

  const selectedList = (policies, setFieldValue) => {
    setFieldValue('roles', policies);
  };

  let history = useHistory();
  const handleAccess = (event) => {
    const value = event.target.value;
    let content =
      menu?.campaignMenu && menu?.campaignMenu ? menu?.campaignMenu : [];
    content = content.find((item) => item.id == value);

    if (content) {
      setPermission((val) => [
        ...val,
        {
          ...initialPermission,
          name: content.name,
        },
      ]);
    }
  };
  console.log(permission);
  return (
    <ErrorBoundary>
      <Formik
        initialValues={
          id ? {...initialValues, roleName: location.state} : initialValues
        }
        enableReinitialize
        onSubmit={(values) => {
          const roles = values.roles.map((item) => {
            return {PolicyName: item.PolicyName, policyArn: item.Arn};
          });

          const newValues = {
            ...values,
            roles: roles,
            accessLevelID: accessLevelList,
          };
          saveRole(newValues);
        }}
        validationSchema={AddRolevalidationSchema}
        onReset={() => {}}>
        {({
          values,
          handleReset,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          errors,
        }) => (
          <>
            <div className="card card-custom">
              <div className="card-header card-header-tabs-line">
                <ul
                  className="nav nav-dark nav-bold nav-tabs nav-tabs-line"
                  data-remember-tab="tab_id"
                  role="tablist">
                  <li className="nav-item">
                    <span className={`nav-link active}`} data-toggle="tab">
                      {id ? 'Update' : 'Add'} Role
                    </span>
                  </li>
                </ul>
                <button
                  type="text"
                  style={{margin: '16px'}}
                  onClick={() => history.push('/role/list')}
                  className={`btn btn-info font-weight-bold mr-2`}>
                  Back
                </button>
              </div>
              <div className="form">
                <div className="card-body">
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Role Name:
                    </label>
                    <div className="col-lg-9 col-xl-4">
                      <input
                        ref={inputRef}
                        className="form-control form-control-lg form-control-solid"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="roleName"
                        value={values?.roleName}
                      />

                      <FormHelperText>
                        <ErrorMessage
                          name="roleName"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Role List:
                    </label>
                    <div className="col-lg-9 col-xl-9">
                      <DragAndDrop
                        selectedList={selectedList}
                        setFieldValue={setFieldValue}
                      />
                      <FormHelperText>
                        <ErrorMessage
                          name="roles"
                          render={(msg) => (
                            <span className="text-danger">{msg}</span>
                          )}
                        />
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="form-group row " onChange={handleChange}>
                    <label className="col-lg-3 col-form-label text-lg-left">
                      Access Level:
                    </label>
                    <div className="col-lg-3">
                      <select
                        className="form-control"
                        placeholder="Filter by Status"
                        onChange={(e) => handleAccess(e)}>
                        <option>Select Value</option>
                        {renderCampaignMenu()}
                      </select>
                    </div>

                    {accessLevelList ? (
                      <div>
                        {accessLevelList != 'Select Value' && (
                          <div>
                            <label className="col-lg-3 col-form-label text-lg-left">
                              Read
                            </label>
                            <input
                              name="Read"
                              value={values?.Read}
                              type="checkbox"
                            />
                            <label className="col-lg-3 col-form-label text-lg-left">
                              Write
                            </label>
                            <input
                              name="Write"
                              value={values?.Write}
                              type="checkbox"
                            />
                            <label className="col-lg-3 col-form-label text-lg-left">
                              Update
                            </label>
                            <input
                              name="Update"
                              value={values?.Update}
                              type="checkbox"
                            />
                            <label className="col-lg-3 col-form-label text-lg-left">
                              Delete
                            </label>
                            <input
                              name="Delete"
                              value={values?.Delete}
                              type="checkbox"
                            />
                            <label className="col-lg-3 col-form-label text-lg-left">
                              Report
                            </label>
                            <input
                              name="Report"
                              value={values?.Report}
                              type="checkbox"
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  {!!permission.length &&
                    permission.map((item, key) => (
                      <div className="row" key={key}>
                        <div className="col-lg-3"></div>
                        <div className="col-lg-9">
                          <div>
                            <div className="col-form-label text-left">
                              <h4>{item.name}</h4>
                            </div>
                            <Row
                              className="g-2"
                              style={{border: '1px solid #ccc'}}>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Read</Form.Label>
                                  <div>
                                    <Switch
                                      name={`hasNumeric`}
                                      color="primary"
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Write</Form.Label>
                                  <div>
                                    <Switch
                                      name={`hasNumeric`}
                                      color="primary"
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Update</Form.Label>
                                  <div>
                                    <Switch
                                      name={`hasNumeric`}
                                      color="primary"
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Delete</Form.Label>
                                  <div>
                                    <Switch
                                      name={`hasNumeric`}
                                      color="primary"
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col md>
                                <Form.Group className="mb-3">
                                  <Form.Label>Report</Form.Label>
                                  <div>
                                    <Switch
                                      name={`hasAlpha`}
                                      color="primary"
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col
                                md
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <Button variant="danger">Remove</Button>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

                <div className="card-footer">
                  <div className="row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-9">
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className={`btn btn-primary font-weight-bold mr-2`}>
                        <i className="fa fa-eye" /> {id ? 'Update' : 'Submit'}
                      </button>{' '}
                      {!id && (
                        <button
                          type="button"
                          onClick={handleReset}
                          className={`btn btn-clean font-weight-bold mr-2`}>
                          <i className="fa fa-recycle" /> Reset
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </ErrorBoundary>
  );
};

export default connect(null, {addRoleItem, updateRole, getCampaignList})(
  IsLoadingHOC(AddRole),
);
