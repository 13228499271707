import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {useDispatch, connect} from 'react-redux';
import {useSubheader} from '../../../../_metronic/layout';
import ChangePassword from './components/ChangePassword';
import {IsLoadingHOC} from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';

const ChangePasswordPage = () => {
  const suhbeader = useSubheader();
  const dispatch = useDispatch();
  suhbeader.setTitle('ChangePassword');

  return (
      <Switch>
        <Route path="/changepassword" component={ChangePassword} />
      </Switch>
  );
};

export default connect(null, {})(IsLoadingHOC(ChangePasswordPage));
