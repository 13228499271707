import React from 'react';

function createDate(value) {
    const monthList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const dateResult = new Date(value)
    const date = dateResult.getDate();
    const month = monthList[dateResult.getMonth()];
    const year = dateResult.getFullYear();

    let customDate = `${date} ${month} ${year}`
    return customDate
}



const OffersList = ({ offers, handleShow }) => {
    return (
        <div className="table-responsive" style={{ background: "#fff" }}>
            <table className="table table-borderless table-vertical-center">
                <thead>
                    <tr>
                        <th className="p-0" style={{ width: "50px" }}>Thumb</th>
                        <th className="p-0" style={{ minWidth: "200px" }}>Offer Name</th>
                        <th className="p-0" style={{ minWidth: "125px" }}>Points</th>
                        <th className="p-0 text-right" style={{ minWidth: "110px" }}>Manage Offers</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        offers.map(offer => (
                            <tr id={offer._id}>
                                <td className="pl-0 py-4">
                                    <div className="symbol symbol-50 symbol-light mr-1">
                                        <span className="symbol-label">
                                            {offer.image1 &&
                                                <img width="40" src={`https://dev-content-management.s3.us-west-2.amazonaws.com/${offer.image1}`} />}
                                        </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    {offer.offerName && (<span className="text-muted font-weight-500">{offer.offerName}</span>)}
                                </td>
                                {/* <td className="pl-0">
                                    {offer.shortDesc && <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{offer.shortDesc}</span>}
                                </td>
                                 */}
                                <td className="pl-0">
                                    {offer.pointsEarned && (<span className="text-muted font-weight-500">{offer.pointsEarned}</span>)}
                                </td>

                                <td className="text-right">
                                    {offer._id && (<span onClick={() => handleShow(offer._id)} style={{ cursor: "pointer" }} className="label label-lg label-light-primary label-inline">Manage</span>)}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )

}

export default OffersList