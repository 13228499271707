import {all} from 'redux-saga/effects';
import {combineReducers} from 'redux';

import * as auth from '../app/modules/Auth/_redux/authRedux';
import {customersSlice} from '../app/modules/ECommerce/_redux/customers/customersSlice';
import {productsSlice} from '../app/modules/ECommerce/_redux/products/productsSlice';
import {remarksSlice} from '../app/modules/ECommerce/_redux/remarks/remarksSlice';
import {specificationsSlice} from '../app/modules/ECommerce/_redux/specifications/specificationsSlice';
import * as fm from '../app/modules/FileManager/_redux/reducers/index';
import ActivityReducer from '../app/modules/Activity/_redux/reducer';
import ProductReducer from '../app/modules/Product/_redux/reducer';
import TemplateReducer from '../app/modules/Template/_redux/reducer';
import ProcessAutomationReducer from '../app/modules/ProcessAutomation/_redux/reducer';
import OfferReducer from '../app/modules/Offer/_redux/reducer';
import ReceiptReducer from '../app/modules/Receipt/_redux/reducer';
import HelpDeskReducer from '../app/modules/HelpDesk/_redux/reducer';
import UserReducer from '../app/modules/User/_redux/reducer';
import AdminUserReducer from '../app/modules/AdminUser/_redux/reducer';
import RewardsReducer from '../app/modules/Reward/_redux/reducer';
import CodeGenerationReducer from '../app/modules/CodeGeneration/_redux/reducer';
import OrderReducer from '../app/modules/Order/_redux/reducer';
import ReportReducer from '../_metronic/_partials/dashboards/_redux/reducer';
import RuleReducer from '../app/modules/Rule/_redux/reducer';
import ContestReducer from '../app/modules/Contest/_redux/reducer';
import DynamicReducer from '../app/modules/DynamicMessage/_redux/reducer';
import RoleReducer from '../app/modules/Role/_redux/reducer';
import RetailersReducer from '../app/modules/Retailers/_redux/reducer';
import ReceiptSummaryReducer from '../app/modules/ReceiptSummary/_redux/reducer';
import RewardSummaryReducer from '../app/modules/RewardSummary/_redux/reducer';
import CampaignMappingReducer from '../app/modules/CampaignMapping/_redux/reducer';
import ClientUserReducer from '../app/modules/ClientUser/_redux/reducer';
import CampaignReducer from '../app/modules/Campaign/_redux/reducer';
import GameCenter from '../app/modules/GameCenter/_redux/reducer';
import UserInfo from '../app/modules/UserInfo/_redux/reducer';
import RequestedReports from '../app/modules/RequestedReports/_redux/reducer';
import RBACReducer from '../app/modules/RBAC/_redux/reducer';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  fileManager: fm.reducer,
  activity: ActivityReducer,
  template: TemplateReducer,
  processAutomation: ProcessAutomationReducer,
  product: ProductReducer,
  offer: OfferReducer,
  receipts: ReceiptReducer,
  helpdesk: HelpDeskReducer,
  user: UserReducer,
  adminuser: AdminUserReducer,
  rewards: RewardsReducer,
  codegeneration: CodeGenerationReducer,
  order: OrderReducer,
  report: ReportReducer,
  rule: RuleReducer,
  contest: ContestReducer,
  dynamic: DynamicReducer,
  role: RoleReducer,
  retailers: RetailersReducer,
  receiptSummary: ReceiptSummaryReducer,
  rewardSummary: RewardSummaryReducer,
  campaignMapping: CampaignMappingReducer,
  clientuser: ClientUserReducer,
  campaign: CampaignReducer,
  gamecenter: GameCenter,
  userinfo: UserInfo,
  requestedReports: RequestedReports,
  RBACReducer: RBACReducer,
});

export function* rootSaga() {
  yield all([auth.saga(), fm.saga()]);
}
