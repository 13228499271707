import React, {useEffect, useState} from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {connect} from 'react-redux';
import {getIAMPoliciesList, fetchRole} from '../_redux/action';
import {useLocation} from 'react-router';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  color:`#ffffff`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 'auto',
  height: '500px',
  overflow: 'scroll',
});

const id2List = {
  droppable: 'items',
  droppable2: 'selected',
};
const DragAndDrop = ({
  getIAMPoliciesList,
  selectedList,
  setFieldValue,
  fetchRole,
}) => {
  const [list, setList] = useState({
    items: [],
    selected: [],
  });
  const [selected, setSelected] = useState([]);
  const [items, setItems] = useState([]);
  const location = useLocation();
  useEffect(() => {
    fetchRole(location.state)
      .then((data) => {
        //setList({...list, selected: data.AttachedPolicies});
        const selectedPolicies = data.AttachedPolicies?.map((item) => {
          return {...item, Arn: item.PolicyArn};
        });
        if (selectedPolicies?.length) {
          setSelected(selectedPolicies);
          selectedList(selectedPolicies, setFieldValue);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //const [policiesList, setPoliciesList] = useState();

  useEffect(() => {
    getIAMPoliciesList()
      .then((res) => {
        // setPoliciesList(res);
        // setList({...list, items: res.Policies});
        setItems(res.Policies.filter(item=>
          item.PolicyName.match('terra_')
        ));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const itemsData = items.filter((item) => {
      const find = selected.find((sel) => sel.Arn === item.Arn);
      if (find) {
        return false;
      }
      return item;
    });

    setList({items: itemsData, selected: selected});
  }, [items, selected]);

  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the droppable container to the names of the
   * source arrays stored in the state.
   */

  const getList = (id) => list[id2List[id]];

  const onDragEnd = (result) => {
    const {source, destination} = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index,
      );

      let state = {...list, [source.droppableId]: items};

      //   if (source.droppableId === 'droppable2') {
      //     state = {selected: items};
      //   }

      // this.setState(state);
      setList(state);
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination,
      );

      selectedList(result.droppable2, setFieldValue);

      setList({
        items: result.droppable,
        selected: result.droppable2,
      });
    }
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity

  return (
    <div className="drag-drop-list">
      <DragDropContext onDragEnd={onDragEnd} className="row">
        <div className="col">
          <h3 className="card-label">IAM policies</h3>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}>
                {list?.items?.map((item, index) => (
                  <Draggable
                    key={item.Arn}
                    draggableId={item.Arn}
                    index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}>
                        {item.PolicyName}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        <div className="col">
          <h3 className="card-label">Selected IMA policies</h3>
          <Droppable droppableId="droppable2" className="row">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}>
                {list?.selected?.map((item, index) => (
                  <Draggable
                    key={item.Arn}
                    draggableId={item.Arn}
                    index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}>
                        {item.PolicyName}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </div>
  );
};

export default connect(null, {getIAMPoliciesList, fetchRole})(DragAndDrop);
