export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { accessToken }
      } = store.getState();

      if (accessToken) {
        config.headers.Authorization = `${accessToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );
}
