import {SAVE_REPORTING_LIST} from './type';

const initialState = {
  reportingList: null,
  metaData: {limit: 10, currentPageNo: 0, totalRecs: 10, nextPageNo: 1},
};
const ReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_REPORTING_LIST: {
      const {content, metaData} = action.payload;
      return {
        ...state,
        reportingList: content ? content : null,
        totalCount: metaData?.totalRecs,
        metaData: metaData,
      };
    }
    case 'CLEAR_ALL': {
      return initialState;
    }

    default:
      return state;
  }
};

export default ReportReducer;
