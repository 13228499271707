import React, {useState} from 'react';
import {Tabs, Tab} from 'react-bootstrap/';
import './NodeProperties.css';
const variableType = [
  {name: 'Constant', value: 'constant'},
  {name: 'Runtime', value: 'runtime'},
  {name: 'File Upload', value: 'file_upload'},
];
export default function StartNodeProperties(props) {
  let nodeProperties = props.nodeProperty;
  let [activeTabs, setActiveTabs] = useState('inputTab');
  let [variableList, setVariableList] = useState(
    nodeProperties.outputParam.variables['start']
      ? nodeProperties.outputParam.variables['start']
      : [],
  );
  let [variableOutputList, setVariableOutputList] = useState(variableList);
  let addRemoveVariable = (type) => {
    let vlist = [...variableList];
    if (type === 'add') {
      vlist.push({
        key: '',
        value: '',
        type: 'constant',
      });
      setVariableList(vlist);
    } else {
      vlist.pop();
      setVariableList(vlist);
    }
  };
  let prepareOutput = (event) => {
    let tempArr = variableList.filter((val, ind) => {
      return val.key === '' || (val.type === 'constant' && val.value === '');
    });
    if (tempArr.length > 0) {
      alert(
        'Error!!! Something is missing either Variable name or Constant variable Value',
      );
      return false;
    }
    nodeProperties.outputParam.variables[nodeProperties.nodeID] = variableList;
    setVariableOutputList(variableList);
    setActiveTabs('outputTab');
    props.parentCallback(nodeProperties, 1);
  };
  let mapVariable = (Key, ind, e) => {
    let name = e.target.name;
    if (name === 'key') {
      if (e.target.value !== '') {
        try {
          let isValidVariable =
            e.target.value.indexOf('}') === -1 &&
            eval(
              '(function() { var a = {' +
                e.target.value +
                ':1}; a.' +
                e.target.value +
                '; var ' +
                e.target.value +
                '; }); true',
            );
          if (!isValidVariable) {
            return false;
          }
        } catch (ex) {
          return false;
        }
      }
    }
    let vlist = [...variableList];
    vlist[ind][name] = e.target.value.trim();
    setVariableList(vlist);
  };
  let counter = 1;
  return (
    <>
      <h2 className="leftToolBar">
        Object Properties
        <br />
        <span>Start Node</span>
      </h2>
      <div className="leftToolBarContainer">
        <Tabs
          activeKey={activeTabs}
          transition={false}
          id="noanim-tab-example"
          onSelect={(key) => {
            setActiveTabs(key);
          }}>
          <Tab eventKey={'inputTab'} title="Input">
            <table className="table table-striped" id="startNodeTable">
              <thead>
                <tr>
                  <th colSpan="2" width="40%">
                    Variable
                  </th>
                  <th width="25%">Type</th>
                  <th width="35%">value</th>
                </tr>
              </thead>
              <tbody>
                {variableList.map((val, ind) => {
                  return (
                    <tr key={ind + 'tr'}>
                      <td style={{whiteSpace: 'nowrap'}}>{counter++}</td>
                      <td>
                        <input
                          type="text"
                          onChange={(e) => mapVariable(val.key, ind, e)}
                          name="key"
                          placeholder="Variable Name"
                          className="md-textarea form-control"
                          value={val.key}
                        />
                      </td>
                      <td>
                        <select
                          name="type"
                          onChange={(e) => mapVariable(val.key, ind, e)}
                          defaultValue={val.type}
                          className="customselect form-select">
                          {variableType.map((v, i) => {
                            return (
                              <option key={'opt' + v + i} value={v.value}>
                                {v.name}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="value"
                          onChange={(e) => mapVariable(val.key, ind, e)}
                          placeholder="Value"
                          className="md-textarea form-control"
                          defaultValue={val.value}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <button
                    type="button"
                    title="Add Variable"
                    className="btn btn-success custombtn"
                    onClick={(e) => {
                      addRemoveVariable('add');
                    }}>
                    {' '}
                    +{' '}
                  </button>
                </div>
                <div className="col-md-6" style={{textAlign: 'right'}}>
                  <button
                    type="button"
                    title="Remove Variable"
                    className="btn btn-danger custombtn"
                    onClick={(e) => {
                      addRemoveVariable('remove');
                    }}
                    disabled={variableList.length < 2 ? 'disabled' : false}>
                    {' '}
                    -{' '}
                  </button>
                </div>
              </div>
            </div>
            <p align="center">
              <button
                type="button"
                className="custombtn btn btn-dark"
                title="Prepare Output"
                onClick={prepareOutput}>
                Save & Prepare Output
              </button>
            </p>
          </Tab>
          <Tab
            eventKey={'outputTab'}
            title="Output"
            disabled={variableOutputList.length ? false : 'disabled'}>
            <h5>Variable List</h5>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th colSpan="2" width="40%">
                    Variable
                  </th>
                  <th width="25%">Type</th>
                  <th width="35%">value</th>
                </tr>
              </thead>
              <tbody>
                {variableOutputList.map((val, ind) => {
                  return (
                    <tr key={ind + 'tr'}>
                      <td>{ind + 1}.</td>
                      <td>{val.key}</td>
                      <td>{val.type}</td>
                      <td>{val.value}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Tab>
        </Tabs>
        <div className="notesdiv">
          <h5>Notes</h5>
          <div className="success">
            <p>
              <strong> Constant!</strong> Constant is a value that cannot be
              reassigned. A constant is immutable and cannot be changed. So
              that, whenever we declare a variable as constant will required
              value.
            </p>
          </div>

          <div className="danger">
            <p>
              <strong>Runtime!</strong> Runtime/Dynamic variable is a variable
              that value we will define when our Process model will be execution
            </p>
          </div>

          <div className="warning">
            <p>
              <strong>File Upload!</strong> File upload is also a runtime
              variable Where it will ask you to upload a file or ask to give a
              file link for that when our Process model will be execute
            </p>
          </div>

          <div className="info">
            <p>
              <strong>Variable Name!</strong> Variable names cannot contain
              spaces, must begin with a letter or underscore (_), can only
              contain letters, numbers, underscores and Variable names are
              case-sensitive.{' '}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
