import {
  SAVE_REQUESTEDREPORTS_LIST,
  REFERSH_REQUESTEDREPORTS_LIST,
  LOADING,
} from './type';

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  metaData: {limit: 10, currentPageNo: 0, totalRecs: 10, nextPageNo: 1},
  lastError: null,
};
const RequestedReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_REQUESTEDREPORTS_LIST: {
      const {content, metaData} = action.payload;
      return {
        ...state,
        entities: content ? content : null,
        totalCount: metaData?.totalRecs,
        metaData: metaData,
      };
    }
    case REFERSH_REQUESTEDREPORTS_LIST: {
      const row = action.payload;
      const rowSet = [...state.entities];
      let ind = rowSet.findIndex((v) => v.id === row.id);
      if (ind >= 0) {
        rowSet[ind] = row;
      }
      return {
        ...state,
        entities: rowSet,
      };
    }
    case LOADING: {
      return {...state, listLoading: action.payload};
    }

    case 'CLEAR_ALL': {
      return initialState;
    }
    default:
      return state;
  }
};

export default RequestedReportsReducer;
