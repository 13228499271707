import React, {useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import {Button} from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import {useHistory, useLocation} from 'react-router-dom';
import {saveAs} from 'file-saver';
import HistoryComponent from './HistoryComponent';
import {getHistoryList} from '../_redux/action';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {connect} from 'react-redux';

const TopContaine = ({total, handleRefresh, data}) => {
  const [historyShow, setHistoryShow] = useState(false);

  const history = useHistory();
  const {state} = useLocation();
  let sortValue = new URLSearchParams(history.location.search).get('sort');

  if (!sortValue) sortValue = 'asc';
  const download = (fileUrl, fileName) => {
    (async () => {
      try {
        let blob = await fetch(fileUrl).then((r) => r.blob());
        saveAs(blob, fileName);
      } catch (error) {
        console.error('error', error);
      }
    })();
  };

  const handleHistoryListing = () => {
    setHistoryShow(true);
  };
  const location = {
    pathname: history.location.pathname,
    search: `?sort=${sortValue && sortValue == 'desc' ? 'asc' : 'desc'}`,
  };

  if (state && state.filters) {
    location.state = {filters: state.filters};
  }
  return (
    <Container>
      <Row>
        <Col xs={4}>
          <Paper
            elevation={3}
            border={0}
            style={{height: '70px'}}
            className="p-6 rounded-0 d-flex justify-content-between align-items-center">
            <p className="h6 mb-0">All Receipts</p>

            <div>
              <Button
                variant="contained"
                size="large"
                title={sortValue == 'asc' ? 'ascending' : 'descending'}
                className="rounded-0 ml-2"
                onClick={() => {
                  history.push(location);
                }}
                color="primary">
                <i
                  style={{color: '#000'}}
                  className={
                    sortValue == 'asc'
                      ? 'fas fa-sort-amount-up'
                      : 'fas fa-sort-amount-down'
                  }></i>
              </Button>
            </div>
            <p className="h2 text-primary mb-0">{total}</p>
          </Paper>
        </Col>
        <Col xs={8}>
          <Paper
            style={{height: '70px'}}
            elevation={3}
            border={0}
            className="p-6 rounded-0 d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <Button
                variant="contained"
                size="large"
                className="rounded-0"
                style={{float: 'right'}}
                onClick={() => history.push('/receipt/searchform')}
                color="primary">
                Search
              </Button>
              <div>
                <div xs={2} className="d-flex justify-content-between p-4">
                  <p className="h6 mb-0">Refresh</p>
                  <ReplayIcon onClick={handleRefresh} />
                </div>
              </div>
              {data?.id && (
                <Button
                  variant="contained"
                  size="large"
                  className="rounded-0"
                  onClick={handleHistoryListing}
                  color="primary">
                  Receipt History
                </Button>
              )}

              {data?.imageUrl && (
                <Button
                  variant="contained"
                  size="large"
                  className="rounded-0 ml-2"
                  onClick={() => download(data?.imageUrl, 'ReceiptFile')}
                  color="primary">
                  Export Receipt
                </Button>
              )}
            </div>
            <div>
              {/* <Button
                variant="contained"
                size="large"
                className="rounded-0 ml-2"
                onClick={() => setOrder('asc')}
                color="primary">
                <i
                  style={{color: '#000'}}
                  className="ki ki-double-arrow-up"></i>
              </Button> */}
              {/* <Button
                variant="contained"
                size="large"
                title={toggleOrder?"ascending":"descending"}
                className="rounded-0 ml-2"
                onClick={() => {
                  setToggleOrder(!toggleOrder);

                  sortOrder();
                  }}
                color="primary">
                  <i 
                  style={{color: '#000'}}
                  className=" fas fa-solid fa-sort"></i> 
              </Button> */}
            </div>
            {historyShow && (
              <HistoryComponent
                id={data?.id}
                show={historyShow}
                setShow={setHistoryShow}
              />
            )}
          </Paper>
        </Col>
      </Row>
    </Container>
  );
};

export default connect(null, {getHistoryList})(IsLoadingHOC(TopContaine));
