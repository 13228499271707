// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from 'react';
import SVG from 'react-inlinesvg';
import {toAbsoluteUrl} from '../../../../_metronic/_helpers';
export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  {
    openEditProcessAutomationDialog,
    openDeleteProcessAutomationDialog,
    openExecuteProcessModele,
  },
) {
  return (
    <>
      <a
        title="Delete customer"
        //  className="btn btn-icon btn-light btn-hover-danger danger btn-sm"
        className="danger"
        onClick={() => openExecuteProcessModele(row.id)}>
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG
            src={toAbsoluteUrl('/media/svg/icons/play-button.svg')}
            title="execute"
          />
          {/* execute */}
        </span>
      </a>
      <a
        title="Edit customer"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={() => openEditProcessAutomationDialog(row.id)}>
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')}
            title="Edit"
          />
        </span>
      </a>
      <> </>

      {/* <a
        title="Delete customer"
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={() => openDeleteProcessAutomationDialog(row.id)}>
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG
            src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')}
            title="Delete"
          />
        </span>
      </a> */}
    </>
  );
}
