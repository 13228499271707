import React, {useEffect, useState} from 'react';
import {
  getReceiptDataById,
  getReceiptList,
  occupieReceipt,
  refreshReceiptById,
  getSearchResult,
} from './_redux/action';
import {regenerateCampaignToken} from '../Auth/_redux/authCrud';
import ReceiptForm from './component/ReceiptForm';
import {connect} from 'react-redux';
import {useSelector, useDispatch} from 'react-redux';
import './styles.css';
import {Container, Row, Col} from 'react-bootstrap';
import RightContainer from './component/RightContainer';
import NotFound from './component/NotFound';
import IsLoadingHOC from '../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {useLocation, useHistory, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import jwt from 'jwt-decode';
import TopContainer from './component/TopContainer';
import ReceiptSlider from './component/ReceiptSlider';

const limit = 8;

const ReceiptMain = ({
  regenerateCampaignToken,
  setLoading,
  getReceiptList,
  occupieReceipt,
  refreshReceiptById,
}) => {
  const receiptUrl = 'search';
  
  const [currentReceipt, setCurrentReceipt] = useState({});
  const [updatedBatchId, setUpdatedBatchId] = useState();
  const [selectedSliderId, setSelectedSliderId] = useState();
  const [order, setOrder] = useState('');
  const dispatch = useDispatch();
  const {state} = useLocation();
  const {accessToken} = useSelector((state) => state.auth);
  const {campaign: campaignList} = useSelector((state) => state.auth);
  const userData = jwt(accessToken);
  const campaignID = userData?.cId || '';
  const uId = userData?.uId;
  // const {campaignId: urlCampaignId, receiptId: urlReceiptId} = useParams();
  const {campaignId: urlCampaignId, receiptId: urlReceiptId} = useParams();
  const [pageNo, setpageNo] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const history = useHistory();
  const sortValue = new URLSearchParams(history.location.search).get('sort');
  const startDate = new URLSearchParams(history.location.search).get('start');
  const endDate = new URLSearchParams(history.location.search).get('end');
  const {receipts, metaData} = useSelector((state) => state.receipts);
  const updatedReceipt = receipts.find((item) => item.id === selectedSliderId);

  useEffect(() => {
    if (userData?.cId) {
      setPageCount(Math.ceil(metaData?.totalRecs / limit));
    }
  }, [accessToken]);

  useEffect(() => {
    if (
      campaignID.toLowerCase() !== urlCampaignId.toLowerCase() &&
      campaignList?.content?.length > 0
    ) {
      let findClientIDs = campaignList.content.filter((v) => {
        return v.id.toLowerCase() === urlCampaignId.toLowerCase();
      });
      if (findClientIDs.length > 0) {
        selectCampaign(urlCampaignId, findClientIDs[0].clientId);
      } else {
        toast.error(`You don't have right to access this campaign receipt.`);
      }
    } else if (campaignID.toLowerCase() === urlCampaignId.toLowerCase()) {
      getList(0, sortValue);
    }
  }, [campaignID, campaignList, urlCampaignId]);
  const selectCampaign = (CampaignId, clientId) => {
    setLoading(true);
    regenerateCampaignToken(CampaignId, clientId)
      .then((response) => {
        setLoading(false);
        dispatch({
          type: 'SAVE_CAMPAIGN_ID',
          payload: `${CampaignId}-${clientId}`,
        });
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    if (campaignID.length > 0 && urlReceiptId.length > 0) {
     
      getReceiptDataById(urlReceiptId)
        .then((resp) => {
          setCurrentReceipt(resp);
          selectReceipt(resp?.id);
          setOccupieReceipt(urlReceiptId)
        })
        .catch((err) => {
          toast.error(`Something went wrong.`);
        });
    }
  }, [campaignID, urlReceiptId]);
  const onChangeBatchId = (id) => {
    setUpdatedBatchId(id);
  };
  const handleRefresh = () => {
    getList(pageNo, sortValue);
  };
  const getList = (page = 0, pageOrder = 'desc') => {
    setOccupieReceipt(urlReceiptId)
    setLoading(true);
    if (state) {
      getReceiptList(receiptUrl, page, limit, pageOrder, state?.filters)
        .then((res) => {
          setpageNo(res?.metaData?.currentPageNo);
          // console.log(res)
          setLoading(false);

          // console.log('dtesdd java =====>', res);
          let id = returnId(res);
          // id && selectReceipt(id);
          // id && selectReceipt(res?.id);
        
          //
        })
        .catch((err) => {
          setLoading(false);
          console.log('error', err);
        });
    } else {
      let filters = null;
      filters = {
        submissionDate: {
          from: '',
          to: '',
        },
        receiptId: urlReceiptId,
      };
      getReceiptList(receiptUrl, page, limit, pageOrder, filters)
        .then((res) => {
          setpageNo(res?.metaData?.currentPageNo);
          setLoading(false);
          //  let id = returnId(res);
          // id && selectReceipt(id);
          // id && selectReceipt(res?.id);
        })
        .catch(() => {
          setLoading(false);
          console.log('error');
        });
    }
  };
  const setOccupieReceipt = (id) => {
     occupieReceipt(id, uId)
     .then((resp) => {
          setGetReceiptDataById(id);
          setLoading(false);
        })
        .catch(() => setLoading(false));
  };
  const setGetReceiptDataById = (id) => {
       getReceiptDataById(id)
      .then((resp) => {
        setCurrentReceipt(resp);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(`Something went wrong.`);
        setLoading(false);
      });
 };
  const selectReceipt = (id) => {
    setSelectedSliderId(id);
    // setLoading(true);
    refreshReceiptById(id)
    .then((res) => setLoading(false))
      .catch(() => setLoading(false));
  };
  const handlePageClick = (event) => {
    getList(event.selected, sortValue);
    setpageNo(event.selected);
  };
  return (
    <>
      <TopContainer
        onChangeBatchId={onChangeBatchId}
        total={metaData.totalRecs}
        handleRefresh={handleRefresh}
        data={currentReceipt}
        setOrder={setOrder}
        order={order}
      />
      {currentReceipt?.id ? (
        <>
          <ReceiptSlider
            data={receipts}
            currentId={currentReceipt?.id}
            selectReceipt={selectReceipt}
            pageCount={pageCount}
            handlePageClick={handlePageClick}
            pageNo={pageNo}
            order={order}
          />
          <Container>
            <Row>
              <Col xs={6} className="p-0">
                <ReceiptForm
                  // viewOnly={false}
                  handleRefresh={handleRefresh}
                  data={currentReceipt}
                  batchId={updatedBatchId}
                />
              </Col>
              <Col xs={6} className="p-0">
                <RightContainer data={currentReceipt} />
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default connect(null, {
  regenerateCampaignToken,
  getReceiptList,
  occupieReceipt,
  refreshReceiptById,
  getSearchResult,
})(IsLoadingHOC(ReceiptMain));
