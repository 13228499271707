import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import {Document, Page, pdfjs} from 'react-pdf';
//import {pdfjs} from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

//import samplePDF from './test.pdf';

// export default function Test() {
//   const [numPages, setNumPages] = useState(null);

//   function onDocumentLoadSuccess({numPages}) {
//     setNumPages(numPages);
//   }

//   return (
//     <Document
//       file={{
//         url: 'http://www.africau.edu/images/default/sample.pdf',
//       }}
//       onLoadSuccess={onDocumentLoadSuccess}>
//       {Array.from(new Array(numPages), (el, index) => (
//         <Page key={`page_${index + 1}`} pageNumber={index + 1} />
//       ))}
//     </Document>
//   );
// }

//import samplePDF from './test.pdf';

export default function Test({filePath}) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isProtected, setProtected] = useState(false);

  useEffect(() => {
    setProtected(false);
  }, [filePath]);

  function onDocumentLoadSuccess({numPages}) {
    // console.log('numPages', numPages);
    setNumPages(numPages);
    //setPageNumber(1);
  }

  function changePage(offset) {
    // console.log('offset', offset);
    setPageNumber((prevPageNumber) => {
      //console.log('console.log', prevPageNumber, offset);
      return prevPageNumber + offset;
    });
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }
  function onPassword(callback, reason) {
    function callbackProxy(password) {
      // Cancel button handler
      if (password === null) {
        setProtected(true);
        // Reset your `document` in `state`, un-mount your `<Document />`, show custom message, whatever
      } else {
        callback(password);
      }
    }

    switch (reason) {
      case 1: {
        const password = prompt('Enter the password to open this PDF file.');
        callbackProxy(password);
        break;
      }
      case 2: {
        const password = prompt('Invalid password. Please try again.');
        callbackProxy(password);
        break;
      }
      default:
    }
  }

  return (
    <Container className="pb-3">
      {isProtected ? (
        <div className="p-3 font-weight-bolder h5">File is Protected</div>
      ) : (
        <>
          <Document
            onPassword={onPassword}
            width={500}
            height={500}
            file={{
              url: filePath,
            }}
            onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
          <div>
            <p>
              Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
            </p>
            <button
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}>
              Previous
            </button>
            <button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}>
              Next
            </button>
          </div>
        </>
      )}
    </Container>
  );
}
