import { fetchRequest, downloadFileRequest } from "../../../_metronic/_helpers/APIRequestHelpers";
const getPathWithoutTenentID = (path) => {
    let tempArr = path.split("/");
    tempArr = tempArr.slice(1, tempArr.length);
    path = tempArr.join("/");
    return path
}
const getFilesListService = (path) => {
    let body = {};
    path = getPathWithoutTenentID(path);
    if (path !== '') {
        body["path"] = path
    }
    return fetchRequest('/s1/filemanager/filelist', "POST", body);
}
const getFoldersListService = () => {
    let body = {};
    return fetchRequest('/s1/filemanager/folderlist', "POST", body);
}
const uploadFileService = (param) => {
    param.path = getPathWithoutTenentID(param.path);
    let body = { path: param.path, fileName: param.fileName,contentType: param.contentType };
    if (body.path.substr(-1) === "/" || body.path.substr(-1) === "\\") {
        body.path = body.path.substr(0, body.path.length - 1);
    }
    return fetchRequest('/s1/filemanager/upload', "POST", body);
}
const createDirService = (param) => {
    param.path = getPathWithoutTenentID(param.path);
    let body = { path: param.path, dirName: `${param.dirName}/` };
    if (body.path.substr(-1) === "/" || body.path.substr(-1) === "\\") {
        body.path = body.path.substr(0, body.path.length - 1);
    }
    return fetchRequest('/s1/filemanager/directory', "POST", body);
}
const deleteService = (param) => {
    let body = {
        path: param.path,
        key: param.name,
        type: param.type
    };
    if (body.path.substr(-1) === "/" || body.path.substr(-1) === "\\") {
        body.path = body.path.substr(0, body.path.length - 1);
    }
    body.path = body.path.replace(`/${param.name}`, '');
    body.path = getPathWithoutTenentID(body.path);

    if (body.path.substr(-1) === "/" || body.path.substr(-1) === "\\") {
        body.path = body.path.substr(0, body.path.length - 1);
    }
    return fetchRequest('/s1/filemanager/delete', "DELETE", body);
}
const renameService = (param) => {

    let body = {
        path: param.path,
        newFileName: param.newFileName,
        oldFileName: param.oldFileName,
        type: param.type
    };

    if (body.path.substr(-1) === "/" || body.path.substr(-1) === "\\") {
        body.path = body.path.substr(0, body.path.length - 1);
    }
    body.path = body.path.replace(`/${param.oldFileName}`, '');
    body.path = getPathWithoutTenentID(body.path);

    return fetchRequest('/s1/filemanager/rename', "PUT", body);
}
const downloadFileService = (path, filename) => {
    if (path.substr(-1) === "/" || path.substr(-1) === "\\") {
        path = path.substr(0, path.length - 1);
    }
    path = path.replace(`/${filename}`, '');
    path = getPathWithoutTenentID(path);
    let url = `/s1/filemanager/download/?path=${path}&fileName=${filename}`;
    return downloadFileRequest(url);
}
const fmService = {
    getFilesListService: getFilesListService,
    getFoldersListService: getFoldersListService,
    uploadFileService: uploadFileService,
    createDirService: createDirService,
    downloadFileService: downloadFileService,
    renameService: renameService,
    deleteService: deleteService,
    getPathWithoutTenentID: getPathWithoutTenentID
};
export default fmService;