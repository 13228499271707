import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import ListTable from '../component/ListTable';
import {useHistory} from 'react-router-dom';
const RequestedReportsList = () => {
  const history = useHistory();
  return (
    <>
      <Card>
        <CardHeader title="Role List">
          <CardHeaderToolbar>
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => history.push('/rbac/roles/add')}>
              Add Role
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <ListTable />
        </CardBody>
      </Card>
    </>
  );
};

export default RequestedReportsList;
