import React from 'react';
import Loader from 'react-loader-spinner';
export default function Loading(props) {
  return (
    <div className="loaderHolder">
      <div className="loaderMain">
        <Loader type="Circles" color="#ccc" height={100} width={100} />
        <div>{props.message}</div>
      </div>
    </div>
  );
}
