import React, {createContext, useContext, useState, useCallback} from 'react';
import {isEqual, isFunction} from 'lodash';
import {initialFilter} from './TemplateUIHelpers';

const TemplateUIContext = createContext();

export function useTemplateUIContext() {
  return useContext(TemplateUIContext);
}

export const TemplateUIConsumer = TemplateUIContext.Consumer;

export function TemplateUIProvider({templateUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initTemplate = {
    id: undefined,
    title: '',
    actionType: '',
    points: '',
    maxAttempt: '',
    enabled: '',
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initTemplate,
    newTemplateButtonClick: templateUIEvents.newTemplateButtonClick,
    openEditTemplateDialog: templateUIEvents.openEditTemplateDialog,
    openDeleteTemplateDialog: templateUIEvents.openDeleteTemplateDialog,
    // openDeleteCustomersDialog: customersUIEvents.openDeleteCustomersDialog,
    // openFetchCustomersDialog: customersUIEvents.openFetchCustomersDialog,
    // openUpdateCustomersStatusDialog:
    //   customersUIEvents.openUpdateCustomersStatusDialog,
  };

  return (
    <TemplateUIContext.Provider value={value}>
      {children}
    </TemplateUIContext.Provider>
  );
}
