import OffersList from './OffersList';
import * as utils from "../../_helpers/LocalStorageHelpers";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import EditOffer from './EditOffer';
const BaseUrl = process.env.REACT_APP_API_URL;
const instance = axios.create();


export function OffersMgmt() {
  const AuthStr = utils.getStorage('accessToken')

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [offerId, setOfferId] = useState('');
  const [offerDetails, setOfferDetails] = useState({});

  const handleShow = (value) => {
    setShow(true);
    setOfferId(value);
    instance.get(`${BaseUrl}/s1/offer/${value}`, { headers: { Authorization: AuthStr } })
      .then((res) => {
        setOfferDetails(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      });
  }


  const [offers, setOffers] = useState([]);
  useEffect(() => {
    instance.get(`${BaseUrl}/s1/offer`, { headers: { Authorization: AuthStr } })
      .then((res) => {
       // console.log("Offer Data =========================");        
        setOffers(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [AuthStr])

  const handleFormSubmit = (values) => {
    let data = { ...values }
    delete data.image1;
    instance.put(`${BaseUrl}/s1/offer/${offerId}`, data, { headers: { Authorization: AuthStr } })
      .then((res) => {
      })
      .catch((err) => {
        console.log(err)
      })
  }


  return (
    <div>
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-header border-0 pt-5">
          <h1 className="font-size-sm-100 font-weight-boldest text-dark-75 mt-2">
            Offers
          </h1>
        </div>


        <div className="card-body pt-3 pb-2">
          {offers && offers.length <= 0 && <span>Loading...</span>}
          {offers && offers.length > 0 && (
            <OffersList offers={offers} handleShow={handleShow} />
          )}
        </div>

        {offerDetails && (
          <EditOffer show={show} handleClose={handleClose} offerDetails={offerDetails} handleFormSubmit={handleFormSubmit} />
        )}

      </div>
    </div>
  );
}