let nodeCreate = (paramList) => {
    if (typeof paramList !== "object") {
        return false;
    }
    return {
        nodeID: (paramList.nodeID ? paramList.nodeID : ""),
        nodeType: (paramList.nodeType ? paramList.nodeType : ""),
        nodeTitle: (paramList.nodeTitle ? paramList.nodeTitle : "-"),
        description: (paramList.description ? paramList.description : ""),
        xaxis: (paramList.xaxis ? paramList.xaxis : 200),
        yaxis: (paramList.yaxis ? paramList.yaxis : 100),
        nodeShape: {
            width: (paramList.width ? paramList.width : 50),
            height: (paramList.height ? paramList.height : 50),
            style: (paramList.style ? paramList.style : "shape=doubleEllipse;fillColor=green;fontColor=yellow"),
        },
        inputParam: (paramList.inputParam ? paramList.inputParam : {}),
        outputParam: (paramList.outputParam ? paramList.outputParam : {}),
        logo: (paramList.logo ? paramList.logo : ""),

    };
};
export let edge = (edgeID, inboundNodeId, outboundNodeId) => {
    return {
        edgeID: edgeID,
        inboundNodeId: inboundNodeId,
        outboundNodeId: outboundNodeId,
        style: "strokeWidth=3;endArrow=block;endSize=2;endFill=1;strokeColor=blue;rounded=1;",
    };
};

export let startNodeObj = nodeCreate(
    {
        nodeID: "start",
        description: "Start Node",
        nodeType: "start",
        nodeTitle: "Start",
        xaxis: 20,
        yaxis: 100,
        width: 50,
        height: 50,
        style: "shape=doubleEllipse;fillColor=green;fontColor=yellow",
        inputParam: {
            variables: {}
        },
        outputParam: {
            variables: {
            }
        },
        logo: ""
    }
);
export let endNodeObj = nodeCreate({
    nodeID: "end",
    description: "end Node",
    nodeType: "end",
    nodeTitle: "Stop",
    xaxis: 450,
    yaxis: 100,
    width: 50,
    height: 50,
    style: "shape=doubleEllipse;fillColor=red;fontColor=yellow",
    inputParam: {
        variables: {
        }
    },
    outputParam: {
        variables: {
        }
    },
    logo: ""
});
export let dbNodeObj = nodeCreate({
    nodeID: "db",
    description: "DB Provider Element",
    nodeType: "db",
    nodeTitle: "DB Provider Element",
    xaxis: 100,
    yaxis: 90,
    width: 70,
    height: 70,
    style: "rounded=1;strokeColor=red;fillColor=orange;fontColor=white",
    inputParam: {
        dbQuery: "", column: [], variables: {
        }
    },
    outputParam: {
        variables: {
        }
    },
    logo: "dbicon"
});

export let templateNodeObj = nodeCreate({
    nodeID: "template",
    description: "Template Engine",
    nodeType: "template",
    nodeTitle: "Template Engine",
    xaxis: 220,
    yaxis: 90,
    width: 70,
    height: 70,
    style: "rounded=1;strokeColor=red;fillColor=green;fontColor=white",
    inputParam: {
        templateId: "", mapping: [],
        variables: {
        }
    },
    outputParam: {
        variables: {
        }
    },
    logo: "templateicon"
});

export let notificationNodeObj = nodeCreate({
    nodeID: "notification",
    description: "Notification Node",
    nodeType: "notification",
    nodeTitle: "Notification",
    xaxis: 300,
    yaxis: 90,
    width: 70,
    height: 70,
    style: "rounded=1;strokeColor=#0d9cb7;fillColor=#F1F1F1;fontColor=#0d9cb7",
    inputParam: {
        mapping: [],
        variables: {
        }
    },
    outputParam: {
        variables: {
        }
    },
    logo: "emailicon"
});

export let pdfNodeObj = nodeCreate({
    nodeID: "pdf",
    description: "PDF Node",
    nodeType: "pdf",
    nodeTitle: "PDF",
    xaxis: 380,
    yaxis: 90,
    width: 70,
    height: 70,
    style: "rounded=1;strokeColor=red;fillColor=blue;fontColor=white",
    inputParam: {
        variables: {
        }
    },
    outputParam: {
        variables: {
        }
    },
    logo: "pdficon"
});

export let csvNodeObj = nodeCreate({
    nodeID: "csv",
    description: "CSV Node",
    nodeType: "csv",
    nodeTitle: "CSV File Input",
    xaxis: 20,
    yaxis: 200,
    width: 70,
    height: 70,
    style: "rounded=1;strokeColor=red;fillColor=#28659d;fontColor=white",
    inputParam: {
        mapping: [],
        csvHeaders: "",
        column: [],
        variables: {
        }
    },
    outputParam: {
        variables: {
        }
    },
    logo: "csvicon"
});

export let dbloaderNodeObj = nodeCreate({
    nodeID: "dbloader",
    description: "DB Loader Node",
    nodeType: "dbloader",
    nodeTitle: "DB Loader",
    xaxis: 120,
    yaxis: 200,
    width: 70,
    height: 70,
    style: "rounded=1;strokeColor=red;fillColor=green;fontColor=white",
    inputParam: {
        collection_name: "", mapping: [],
        variables: {
        }
    },
    outputParam: {
        variables: {
        }
    },
    logo: "dbImporticon"
});

export let dataexportNodeObj = nodeCreate({
    nodeID: "dataexport",
    description: "Data Exporter Node",
    nodeType: "dataexport",
    nodeTitle: "Data Exporter",
    xaxis: 220,
    yaxis: 200,
    width: 70,
    height: 70,
    style: "rounded=1;strokeColor=green;fillColor=white;fontColor=green",
    inputParam: {
        export_type: "", filename: "", filepath: "", mapping: [],
        variables: {
        }
    },
    outputParam: {
        variables: {
        }
    },
    logo: "dataExporterIcon"
});
