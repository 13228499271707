import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';

import {useSubheader} from '../../../_metronic/layout';
import RewardSummaryList from './pages/RewardSummaryList';
import {IsLoadingHOC} from '../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {RewardSummaryUIProvider} from './RewardSummaryUIContext';
import PincodeSummary from './pages/PincodeSummary';

const RewardSummaryPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle('');
  const rewardSummaryUIEvents = {
    openDeleteRewardSummaryDialog: (id) => {},
  };

  return (
    <RewardSummaryUIProvider rewardSummaryUIEvents={rewardSummaryUIEvents}>
      <Switch>
        <Redirect from="/rewardsummary" exact={true} to="/rewardsummary/list" />
        <Route path="/rewardsummary/list" component={RewardSummaryList} />
        <Route path="/pincodereport" component={PincodeSummary} />
      </Switch>
    </RewardSummaryUIProvider>
  );
};

export default connect(null, {})(IsLoadingHOC(RewardSummaryPage));
