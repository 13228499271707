import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';

import {useSubheader} from '../../../_metronic/layout';
import RetailersList from './pages/RetailersList';
import {IsLoadingHOC} from '../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {RetailersUIProvider} from './RetailersUIContext';

const RetailersPage = ({history, setLoading}) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle('Retailers');
  const retailersUIEvents = {
    newRetailersButtonClick: () => {
      history.push('/retailers/new');
    },
    openEditRetailersDialog: (id) => {
      history.push(`/retailers/${id}/edit`);
    },
    openDeleteRetailersDialog: (id) => {},
  };

  return (
    <RetailersUIProvider retailersUIEvents={retailersUIEvents}>
      <Switch>
        <Redirect from="/retailers" exact={true} to="/retailers/list" />
        <Route path="/retailers/list" component={RetailersList} />
      </Switch>
    </RetailersUIProvider>
  );
};

export default connect(null, {})(IsLoadingHOC(RetailersPage));
