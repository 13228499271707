export const ReceiptSummaryStatusCssClasses = ['danger', 'success', 'info', ''];
export const ReceiptSummaryStatusTitles = [
  'Suspended',
  'Active',
  'Pending',
  '',
];
export const ReceiptSummaryTypeCssClasses = ['success', 'primary', ''];
export const ReceiptSummaryTypeTitles = ['Business', 'Individual', ''];
export const defaultSorted = [{dataField: '_id', order: 'asc'}];
export const sizePerPageList = [
  {text: '3', value: 3},
  {text: '5', value: 5},
  {text: '10', value: 10},
];
export const initialFilter = {
  page: 1,
  limit: 10,
};
