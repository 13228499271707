import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {useDispatch, connect} from 'react-redux';

import Swal from 'sweetalert2';
import {useSubheader} from '../../../_metronic/layout';
import AddTemplate from './pages/AddTemplate';
import {TemplateList} from './pages/TemplateList';
import {TemplateUIProvider} from './TemplateUIContext';
import {IsLoadingHOC} from '../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {deleteTemplateById} from './_redux/action';

const TemplatePage = ({history, setLoading, deleteTemplateById}) => {
  const suhbeader = useSubheader();
  const dispatch = useDispatch();
  suhbeader.setTitle('Template');

  const templateUIEvents = {
    newTemplateButtonClick: () => {
      history.push('/template/new');
    },
    openEditTemplateDialog: (id) => {
      history.push(`/template/${id}/edit`);
    },
    openDeleteTemplateDialog: (id) => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          deleteTemplateById(id)
            .then((res) => {
              setLoading(false);

              Swal.fire('Deleted!', 'Template has been deleted.', 'success');
            })
            .catch((error) => {
              setLoading(false);
              console.log(error);
            });
        }
      });
    },
    // openDeleteCustomersDialog: () => {
    //   history.push(`/e-commerce/customers/deleteCustomers`);
    // },
    // openFetchCustomersDialog: () => {
    //   history.push(`/e-commerce/customers/fetch`);
    // },
    // openUpdateCustomersStatusDialog: () => {
    //   history.push('/e-commerce/customers/updateStatus');
    // },
  };
  return (
    <TemplateUIProvider templateUIEvents={templateUIEvents}>
      <Switch>
        <Redirect from="/template" exact={true} to="/template/list" />
        <Route path="/template/list" component={TemplateList} />
        <Route path="/template/new" component={AddTemplate} />
        <Route path="/template/:id/edit" component={AddTemplate} />
      </Switch>
    </TemplateUIProvider>
  );
};

export default connect(null, {deleteTemplateById})(IsLoadingHOC(TemplatePage));
