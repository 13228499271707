import React from 'react';
import {Modal, Button} from 'react-bootstrap';

const ModelForm = (props) => {
  return (
    <Modal
      // className="container-fluid"
      size="md"
      show={props.modelVisible}
      onHide={() => {
        props.dialogClose();
      }}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title">
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          SQS Process Started
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group row">
          <div class="col-12 text-center text-success">
            Report Generation process has been started. Your report Filename is{' '}
            <strong>{props.fileName}</strong>. For checking the status or
            download please{' '}
            <a
              className="btn-link"
              href="/requestedreports/list"
              title="Requested(SQS) Report">
              Click here
            </a>
            .
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            props.dialogClose();
          }}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModelForm;
