import React, {useState} from 'react';
import {Spinner} from 'react-bootstrap';
import {postRequest} from '../../../_helpers/APIRequestHelpers';
import {Tabs, Tab} from 'react-bootstrap/';
import './NodeProperties.css';
export default function DBNodeProperties(props) {
  let nodeProperties = props.nodeProperty;
  let columns = nodeProperties.inputParam['column']
    ? nodeProperties.inputParam.column
    : [];
  columns = nodeProperties.inputParam.dbQuery !== '' ? columns : [];
  let [dbColumnList, setDBColumnList] = useState(columns);
  let [activeTabs, setaActiveTabs] = useState('inputTab');
  let [selectedColumn, setSelectedColumn] = useState(
    dbColumnList.filter((val) => val.selected === true),
  );
  const [exeDbQuery, setExeDbQuery] = useState(false);
  let dbqueryTextArea = React.createRef();
  let executeQuery = (event) => {
    event.preventDefault();
    let dbQuery = dbqueryTextArea.current.value;
    if (dbQuery && dbQuery !== '' && !exeDbQuery) {
      setExeDbQuery(true);
      nodeProperties.inputParam.dbQuery = JSON.stringify(
        dbqueryTextArea.current.value,
      );
      props.parentCallback(nodeProperties, 1);
      const body = {
        dbQuery: JSON.stringify(dbqueryTextArea.current.value),
      };
      postRequest('/s1/automation/parse-query', body, (response, error) => {
        if (error) {
          alert(error);
          setDBColumnList([]);
          setExeDbQuery(false);
        } else if (response) {
          setDBColumnList(response.data.data);
          setExeDbQuery(false);
        }
      });
    }
  };
  let prepareOutput = (event) => {
    event.preventDefault();
    let tempArr = dbColumnList.filter((val) => val.selected === true);
    if (tempArr.length === 0) {
      alert('Please select at least one column');
    } else {
      setSelectedColumn(JSON.parse(JSON.stringify(tempArr)));
      nodeProperties.inputParam.column = dbColumnList;
      let outPutVariables = {[nodeProperties.nodeID]: []};
      for (let i in tempArr) {
        outPutVariables[nodeProperties.nodeID].push({
          key: tempArr[i].name,
          value: '',
          type: 'db',
        });
      }
      nodeProperties.outputParam.variables = Object.assign(
        {},
        nodeProperties.inputParam.variables,
        outPutVariables,
      );
      setaActiveTabs('outputTab');
      props.parentCallback(nodeProperties, 1);
    }
  };
  let columnSelected = (ind) => {
    let tempColumnList = [...dbColumnList];
    tempColumnList[ind]['selected'] = tempColumnList[ind].selected
      ? false
      : true;
    setDBColumnList(tempColumnList);
  };
  let counter = 1;
  return (
    <>
      <h2 className="leftToolBar">
        Object Properties
        <br />
        <span>DB Node</span>
      </h2>
      <div className="leftToolBarContainer">
        <Tabs
          activeKey={activeTabs}
          transition={false}
          id="noanim-tab-example"
          onSelect={(key) => {
            setaActiveTabs(key);
          }}>
          <Tab eventKey={'inputTab'} title="Input">
            <table className="table table-striped" id="dbNodeTable">
              <tbody>
                <tr>
                  <td width="25%">
                    <strong> Query</strong>
                  </td>
                  <td>
                    <textarea
                      rows="5"
                      id="dbqueryTextArea"
                      ref={dbqueryTextArea}
                      className="md-textarea form-control"
                      defaultValue={
                        nodeProperties.inputParam.dbQuery
                          ? JSON.parse(nodeProperties.inputParam.dbQuery)
                          : ''
                      }></textarea>
                    <button
                      type="button"
                      className="custombtn btn btn-dark"
                      disabled={!exeDbQuery ? '' : 'disabled'}
                      title="Execute Query"
                      onClick={executeQuery}>
                      {exeDbQuery ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        ''
                      )}{' '}
                      !Execute
                    </button>
                  </td>
                </tr>
                <tr>
                  <td width="25%">
                    <strong> Column</strong>
                  </td>
                  <td>
                    <div className="container">
                      {dbColumnList.map((val, ind) => {
                        return (
                          <div className="form-check" key={ind}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={val.selected}
                              id={'key' + ind}
                              onChange={() => columnSelected(ind)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={'key' + ind}>
                              {val.name}
                            </label>
                          </div>
                        );
                      })}
                      {dbColumnList.length > 0 ? (
                        <p>
                          <button
                            type="button"
                            className="custombtn btn btn-dark"
                            title="Prepare Output"
                            onClick={prepareOutput}>
                            Prepare Output
                          </button>
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Tab>
          <Tab
            eventKey={'outputTab'}
            title="Output"
            disabled={selectedColumn.length > 0 ? false : 'disabled'}>
            <h5>Selected Column</h5>
            {selectedColumn.map((val, ind) => {
              if (val.selected) {
                return (
                  <div className="form-check" key={ind}>
                    <label
                      className="form-check-label"
                      key={'outputlable' + ind}>
                      {counter++} {val.name}
                    </label>
                  </div>
                );
              } else {
                return '';
              }
            })}
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
