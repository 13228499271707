export const SAVE_RECEIPT_LIST = 'SAVE_RECEIPT_LIST';
export const SAVE_NEW_RECEIPT_LIST = 'SAVE_NEW_RECEIPT_LIST';
export const SAVE_PENDING_RECEIPT_LIST = 'SAVE_PENDING_RECEIPT_LIST';
export const SAVE_APPROVED_RECEIPT_LIST = 'SAVE_APPROVED_RECEIPT_LIST';
export const SAVE_REJECTED_RECEIPT_LIST = 'SAVE_REJECTED_RECEIPT_LIST';
export const SAVE_AMBIGUOUS_RECEIPT_LIST = 'SAVE_AMBIGUOUS_RECEIPT_LIST';
export const SAVE_SEARCH_RESULT_RECEIPT_LIST =
  'SAVE_SEARCH_RESULT_RECEIPT_LIST';
export const REMOVED_FROM_CHEKOUT = 'REMOVED_FROM_CHEKOUT';
export const Add_TO_CHEKOUT = 'Add_TO_CHEKOUT';
export const SAVE_RECEIPT_LIST_NEW = 'SAVE_RECEIPT_LIST_NEW';
export const UPDATE_RECEIPT_DATA = 'UPDATE_RECEIPT_DATA';
export const REFRES_RECEIPT = 'REFRES_RECEIPT';
