import React, {useState} from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import RewardSummaryFilter from '../component/RewardSummaryFilter';
import RewardSummaryTable from '../component/RewardSummaryTable';

const RewardSummaryList = () => {
  const [campaignId, setCampaignId] = useState();

  return (
    <>
      <Card>
        <CardHeader title="Reward Summary">
          <CardHeaderToolbar>
            {/* <button
              className="btn btn-primary"
              type="button"
              onClick={exportToCsv}>
              Export to CSV
            </button> */}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <RewardSummaryFilter
            setCampaignId={setCampaignId}
            campaignId={campaignId}
          />
          <RewardSummaryTable campaignId={campaignId} />
        </CardBody>
      </Card>
    </>
  );
};

export default RewardSummaryList;
