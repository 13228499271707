import * as Yup from 'yup';
const AddTemplatevalidationSchema = Yup.object({
  templateName: Yup.string()
    .min(2, 'Title should be greater than 2 letters')
    .max(100, 'Title should be less than 100 letters')
    .required('Required'),
  templateTag: Yup.string()
    .min(5, 'Description should be greater than 5 letters')
    .max(50, 'Description should be less than 500 letters')
    .required('Required'),
  body: Yup.string().required('Required'),
  templateFieldsDef: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      fieldType: Yup.string().required('Field Type is required'),
    }),
  ),
  status: Yup.string().required('Required'),
  shortMessage: Yup.string(),
  subject: Yup.string().required('Email Subject is Required'),
});

export default AddTemplatevalidationSchema;
