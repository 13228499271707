export const SAVE_ORDER_LIST = 'SAVE_ORDER_LIST';
export const LOADING = 'LOADING';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const ORDER_DELETE = 'ORDER_DELETE';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const SET_QUANTITY = 'SET_QUANTITY';
export const UPDATE_COLUMN_VALUE = 'UPDATE_COLUMN_VALUE';
export const EMPTY_CHECKOUT = 'EMPTY_CHECKOUT';
export const SAVE_ORDER_HISTORY_LIST = 'SAVE_ORDER_HISTORY_LIST';
export const ORDER_DETAILS = 'ORDER_DETAILS';
export const SAVE_REWARD_POINTS_LIST = 'SAVE_REWARD_POINTS_LIST';
export const REWARD_ORDER_POINTS_FOR_EDIT = 'REWARD_ORDER_POINTS_FOR_EDIT';
export const UPDATE_REWARD_ORDER_POINTS = 'UPDATE_REWARD_ORDER_POINTS';
