import api from '../../../../api';
import { SAVE_LIST, REFERSH_REQUESTEDREPORTS_LIST, LOADING } from './type';
import { toast } from 'react-toastify';
export const getList = (query, screen) => async (dispatch) => {
  const newQuery = { ...query, page: query.page - 1 };
  let queryString = Object.keys(newQuery)
    .map((key) => key + '=' + newQuery[key])
    .join('&');
  dispatch({ type: LOADING, payload: true });
  return new Promise(async (resolve, reject) => {
    let data = await api.post(
      `/s1/auth/rbac-actions?actionType=get-roles&${queryString}${screen ? `&page=${screen}` : ''
      }`,
    );
    if (data.httpCode >= 200 && data.httpCode <= 299) {
      dispatch({ type: LOADING, payload: false });
      dispatch({ type: SAVE_LIST, payload: data.data });
      resolve(data.data);
    } else {
      dispatch({ type: LOADING, payload: false });
      reject('error');
      dispatch({ type: SAVE_LIST, payload: [] });
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }

      dispatch({ type: LOADING, payload: false });
    }
  });
  // });
};
export const addEditRoleAction = (body, id) => {
  let role = 'add-role';
  if (id) {
    role = 'edit-role';
    body = { ...body };
    return api.patch(
      `/s1/auth/rbac-actions?actionType=${role}&roleId=${id}`,
      body,
    );
  }
  return api.post(`/s1/auth/rbac-actions?actionType=${role}`, body);
};

export const getRoleDetailsAction = (id) => {
  return api.get(`/s1/auth/rbac-actions?actionType=get-roles&roleId=${id}`);
};
export const getAllRolesAction = (page) => {
  return api.post(
    `/s1/auth/rbac-actions?actionType=get-roles&page=0&limit=10000${page ? `&page=${page}` : ''
    }`,
  );
};
export const getAllRolesActionFilter = (page) => {
  return api.post(
    `/s1/auth/rbac-actions?actionType=get-roles-search&page=0&limit=10000${page ? `&page=${page}` : ''
    }`,
  );
};
export const getRolePermissionAction = (id) => {
  return api.get(
    `/s1/auth/rbac-actions?actionType=get-role-permissions&roleId=${id}`,
  );
};

export const updateRolePermissionAction = (body) => {
  return api.put(
    `/s1/auth/rbac-actions?actionType=update-role-permissions`,
    body,
  );
};

export const getAllModulesAction = () => {
  return api.get(`/s1/auth/rbac-actions?actionType=get-modules`);
};
