const checkErrorResponse = (error) => {
  // console.log('response for error', error['response']);
  let response = {};
  if (error['response']) {
    const check = varifyResponse(error.response);
    if (check) {
      let {checher, result} = structureCheck(error.response.data);
      if (checher) {
        response = error.response.data;
      } else {
        response = result;
      }
    } else {
      response = {
        httpCode: error.response.status,
        errors: [{message: 'Server issue : ' + error.response.statusText}],
      };
    }
  } else {
    response = error;
  }

  return response;
};

const varifyResponse = (response) => {
  // console.log('response chdckker', response);
  return typeof response.data === 'object' && response.data !== null;
};

const structureCheck = (response) => {
  // console.log('structureCheck', response);
  let checker = true;
  if (!response?.httpCode) {
    checker = false;
    response['httpCode'] = 403;
    response['errors'] = [{message: 'Structure Error'}];
  }

  return {result: response, checker};
};

export {checkErrorResponse};
