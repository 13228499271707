import {
  SAVE_ORDER_LIST,
  LOADING,
  UPDATE_ORDER,
  ORDER_DELETE,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  SET_QUANTITY,
  UPDATE_COLUMN_VALUE,
  EMPTY_CHECKOUT,
  SAVE_ORDER_HISTORY_LIST,
  ORDER_DETAILS,
  SAVE_REWARD_POINTS_LIST,
  REWARD_ORDER_POINTS_FOR_EDIT,
} from './type';

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  rewardPoints: null,
  orderHistory: null,
  metaData: {limit: 10, currentPageNo: 0, totalRecs: 10, nextPageNo: 1},
  viewOrder: undefined,
  lastError: null,
  checkout: {
    items: [],
  },
  itemInCart: false,
  rewardOrderPointForEdit: null,
};
const OrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_ORDER_LIST: {
      const {content, metaData} = action.payload;
      const newContent = content?.map((item) => {
        return {...item, qty: 1};
      });
      return {
        ...state,
        entities: newContent ? newContent : null,
        totalCount: metaData?.totalRecs,
        metaData: metaData,
      };
    }
    case REWARD_ORDER_POINTS_FOR_EDIT: {
      return {...state, rewardOrderPointForEdit: action.payload};
    }
    case SAVE_REWARD_POINTS_LIST: {
      const {content, metaData} = action.payload;
      return {
        ...state,
        rewardPoints: content ? content : null,
        totalCount: metaData?.totalRecs,
        metaData: metaData,
      };
    }
    case SAVE_ORDER_HISTORY_LIST: {
      const {content, metaData, recordCount} = action.payload;
      return {
        ...state,
        orderHistory: content ? content : null,
        totalCount: metaData?.totalRecs,
        metaData: metaData,
      };
    }
    case UPDATE_COLUMN_VALUE: {
      const {id, dataField, value} = action.payload;
      const updated = state.entities?.map((item) => {
        if (item.id === id) {
          return {...item, [dataField]: value};
        }
        return item;
      });
      return {
        ...state,
        entities: updated,
      };
    }
    case LOADING: {
      return {...state, listLoading: action.payload};
    }

    case ORDER_DETAILS: {
      return {...state, viewOrder: action.payload};
    }
    case ADD_TO_CART: {
      // console.log('state chekcout', state.checkout);
      // let checkout = [];
      // if (state.checkout.some((item) => item.id === action.payload.id)) {
      //   checkout = state.checkout;
      // } else {
      //   checkout = [...state.checkout, action.payload];
      // }

      return {
        ...state,
        checkout: action.payload,
        itemInCart: true,
      };
    }
    case EMPTY_CHECKOUT: {
      return {
        ...state,
        checkout: {items: []},
        itemInCart: false,
      };
    }
    case REMOVE_FROM_CART: {
      // console.log('remove', action.payload);
      const newCartList = state.checkout.filter((item) => {
        return item.id !== action.payload;
      });
      return {...state, checkout: newCartList};
    }
    case SET_QUANTITY: {
      let items = state.checkout?.items?.map((item) => {
        if (item.itemId === action.payload.id) {
          return {...item, quantity: action.payload.quantity};
        }
        return item;
      });
      return {
        ...state,
        checkout: {...state.checkout, items: items},
      };
    }
    case ORDER_DELETE: {
      const entities = state.entities.filter(
        (item) => item.id != action.payload,
      );
      return {...state, entities: entities, totalCount: state.totalCount - 1};
    }

    case UPDATE_ORDER: {
      let entities = state.entities.map((entity) => {
        if (entity.id === action.payload.id) {
          return action.payload;
        }
        return entity;
      });
      return {...state, entities: entities};
    }
    case 'CLEAR_ALL': {
      return initialState;
    }
    default:
      return state;
  }
};

export default OrderReducer;
