import React, {useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import IsLoadingHOC from '../../../layout/components/HOC/IsLoadingHOC';
import {Row, Col} from 'react-bootstrap';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import {getSummeryData} from '../_redux/action';
import DatePicker from 'react-datepicker';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {Card, CardBody} from '../../controls';
import jwt_decode from 'jwt-decode';

function numberWithCommas(x) {
  return x ? x.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
}

const SummeryData = ({getSummeryData, setLoading}) => {
  const [data, setData] = useState([]);
  const [gaData, setGaData] = useState([]);
  const [reportList, setReportList] = useState([]);
  const {activationEnd, activationStart} = useSelector(
    (state) => state.auth.tenantConfig,
  );
  const {campaignId} = useSelector((state) => ({
    campaignId: state.auth.campaignId,
  }));
  const {accessToken} = useSelector((state) => state.auth);
  let decoded = jwt_decode(accessToken);

  const [startDate, setStartDate] = useState(new Date(activationStart));
  const [endDate, setEndDate] = useState(new Date(activationEnd));
  useEffect(() => {
    setLoading(true);
    let sDate = moment(startDate).format('YYYY-MM-DD');
    let eDate = moment(endDate).format('YYYY-MM-DD');
    getSummeryData({
      startDate: sDate,
      endDate: eDate,
      tag: 'LIST',
    })
      .then((res) => {
        setLoading(false);
        setReportList(res);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [campaignId]);
  useEffect(() => {
    setLoading(true);
    let sDate = moment(startDate).format('YYYY-MM-DD');
    let eDate = moment(endDate).format('YYYY-MM-DD');
    setData([]);
    getRptData();
    getSummeryData({
      startDate: sDate,
      endDate: eDate,
      tag: 'GA',
    })
      .then((res) => {
        setLoading(false);
        setGaData(res);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [campaignId, reportList, startDate, endDate]);
  const getRptData = async () => {
    let rptData = [];
    let sDate = moment(startDate).format('YYYY-MM-DD');
    let eDate = moment(endDate).format('YYYY-MM-DD');
    for (let i in reportList) {
      try {
        setLoading(true);
        let row = await getSummeryData({
          startDate: sDate,
          endDate: eDate,
          tag: 'DETAIL',
          reportId: reportList[i].reportId,
        });
        if (row?.length > 0) {
          rptData.push(row[0]);
        }
        setLoading(false);
      } catch (err) {
        console.log('error', err);
        setLoading(false);
      }
    }
    setData(rptData);
  };
  const downloadFile = ({data, fileName, fileType}) => {
    const blob = new Blob([data], {type: fileType});

    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };
  const sd = moment(startDate).format('YYYY-MM-DD');
  const ed = moment(endDate).format('YYYY-MM-DD');

  const exportToCsv = async (e) => {
    e.preventDefault();

    // Headers for each column
    let headers = ['Name, Value'];
    setLoading(true);
    //const users = data;

    setLoading(false);
    let summaryCsv = data.reduce((acc, summ) => {
      const {name, value} = summ;
      acc.push([name, value].join(','));
      return acc;
    }, []);
    const dateToUpdate = [`Start Date,${sd}`, `End Date,${ed}`];
    const updatedSummaryCsv = [...summaryCsv, ...dateToUpdate];

    downloadFile({
      data: [...headers, ...updatedSummaryCsv].join('\n'),
      fileName: 'summary.csv',
      fileType: 'text/csv',
    });
  };
  const renderHtml = (data) => {
    return data.map((item, index) => {
      return (
        <Col xs={3} key={index}>
          <Card style={{backgroundColor: '#C8C7D6', height: '125px'}}>
            <CardBody>
              <div className="font-weight-bold" style={{fontSize: '16px'}}>
                {item.name}
              </div>
              <div className="font-weight-bolder">
                <span style={{fontSize: '22px'}}>
                  {item.value ? numberWithCommas(+item.value) : item.value}
                </span>
              </div>
            </CardBody>
          </Card>
        </Col>
      );
    });
  };

  return (
    <>
      <Row>
        <Col>
          <div className={`card card-custom gutter-b`}>
            {/* begin::Header */}
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  Please Select the Date Range
                </span>
              </h3>
              <div className="d-flex  pl-5 flex-row">
                <div className="mr-3">
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    className="form-control form-control-md form-control-solid "
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    showYearDropdown
                    dropdownMode="select"
                    enableTabLoop
                  />
                </div>
                <div className="mr-3">
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    className="form-control form-control-md form-control-solid"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    showYearDropdown
                    dropdownMode="select"
                    enableTabLoop
                  />
                </div>
                <div className="mr-3">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={exportToCsv}>
                    Export to CSV
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="">
        <Col>
          <Card style={{backgroundColor: '#A8AAC0', height: '125px'}}>
            <CardBody>
              <Row>
                <Col className="d-flex justify-content-center">
                  <div className="campaign-icon">
                    <SupervisorAccountIcon style={{fontSize: 27}} />
                  </div>
                </Col>
                {!decoded?.roles?.includes('DASHBOARD') ? (
                  <Col xs={8}>
                    <Link to={`/user/list?start=${sd}&end=${ed}`}>
                      <div className="campaign-metrics-name">
                        {data?.length > 0 && data[0].name}
                      </div>
                      <div className="campaign-metrics-value mt-2">
                        {data?.length > 0 &&
                          (!isNaN(data[0].value)
                            ? numberWithCommas(+data[0].value)
                            : data[0].value)}
                      </div>
                    </Link>
                  </Col>
                ) : (
                  <Col xs={8}>
                    <div className="campaign-metrics-name">
                      {data?.length > 0 && data[0].name}
                    </div>
                    <div className="campaign-metrics-value mt-2">
                      {data?.length > 0 &&
                        (!isNaN(data[0].value)
                          ? numberWithCommas(+data[0].value)
                          : data[0].value)}
                    </div>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card style={{backgroundColor: '#A8AAC0', height: '125px'}}>
            <CardBody>
              <Row>
                <Col className="d-flex justify-content-center">
                  <div className="campaign-icon">
                    <SupervisorAccountIcon style={{fontSize: 27}} />
                  </div>
                </Col>
                {!decoded?.roles?.includes('DASHBOARD') ? (
                  <Col xs={8}>
                    <Link to={`/receipt/search?start=${sd}&end=${ed}`}>
                      <div className="campaign-metrics-name">
                        {data?.length > 1 && data[1].name}
                      </div>
                      <div className="campaign-metrics-value mt-2">
                        {' '}
                        {data?.length > 1 &&
                          (!isNaN(data[1].value)
                            ? numberWithCommas(+data[1].value)
                            : data[1].value)}
                      </div>
                    </Link>
                  </Col>
                ) : (
                  <Col xs={8}>
                    <div className="campaign-metrics-name">
                      {data?.length > 1 && data[1].name}
                    </div>
                    <div className="campaign-metrics-value mt-2">
                      {' '}
                      {data?.length > 1 &&
                        (!isNaN(data[1].value)
                          ? numberWithCommas(+data[1].value)
                          : data[1].value)}
                    </div>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card style={{backgroundColor: '#A8AAC0', height: '125px'}}>
            <CardBody>
              <Row>
                <Col className="d-flex justify-content-center">
                  <div className="campaign-icon">
                    <SupervisorAccountIcon style={{fontSize: 27}} />
                  </div>
                </Col>
                <Col xs={8}>
                  <div className="campaign-metrics-name">
                    {data?.length > 2 && data[2].name}
                  </div>
                  <div className="campaign-metrics-value mt-2">
                    {' '}
                    {data?.length > 2 &&
                      (!isNaN(data[2].value)
                        ? numberWithCommas(+data[2].value)
                        : data[2].value)}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        {data?.length > 3 && renderHtml(data.slice(3))}
        {gaData?.length > 0 && data.length > 0 && renderHtml(gaData)}
      </Row>
    </>
  );
};

export default connect(null, {getSummeryData})(IsLoadingHOC(SummeryData));
