import api from '../../../../api';
import {
  SAVE_REQUESTEDREPORTS_LIST,
  REFERSH_REQUESTEDREPORTS_LIST,
  LOADING,
} from './type';
import {toast} from 'react-toastify';
export const getRequestedReportsList = (query) => async (dispatch) => {
  const newQuery = {...query, page: query.page - 1};
  let queryString = Object.keys(newQuery)
    .map((key) => key + '=' + newQuery[key])
    .join('&');
  dispatch({type: LOADING, payload: true});
  return new Promise(async (resolve, reject) => {
    let data = await api.get(`/s1/report/report-process-logs?${queryString}`);
    if (data.httpCode >= 200 && data.httpCode <= 299) {
      dispatch({type: LOADING, payload: false});
      dispatch({type: SAVE_REQUESTEDREPORTS_LIST, payload: data.data});
      resolve(data.data);
    } else {
      dispatch({type: LOADING, payload: false});
      reject('error');
      dispatch({type: SAVE_REQUESTEDREPORTS_LIST, payload: []});
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }

      dispatch({type: LOADING, payload: false});
    }
  });
  // });
};
export const refreshRequestedReportsStatus = (id) => async (dispatch) => {
  dispatch({type: LOADING, payload: true});
  return new Promise(async (resolve, reject) => {
    let data = await api.get(
      `/s1/report/report-process-logs?reportProcessId=${id}`,
    );
    if (data.httpCode >= 200 && data.httpCode <= 299) {
      dispatch({type: LOADING, payload: false});
      let row = data?.data?.content || [];
      row = row.length > 0 ? row[0] : row;
      dispatch({type: REFERSH_REQUESTEDREPORTS_LIST, payload: row});
      resolve(data.data);
    } else {
      dispatch({type: LOADING, payload: false});
      reject('error');
      //dispatch({type: SAVE_REQUESTEDREPORTS_LIST, payload: []});
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }

      dispatch({type: LOADING, payload: false});
    }
  });
  // });
};

export const downloadFileAction = (path, filename) => {
  let url = `/s1/filemanager/download/?path=${path}&fileName=${filename}`;
  return api.get(url);
};
