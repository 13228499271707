import React, {useState} from 'react';
import {Tabs, Tab} from 'react-bootstrap/';
import SelectBox from './SelectBox';
import './NodeProperties.css';
export default function CsvNodeProperties(props) {
  let nodeProperties = props.nodeProperty;
  let columns = nodeProperties.inputParam['column']
    ? nodeProperties.inputParam.column
    : [];
  let [columnList, setColumnList] = useState(columns);
  let [csvHeaders, setCsvHeaders] = useState(
    nodeProperties.inputParam.csvHeaders,
  );
  let [activeTabs, setaActiveTabs] = useState('inputTab');
  let [inputParamMapping, setInputParamMapping] = useState(
    nodeProperties.inputParam.mapping.length !== 0
      ? nodeProperties.inputParam.mapping
      : [
          {
            csv_key: 'csvfile',
            required: 'true',
            input_key: {},
          },
        ],
  );
  let [selectedColumn, setSelectedColumn] = useState(
    columnList.filter((val) => val.selected === true),
  );
  let inputNodeVariableField = nodeProperties.inputParam.variables
    ? nodeProperties.inputParam.variables
    : {};
  let parseHeaderTextareaData = (event) => {
    event.preventDefault();
    let cols = [];
    let headerText = csvHeaders;
    if (headerText && headerText !== '') {
      let splitCols = headerText.split(',');
      for (let i in splitCols) {
        cols.push({
          name: splitCols[i].trim(),
          selected: false,
        });
      }
    }
    setColumnList(cols);
  };
  let prepareOutput = (event) => {
    event.preventDefault();
    let checkList = inputParamMapping.filter(
      (val, ind) =>
        val.required &&
        (!val['input_key'] || Object.entries(val['input_key']).length === 0),
    );
    if (checkList.length > 0) {
      let ErrorMessage = '';
      let reqVariablePending = [];
      checkList.forEach((val) => {
        reqVariablePending.push(val.csv_key);
      });
      ErrorMessage += reqVariablePending.toString() + ' is Required';
      alert(ErrorMessage);
      return false;
    }
    let tempArr = columnList.filter((val) => val.selected === true);
    if (tempArr.length === 0) {
      alert('Please select at least one column');
    } else {
      setSelectedColumn(JSON.parse(JSON.stringify(tempArr)));
      nodeProperties.inputParam.column = columnList;
      let outPutVariables = {[nodeProperties.nodeID]: []};
      for (let i in tempArr) {
        outPutVariables[nodeProperties.nodeID].push({
          key: tempArr[i].name,
          value: '',
          type: 'csv',
        });
      }
      nodeProperties.inputParam.mapping = inputParamMapping;
      nodeProperties.inputParam.csvHeaders = csvHeaders;
      nodeProperties.outputParam.variables = Object.assign(
        {},
        nodeProperties.inputParam.variables,
        outPutVariables,
      );
      setaActiveTabs('outputTab');
      props.parentCallback(nodeProperties, 1);
    }
  };
  let columnSelected = (ind) => {
    let tempColumnList = [...columnList];
    tempColumnList[ind]['selected'] = tempColumnList[ind].selected
      ? false
      : true;
    setColumnList(tempColumnList);
  };
  let mapVariable = (templateKey, ind, e) => {
    let selectedVal = e.target.value;
    let paramMapping = [...inputParamMapping];
    if (selectedVal && selectedVal !== '') {
      let details = selectedVal.split('|');
      let nodeID = details.length > 0 ? details[0] : '';
      let key = details.length > 1 ? details[1] : '';
      paramMapping[ind]['input_key'] = {
        key: key,
        nodeID: nodeID,
      };
    } else {
      paramMapping[ind]['input_key'] = {};
    }
    setInputParamMapping(paramMapping);
  };
  let counter = 1;
  return (
    <>
      <h2 className="leftToolBar">
        Object Properties
        <br />
        <span>CSV File Input Node</span>
      </h2>
      <div className="leftToolBarContainer">
        <Tabs
          activeKey={activeTabs}
          transition={false}
          id="noanim-tab-example"
          onSelect={(key) => {
            setaActiveTabs(key);
          }}>
          <Tab eventKey={'inputTab'} title="Input">
            <table className="table table-striped" id="dbNodeTable">
              <tbody>
                {inputParamMapping.map((val, ind) => {
                  return (
                    <tr>
                      <td width="25%">
                        <strong> {val.csv_key}</strong>
                      </td>
                      <td>
                        <SelectBox
                          callBack={mapVariable}
                          key_name={val.name}
                          key_ind={ind}
                          selectedVal={val['input_key'] ? val.input_key : {}}
                          inputNodeVariableField={inputNodeVariableField}
                        />
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td width="25%">
                    <strong> Headers</strong>
                  </td>
                  <td>
                    <textarea
                      rows="5"
                      onChange={(e) => setCsvHeaders(e.target.value)}
                      id="headerTextArea"
                      className="md-textarea form-control"
                      defaultValue={csvHeaders}></textarea>
                    <button
                      type="button"
                      className="custombtn btn btn-dark"
                      disabled={csvHeaders !== '' ? '' : 'disabled'}
                      title="Parse"
                      onClick={parseHeaderTextareaData}>
                      Parse
                    </button>
                  </td>
                </tr>
                <tr>
                  <td width="25%">
                    <strong> Column</strong>
                  </td>
                  <td>
                    <div className="container">
                      {columnList.map((val, ind) => {
                        return (
                          <div className="form-check" key={ind}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={val.selected}
                              id={'key' + ind}
                              onChange={() => columnSelected(ind)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={'key' + ind}>
                              {val.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <p align="center">
              {columnList.length > 0 ? (
                <p>
                  <button
                    type="button"
                    className="custombtn btn btn-dark"
                    title="Prepare Output"
                    onClick={prepareOutput}>
                    Prepare Output
                  </button>
                </p>
              ) : (
                ''
              )}
            </p>
          </Tab>
          <Tab
            eventKey={'outputTab'}
            title="Output"
            disabled={selectedColumn.length > 0 ? false : 'disabled'}>
            <h5>Selected Column</h5>
            {selectedColumn.map((val, ind) => {
              if (val.selected) {
                return (
                  <div className="form-check" key={ind}>
                    <label
                      className="form-check-label"
                      key={'outputlable' + ind}>
                      {counter++} {val.name}
                    </label>
                  </div>
                );
              } else {
                return '';
              }
            })}
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
