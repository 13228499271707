import {SAVE_RETAILERS_LIST, LOADING} from './type';

const initialState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  lastError: null,
};
const RetailersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_RETAILERS_LIST: {
      const {content, metaData} = action.payload;
      return {
        ...state,
        entities: content ? content : null,
        totalCount: metaData?.totalRecs,
        metaData: metaData,
      };
    }
    case LOADING: {
      return {...state, listLoading: action.payload};
    }

    case 'CLEAR_ALL': {
      return initialState;
    }
    default:
      return state;
  }
};

export default RetailersReducer;
