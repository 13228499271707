import React, {useState, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import {connect} from 'react-redux';
import moment from 'moment';
import {getReportingList} from '../_redux/action';
import BootstrapTable from 'react-bootstrap-table-next';
import {useSelector} from 'react-redux';
import paginationFactory from 'react-bootstrap-table2-paginator';
import IsLoadingHOC from '../../../layout/components/HOC/IsLoadingHOC';
import ErrorBoundary from '../../../_helpers/ErrorBoundary';

const ReportListingTable = ({getReportingList, setLoading, showModel, url}) => {
  const {totalCount, reportingList} = useSelector((state) => state.report);
  const {campaignId} = useSelector((state) => state.auth);
  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);

  useEffect(() => {
    if (campaignId) {
      getList(page, pagePerSize);
    }
  }, [page, pagePerSize, campaignId]);

  const getList = (page, sizePerPage) => {
    setLoading(true);
    getReportingList(page, sizePerPage, url)
      .then((res) => {
        setLoading(false);
        setPage(page);
      })
      .catch((error) => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
    },
    {
      dataField: 'createdAt',
      text: 'Created At',
      formatter: (cellContent, row, rowId) => {
        return <span>{moment(row.createdAt).format('YYYY-MM-DD')}</span>;
      },
    },

    {
      dataField: 'action',
      text: 'Actions',
      formatter: (cellContent, row) => {
        return (
          <>
            <Button variant="info" onClick={() => showModel(row?.id)}>
              View Report
            </Button>
          </>
        );
      },

      classes: 'text-right pr-0',
      headerClasses: 'text-right pr-3',
      // style: {
      //   minWidth: '100px',
      // },
    },
  ];

  const options =paginationFactory( {
    totalSize: totalCount,
    sizePerPage: pagePerSize,
    sizePerPageList: [
      {text: '10', value: 10},
      {text: '20', value: 20},
      {text: '50', value: 50},
    ],
    page: page,
    onSizePerPageChange: (sizePerPage) => {
      setPagePerSize(sizePerPage);
      setPage(1);
    },
    onPageChange: (page) => {
      setLoading(true);
      setPage(page);
    },
  });

  //console.log('options', options);
  return (
    <ErrorBoundary>
      {reportingList && (
        <BootstrapTable
          keyField="id"
          data={reportingList}
          bootstrap4
          remote
          columns={columns}
          pagination={options}
          bordered={false}
        />
      )}
    </ErrorBoundary>
  );
};

export default connect(null, {getReportingList})(
  IsLoadingHOC(ReportListingTable),
);
