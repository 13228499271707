export const RetailersStatusCssClasses = ['danger', 'success', 'info', ''];
export const RetailersStatusTitles = ['Suspended', 'Active', 'Pending', ''];
export const RetailersTypeCssClasses = ['success', 'primary', ''];
export const RetailersTypeTitles = ['Business', 'Individual', ''];
export const defaultSorted = [{dataField: '_id', order: 'asc'}];
export const sizePerPageList = [
  {text: '5', value: 5},
  {text: '10', value: 10},
  {text: '25', value: 25},
  {text: '50', value: 50},
  {text: '100', value: 100},
];
export const initialFilter = {
  page: 1,
  limit: 10,
};
