import * as Yup from 'yup';
const AddEditRBACvalidationSchema = Yup.object({
  roleType: Yup.string().required('Please select the Role Type'),
  name: Yup.string()
    .min(2, 'Role should be greater than 2 letters')
    .max(100, 'Role should be less than 100 letters')
    .required('Role name is required.'),
  description: Yup.string(),
  status: Yup.string().required('Required'),
  clientId: Yup.string().when('roleType', {
    is: (roleType) => {
      return ['client', 'campaign'].indexOf(roleType) >= 0 ? true : false;
    },
    then: Yup.string().required('Please select Client.'),
    otherwise: Yup.string(),
  }),
  campaignId: Yup.string().when('roleType', {
    is: (roleType) => {
      return roleType === 'campaign' ? true : false;
    },
    then: Yup.string().required('Please select Campaign.'),
    otherwise: Yup.string(),
  }),
});

export default AddEditRBACvalidationSchema;
