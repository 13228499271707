import React from 'react';
import './NodeProperties.css';
export default function NodeDefaultProperties(props) {
  return (
    <>
      <h2 className="leftToolBar">Object Properties</h2>
      <div className="leftToolBarContainer"></div>
    </>
  );
}
