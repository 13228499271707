import api from '../../../../api';
import {SAVE_RECEIPTSUMMARY_LIST, LOADING} from './type';
import {toast} from 'react-toastify';
export const getReceiptSummaryList = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.get(`/s1/receipt/status-summary`);
    if (data.httpCode === 200) {
      resolve(data.data);
    } else {
      // reject(data.message);
      reject('error');
      // dispatch({type: SAVE_RECEIPTSUMMARY_LIST, payload: []});
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }

      dispatch({type: LOADING, payload: false});
    }
  });
  // });
};
