import React, {createContext, useContext, useState, useCallback} from 'react';
import {isEqual, isFunction} from 'lodash';
import {initialFilter} from './TrackingReportsUIHelpers';

const TrackingReportsUIContext = createContext();

export function useTrackingReportsUIContext() {
  return useContext(TrackingReportsUIContext);
}

export const TrackingReportsUIConsumer = TrackingReportsUIContext.Consumer;

export function TrackingReportsUIProvider({
  requestedReportsUIEvents,
  children,
}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
        // console.log('nextQueryparams', nextQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initTrackingReports = {
    id: undefined,
    moduleName: '',
    reportName: '',
    requestDateRange: '',
    fileName: '',
    status: '',
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initTrackingReports,
    openDeleteTrackingReportsDialog:
      requestedReportsUIEvents.openDeleteTrackingReportsDialog,
  };

  //console.log('value', value);

  return (
    <TrackingReportsUIContext.Provider value={value}>
      {children}
    </TrackingReportsUIContext.Provider>
  );
}
