import api from '../../../../api';
import {SAVE_RETAILERS_LIST, LOADING} from './type';
import {toast} from 'react-toastify';
import promise from 'redux-promise';
import {reject} from 'lodash';
export const getRetailersList =
  (query, startDate, endDate) => async (dispatch) => {
    const newQuery = {
      ...query,
      page: query.page - 1,
      startDate: startDate,
      endDate: endDate,
    };
    // let queryString = Object.keys(newQuery)
    //   .map((key) => key + '=' + newQuery[key])
    //   .join('&');

    //console.log('query string', queryString, newQuery);
    //return new Promise(async (resolve, reject) => {
    dispatch({type: LOADING, payload: true});
    let data = await api.post(`/s1/report/get-retailers-data`, newQuery);
    if (data.httpCode === 200) {
      // resolve(data.message);
      dispatch({type: LOADING, payload: false});
      dispatch({type: SAVE_RETAILERS_LIST, payload: data.data});
    } else {
      // reject(data.message);
      dispatch({type: SAVE_RETAILERS_LIST, payload: []});
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }

      dispatch({type: LOADING, payload: false});
    }
    // });
  };

export const getRetailersExportData = (query) => async (dispatch) => {
  //console.log(query);
  return new Promise(async (resolve, reject) => {
    let data = await api.post('/s1/report/get-retailers-data', query);
    if (data.httpCode === 200) {
      resolve(data.data);
    } else {
      data?.errors && data.errors.forEach((item) => toast.error(item.message));
    }
  });
};

export const getRetailerData = (query) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.post(`/s1/report/qualifying-product`, query);
    if (data.httpCode === 200) {
      resolve(data.data);
    } else {
      reject(data.message);
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }

      dispatch({type: LOADING, payload: false});
    }
  });

  // });
};
