/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react';
import {Redirect, Switch, Route} from 'react-router-dom';
import {shallowEqual, useSelector} from 'react-redux';
import {Layout} from '../_metronic/layout';
import BasePage from './BasePage';
import {Logout, AuthPage} from './modules/Auth';
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage';

// export function Routes() {
//   const { isAuthorized } = useSelector(
//     ({ auth }) => {
//       return ({
//       isAuthorized: (auth.user !== undefined ) && auth.user.id ? true  : false
//     })},
//     shallowEqual
//   );

export function Routes() {
  const {isAuthorized, configArr = []} = useSelector(({auth}) => {
    return {
      isAuthorized: auth.accessToken != null,
      configArr: auth.accessToken != null ? auth.accessToken.config : [],
    };
  }, shallowEqual);

  const arr = [];

  if (configArr.length !== 0) {
    configArr.forEach((element) => {
      arr.push(element.panelId);
    });
  }
  let redirctUrl = new URLSearchParams(window.location.search).get('routes');
  redirctUrl = redirctUrl ? redirctUrl : '';
  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect
          from="/auth"
          to={redirctUrl.indexOf('receipt/all/') >= 0 ? redirctUrl : '/'}
        />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {/* <Layout>
        {isClientMgmt ? (
            <Route path="/client-mgmt" component={ClientManagement} />
        ) : (
          <Route path="/error" component={ErrorsPage} />
        )}

        {isTenantMgmg ? (
            <Route path="/temp-mgmt" component={Tenantmgmt} />
        ) : (
          <Route path="/error" component={ErrorsPage} />
        )}

        {isRoleManager ? (
        <Route path="/role-mgmt" component={RoleManager} />
        ) : (
        <Route path="/error" component={ErrorsPage} />
        )}

        {isTemplateEngine ? (
        <Route path="/temp-eng" component={TemplateEngine} />
        ) : (
        <Route path="/error" component={ErrorsPage} />
        )}
      </Layout> */}

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect
          to={`/auth/login${
            window.location.pathname !== ''
              ? `?routes=${window.location.pathname}${window.location.search}`
              : ''
          }`}
        />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
