import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
function AlertPopup(props) {
    let message = props.message;
    const [showModal, setShowModal] = useState(true);
    const close = () => {
        setShowModal(false);
        props.parentCallback(null, 3);
        if (props.reloadWindow) {
            window.location.reload();
        }
    }
    return (<div>
        <Modal show={showModal} onHide={close}>
            <Modal.Body>
                <p align="center" style={{ marginTop: "30px" }}>{message}</p>
                <p align="center"><Button className="btn btn-dark" onClick={close}>Ok</Button></p>
            </Modal.Body>
        </Modal>
    </div>
    )
}


export default React.memo(AlertPopup, (prevProps, nextProps) => (prevProps.message !== nextProps.message));