import React, {useEffect, useState} from 'react';
import {Table, Row, Col} from 'react-bootstrap';
import {connect, useSelector} from 'react-redux';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {getPincodeReport} from '../_redux/action';
import {Formik} from 'formik';
import {Button, Modal} from 'react-bootstrap';
import SQSReportPopup from '../../../common/SQSReportPopup';
import {
  Card,
  CardBody,
  CardHeader,
} from '../../../../_metronic/_partials/controls';
import PincodeSummaryTable from '../component/PincodeSummaryTable';
const PincodeSummary = ({getPincodeReport, setLoading}) => {
  const {campaignId, campaign} = useSelector((state) => state.auth);
  const [filter, setFilter] = useState({campaignId: '', packageCode: ''});
  const [exportAllResp, setExportAllResp] = React.useState(null);
  //console.log('campaping', campaignId);
  useEffect(() => {
    if (!filter.campaignId) {
      setFilter({
        ...filter,
        campaignId: campaignId ? campaignId.split('-')[0] : '',
      });
    }
  }, [campaignId]);
  const dialogClose = () => {
    setExportAllResp(null);
  };
  const renderCampaignList = () => {
    let content = campaign && campaign?.content ? campaign?.content : [];
    content = content.sort((a, b) => a.name.localeCompare(b.name));
    return content.map((item) => {
      return (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      );
    });
  };
  const downloadFile = ({data, fileName, fileType}) => {
    const blob = new Blob([data], {type: fileType});
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };
  const exportAllToCsv = async () => {
    try {
      setLoading(true);
      const data = await getPincodeReport({
        page: 1,
        limit: 10,
        campaignId: filter.campaignId,
        packageCode: '',
        download: true,
      });
      setLoading(false);
      setExportAllResp(data?.fileName || data?.reportName || 'fileName');
    } catch (err) {
      setLoading(false);
    }
  };
  const exportToCsv = async () => {
    // Headers for each column
    let headers = [
      'PackageCode, FirstName, LastName, UserName, Email, RedemptionDate, Points',
    ];
    try {
      setLoading(true);
      const data = await getPincodeReport({
        page: 1,
        limit: 10,
        campaignId: filter.campaignId,
        packageCode: filter.packageCode,
        download: true,
      });
      setLoading(false);
      let content = [];
      if (Array.isArray(data)) {
        content = data;
      } else {
        content = data?.content || [];
      }
      if (content?.length) {
        let dataCsv = content.reduce((acc, item) => {
          let arrValues = [
            item.packageCode,
            item.firstName,
            item.lastName,
            item.userName,
            item.redemptionEmail,
            new Date(item.redemptionDate).toLocaleDateString(),
            item.points,
          ];
          acc.push(arrValues);
          return acc;
        }, []);

        downloadFile({
          data: [...headers, ...dataCsv].join('\n'),
          fileName: 'pincodesummary.csv',
          fileType: 'text/csv',
        });
      }
    } catch (err) {
      setLoading(false);
    }
  };
  return (
    <Card>
      <CardHeader title="Pincode Information">
        <Button
          type="submit"
          className="my-3 mx-1 float-right btn-sm"
          style={{height: '40px'}}
          variant="info"
          onClick={exportAllToCsv}
          disabled={filter.campaignId === '' ? true : false}>
          Export All in CSV
        </Button>
      </CardHeader>
      <CardBody>
        <div>
          <Formik
            initialValues={{
              packageCode: '',
              campaignId: campaignId ? campaignId.split('-')[0] : '',
            }}
            onSubmit={(values) => {
              setFilter(values);
            }}>
            {({
              values,
              handleReset,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col md={4} sm={12} xs={12}>
                    <div className="form-group row">
                      <label
                        className="col-sm-3 col-form-label"
                        htmlFor="packageCode">
                        Pin Code
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          id="packageCode"
                          name="packageCode"
                          type="text"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={4} sm={12} xs={12}>
                    <div className="form-group row">
                      <div className="col-sm-9">
                        <select
                          className="form-control form-control-lg form-control-solid"
                          name="campaignId"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.campaignId}>
                          <option value="">Select Campaign</option>
                          {renderCampaignList()}
                        </select>
                      </div>
                    </div>
                  </Col>

                  <Col md={4} sm={12} xs={12}>
                    <Button
                      type="submit"
                      variant="primary"
                      className="my-3 mx-1 float-left">
                      Submit
                    </Button>

                    <Button
                      type="submit"
                      variant="primary"
                      onClick={exportToCsv}
                      className="my-3 mx-1 float-right"
                      disabled={
                        filter.campaignId === '' || filter.packageCode === ''
                          ? true
                          : false
                      }>
                      Export to CSV
                    </Button>
                  </Col>
                </Row>
              </form>
            )}
          </Formik>
        </div>
        <PincodeSummaryTable
          campaignId={filter.campaignId}
          packageCode={filter.packageCode}
        />
      </CardBody>
      <SQSReportPopup
        dialogClose={dialogClose}
        modelVisible={exportAllResp ? true : false}
        fileName={exportAllResp}
      />
    </Card>
  );
};

export default connect(null, {getPincodeReport})(IsLoadingHOC(PincodeSummary));
