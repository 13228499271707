import * as Yup from 'yup';
const AddRulevalidationSchema = Yup.object({
  ruleName: Yup.string()
    .min(2, 'Rule name should be greater than 2 letters')
    .max(100, 'Rule should be less than 100 letters')
    .required('Please Enter Rule name'),
  description: Yup.string()
    .min(5, 'Short Description should be greater than 5 letters')
    .max(500, 'Short Description should be less than 200 letters')
    .required('Please Enter Description'),
  ruleCode: Yup.string().required('Please Enter Rule Code'),
  tag: Yup.string().required('Please Enter Tag'),
  isStoreRequired: Yup.boolean().required('Required'),
  isProductRequired: Yup.boolean().required('Required'),
  isUserDataRequired: Yup.boolean().required('Required'),
  status: Yup.string().required('Required'),
  isUserPointsRequired: Yup.boolean().required('Required'),
  isUserRewardsRequired: Yup.boolean().required('Required'),
  // isUserPurchaseHistoryRequired: Yup.boolean().required('Required'),
  //isUserRewardClaimTokensRequired: Yup.boolean().required('Required'),
  isUserActivityAttemptsRequired: Yup.string().required('Required'),
  panelRuleConfigurations: Yup.object().shape({
    enabled: Yup.boolean().nullable(),
    requiredTotalQualifyingQuantity: Yup.number()
      .positive('Please enter positive Number')
      .integer('Please enter integer'),
    requiredTotalQualifyingAmount: Yup.number()
      .positive('Please enter positive Number')
      .integer('Please enter integer'),
    panelCheckReceiptDate: Yup.object().shape({
      enabled: Yup.boolean().nullable(),
      // purchaseStartDate: Yup.string()
      //   .when('enabled', {
      //     is: true,
      //     then: Yup.string().required('Please enter purchase start date'),
      //   })
      //   .nullable(),
      // purchaseEndDate: Yup.string()
      //   .when('enabled', {
      //     is: true,
      //     then: Yup.string().required('Please enter purchase end date'),
      //   })
      //   .nullable(),
      checkApplicableStatuses: Yup.array().of(Yup.string()).nullable(),
      failedReceiptStatus: Yup.string()
        .when('enabled', {
          is: true,
          then: Yup.string().required('Please enter failed receipt status'),
        })
        .nullable(),
    }),
    panelValidateStore: Yup.object().shape({
      enabled: Yup.boolean().nullable(),
      validStoreNames: Yup.string().nullable(),
      excludedStoreNames: Yup.string().nullable(),
      checkApplicableStatuses: Yup.array().of(Yup.string()).nullable(),
      failedReceiptStatus: Yup.string()
        .when('enabled', {
          is: true,
          then: Yup.string().required('Please enter failed receipt status'),
        })
        .nullable(),
    }),
    panelCheckReceiptForDiscount: Yup.object().shape({
      enabled: Yup.boolean().nullable(),
      failedReceiptStatus: Yup.string()
        .when('enabled', {
          is: true,
          then: Yup.string().required('Please enter failed receipt status'),
        })
        .nullable(),
    }),
    panelCheckReceiptLocation: Yup.object().shape({
      enabled: Yup.boolean().nullable(),
      validLocationRegex: Yup.array().when('enabled', {
        is: true,
        then: Yup.array()
          .of(Yup.string().required('Please enter valid location'))
          .min(1, 'Please enter min 1 valid location regex')
          .nullable(),
      }),
      checkApplicableStatuses: Yup.array().of(Yup.string()).nullable(),
      failedReceiptStatus: Yup.string()
        .when('enabled', {
          is: true,
          then: Yup.string().required('Please enter failed receipt status'),
        })
        .nullable(),
    }),
    panelCheckRewardAlreadyClaimed: Yup.object().shape({
      enabled: Yup.boolean().nullable(),
      maxRewardLimit: Yup.number()
        .when('enabled', {
          is: true,
          then: Yup.number()
            .integer('Limit Reward should be integer number')
            .min(2, 'Limit Reward should be greater than 1')
            .required('Please enter max reward limit')
            .test('is-number', 'Please enter a valid number', (value) => {
              if (typeof value === 'number' && !isNaN(value)) {
                return true;
              }
              return false;
            }),
        })
        .nullable(),
      checkApplicableStatuses: Yup.array().of(Yup.string()).nullable(),
      failedReceiptStatus: Yup.string()
        .when('enabled', {
          is: true,
          then: Yup.string().required('Please enter failed receipt status'),
        })
        .nullable(),
    }),
    panelCheckReceiptKeywords: Yup.object().shape({
      enabled: Yup.boolean().nullable(),
      keywords: Yup.string()
        .when('enabled', {
          is: true,
          then: Yup.string().required('Please enter keywords').nullable(),
        })
        .nullable(),
      passedReceiptStatus: Yup.string()
        .when('enabled', {
          is: true,
          then: Yup.string().required('Please enter passed receipt status'),
        })
        .nullable(),
    }),

    panelSpecialProducts: Yup.object().shape({
      enabled: Yup.boolean().nullable(),
      productUpcs: Yup.string()
        .when('enabled', {
          is: true,
          then: Yup.string().required('Please enter Product Upcs').nullable(),
        })
        .nullable(),

      minimumQuantities: Yup.number('Please enter number').when('enabled', {
        is: true,
        then: Yup.number()
          .integer('Please enter integer number')
          .positive('Please enter positive number')
          .required('Please enter Minimum Quantities')
          .nullable(),
      }),
      maximumQuantities: Yup.number('Please enter number')
        .integer('Please enter integer number')
        .positive('Please enter positive number')
        .nullable(),
    }),
  }),
});

export default AddRulevalidationSchema;
