import api from '../../../../api';
import {
  SAVE_CAMPAIGNMAPPING_LIST,
  LOADING,
  CAMPAIGNMAPPING_FOR_EDIT,
  UPDATE_CAMPAIGNMAPPING,
  CAMPAIGNMAPPING_DELETE,
} from './type';
import {toast} from 'react-toastify';

export const getUserList = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.get(`/s1/identities/client?role=DEO`);
    if (data.httpCode === 200) {
      resolve(data.data);
    } else {
      reject(data.message);
      // dispatch({type: SAVE_ADMINUSER_LIST, payload: []});
      // dispatch({type: LOADING, payload: false});
      if (data?.errors) {
        if (Array.isArray(data.errors)) {
          data.errors.map((item) => {
            toast.error(item?.message);
          });
        }
      }
    }
  });

  // });
};
export const getCampaignMappingList = (query) => async (dispatch) => {
  const newQuery = {...query, page: query.page - 1};
  let queryString = Object.keys(newQuery)
    .map((key) => key + '=' + newQuery[key])
    .join('&');

  //console.log('query string', queryString, newQuery);
  //return new Promise(async (resolve, reject) => {
  dispatch({type: LOADING, payload: true});
  let data = await api.get(`/s1/mappusercampaign?${queryString}`);
  if (data.httpCode === 200) {
    // resolve(data.message);
    dispatch({type: LOADING, payload: false});
    dispatch({type: SAVE_CAMPAIGNMAPPING_LIST, payload: data.data});
  } else {
    // reject(data.message);
    dispatch({type: SAVE_CAMPAIGNMAPPING_LIST, payload: []});
    if (data?.errors) {
      if (Array.isArray(data.errors)) {
        data.errors.map((item) => {
          toast.error(item?.message);
        });
      }
    }

    dispatch({type: LOADING, payload: false});
  }
  // });
};

export const addCampaignMappingItem = (values) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.post('/s1/mappusercampaign', values);
    if (data.httpCode === 201) {
      resolve(data.message);
      //dispatch({type: SAVE_CAMPAIGNMAPPING_LIST, payload: data.data});
    } else {
      reject(data.errors);
    }
  });
};

export const updateCampaignMapping =
  (id, campaignMapping) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      let data = await api.put(`/s1/mappusercampaign/${id}`, campaignMapping);
      if (data.httpCode === 200) {
        resolve(data.message);
        dispatch({type: UPDATE_CAMPAIGNMAPPING, payload: campaignMapping});
      } else {
        reject(data.errors);
      }
    });
  };

export const fetchCampaignMapping = (id) => async (dispatch) => {
  if (!id) {
    return dispatch({type: CAMPAIGNMAPPING_FOR_EDIT, payload: undefined});
  }
  dispatch({type: LOADING, payload: true});
  return new Promise(async (resolve, reject) => {
    let data = await api.get(`/s1/mappusercampaign/${id}`);
    if (data.httpCode === 200) {
      resolve(data.message);
      dispatch({type: LOADING, payload: false});
      dispatch({type: CAMPAIGNMAPPING_FOR_EDIT, payload: data.data});
    } else {
      toast.success('working');
      reject(data.errors);
      dispatch({type: LOADING, payload: false});
    }
  });
};

export const deleteCampaignMappingById = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.delete(`/s1/campaignMapping/${id}`);
    if (data.httpCode === 200) {
      resolve(data.message);
      dispatch({type: CAMPAIGNMAPPING_DELETE, payload: id});
    } else {
      reject(data.errors);
    }
  });
};
