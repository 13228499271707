import React, {createContext, useContext, useState, useCallback} from 'react';
import {isEqual, isFunction} from 'lodash';
import {initialFilter} from './RewardSummaryUIHelpers';

const RewardSummaryUIContext = createContext();

export function useRewardSummaryUIContext() {
  // console.log(
  //   'useContext(RewardSummaryUIContext);',
  //   useContext(RewardSummaryUIContext),
  // );
  return useContext(RewardSummaryUIContext);
}

export const RewardSummaryUIConsumer = RewardSummaryUIContext.Consumer;

export function RewardSummaryUIProvider({rewardSummaryUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initRewardSummary = {
    id: undefined,
    title: '',
    actionType: '',
    points: '',
    maxAttempt: '',
    enabled: '',
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initRewardSummary,
    openDeleteRewardSummaryDialog:
      rewardSummaryUIEvents.openDeleteRewardSummaryDialog,
  };

  return (
    <RewardSummaryUIContext.Provider value={value}>
      {children}
    </RewardSummaryUIContext.Provider>
  );
}
