import React, {useMemo} from 'react';
import {Formik} from 'formik';
import {isEqual} from 'lodash';
import {useRetailersUIContext} from '../RetailersUIContext';

const prepareFilter = (queryParams, values) => {
  const {status, type, searchText} = values;
  const newQueryParams = {...queryParams};
  const filter = {};
  // Filter by status
  filter.status = status !== '' ? +status : undefined;
  // Filter by type
  filter.type = type !== '' ? +type : undefined;
  // Filter by all fields
  filter.lastName = searchText;
  if (searchText) {
    filter.firstName = searchText;
    filter.email = searchText;
    filter.ipAddress = searchText;
  }
  //  newQueryParams.filter = filter;
  newQueryParams.searchText = searchText;
  return newQueryParams;
};

export function RetailersFilters({listLoading}) {
  // Customers UI Context
  const customersUIContext = useRetailersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
    };
  }, [customersUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(customersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      customersUIProps.setQueryParams(newQueryParams);
    }
  };
  const debounce = function (fn, delay) {
    let timer = false;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn();
    }, delay);
  };

  return (
    <>
      <Formik
        initialValues={{
          // status: '', // values => All=""/Susspended=0/Active=1/Pending=2
          // type: '', // values => All=""/Business=0/Individual=1
          searchText: '',
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}>
        {({values, handleSubmit, handleBlur, handleChange, setFieldValue}) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              {/* <div className="col-lg-2">
                <select
                  className="form-control"
                  name="status"
                  placeholder="Filter by Status"
                  // TODO: Change this code
                  onChange={(e) => {
                    setFieldValue('status', e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.status}>
                  <option value="">All</option>
                  <option value="0">Susspended</option>
                  <option value="1">Active</option>
                  <option value="2">Pending</option>
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Status
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Type"
                  name="type"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue('type', e.target.value);
                    handleSubmit();
                  }}
                  value={values.type}>
                  <option value="">All</option>
                  <option value="0">Business</option>
                  <option value="1">Individual</option>
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Type
                </small>
              </div> */}
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue('searchText', e.target.value);
                    debounce(handleSubmit, 1000);
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
