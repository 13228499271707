import api from '../../../../api';
import {
  SAVE_PROCESSAUTOMATION_LIST,
  LOADING,
  PROCESSAUTOMATION_FOR_EDIT,
  UPDATE_PROCESSAUTOMATION,
  PROCESSAUTOMATION_DELETE,
} from './type';
import {toast} from 'react-toastify';
export const getProcessAutomationList = (query) => async (dispatch) => {
  const newQuery = {...query, page: query.page - 1};
  let queryString = Object.keys(newQuery)
    .map((key) => key + '=' + newQuery[key])
    .join('&');

  //console.log('query string', queryString, newQuery);
  //return new Promise(async (resolve, reject) => {
  dispatch({type: LOADING, payload: true});
  let data = await api.get(`/s1/automation/get-process-model?${queryString}`);
  if (data.httpCode === 200) {
    // resolve(data.message);
    dispatch({type: LOADING, payload: false});
    dispatch({type: SAVE_PROCESSAUTOMATION_LIST, payload: data.data});
  } else {
    // reject(data.message);
    dispatch({type: LOADING, payload: false});
    dispatch({type: SAVE_PROCESSAUTOMATION_LIST, payload: []});
    if (data?.errors) {
      if (Array.isArray(data.errors)) {
        data.errors.map((item) => {
          toast.error(item?.message);
        });
      }
    }
  }
  // });
};

export const addProcessAutomationItem = (values) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.post('/s1/activity', values);
    if (data.httpCode === 201) {
      resolve(data.message);
      //dispatch({type: SAVE_ACTIVITY_LIST, payload: data.data});
    } else {
      reject(data.errors);
    }
  });
};

export const updateProcessAutomation = (id, activity) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.put(`/s1/activity/${id}`, activity);
    if (data.httpCode === 200) {
      resolve(data.message);
      dispatch({type: UPDATE_PROCESSAUTOMATION, payload: activity});
    } else {
      reject(data.errors);
    }
  });
};

export const fetchProcessAutomation = (id) => async (dispatch) => {
  if (!id) {
    return dispatch({type: PROCESSAUTOMATION_FOR_EDIT, payload: undefined});
  }
  dispatch({type: LOADING, payload: true});
  return new Promise(async (resolve, reject) => {
    let data = await api.get(`/s1/automation/get-process-model/${id}`);
    if (data.httpCode === 200) {
      resolve(data.message);
      dispatch({type: LOADING, payload: false});
      dispatch({type: PROCESSAUTOMATION_FOR_EDIT, payload: data.data});
    } else {
      reject(data.errors);
      dispatch({type: LOADING, payload: false});
    }
  });
};
//
export const getProcessLogs = (id, startTime) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.get(
      `/s1/cloudwatchlogger/filter-log-events?filterPattern=${id}&startTime=${startTime}`,
    );
    if (data.httpCode === 200) {
      resolve(data.data);
    } else {
      reject('Not found');
    }
  });
};

export const deleteProcessAutomationById = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.delete(`/s1/activity/${id}`);
    if (data.httpCode === 200) {
      resolve(data.message);
      dispatch({type: PROCESSAUTOMATION_DELETE, payload: id});
    } else {
      reject(data.errors);
    }
  });
};

export const executeProcess = (values) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.post('/s1/automation/execute-process', values);
    if (data.httpCode === 200) {
      resolve(data.message);
      //dispatch({type: SAVE_ACTIVITY_LIST, payload: data.data});
    } else {
      reject(data.errors);
    }
  });
};

export const getHistory = (id, page, sizePerPage) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.get(
      `/s1/automation/processhistory/${id}?page=${
        page - 1
      }&limit=${sizePerPage}`,
    );
    if (data.httpCode === 200) {
      resolve(data.data);
      //console.log('data.data', data.data);
      //  dispatch({type: PROCESSAUTOMATION_FOR_EDIT, payload: data.data});
    } else {
      reject(data.errors);
    }
  });
};

export const refreshHistory = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.get(`/s1/automation/process-referesh/${id}`);
    if (data.httpCode === 200) {
      resolve(data.data);
      //  dispatch({type: PROCESSAUTOMATION_FOR_EDIT, payload: data.data});
    } else {
      reject(data.errors);
    }
  });
};

export const stopExecution = (id) => async (dispatch) => {
  //console.log('dksfjd', id);
  return new Promise(async (resolve, reject) => {
    let data = await api.post(`/s1/automation/stop-process-exection`, {
      processUuid: id,
    });
    if (data.httpCode === 200) {
      resolve(data.data);
      //dispatch({type: PROCESSAUTOMATION_FOR_EDIT, payload: data.data});
    } else {
      reject(data.errors);
    }
  });
};
