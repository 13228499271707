import React, {useEffect, useState} from 'react';
import {Typography} from '@material-ui/core';
import {connect, useSelector, useDispatch, shallowEqual} from 'react-redux';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {
  addProcessAutomationItem,
  updateProcessAutomation,
  fetchProcessAutomation,
} from '../_redux/action';
import {useHistory, useParams} from 'react-router';
import * as nodeObjectModel from '../../../../_metronic/_partials/ProcessAutomation/toolboxObjectModel';
import ProcessDiagram from '../component/ProcessDiagram';

const AddProcessAutomation = ({
  setLoading,
  addProcessAutomationItem,
  updateProcessAutomation,
}) => {
  const {id} = useParams();
  const [nodeList, setNodeList] = useState([
    nodeObjectModel.startNodeObj,
    nodeObjectModel.endNodeObj,
  ]);
  const [edgeList, setEdgeList] = useState({inboundNodeId: 'start'});
  const [diagramData, setDiagramData] = useState({
    nodes: nodeList,
    connectors: edgeList,
    name: '',
    description: '',
    status: 'active',
    id: '',
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProcessAutomation(id));
  }, [id, dispatch]);
  const getNodeObjectModel = (nodeid) => {
    let nodeObj = {};
    for (let i in nodeObjectModel) {
      if (nodeObjectModel[i].nodeID === nodeid) {
        nodeObj = nodeObjectModel[i];
      }
    }
    return nodeObj;
  };
  const {processAutomationForEdit, listLoading} = useSelector(
    (state) => ({
      processAutomationForEdit:
        state.processAutomation.processAutomationForEdit,
      listLoading: state.processAutomation.listLoading,
    }),
    shallowEqual,
  );

  useEffect(() => {
    let nodes = [];
    let connectors = [];
    if (processAutomationForEdit) {
      for (let i in processAutomationForEdit.process_model.nodes) {
        let nodeModel = getNodeObjectModel(
          processAutomationForEdit.process_model.nodes[i].nodeID,
        );
        if (processAutomationForEdit.process_model.nodes[i]['outParam']) {
          processAutomationForEdit.process_model.nodes[i]['outputParam'] =
            JSON.parse(
              JSON.stringify(
                processAutomationForEdit.process_model.nodes[i]['outParam'],
              ),
            );
          delete processAutomationForEdit.process_model.nodes[i]['outParam'];
        }
        nodes.push(
          Object.assign(
            {},
            nodeModel,
            processAutomationForEdit.process_model.nodes[i],
          ),
        );
      }
      for (let i in processAutomationForEdit.process_model.connectors) {
        connectors.push({
          edgeID:
            processAutomationForEdit.process_model.connectors[i].connectorId,
          inboundNodeId:
            processAutomationForEdit.process_model.connectors[i].inboundNodeId,
          outboundNodeId:
            processAutomationForEdit.process_model.connectors[i].outboundNodeId,
        });
      }
      setDiagramData({
        nodes: nodes,
        connectors: connectors,
        name: processAutomationForEdit.process_model_name,
        description: processAutomationForEdit.description,
        status: processAutomationForEdit.status,
        id: processAutomationForEdit.id,
      });
    } else {
      setDiagramData({
        nodes: nodeList,
        connectors: edgeList,
        name: '',
        description: '',
        status: 'active',
        id: '',
      });
    }
  }, [processAutomationForEdit]);
  let history = useHistory();
  if (listLoading) {
    return (
      <Typography variant="h3" component="div">
        Loading....
      </Typography>
    );
  }
  return (
    <>
      <ProcessDiagram diagramData={diagramData} />
    </>
  );
};

export default connect(null, {
  addProcessAutomationItem,
  updateProcessAutomation,
  fetchProcessAutomation,
})(IsLoadingHOC(AddProcessAutomation));
