import React, {useState, useCallback, useEffect} from 'react';
import CustomWidget from '../widgets/mixed/CustomWidget';
import ModelForm from './component/ModelForm';

import ReportListingTable from './component/ReportListingTable';

export function ReportingList({url}) {
  const [modelV, setModelV] = useState(false);
  const [currentId, setCurrentId] = useState();

  const formHide = useCallback(() => {
    //console.log('dialog close');
    setModelV(false);
  }, []);
  const showModel = useCallback((id) => {
    // console.log('dialog open', id);
    setModelV(true);
    setCurrentId(id);
  }, []);
  return (
    <div className="row">
      <div className="col-xl-12">
        <CustomWidget className="gutter-b card-stretch" />
      </div>
      <div className="col-xl-12">
        <div className={`card card-custom gutter-b`}>
          {/* begin::Header */}
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                Reports List
              </span>
            </h3>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className="card-body pt-2 pb-0">
            {/* begin::Table */}
            <ReportListingTable showModel={showModel} url={url} />
            {currentId && (
              <ModelForm
                modelVisible={modelV}
                id={currentId}
                dialogClose={formHide}
              />
            )}

            {/* end::Table */}
          </div>
          {/* end::Body */}
        </div>
      </div>
    </div>
  );
}
