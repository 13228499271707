import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {useDispatch, connect} from 'react-redux';

import Swal from 'sweetalert2';
import {useSubheader} from '../../../_metronic/layout';
import AddCampaignMapping from './pages/AddCampaignMapping';
import {CampaignMappingList} from './pages/CampaignMappingList';
import {CampaignMappingUIProvider} from './CampaignMappingUIContext';
import {IsLoadingHOC} from '../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {deleteCampaignMappingById} from './_redux/action';
// import {CampaignMappingEditDialog} from "./component/CampaignMapping-edit-dialog/CampaignMappingEditDialog";

const CampaignMappingPage = ({
  history,
  setLoading,
  deleteCampaignMappingById,
}) => {
  const suhbeader = useSubheader();
  const dispatch = useDispatch();
  suhbeader.setTitle('Campaign Mapping');

  const campaignMappingUIEvents = {
    newCampaignMappingButtonClick: () => {
      history.push('/campaignMapping/new');
    },
    openEditCampaignMappingDialog: (id) => {
      history.push(`/campaignMapping/${id}/edit`);
    },
    openDeleteCampaignMappingDialog: (id) => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          deleteCampaignMappingById(id)
            .then((res) => {
              setLoading(false);
              Swal.fire(
                'Deleted!',
                'CampaignMapping has been deleted.',
                'success',
              );
            })
            .catch((error) => {
              setLoading(false);
              console.log(error);
            });
        }
      });
    },
    // openDeleteCustomersDialog: () => {
    //   history.push(`/e-commerce/customers/deleteCustomers`);
    // },
    // openFetchCustomersDialog: () => {
    //   history.push(`/e-commerce/customers/fetch`);
    // },
    // openUpdateCustomersStatusDialog: () => {
    //   history.push('/e-commerce/customers/updateStatus');
    // },
  };
  return (
    <CampaignMappingUIProvider
      campaignMappingUIEvents={campaignMappingUIEvents}>
      <Switch>
        <Redirect
          from="/campaignMapping"
          exact={true}
          to="/campaignMapping/list"
        />
        <Route path="/campaignMapping/list" component={CampaignMappingList} />
        <Route path="/campaignMapping/new" component={AddCampaignMapping} />
        <Route
          path="/campaignMapping/:id/edit"
          component={AddCampaignMapping}
        />
      </Switch>
    </CampaignMappingUIProvider>
  );
};

export default connect(null, {deleteCampaignMappingById})(
  IsLoadingHOC(CampaignMappingPage),
);
