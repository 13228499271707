import {put, takeLatest} from 'redux-saga/effects';
import {getUserByToken} from './authCrud';
import {
  Login,
  Logout,
  Register,
  UserLoaded,
  UserRequested,
  setUserConfig,
  SetUser,
  UPDATE_CAMPAIGN,
  SAVE_LOOK_UP,
} from './types';
import {customFields, tenantConfig} from '../utils';

const initialAuthState = {
  user: undefined,
  accessToken: undefined,
  name: '',
  refreshToken: '',
  lookup: '',
  username: '',
  email: '',
  role: [],
  campaign: {
    content: [],
    metaData: {limit: 10, currentPageNo: 0, totalRecs: 10, nextPageNo: 1},
  },
  menu: [],
  campaignId: '',
  tenantConfig: {...tenantConfig},
  tenantConfigDefault: {...tenantConfig},
  customFields: [...customFields],
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case Login: {
      const {accessToken} = action.payload;

      return {...state, accessToken: accessToken, user: undefined};
    }

    case Register: {
      const {accessToken} = action.payload;

      return {...state, accessToken: accessToken, user: undefined};
    }

    case Logout: {
      // TODO: Change this code. Actions in reducer aren't allowed.
      return initialAuthState;
    }

    case UserLoaded: {
      const {user} = action.payload;
      return {...state, user};
    }

    case SetUser: {
      // const { user } = action.payload;
      return {...state, ...action.payload};
    }

    case setUserConfig: {
      const {data: config} = action.payload;
      return {...state, config};
    }

    case 'SAVE_CAMPAIGN': {
      // console.log('working', action.payload);
      return {...state, campaign: action.payload};
    }
    case SAVE_LOOK_UP: {
      return {...state, lookup: action.payload};
    }
    case 'SAVE_LOOK_UP_CHART': {
      return {...state, lookupChart: action.payload};
    }
    case 'SAVE_CAMPAIGN_ID': {
      return {...state, campaignId: action.payload};
    }

    case 'SAVE_TENANT_CONFIG': {
      return {
        ...state,
        tenantConfig: action.payload ? action.payload : {...state.tenantConfig},
      };
    }

    case UPDATE_CAMPAIGN: {
      let content = state.campaign.content.map((entity) => {
        if (entity.id === action.payload.id) {
          return action.payload;
        }
        return entity;
      });
      return {
        ...state,
        campaign: {...state.campaign.metaData, content: content},
      };
    }

    case 'SAVE_MENU': {
      // let menu = [];
      // if (Object.prototype.toString.call(action.payload) === '[object Array]') {
      //   menu = action.payload;
      // } else {
      //   menu = state.menu;
      // }
      return {...state, menu: action.payload};
    }

    default:
      return state;
  }
};

export const actions = {
  login: (accessToken) => ({type: Login, payload: {accessToken}}),
  register: (accessToken) => ({
    type: Register,
    payload: {accessToken},
  }),
  logout: () => ({type: Logout}),
  requestUser: (user) => ({
    type: UserRequested,
    payload: {user},
  }),
  fulfillUser: (user) => ({type: UserLoaded, payload: {user}}),
  // setUser: (user) => ({ type: SetUser, payload: { user } }),
  setUserConfig: (data) => ({type: setUserConfig, payload: {data}}),
};

export function* saga() {
  yield takeLatest(Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(UserRequested, function* userRequested() {
    const {data: user} = yield getUserByToken();

    yield put(actions.fulfillUser(user));
  });
}
