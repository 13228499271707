import {
  // SAVE_APPROVED_RECEIPT_LIST,
  // SAVE_NEW_RECEIPT_LIST,
  // SAVE_PENDING_RECEIPT_LIST,
  SAVE_RECEIPT_LIST,
  // SAVE_REJECTED_RECEIPT_LIST,
  // SAVE_AMBIGUOUS_RECEIPT_LIST,
  SAVE_SEARCH_RESULT_RECEIPT_LIST,
  REMOVED_FROM_CHEKOUT,
  SAVE_RECEIPT_LIST_NEW,
  Add_TO_CHEKOUT,
  UPDATE_RECEIPT_DATA,
  REFRES_RECEIPT,
} from './type';

const initialState = {
  receipts: [],
  metaData: {limit: 10, currentPageNo: 0, totalRecs: 0, nextPageNo: 1},
  currentReceipt: {},

  result: [],
  rMeta: {limit: 10, currentPageNo: 0, totalRecs: 0, nextPageNo: 1},
  Id: null,
  searchFilters: {},
};
const ReceiptReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_RECEIPT_LIST: {
      let currentReceipt = {};
      if (action.payload?.content && action.payload.content.length > 0) {
        currentReceipt = action.payload.content[0];
      }
      return {
        ...state,
        receipts: action.payload?.content ? action.payload.content : [],
        metaData: action.payload?.metaData
          ? action.payload.metaData
          : initialState.metaData,
        currentReceipt: currentReceipt,
      };
    }
    // case SAVE_RECEIPT_LIST_NEW: {
    //   let list = state.receipts;
    //   if (action.payload?.content) {
    //     list = [...list, ...action.payload.content];
    //   }
    //   return {
    //     ...state,
    //     receipts: list,
    //     metaData: action.payload?.metaData
    //       ? action.payload.metaData
    //       : state.metaData,
    //   };
    // }

    case REMOVED_FROM_CHEKOUT: {
      const updateItemRemove = state.receipts.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload.data,
            locked: false,
            adminUserId: action.payload.uId,
          };
        }
        return item;
      });
      return {
        ...state,
        receipts: updateItemRemove,
      };
    }
    case REFRES_RECEIPT: {
      const refreshReceipt = state.receipts.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...action.payload,
          };
        }
        return item;
      });
      return {
        ...state,
        receipts: refreshReceipt,
      };
    }

    case Add_TO_CHEKOUT: {
      const updateItem = state.receipts.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            locked: true,
            adminUserId: action.payload.uId,
            lockedAt: new Date().toISOString(),
          };
        }
        return item;
      });
      return {
        ...state,
        receipts: updateItem,
      };
    }

    case 'SELECT_CURRENT_RECEIPT': {
      const findCurrent = state.receipts.find(
        (item) => item.id === action.payload,
      );
      return {
        ...state,
        currentReceipt: findCurrent ? findCurrent : {},
      };
    }

    case SAVE_SEARCH_RESULT_RECEIPT_LIST: {
      return {
        ...state,
        result: action.payload?.content ? action.payload.content : [],
        metaData: action.payload?.metaData
          ? action.payload.metaData
          : initialState.rMeta,
      };
    }
    case 'SAVE_FILTER':
      return {
        ...state,
        searchFilters: action.payload,
      };
    case 'CLEAR_ALL': {
      return initialState;
    }
    default:
      return state;
  }
};

export default ReceiptReducer;
