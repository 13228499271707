import React, {useState, useEffect} from 'react';
import {Tabs, Tab} from 'react-bootstrap/';
import {getRequest} from '../../../../../_metronic/_helpers/APIRequestHelpers';
import './NodeProperties.css';
import {Spinner} from 'react-bootstrap';
import SelectBox from './SelectBox';
export default function DBLoaderProperties(props) {
  let nodeProperties = props.nodeProperty;
  let lastStateChildData = props.lastStateChildData;
  let [activeTabs, setaActiveTabs] = useState('inputTab');
  let [inputParamMapping, setInputParamMapping] = useState(
    nodeProperties.inputParam.mapping ? nodeProperties.inputParam.mapping : [],
  );
  let [collectionList, setCollectionList] = useState(
    lastStateChildData['collectionList']
      ? lastStateChildData['collectionList']
      : [],
  );
  let inputNodeVariableField = nodeProperties.inputParam.variables
    ? nodeProperties.inputParam.variables
    : {};
  let collection_name = React.createRef();
  let [collectionVariable, setCollectionVariable] = useState([]);
  if (
    nodeProperties.inputParam.collection_name === '' &&
    collectionVariable.length > 0
  ) {
    setCollectionVariable([]);
  }
  if (inputParamMapping.length > 0 && collectionVariable.length === 0) {
    let tempVariableList = [];
    inputParamMapping.map((element) => {
      tempVariableList.push({
        name: element.dbloader_key,
        required: element.required,
        input_key: element.input_key,
      });
    });
    setCollectionVariable(tempVariableList);
  }
  useEffect(() => {
    if (collectionList.length === 0) {
      getRequest(
        '/s1/automation/get-db-entities?node=dbloader',
        (response, error) => {
          if (error) {
            alert(error);
            setCollectionList([]);
          } else if (response) {
            setCollectionList(response.data.data);
            let tempdata = [];
            tempdata[nodeProperties.nodeID] = {
              collectionList: response.data.data,
            };
            props.parentCallback(tempdata, 2);
          }
        },
      );
    }
  }, []);
  let loadCollectionVariable = (event) => {
    let collection_name = event.target.value;
    if (collection_name && collection_name !== '') {
      nodeProperties.inputParam['collection_name'] = collection_name;
      let selectedCollection = collectionList.find(
        (element) => element.collection_name === collection_name,
      );
      if (inputParamMapping.length > 0) {
        for (let i in selectedCollection.visible_fields) {
          let setlectedVariable = inputParamMapping.find(
            (element) =>
              element.dbloader_key ===
              selectedCollection.visible_fields[i].name,
          );
          if (setlectedVariable && setlectedVariable !== undefined) {
            selectedCollection.visible_fields[i]['input_key'] =
              setlectedVariable.input_key;
          }
        }
      }
      setCollectionVariable(selectedCollection.visible_fields);
    } else {
      nodeProperties.inputParam['collection_name'] = '';
      setCollectionVariable([]);
    }
    //props.parentCallback(nodeProperties, 1);
  };
  let mapCollectionVariable = (templateKey, ind, e) => {
    let selectedVal = e.target.value;
    let tempcollectionVariable = [...collectionVariable];
    tempcollectionVariable[ind]['input_key'] = {};
    if (selectedVal && selectedVal !== '') {
      let details = selectedVal.split('|');
      let nodeID = details.length > 0 ? details[0] : '';
      let key = details.length > 1 ? details[1] : '';
      //let variableType = (details.length > 2 ? details[2] : "");
      tempcollectionVariable[ind]['input_key'] = {
        key: key,
        nodeID: nodeID,
      };
    }
    setCollectionVariable(tempcollectionVariable);
  };
  let mapCollectionVariableForChk = (ind, e) => {
    let selectedVal = e.target.checked;
    let tempcollectionVariable = [...collectionVariable];
    console.log('collectionVariable', tempcollectionVariable[ind]);
    if (tempcollectionVariable[ind]['input_key']) {
      tempcollectionVariable[ind]['input_key'].useForUpdate = selectedVal;
      setCollectionVariable(tempcollectionVariable);
    }
  };
  let prepareOutput = (event) => {
    event.preventDefault();
    let checkList = collectionVariable.filter(
      (val, ind) =>
        val.required &&
        (!val['input_key'] || Object.entries(val['input_key']).length === 0),
    );
    if (checkList.length > 0) {
      let ErrorMessage = '';
      let reqVariablePending = [];
      checkList.forEach((val) => {
        reqVariablePending.push(val.name);
      });
      ErrorMessage += reqVariablePending.toString() + ' is Required';
      alert(ErrorMessage);
      return false;
    }
    nodeProperties.outputParam.variables = nodeProperties.inputParam.variables;
    let mappingList = [];
    for (let i in collectionVariable) {
      mappingList.push({
        dbloader_key: collectionVariable[i].name,
        required: collectionVariable[i].required,
        input_key: collectionVariable[i]['input_key']
          ? collectionVariable[i]['input_key']
          : {},
      });
    }
    nodeProperties.inputParam.mapping = mappingList;

    nodeProperties.outputParam.variables = Object.assign(
      {},
      nodeProperties.inputParam.variables,
    );
    setInputParamMapping(mappingList);
    setaActiveTabs('outputTab');
    props.parentCallback(nodeProperties, 1);
  };
  let counter = 1;
  return (
    <>
      <h2 className="leftToolBar">
        Object Properties
        <br />
        <span>DB Import Node</span>
      </h2>
      <div className="leftToolBarContainer">
        <Tabs
          activeKey={activeTabs}
          transition={false}
          id="noanim-tab-example"
          onSelect={(key) => {
            setaActiveTabs(key);
          }}>
          <Tab eventKey={'inputTab'} title="Input">
            <table className="table" id="templateNodeTable">
              <tbody>
                <tr>
                  <td width="50%">
                    <strong>
                      {' '}
                      Collection <span className="requiredfield">*</span>{' '}
                    </strong>
                  </td>
                  <td>
                    {collectionList.length === 0 ? (
                      <Spinner animation="border" size="sm" variant="primary" />
                    ) : (
                      <select
                        className="form-select customselect"
                        id="collection_name"
                        ref={collection_name}
                        onChange={loadCollectionVariable}
                        value={nodeProperties.inputParam.collection_name}>
                        <option value="">Select Collection</option>
                        {collectionList.map((val, ind) => {
                          return (
                            <option
                              key={'tem' + ind}
                              value={val.collection_name}>
                              {val.collection_name}
                            </option>
                          );
                        })}
                      </select>
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" style={{padding: '2px'}}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th colSpan="2" width="30%">
                            Collection Variable
                          </th>
                          <th width="30%">Input Variable</th>
                          <th width="30%">Use For Update</th>
                        </tr>
                      </thead>
                      <tbody>
                        {collectionVariable.map((val, ind) => {
                          return (
                            <tr key={ind + 'tr'}>
                              <td>{counter++}.</td>
                              <td>
                                <label
                                  className="form-check-label"
                                  htmlFor={'key' + ind}>
                                  {' '}
                                  {val.name}
                                  {val.required ? (
                                    <span className="requiredfield">*</span>
                                  ) : (
                                    ''
                                  )}
                                </label>
                              </td>
                              <td>
                                <SelectBox
                                  callBack={mapCollectionVariable}
                                  key_name={val.name}
                                  key_ind={ind}
                                  selectedVal={
                                    val['input_key'] ? val.input_key : {}
                                  }
                                  inputNodeVariableField={
                                    inputNodeVariableField
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    mapCollectionVariableForChk(ind, e)
                                  }
                                  key_name="useForUpdate"
                                  key_ind={ind}
                                  checked={
                                    val?.input_key?.useForUpdate || false
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {collectionVariable.length > 0 ? (
                      <p align="center">
                        <button
                          type="button"
                          className="custombtn btn btn-dark"
                          title="Prepare Output"
                          onClick={prepareOutput}>
                          Map & Prepare Output
                        </button>
                      </p>
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Tab>
          <Tab
            eventKey={'outputTab'}
            title="Output"
            disabled={inputParamMapping.length > 0 ? false : 'disabled'}>
            <h5>Mapped Columns</h5>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>DBLoader Key</th>
                  <th>Variable</th>
                  <th>Use For Update</th>
                </tr>
              </thead>
              <tbody>
                {inputParamMapping.map((val, ind) => {
                  return (
                    <tr key={ind}>
                      <td>{val.dbloader_key}</td>
                      <td>
                        {val['input_key']
                          ? Object.entries(val['input_key']).length === 0
                            ? 'None'
                            : val.input_key['nodeID'] +
                              '->' +
                              val.input_key['key']
                          : 'None'}
                      </td>
                      <td>{val?.input_key?.useForUpdate ? 'Y' : 'N'}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
