import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {useDispatch, connect} from 'react-redux';

import Swal from 'sweetalert2';
import {useSubheader} from '../../../_metronic/layout';
import AddProcessAutomation from './pages/AddProcessAutomation';
import {ProcessAutomationList} from './pages/ProcessAutomationList';
import {ProcessAutomationUIProvider} from './ProcessAutomationUIContext';
import {IsLoadingHOC} from '../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {deleteProcessAutomationById} from './_redux/action';
import ExecuteProcessModel from './component/ExecuteProcessModel';
// import {ProcessAutomationEditDialog} from "./component/ProcessAutomation-edit-dialog/ProcessAutomationEditDialog";

const ProcessAutomationPage = ({
  history,
  setLoading,
  deleteProcessAutomationById,
}) => {
  const suhbeader = useSubheader();
  const dispatch = useDispatch();
  suhbeader.setTitle('ProcessAutomation');

  const processAutomationUIEvents = {
    newProcessAutomationButtonClick: () => {
      history.push('/process-automation/new');
    },
    openEditProcessAutomationDialog: (id) => {
      history.push(`/process-automation/${id}/edit`);
    },
    openDeleteProcessAutomationDialog: (id) => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          deleteProcessAutomationById(id)
            .then((res) => {
              setLoading(false);
              Swal.fire(
                'Deleted!',
                'ProcessAutomation has been deleted.',
                'success',
              );
            })
            .catch((error) => {
              setLoading(false);
              console.log(error);
            });
        }
      });
    },
    // openDeleteCustomersDialog: () => {
    //   history.push(`/e-commerce/customers/deleteCustomers`);
    // },
    // openFetchCustomersDialog: () => {
    //   history.push(`/e-commerce/customers/fetch`);
    // },
    // openUpdateCustomersStatusDialog: () => {
    //   history.push('/e-commerce/customers/updateStatus');
    // },
  };
  return (
    <ProcessAutomationUIProvider
      processAutomationUIEvents={processAutomationUIEvents}>
      <Switch>
        <Redirect
          from="/process-automation"
          exact={true}
          to="/process-automation/list"
        />
        <Route
          path="/process-automation/list"
          component={ProcessAutomationList}
        />
        <Route
          path="/process-automation/new"
          component={AddProcessAutomation}
        />
        <Route
          path="/process-automation/:id/edit"
          component={AddProcessAutomation}
        />
        <Route path="/process-automation/:id/execute">
          {({history, match}) => (
            <ExecuteProcessModel
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push('/process-automation/list');
              }}
            />
          )}
        </Route>
      </Switch>
    </ProcessAutomationUIProvider>
  );
};

export default connect(null, {deleteProcessAutomationById})(
  IsLoadingHOC(ProcessAutomationPage),
);
