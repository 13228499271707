import * as Yup from 'yup';
const ValidationFormSchema = Yup.object({
  status: Yup.string().required('Required'),
  document: Yup.object().when('status', {
    is: (status) => {
      return status === 'APPROVED' ? true : false;
    },
    then: Yup.object({
      storeName: Yup.string()
        .typeError('Store Name should not be empty')
        .required('Store Name should not be empty'),
      amount: Yup.number()
        .typeError('Amount should not be empty')
        .required('Amount should not be empty'),
      qualifyingAmount: Yup.number()
        .min(1, 'Amount should be greater then zero')
        .required('Qualifying Amount should not be empty'),
      postal: Yup.string()
        .typeError('Zip Code should not be empty')
        .required('Zip Code should not be empty'),
      receiptDate: Yup.string()
        .typeError('Receipt Date should not be empty')
        .required('Receipt Date should not be empty'),

      products: Yup.array()
        .min(1, 'Please Enter at lease one UPC')
        .of(
          Yup.object().shape({
            upcCodeId: Yup.string().required('UPC Code is required'),
            quantity: Yup.number().required('Quantity is required'),
            unitPrice: Yup.number().required('Unit price is required'),
          }),
        )
        // .min(1, 'Please Enter at lease one UPC')
        .nullable()
        .required('Atleast one product UPC is required'),
    }),
  }),
});

export const validateSchema = (receiptValidation) => {
  if (Object.keys(receiptValidation).length > 0) {
    let exceptStatus = [
      'NEW',
      'READY',
      'REJECTED',
      'DUPLICATE',
      'AMBIGUOUS',
      'INVALID',
    ];
    let YupDocFields = {};
    let YupDescriptionFields = Yup.string();
    let YupReceiptEmailFields = Yup.string();
    let YupReceiptSubDateFields = Yup.string();
    for (let i in receiptValidation) {
      if (receiptValidation[i]) {
        if (i === 'UPC') {
          YupDocFields = {
            ...YupDocFields,
            products: Yup.array()
              .min(1, 'Please Enter at lease one UPC')
              .of(
                Yup.object().shape({
                  upcCodeId: Yup.string().required('UPC Code is required'),
                  quantity: Yup.number().required('Quantity is required'),
                  unitPrice: Yup.number().required('Unit price is required'),
                }),
              )
              .nullable()
              .required('Atleast one product UPC is required'),
          };
        } else if (i === 'description') {
          YupDescriptionFields = Yup.string()
            .nullable()
            .typeError('Description is required')
            .required('Description is required');
        } else if (i === 'receiptDate') {
          YupDocFields = {
            ...YupDocFields,
            receiptDate: Yup.string()
              .typeError('Receipt Date should not be empty')
              .required('Receipt Date should not be empty'),
          };
        } else if (i === 'receiptEmail') {
          YupReceiptEmailFields = Yup.string()
            .typeError('Receipt Email should not be empty')
            .required('Receipt Email should not be empty');
        } else if (i === 'receiptMemberId') {
          YupDocFields = {
            ...YupDocFields,
            memberId: Yup.string()
              .typeError('Receipt Member ID should not be empty')
              .required('Receipt Member ID should not be empty'),
          };
        } else if (i === 'storeName') {
          YupDocFields = {
            ...YupDocFields,
            storeName: Yup.string()
              .typeError('Store Name should not be empty')
              .required('Store Name should not be empty'),
          };
        } else if (i === 'submissionDate') {
          YupReceiptSubDateFields = Yup.string()
            .typeError('Submission Date should not be empty')
            .required('Submission Date should not be empty');
        } else if (i === 'totalBasketSize') {
          YupDocFields = {
            ...YupDocFields,
            amount: Yup.number()
              .typeError('Total Basket Size/Amount should not be empty')
              .required('Total Basket Size/Amount should not be empty'),
          };
        } else if (i === 'totalQualifyingProducts') {
          YupDocFields = {
            ...YupDocFields,
            qualifyingAmount: Yup.number()
              .min(
                1,
                'Total Qualifying Products Purchased/Qualifying Amount should be greater then zero',
              )
              .required(
                'Total Qualifying Products Purchased/Qualifying Amount should not be empty',
              ),
          };
        } else if (i === 'zipCode') {
          YupDocFields = {
            ...YupDocFields,
            postal: Yup.string()
              .typeError('Zip Code should not be empty')
              .required('Zip Code should not be empty'),
          };
        }
      }
    }
    if (Object.keys(YupDocFields).length === 0) {
      YupDocFields = {...YupDocFields, memberId: Yup.string()};
    }
    return Yup.object({
      status: Yup.string().required('Required'),
      document: Yup.object().when('status', {
        is: (status) => {
          return exceptStatus.indexOf(status) < 0 ? true : false;
        },
        then: Yup.object(YupDocFields),
      }),
      lines: Yup.string().when('status', {
        is: (status) => {
          return exceptStatus.indexOf(status) < 0 ? true : false;
        },
        then: YupDescriptionFields,
      }),
      receiptUploadEmail: Yup.string().when('status', {
        is: (status) => {
          return exceptStatus.indexOf(status) < 0 ? true : false;
        },
        then: YupReceiptEmailFields,
      }),
      date: Yup.string().when('status', {
        is: (status) => {
          return exceptStatus.indexOf(status) < 0 ? true : false;
        },
        then: YupReceiptSubDateFields,
      }),
    });
  } else {
    return Yup.object({
      status: Yup.string().required('Required'),
      document: Yup.object().when('status', {
        is: (status) => {
          return status === 'APPROVED' ? true : false;
        },
        then: Yup.object({
          storeName: Yup.string()
            .typeError('Store Name should not be empty')
            .required('Store Name should not be empty'),
          amount: Yup.number()
            .typeError('Amount should not be empty')
            .required('Amount should not be empty'),
          qualifyingAmount: Yup.number()
            .min(1, 'Amount should be greater then zero')
            .required('Qualifying Amount should not be empty'),
          postal: Yup.string()
            .typeError('Zip Code should not be empty')
            .required('Zip Code should not be empty'),
          receiptDate: Yup.string()
            .typeError('Receipt Date should not be empty')
            .required('Receipt Date should not be empty'),

          products: Yup.array()
            .min(1, 'Please Enter at lease one UPC')
            .of(
              Yup.object().shape({
                upcCodeId: Yup.string().required('UPC Code is required'),
                quantity: Yup.number().required('Quantity is required'),
                unitPrice: Yup.number().required('Unit price is required'),
              }),
            )
            .nullable()
            .required('Atleast one product UPC is required'),
        }),
      }),
    });
  }
};
export default ValidationFormSchema;
