import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {useDispatch, connect} from 'react-redux';

import Swal from 'sweetalert2';
import {useSubheader} from '../../../_metronic/layout';
import AddRule from './pages/AddRule';
import {RuleList} from './pages/RuleList';
import {RuleUIProvider} from './RuleUIContext';
import {IsLoadingHOC} from '../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {deleteRuleById} from './_redux/action';
// import {RuleEditDialog} from "./component/Rule-edit-dialog/RuleEditDialog";

const RulePage = ({history, setLoading, deleteRuleById}) => {
  const suhbeader = useSubheader();
  const dispatch = useDispatch();
  suhbeader.setTitle('Rule');

  const ruleUIEvents = {
    newRuleButtonClick: () => {
      history.push('/rule/new');
    },
    openEditRuleDialog: (id) => {
      history.push(`/rule/${id}/edit`);
    },
    openDeleteRuleDialog: (id) => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          deleteRuleById(id)
            .then((res) => {
              setLoading(false);
              Swal.fire('Deleted!', 'Rule has been deleted.', 'success');
            })
            .catch((error) => {
              setLoading(false);
              console.log(error);
            });
        }
      });
    },
    // openDeleteCustomersDialog: () => {
    //   history.push(`/e-commerce/customers/deleteCustomers`);
    // },
    // openFetchCustomersDialog: () => {
    //   history.push(`/e-commerce/customers/fetch`);
    // },
    // openUpdateCustomersStatusDialog: () => {
    //   history.push('/e-commerce/customers/updateStatus');
    // },
  };
  return (
    <RuleUIProvider ruleUIEvents={ruleUIEvents}>
      <Switch>
        <Redirect from="/rule" exact={true} to="/rule/list" />
        <Route path="/rule/list" component={RuleList} />
        <Route path="/rule/new" component={AddRule} />
        <Route path="/rule/:id/edit" component={AddRule} />
      </Switch>
    </RuleUIProvider>
  );
};

export default connect(null, {deleteRuleById})(IsLoadingHOC(RulePage));
