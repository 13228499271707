import React, {useState, useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import Slider from 'react-slick';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import {toAbsoluteUrl} from '../../FileManager/Utils/Utils';

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 8,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
// console.log(pageNo)
export default function ReceiptSlider({
  data,
  selectReceipt,
  currentId,
  //onChangeIndex,
  pageCount,
  handlePageClick,
  pageNo = 0,
}) {
  const [imgLoadingStatus, setImgLoadingStatus] = useState(
    Array(data.length).fill(true),
  );
  const imgLoadingComplete = (ind) => {
    let imgLoaders = [...imgLoadingStatus];
    imgLoaders[ind] = false;
    setImgLoadingStatus(imgLoaders);
  };
  useEffect(() => {
    setTimeout(() => {
      setImgLoadingStatus(Array(data.length).fill(false));
    }, 30000);
  }, []);
  return (
    <Container>
      <Row className="mt-5">
        <Col>
          <Slider
            {...settings}
            className="inner-class-div-sk"
            // afterChange={(index) => onChangeIndex(index)}
          >
            {data?.map((item, dataInd) => (
              <Paper
                key={item.id}
                onClick={() => selectReceipt(item.id)}
                elevation={3}
                border={0}
                className={`rounded-0  d-flex flex-column justify-content-between align-items-center ${
                  item.id === currentId && 'selected-receipt'
                }`}>
                {imgLoadingStatus[dataInd] && (
                  <img
                    src={toAbsoluteUrl('/media/loader.gif')}
                    style={{height: '130px', cursor: 'pointer'}}
                    className="w-100"
                  />
                )}
                <img
                  src={
                    item?.imageUrl.indexOf('.pdf') != -1
                      ? toAbsoluteUrl('/media/pdf.png')
                      : item?.imageUrl.indexOf('.xlsx') != -1 ||
                        item?.imageUrl.indexOf('.xls') != -1
                      ? toAbsoluteUrl('/media/xlsx.png')
                      : item?.imageUrl.indexOf('.csv') != -1
                      ? toAbsoluteUrl('/media/csv.png')
                      : item.imageUrl
                  }
                  style={{
                    height: '130px',
                    cursor: 'pointer',
                    display: imgLoadingStatus[dataInd] ? 'none' : 'block',
                  }}
                  className="w-100"
                  onError={(ev) => {
                    // ev.target.src =
                    //   'https://www.google.com/imgres?imgurl=https%3A%2F%2Fwww.drupal.org%2Ffiles%2Fproject-images%2Fbroken-image.jpg&imgrefurl=https%3A%2F%2Fwww.drupal.org%2Fproject%2Fbroken_image&tbnid=nitiYaiinyjKwM&vet=12ahUKEwi6noqC3rf6AhUaBrcAHZaOCRYQMygHegUIARDyAQ..i&docid=oMflpvLsnQkrhM&w=512&h=512&q=image%20broken%20image&hl=en&ved=2ahUKEwi6noqC3rf6AhUaBrcAHZaOCRYQMygHegUIARDyAQ';
                    imgLoadingComplete(dataInd);
                  }}
                  onLoad={(e) => imgLoadingComplete(dataInd)}
                  alt="something is wrong"
                />
                <p className="h6">{moment(item.date).format('YYYY-MM-DD')}</p>
              </Paper>
            ))}
          </Slider>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination justify-content-center"
            activeClassName="active"
            onPageChange={(evt) => {
              handlePageClick(evt);
              setImgLoadingStatus(Array(data.length).fill(true));
              setTimeout(() => {
                setImgLoadingStatus(Array(data.length).fill(false));
              }, 30000);
            }}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            forcePage={pageNo}
          />
        </Col>
      </Row>
    </Container>
  );
}
