import {
  SAVE_REWARDS_LIST,
  LOADING,
  REWARDS_FOR_EDIT,
  UPDATE_REWARDS,
  REWARDS_DELETE,
} from './type';
import {combineReducers} from 'redux';

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  metaData: {limit: 10, currentPageNo: 0, totalRecs: 10, nextPageNo: 1},
  rewardsForEdit: undefined,
  lastError: null,
};
const RewardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_REWARDS_LIST: {
      const {content, metaData} = action.payload;

      return {
        ...state,
        entities: content ? content : null,
        totalCount: metaData?.totalRecs,
        //totalCount: recordCount ? recordCount : 0,
      };
    }
    case LOADING: {
      return {...state, listLoading: action.payload};
    }
    case REWARDS_FOR_EDIT: {
      return {...state, rewardsForEdit: action.payload};
    }

    case REWARDS_DELETE: {
      const entities = state.entities.filter(
        (item) => item.id != action.payload,
      );
      return {...state, entities: entities, totalCount: state.totalCount - 1};
    }

    case UPDATE_REWARDS: {
      let entities = state.entities.map((entity) => {
        if (entity.id === action.payload.id) {
          return action.payload;
        }
        return entity;
      });
      return {...state, entities: entities};
    }
    case 'CLEAR_ALL': {
      return initialState;
    }
    default:
      return state;
  }
};

const initialStateHistory = {
  entities: [],
  meta: {limit: 10, currentPageNo: 0, totalRecs: 10, nextPageNo: 1},
  loading: false,
};

const rewardHistory = (state = {...initialStateHistory}, action) => {
  switch (action.type) {
    case 'INIT_REWARD_HISTORY': {
      return {...state, loading: true};
    }
    case 'SET_REWARD_HISTORY':
      return {
        entities: action.entities,
        meta: action.meta,
        loading: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  RewardsReducer,
  rewardHistory,
});
