import {
  SAVE_CODEGENERATION_LIST,
  LOADING,
  CODEGENERATION_FOR_EDIT,
  UPDATE_CODEGENERATION,
  CODEGENERATION_DELETE,
  SAVE_PRODUCT_LIST,
} from './type';

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  metaData: {limit: 10, currentPageNo: 0, totalRecs: 10, nextPageNo: 1},
  codegenerationForEdit: undefined,
  lastError: null,
  productOption: [
    {value: '618ce951e2c91c505890c1f8', label: 'Product 1'},
    {value: '618ce6bc30c91e00087f8b52', label: 'Product 2'},
    {value: '618cd22314b5710009a5c38c', label: 'Product 3'},
    {value: '618cd0cbc8c0bf32702991e4', label: 'Product 4'},
    {value: '615fdf9269e7af4918adf131', label: 'Product 5'},
    {value: '618bb1ea2980a70009beed59', label: 'Product 6'},
    {value: '618b9d58090b4159284a092e', label: 'Product 7'},
  ],
};
const CodeGenerationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CODEGENERATION_LIST: {
      const {content, metaData} = action.payload;
      return {
        ...state,
        entities: content ? content : null,
        totalCount: metaData?.totalRecs,
        metaData: metaData,
      };
    }
    case SAVE_PRODUCT_LIST: {
      return {
        ...state,
        productOption: action.payload ? action.payload : [],
      };
    }
    case LOADING: {
      return {...state, listLoading: action.payload};
    }
    case CODEGENERATION_FOR_EDIT: {
      return {...state, codegenerationForEdit: action.payload};
    }

    case CODEGENERATION_DELETE: {
      const entities = state.entities.filter(
        (item) => item.id != action.payload,
      );
      return {...state, entities: entities, totalCount: state.totalCount - 1};
    }

    case UPDATE_CODEGENERATION: {
      let entities = state.entities.map((entity) => {
        if (entity.id === action.payload.id) {
          return action.payload;
        }
        return entity;
      });
      return {...state, entities: entities};
    }
    case 'CLEAR_ALL': {
      return initialState;
    }
    default:
      return state;
  }
};

export default CodeGenerationReducer;
