import ProductsList from './ProductsList';
import * as utils from '../../_helpers/LocalStorageHelpers';
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import EditProduct from './EditProduct';
const instance = axios.create();
const BaseUrl = process.env.REACT_APP_API_URL;
export function ProductsMgmt() {
  const AuthStr = utils.getStorage('accessToken');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [productId, setProductId] = useState('');
  const [productDetails, setProductDetails] = useState({});

  const handleShow = (value) => {
    setShow(true);
    setProductId(value);
    instance
      .get(`${BaseUrl}/s1/product/${value}`, {
        headers: {Authorization: AuthStr},
      })
      .then((res) => {
        setProductDetails(res.data.data);
        //  console.log("productsDetails", res.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [products, setProducts] = useState([]);
  useEffect(() => {
    instance
      .get(`${BaseUrl}/s1/product`, {headers: {Authorization: AuthStr}})
      .then((res) => {
        setProducts(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleFormSubmit = (values) => {
    let data = {...values};
    delete data.image1;
    instance
      .put(`${BaseUrl}/s1/product/${productId}`, data, {
        headers: {Authorization: AuthStr},
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-header border-0 pt-5">
          <h1 className="font-size-sm-100 font-weight-boldest text-dark-75 mt-2">
            Products
          </h1>
        </div>

        <div className="card-body pt-3 pb-2">
          {products && products.length <= 0 && <span>Loading...</span>}

          {products && products.length > 0 && (
            <ProductsList products={products} handleShow={handleShow} />
          )}

          {productDetails && (
            <EditProduct
              show={show}
              handleClose={handleClose}
              productDetails={productDetails}
              handleFormSubmit={handleFormSubmit}
            />
          )}
        </div>
      </div>
    </div>
  );
}
