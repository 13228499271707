import {SAVE_REWARDSUMMARY_LIST, LOADING} from './type';

const initialState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  lastError: null,
};
const RewardSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_REWARDSUMMARY_LIST: {
      const {content, metaData} = action.payload;
      //console.log('content', content);
      return {
        ...state,
        entities: content ? content : null,
        totalCount: metaData?.totalRecs,
        tokenAvailable: metaData?.tokenAvailable,
        //totalCount: recordCount ? recordCount : 0,
      };
    }
    case LOADING: {
      return {...state, listLoading: action.payload};
    }

    case 'CLEAR_ALL': {
      return initialState;
    }
    default:
      return state;
  }
};

export default RewardSummaryReducer;
