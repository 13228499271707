import React, {useMemo} from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
// import { CustomersFilter } from "./customers-filter/CustomersFilter";
import CampaignMappingTable from '../component/CampaignMappingTable';
// import { CustomersGrouping } from "./customers-grouping/CustomersGrouping";
import {useCampaignMappingUIContext} from '../CampaignMappingUIContext';
import {ActionsFilter} from '../component/ActionsFilter';

export function CampaignMappingList() {
  const campaignMappingUIContext = useCampaignMappingUIContext();
  //console.log('user context', customersUIContext);
  const campaignMappingUIProps = useMemo(() => {
    return {
      ids: campaignMappingUIContext.ids,
      newCampaignMappingButtonClick:
        campaignMappingUIContext.newCampaignMappingButtonClick,
    };
  }, [campaignMappingUIContext]);

  return (
    <Card>
      <CardHeader title="Campaign Mapping">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={campaignMappingUIContext.newCampaignMappingButtonClick}>
            New Campaign Mapping
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <ActionsFilter /> */}
        {/* <CustomersFilter />
        {campaignMappingUIProps.ids.length > 0 && <CustomersGrouping />} */}
        <CampaignMappingTable />
      </CardBody>
    </Card>
  );
}
