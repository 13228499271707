import React from 'react';

const CustomWidget = ({className}) => {
  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div
        className={`card card-custom bg-radial-gradient-danger ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 ">
          <h3 className="card-title font-weight-bolder text-white">
            Report System
          </h3>
        </div>
        {/* end::Header */}
      </div>

      {/* end::Tiles Widget 1 */}
    </>
  );
};

export default CustomWidget;
