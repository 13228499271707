// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, {useEffect, useState, useMemo} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
//import * as actions from '../../../_redux/customers/customersActions';
import {
  getRequestedReportsList,
  downloadFileAction,
  refreshRequestedReportsStatus,
} from '../_redux/action';
import {connect} from 'react-redux';
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from '../../../../_metronic/_helpers';
import * as uiHelpers from '../RequestedReportsUIHelpers';
import {Pagination} from '../../../../_metronic/_partials/controls';
import {useRequestedReportsUIContext} from '../RequestedReportsUIContext';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import filterFactory from 'react-bootstrap-table2-filter';
import {toast} from 'react-toastify';
import filterFun from '../../../../_metronic/_helpers/FilterHelper';
import {Button, Modal} from 'react-bootstrap';
const RequestedReportsTable = ({
  getRequestedReportsList,
  refreshRequestedReportsStatus,
  setLoading,
}) => {
  // RequestedReports UI Context
  const requestedReportsUIContext = useRequestedReportsUIContext();
  const requestedReportsUIProps = useMemo(() => {
    return {
      queryParams: requestedReportsUIContext.queryParams,
      setQueryParams: requestedReportsUIContext.setQueryParams,
    };
  }, [requestedReportsUIContext]);

  // Getting curret state of RequestedReports list from store (Redux)
  const {campaignId, currentState} = useSelector(
    (state) => ({
      currentState: state.requestedReports,
      campaignId: state.auth.campaignId,
    }),
    shallowEqual,
  );
  const {totalCount, entities, listLoading} = currentState;

  //const {entities} = currentState;
  // console.log('entities, data', entities);

  useEffect(() => {
    setLoading(true);
    getRequestedReportsList({...requestedReportsUIContext.queryParams});
  }, [campaignId, requestedReportsUIProps.queryParams]);
  useEffect(() => {
    setLoading(listLoading);
  }, [listLoading]);
  const refeshBtnClickHandler = (row) => {
    refreshRequestedReportsStatus(row.id);
  };
  const openFileManagerBtnClickHandler = (row) => {
    let path = row?.reportPath || '';
    let anchor = document.createElement('a');
    let fileName = row?.fileName || '';
    if (!fileName) {
      toast.error(`Invalid File Name.`);
      return false;
    }
    setLoading(true);
    downloadFileAction(path, fileName)
      .then(async (respdata) => {
        let fileData = await fetch(respdata.data, {method: 'GET'});
        const fileBlobData = await fileData.blob();
        let objectUrl = window.URL.createObjectURL(fileBlobData);
        anchor.href = objectUrl;
        anchor.target = 'new';
        anchor.download = fileName;
        anchor.click();
        window.URL.revokeObjectURL(objectUrl);
        setLoading(false);
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [reportData, setReportData] = useState({});
  const [reportInfo, setReportInfo] = useState({});
  const viewReportBtnClickHandler = (row) => {
    setReportInfo(row);
    let path = row?.reportPath || '';
    let fileName = row?.fileName || '';
    if (!fileName) {
      toast.error(`Invalid File Name.`);
      return false;
    }
    setLoading(true);
    downloadFileAction(path, fileName)
      .then(async (respdata) => {
        let fileData = await fetch(respdata.data, {method: 'GET'});
        const fileTextData = await fileData.text();
        const dataLines = fileTextData.split('\n');
        const csvData = dataLines.map((line) => line.split(','));
        let headers = [];
        if (csvData) {
          for (let i in csvData[0]) {
            headers.push({dataField: csvData[0][i], text: csvData[0][i]});
          }
        }
        let csvDataArrObj = [];
        for (let i in csvData) {
          if (parseInt(i) > 0) {
            let objs = {};
            for (let j in headers) {
              objs[headers[j].dataField] = csvData[i][j];
            }
            if (csvData[i][0] !== '') csvDataArrObj.push(objs);
          }
        }

        setReportData({cols: headers, rows: csvDataArrObj});
        setLoading(false);
        setShow(true);
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });
  };
  // Table columns
  const columns = [
    {
      dataField: 'moduleName',
      text: 'Module Name',
      headerSortingClasses,
    },
    {
      dataField: 'reportName',
      text: 'Report Name',
      headerSortingClasses,
      filter: filterFun(),
    },
    {
      dataField: 'username',
      text: 'Exported By',
      headerSortingClasses,
      formatter: (cellContent, row) => {
        return <span>{row?.userInfo?.username}</span>;
      },
    },
    {
      dataField: 'startTimeStamp',
      text: 'Requested Time',
      headerSortingClasses,
      formatter: (cellContent) => {
        return (
          <span>
            {cellContent ? new Date(cellContent).toLocaleString() : ''}
          </span>
        );
      },
    },
    {
      dataField: 'requestDateRange',
      text: 'Request Date Range',
      headerSortingClasses,
      formatter: (cellContent) => {
        let arr = cellContent ? cellContent.split('--') : [];
        let cellStr1 = arr[0] ? arr[0].trim() : '';
        let cellStr2 = arr[1] ? arr[1].trim() : '';
        if (cellStr1.split('-').length == 3) {
          let arr = cellStr1.split('-');
          cellStr1 = `${arr[2]}/${arr[1]}/${arr[0]}`;
        }
        if (cellStr2.split('-').length == 3) {
          let arr2 = cellStr2.split('-');
          cellStr2 = `${arr2[2]}/${arr2[1]}/${arr2[0]}`;
        }
        return <span>{cellContent ? `${cellStr1} -- ${cellStr2}` : '-'}</span>;
      },
    },
    ,
    {
      dataField: 'fileName',
      text: 'FileName',
      headerSortingClasses,
      formatter: (cellContent) => {
        return <span>{cellContent ? cellContent : '-'}</span>;
      },
      filter: filterFun(),
    },
    {
      dataField: 'status',
      text: 'Status',
      headerSortingClasses,
      formatter: (cellContent) => {
        return (
          <span
            className={
              cellContent === 'running' ? 'text-primary' : 'text-success'
            }>
            {cellContent ? cellContent.toUpperCase() : '-'}
          </span>
        );
      },
    },
    {
      dataField: 'action',
      text: 'Action',
      headerSortingClasses,
      formatter: (cellContent, row) => {
        return (
          <>
            {row.status.toLowerCase() === 'running' && (
              <span
                title="Refresh"
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={() => refeshBtnClickHandler(row)}>
                <i className="fa fa-refresh">&#8634;</i>
              </span>
            )}
            {row.status === 'completed' && (
              <span
                title="Download"
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={() => openFileManagerBtnClickHandler(row)}>
                <i className="fa fa-download"></i>
              </span>
            )}
            {row.status === 'completed' && (
              <span
                title="View Report"
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={() => viewReportBtnClickHandler(row)}>
                <i className="fa fa-eye"></i>
              </span>
            )}
          </>
        );
      },
    },
  ];
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: requestedReportsUIProps.queryParams.limit,
    page: requestedReportsUIProps.queryParams.page,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({paginationProps, paginationTableProps}) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive text-nowrap filter-label-custom"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden w-auto"
                bootstrap4
                remote
                keyField="id"
                filter={filterFactory()}
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  requestedReportsUIProps.setQueryParams,
                )}
                {...paginationTableProps}>
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
      <Modal show={show} onHide={handleClose} size="xl" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{reportInfo?.reportName || 'View Report'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {reportData?.rows && (
            <BootstrapTable
              keyField={reportData?.cols[0]?.dataField}
              data={reportData?.rows || []}
              columns={reportData?.cols || []}
              pagination={paginationFactory()}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default connect(null, {
  getRequestedReportsList,
  refreshRequestedReportsStatus,
})(IsLoadingHOC(RequestedReportsTable));
