import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';

import {useSubheader} from '../../../_metronic/layout';
import {IsLoadingHOC} from '../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {TrackingReportsUIProvider} from './TrackingReportsUIContext';
import GTMTrackingReport from './pages/GTMTrackingReport';

const TrackingReportsPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle('');
  const requestedReportsUIEvents = {
    openDeleteTrackingReportsDialog: (id) => {},
  };

  return (
    <TrackingReportsUIProvider
      requestedReportsUIEvents={requestedReportsUIEvents}>
      <Switch>
        <Redirect
          from="/tracking-reports"
          exact={true}
          to="/tracking-reports/gtm-reports"
        />
        <Route
          path="/tracking-reports/gtm-reports"
          component={GTMTrackingReport}
        />
      </Switch>
    </TrackingReportsUIProvider>
  );
};

export default connect(null, {})(IsLoadingHOC(TrackingReportsPage));
