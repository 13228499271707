import React, {useMemo} from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
// import { CustomersFilter } from "./customers-filter/CustomersFilter";
import RuleTable from '../component/RuleTable';
// import { CustomersGrouping } from "./customers-grouping/CustomersGrouping";
import {useRuleUIContext} from '../RuleUIContext';

export function RuleList() {
  const ruleUIContext = useRuleUIContext();
  //console.log('user context', customersUIContext);
  const ruleUIProps = useMemo(() => {
    return {
      ids: ruleUIContext.ids,
      newRuleButtonClick: ruleUIContext.newRuleButtonClick,
    };
  }, [ruleUIContext]);

  return (
    <Card>
      <CardHeader title="Rules Engine">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={ruleUIContext.newRuleButtonClick}>
            New Rule
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <CustomersFilter />
        {RuleUIProps.ids.length > 0 && <CustomersGrouping />} */}
        <RuleTable />
      </CardBody>
    </Card>
  );
}
