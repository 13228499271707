import React, {useState} from 'react';
import {Tabs, Tab} from 'react-bootstrap/';
import './NodeProperties.css';
import SelectBox from './SelectBox';
export default function NotificationNodeProperties(props) {
  let nodeProperties = props.nodeProperty;
  let [activeTabs, setActiveTabs] = useState('inputTab');
  let [notificationVariableMapping, setNotificationVariableMapping] = useState(
    nodeProperties.inputParam.mapping.length > 0
      ? nodeProperties.inputParam.mapping
      : [
          {notification_key: 'highPriority', required: true, input_key: {}},
          {notification_key: 'subject', required: true, input_key: {}},
          {notification_key: 'shortMessage', required: false, input_key: {}},
          {notification_key: 'fullMessage', required: true, input_key: {}},
          {notification_key: 'receiverEmail', required: true, input_key: {}},
        ],
  );
  let inputNodeVariableField = nodeProperties.inputParam.variables
    ? nodeProperties.inputParam.variables
    : {};
  let prepareOutput = (event) => {
    event.preventDefault();
    let checkList = notificationVariableMapping.filter(
      (val, ind) =>
        val.required &&
        (!val['input_key'] || Object.entries(val['input_key']).length === 0),
    );
    if (checkList.length > 0) {
      let ErrorMessage = '';
      let reqVariablePending = [];
      checkList.forEach((val) => {
        reqVariablePending.push(val.notification_key);
      });
      ErrorMessage += reqVariablePending.toString() + ' is Required';
      alert(ErrorMessage);
      return false;
    }

    nodeProperties.inputParam.mapping = notificationVariableMapping;
    nodeProperties.outputParam.variables = Object.assign(
      {},
      nodeProperties.inputParam.variables,
    );
    setActiveTabs('outputTab');
    props.parentCallback(nodeProperties, 1);
  };
  let mapVariable = (Key, ind, e) => {
    let selectedVal = e.target.value;
    let tempnotificationVariableMapping = [...notificationVariableMapping];
    tempnotificationVariableMapping[ind]['input_key'] = {};
    if (selectedVal && selectedVal !== '') {
      let details = selectedVal.split('|');
      let nodeID = details.length > 0 ? details[0] : '';
      let key = details.length > 1 ? details[1] : '';
      tempnotificationVariableMapping[ind]['input_key'] = {
        key: key,
        nodeID: nodeID,
      };
    }
    /*if (notificationVariableMapping[ind].required && selectedVal === "") {
            alert(`${notificationVariableMapping[ind].notification_key} is Required`);
            return false;
        }*/
    setNotificationVariableMapping(tempnotificationVariableMapping);
  };
  let counter = 1;
  return (
    <>
      <h2 className="leftToolBar">
        Object Properties
        <br />
        <span>Notification Node</span>
      </h2>
      <div className="leftToolBarContainer">
        <Tabs
          activeKey={activeTabs}
          transition={false}
          id="noanim-tab-example"
          onSelect={(key) => {
            setActiveTabs(key);
          }}>
          <Tab eventKey={'inputTab'} title="Input">
            <table className="table table-striped" id="notificationNodeTable">
              <thead>
                <tr>
                  <th colSpan="2" width="40%">
                    Email Variable
                  </th>
                  <th width="60%">Input Node Variable</th>
                </tr>
              </thead>
              <tbody>
                {notificationVariableMapping.map((val, ind) => {
                  return (
                    <tr key={ind + 'tr'}>
                      <td>{counter++}.</td>
                      <td>
                        <label
                          className="form-check-label"
                          htmlFor={'key' + ind}>
                          {' '}
                          {val.notification_key}
                          {val.required ? (
                            <span className="requiredfield">*</span>
                          ) : (
                            ''
                          )}
                        </label>
                      </td>
                      <td>
                        <SelectBox
                          callBack={mapVariable}
                          key_name={val.notification_key}
                          key_ind={ind}
                          selectedVal={val['input_key'] ? val.input_key : {}}
                          inputNodeVariableField={inputNodeVariableField}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <p align="center">
              <button
                type="button"
                className="custombtn btn btn-dark"
                title="Prepare Output"
                onClick={prepareOutput}>
                Map & Prepare Output
              </button>
            </p>
          </Tab>
          <Tab
            eventKey={'outputTab'}
            title="Output"
            disabled={
              notificationVariableMapping[0].input_key['nodeID']
                ? false
                : 'disabled'
            }>
            <h5>Mapped Columns</h5>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Email Variable</th>
                  <th>Input Node Variable</th>
                </tr>
              </thead>
              <tbody>
                {notificationVariableMapping.map((val, ind) => {
                  return (
                    <tr key={ind}>
                      <td>{val.notification_key}</td>
                      <td>
                        {val['input_key']
                          ? Object.entries(val['input_key']).length === 0
                            ? 'None'
                            : val.input_key['nodeID'] +
                              '->' +
                              val.input_key['key']
                          : 'None'}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
