import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import {ExectutionTabs} from './ExecuteProcessCM/ExectutionTabs';
import {useLocation} from 'react-router';

export default function ExecuteProcessModel({id, show, onHide}) {
  const location = useLocation();
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title">
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Execute Process{' '}
          {location.state?.process_model_name &&
            `: ${location.state?.process_model_name}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ExectutionTabs onHide={onHide} id={id} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
