import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonList from './ButtonGroupSimple';
import {formatBytes} from '../../Utils/Utils';
import fmService from '../../FileManager.Service';
import Alert from '@material-ui/lab/Alert';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  acceptedFiles: {
    fontSize: '12px',
    padding: '0px',
    margin: '0px',
  },
  container: {
    position: 'absolute',
    zIndex: '55',
    top: '-1px',
    background: '#f6f7fd',
    border: '1px solid #868DAA',
    borderTop: 'none',
    borderRadius: '0px 0px 5px 5px',
    padding: '20px 40px',
    margin: '0px 0px 0px 50px',
    '& .dropzone': {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '50px 20px',
      borderWidth: '2px',
      borderRadius: '2px',
      borderStyle: 'dashed',
      backgroundColor: '#fff',
      margin: '10px 0px',
      color: '#bdbdbd',
      cursor: 'pointer',
      outline: 'none',
      transition: 'border .24s ease-in-out',
      '&:focus': {
        borderColor: '#1e1e2d',
      },
      '& p': {
        padding: '0px',
        margin: '0px',
      },
    },
  },
}));

export default function UploadFiles(props) {
  const classes = useStyles();
  const {handleCancel} = props;
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const {getRootProps, getInputProps} = useDropzone({
    //accept: 'image/*',
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const removeFile = (index) => {
    var newFiles = [...files];
    newFiles.splice(index, 1);
    console.log(newFiles, files);
    setFiles(newFiles);
    setConfirmBox(false);
  };

  const acceptedFiles = files.map((file, index) => (
    <li key={file.path}>
      {file.name} - {formatBytes(file.size)} ({' '}
      <a href="#" onClick={() => removeFile(index)}>
        <span>Remove</span>
      </a>
      )
    </li>
  ));
  const getBase64 = (files) => {
    let file = files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((res, rej) => {
      reader.onload = (event) => {
        res(event.target.result);
      };
      reader.onerror = (error) => {
        rej(error);
      };
    });
  };
  const handleSubmitUpload = async () => {
    setIsUploading(true);
    let body = {path: props.currentFolder, fileName: null, contentType: ''};
    files.map(async (file, index) => {
      body.fileName = file.name;
      body.contentType = file.type;
    });
    let tempcheckName = props.filesList.find((v) => v.name === body.fileName);
    if (tempcheckName) {
      setConfirmBox(true);
      setIsUploading(false);
      return true;
    }
    let fileBody = await getBase64(files);
    fmService
      .uploadFileService(body)
      .then(async (result) => {
        try {
          let binary = atob(fileBody.split(',')[1]);
          let array = [];
          for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
          }
          let blobData = new Blob([new Uint8Array(array)], {
            type: body.contentType,
          });
          let uploadStatus = await fetch(result, {
            method: 'PUT',
            body: blobData,
          });
          if (uploadStatus.status < 200 || uploadStatus.status > 300) {
            setIsUploading(false);
            alert('error in uploading');
            console.log('error', uploadStatus);
          } else {
            props.handleReload();
            handleCancel();
            setIsUploading(false);
          }
        } catch (ex) {
          setIsUploading(false);
          alert('error in uploading');
          console.log('error', ex);
        }
      })
      .catch((ex) => {
        setIsUploading(false);
        alert('error in uploading');
        console.log('error', ex);
      });
  };
  const handleSubmitForceUpload = async () => {
    setIsUploading(true);
    setConfirmBox(false);
    let body = {path: props.currentFolder, fileBody: null, fileName: null};
    files.map(async (file, index) => {
      body.fileName = file.name;
    });
    body.fileBody = await getBase64(files);
    fmService
      .uploadFileService(body)
      .then((result) => {
        props.handleReload();
        handleCancel();
        setIsUploading(false);
      })
      .catch((ex) => {
        setIsUploading(false);
        alert(ex);
      });
  };
  const handleCancelUpload = () => {
    handleCancel();
    setConfirmBox(false);
  };
  const handleCancelForceUpload = () => {
    setConfirmBox(false);
  };
  const buttons = [
    {
      name: 'submit',
      icon: 'icon-save',
      label: 'Upload Files To Server',
      class: 'green',
      onClick: handleSubmitUpload,
      disabled: !(files.length > 0),
    },
    {
      name: 'submit',
      icon: 'icon-ban',
      label: 'Cancel',
      type: 'link',
      onClick: handleCancelUpload,
    },
  ];
  const confirmButtons = [
    {
      name: 'submit',
      icon: 'icon-save',
      label: 'Replace File',
      class: 'green',
      onClick: handleSubmitForceUpload,
      disabled: !(files.length > 0),
    },
    {
      name: 'submit',
      icon: 'icon-ban',
      label: 'Cancel',
      type: 'link',
      onClick: handleCancelForceUpload,
    },
  ];

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  return (
    <section className={classes.container}>
      {confirmBox ? (
        <div className="confirmBox">
          <Alert severity="error">
            File already exists with this name, Kindly choose any given below
            options.
          </Alert>
          <p style={{width: '100%'}} align="center">
            <ButtonList buttons={confirmButtons} />
          </p>
        </div>
      ) : (
        <>
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop a file here, or click to select a file</p>
          </div>
          <ul className={classes.acceptedFiles}>{acceptedFiles}</ul>
          {!isUploading ? (
            <ButtonList buttons={buttons} />
          ) : (
            <p align="center">
              <br />
              <CircularProgress />
            </p>
          )}
        </>
      )}
    </section>
  );
}
