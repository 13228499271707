import React, { Component } from "react";
import './ProcessDiagram.css';
import { Spinner } from "react-bootstrap";
import { dbNodeObj, csvNodeObj, notificationNodeObj, pdfNodeObj, startNodeObj, templateNodeObj, endNodeObj, dbloaderNodeObj, dataexportNodeObj } from "./toolboxObjectModel";
import ToolBox from "./ToolBox";
import { DBNodeProperties, TemplateNodeProperties, NodeDefaultProperties, NotificationNodeProperties, StartNodeProperties, EndNodeProperties, CsvNodeProperties, DBLoaderProperties, DataExporterNodeProperties } from "./NodeProperties/NodeProperties";
import ReactDOM from "react-dom";
import { postRequest } from '../../_helpers/APIRequestHelpers';
import { mxGraph, mxClient, mxUtils, mxEvent, mxRubberband, mxKeyHandler } from "mxgraph-js";
import dbicon from '../../_assets/images/db.png';
import pdficon from '../../_assets/images/pdf.png';
import emailicon from '../../_assets/images/email.png';
import templateicon from '../../_assets/images/template.png';
import eventBusicon from '../../_assets/images/eventBus.png';
import jsCodeicon from '../../_assets/images/jscode.png';
import dbImporticon from '../../_assets/images/db_import.png';
import csvicon from '../../_assets/images/csv.png';
import dataExporterIcon from '../../_assets/images/dataExport.png';
import AlertPopup from "../PopupModel/AlertPopup";
let iconList = {
    dbicon: dbicon,
    pdficon: pdficon,
    emailicon: emailicon,
    templateicon: templateicon,
    jsCodeicon: jsCodeicon,
    eventBusicon: eventBusicon,
    dbImporticon: dbImporticon,
    csvicon: csvicon,
    dataExporterIcon: dataExporterIcon
}

var graphData = null, lastStateChildData = {}, graphParent = null, graphDoc = null, NodeObjectProperty = NodeDefaultProperties;
export class ProcessDiagram extends Component {
    constructor(props) {
        super(props);
        this.state = { showAlertMessage: null, reloadWindow: false, selectedNodeID: '', nodeProperty: {}, processModelName: "", processModelDescription: "", processModelStatus: "active", processModelSaveLoading: false };
        this.divGraph = null;
        this.setDivGraphRef = element => {
            this.divGraph = element;
        };
        this.loadGraph = this.loadGraph.bind(this);
        this.createNewNode = this.createNewNode.bind(this);
        NodeObjectProperty = NodeDefaultProperties;
    }
    loadObjectProperty(selectedNode) {
        let Node = null;
        NodeObjectProperty = NodeDefaultProperties;
        if (selectedNode) {
            let nodeID = selectedNode.id;

            if (nodeID === "start") {
                NodeObjectProperty = StartNodeProperties;
                Node = startNodeObj
            } else if (nodeID === "db") {
                NodeObjectProperty = DBNodeProperties;
                Node = dbNodeObj
            } else if (nodeID === "template") {
                NodeObjectProperty = TemplateNodeProperties;
                Node = templateNodeObj
            } else if (nodeID === "notification") {
                NodeObjectProperty = NotificationNodeProperties;
                Node = notificationNodeObj;
            } else if (nodeID === "pdf") {
                NodeObjectProperty = NodeDefaultProperties
                Node = pdfNodeObj;
            } else if (nodeID === "end") {
                NodeObjectProperty = EndNodeProperties
                Node = endNodeObj;
            } else if (nodeID === "csv") {
                NodeObjectProperty = CsvNodeProperties
                Node = csvNodeObj;
            } else if (nodeID === "dbloader") {
                NodeObjectProperty = DBLoaderProperties
                Node = dbloaderNodeObj;
            } else if (nodeID === "dataexport") {
                NodeObjectProperty = DataExporterNodeProperties
                Node = dataexportNodeObj;
            }
        }
        if (Node) {
            Node.inputParam = JSON.parse(selectedNode.getAttribute("inputParam"));
            Node.outputParam = JSON.parse(selectedNode.getAttribute("outputParam"));
            this.setState(Object.assign({}, this.state, {
                "selectedNodeID": Node.nodeID, nodeProperty: {
                    "nodeID": Node.nodeID,
                    "nodeType": Node.nodeType,
                    "nodeTitle": Node.nodeTitle,
                    "description": Node.description,
                    "xaxis": Node.xaxis,
                    "yaxis": Node.yaxis,
                    "inputParam": Node.inputParam,
                    "outputParam": Node.outputParam,
                }
            }
            ));
        } else {
            this.setState(Object.assign({}, this.state, { "selectedNodeID": "", nodeProperty: {} }));
        }
        window.scrollTo(0, 0);
    }
    createNewNode(nodeType) {
        if (graphData) {
            let { model } = graphData;
            for (let key in model.cells) {
                let cell = model.cells[key];
                if (cell.value && cell.value !== undefined) {
                    if (cell.getAttribute("nodeType") === nodeType) {
                        this.setState(Object.assign({}, this.state, { showAlertMessage: `Already added ${nodeType} node in Process model kindly. Use the same` }));
                        return false;
                    }
                }
            }
            graphData.getModel().beginUpdate();
            try {
                let newNode = null;
                if (nodeType === "db") {
                    newNode = dbNodeObj
                    dbNodeObj.inputParam.dbQuery = "";
                } else if (nodeType === "template") {
                    newNode = templateNodeObj
                } else if (nodeType === "notification") {
                    newNode = notificationNodeObj;
                } else if (nodeType === "pdf") {
                    newNode = pdfNodeObj;
                } else if (nodeType === "csv") {
                    newNode = csvNodeObj;
                } else if (nodeType === "dbloader") {
                    newNode = dbloaderNodeObj;
                } else if (nodeType === "dataexport") {
                    newNode = dataexportNodeObj;
                }

                if (newNode) {
                    let obj = graphDoc.createElement('customcontainer');
                    let lableValue = newNode["nodeTitle"];
                    if (newNode["logo"] && newNode["logo"] !== '') {
                        lableValue = '<p style="font-size:0.8rem; white-space: normal;margin-top:10px" align="center"><img src="' + iconList[newNode["logo"]] + '" width="30px"/></br> <strong>' + newNode["nodeTitle"] + '</strong></p>';
                    }
                    obj.setAttribute('label', lableValue);
                    obj.setAttribute('inputParam', JSON.stringify(newNode["inputParam"]));
                    obj.setAttribute('description', newNode.description);
                    obj.setAttribute('outputParam', JSON.stringify(newNode["outputParam"]));
                    obj.setAttribute('nodeType', newNode.nodeType);
                    graphData.insertVertex(graphParent, newNode.nodeID, obj, newNode["xaxis"], newNode["yaxis"], newNode["nodeShape"]["width"], newNode["nodeShape"]["height"], newNode["nodeShape"]["style"]);
                }
            } finally {
                graphData.getModel().endUpdate();
            }
        }
    }
    updateInputOutputParam = (cell, resetCells = 0) => {
        if (cell["edges"]) {
            let curCell = cell, Edgestatus = (curCell.edges ? true : false);
            while (Edgestatus) {
                let nodeID = curCell.id;
                curCell.edges.forEach(element => {
                    if (element.source.id === nodeID && element.target) {
                        let temp = JSON.parse(element.target.getAttribute("inputParam"));
                        let temp2 = JSON.parse(element.source.getAttribute("outputParam"));
                        if (resetCells === 1) {
                            temp.variables = {};
                            if (temp["mapping"]) {
                                temp.mapping = [];
                            }
                            if (temp["templateId"]) {
                                temp.templateId = "";
                            }
                        } else {
                            temp.variables = Object.assign(temp.variables, temp2.variables);
                        }
                        element.target.setAttribute('inputParam', JSON.stringify(temp));
                        temp = JSON.parse(element.target.getAttribute("outputParam"));
                        temp2 = JSON.parse(element.source.getAttribute("outputParam"));
                        if (resetCells === 1) {
                            temp.variables = {};
                        } else {
                            temp.variables = Object.assign(temp.variables, temp2.variables);
                        }
                        element.target.setAttribute('outputParam', JSON.stringify(temp));
                        curCell = element.target;
                    }
                });
                if (nodeID === curCell.id) {
                    Edgestatus = false;
                }
            }
        }
        return true;
    }
    handleChildComponentCallback = (childData, type = 1) => {
        if (graphData && type === 1) {
            let { model } = graphData;
            for (let key in model.cells) {
                let cell = model.cells[key];
                if (cell.value && cell.value !== undefined) {
                    if (cell.id === childData.nodeID) {
                        cell.setAttribute('inputParam', JSON.stringify(childData.inputParam));
                        cell.setAttribute('outputParam', JSON.stringify(childData.outputParam));
                        if (cell["edges"]) {
                            this.updateInputOutputParam(cell);
                        }
                        return true;
                    }
                }
            }
        }
        else if (type === 2) {
            lastStateChildData = Object.assign(lastStateChildData, childData);
        }
        else if (type === 3) {
            this.setState(Object.assign({}, this.state, { showAlertMessage: null }));
        }
    }
    componentDidMount() {
        this.loadGraph(this.props.diagramData);
    }
    onChangeHandler = (e) => {
        let inputName = e.target.name, inputValue = e.target.value;
        this.setState(Object.assign({}, this.state, { [inputName]: inputValue }));
    }
    saveProcessModel = (e) => {
        if (this.state.processModelName === "" || !this.state.processModelName) {
            this.setState(Object.assign({}, this.state, { showAlertMessage: "Please enter process model name" }));
            return false;
        }
        this.setState(Object.assign({}, this.state, { processModelSaveLoading: true }));
        let processModelData = {
            process_name: this.state.processModelName,
            description: this.state.processModelDescription,
            status: this.state.processModelStatus,
            process_model: { nodes: {}, connectors: {}, globalParam: {} }
        }
        if (graphData) {
            let { model } = graphData;
            for (let key in model.cells) {
                let cell = model.cells[key];
                if (cell.value && cell.value !== undefined) {
                    let nodeID = cell.id;
                    if (nodeID === "start") {
                        processModelData.process_model.connectors = this.getConnector(cell);
                    }
                    let nodeOutputParam = JSON.parse(cell.getAttribute("outputParam"));
                    processModelData.process_model.nodes[nodeID] = {
                        nodeID: cell.id,
                        "nodeType": cell.getAttribute("nodeType"),
                        "description": cell.getAttribute("description"),
                        "xaxis": cell.geometry.x,
                        "yaxis": cell.geometry.y,
                        "inputParam": JSON.parse(cell.getAttribute("inputParam")),
                        "outParam": nodeOutputParam
                    }
                    processModelData.process_model.globalParam = Object.assign(processModelData.process_model.globalParam, nodeOutputParam.variables);
                }
            }
        }
        let connectorLen = processModelData.process_model.connectors.length;
        let lastOutbondNode = (connectorLen > 0 ? processModelData.process_model.connectors[connectorLen - 1].outboundNodeId : "");
        if (connectorLen < 2 || lastOutbondNode !== "end") {

            this.setState(Object.assign({}, this.state, { processModelSaveLoading: false, showAlertMessage: "Please define proper process model execution connector" }));
            return false;
        } else {
            postRequest('/s1/automation/create-process-model', processModelData, (response, error) => {
                if (error) {
                    this.setState(Object.assign({}, this.state, { processModelSaveLoading: false, showAlertMessage: error }));
                } else if (response) {
                    this.setState(Object.assign({}, this.state, { processModelSaveLoading: false }));
                    if (response.data["httpCode"] >= 200 && response.data["httpCode"] <= 299) {
                        this.setState(Object.assign({}, this.state, { showAlertMessage: "Process Model saved successfully", reloadWindow: true }));
                    } else {
                        this.setState(Object.assign({}, this.state, { showAlertMessage: "something went wrong" }));
                    }
                }
            });
        }
    }
    getConnector = (cell) => {
        let connectors = [];
        if (cell["edges"]) {
            let curCell = cell, Edgestatus = (curCell.edges ? true : false);
            while (Edgestatus) {
                let nodeID = curCell.id;
                curCell.edges.forEach(element => {
                    if (element.source.id === nodeID && element.target) {
                        connectors.push({
                            connectorId: element.id,
                            inboundNodeId: nodeID,
                            outboundNodeId: element.target.id
                        });
                        curCell = element.target;
                    }
                });
                if (nodeID === curCell.id) {
                    Edgestatus = false;
                }
            }
        }
        return connectors;
    }
    loadGraph(diagramData) {
        let container = ReactDOM.findDOMNode(this.divGraph);
        // Checks if the browser is supported
        if (!mxClient.isBrowserSupported()) {
            // Displays an error message if the browser is not supported.
            mxUtils.error("Browser is not supported!", 200, false);
        } else {
            mxGraph.prototype.addListener(mxEvent.CLICK, (sender, event) => {
                var selectedCell = event.getProperty("cell");
                if (selectedCell) {
                    if (selectedCell["vertex"]) {
                        this.loadObjectProperty(selectedCell);
                    } else {
                        this.loadObjectProperty(null);
                    }
                }
            });

            mxGraph.prototype.addListener(mxEvent.CELL_CONNECTED, (sender, event) => {
                var sourceNode = event["properties"]["edge"]["source"];
                var targetNode = event["properties"]["edge"]["target"];
                if (sourceNode && targetNode) {
                    this.updateInputOutputParam(sourceNode);
                }
            });

            let graph = new mxGraph(container);
            graph.getEdgeValidationError = function (edge, source, target) {
                if (source !== null && target !== null && this.model.getValue(source) !== null && this.model.getValue(target) !== null) {
                    if (source.getAttribute("nodeType") === "end") {
                        return 'End Node should be last node of Our Process Model';
                    } else if (target.getAttribute("nodeType") === "start") {
                        return 'Start Node should be entry point of Our Process Model';
                    } else if (source.edges) {
                        let isSourceExists = false;
                        for (let i in source.edges) {
                            if (source.getAttribute("nodeType") === source.edges[i].source.getAttribute("nodeType")) {
                                isSourceExists = true;
                            }
                        }
                        if (isSourceExists) {
                            return "You can't connect more then one node from Source node";
                        }
                    } else if (target.edges) {
                        let isTargetExists = false;
                        for (let i in target.edges) {
                            if (target.getAttribute("nodeType") === target.edges[i].target.getAttribute("nodeType")) {
                                isTargetExists = true;
                            }
                        }
                        if (isTargetExists) {
                            return "You can't connect more then one source node to Target node";
                        }
                    }
                }
                return mxGraph.prototype.getEdgeValidationError.apply(this, arguments);
            }
            // Enables rubberband selection
            new mxRubberband(graph);

            let parent = graph.getDefaultParent();
            graphParent = parent;
            // Enables tooltips, new connections and panning
            graph.setPanning(true);
            graph.setTooltips(true);
            graph.setConnectable(true);
            graph.setEnabled(true);
            graph.setEdgeLabelsMovable(false);
            graph.setVertexLabelsMovable(false);
            graph.setGridEnabled(true);
            graph.setAllowDanglingEdges(false);
            graph.setCellsResizable(false);
            graph.setCellsEditable(false);
            graph.dropEnabled = true;
            graph.getModel().beginUpdate();
            try {
                //mxGrapg component
                graphDoc = mxUtils.createXmlDocument();
                graph.getEditingValue = (cell, attr = 'label') => {
                    if (mxUtils.isNode(cell.value) && cell.value.nodeName.toLowerCase() === 'customcontainer') {
                        return cell.getAttribute(attr);
                    } else {
                        return false;
                    }
                };
                // Overrides method to provide a cell label in the display
                graph.convertValueToString = (cell) => {
                    if (mxUtils.isNode(cell.value) && cell.value.nodeName.toLowerCase() === 'customcontainer') {
                        // Returns a DOM for the label
                        var div = document.createElement('div');
                        div.innerHTML = cell.getAttribute('label');
                        mxUtils.br(div);
                        return div;
                    }
                    return '';
                };
                let { nodeList, edgeList } = diagramData;
                let vertexList = {};
                for (let i = 0; i < nodeList.length; i++) {
                    let obj = graphDoc.createElement('customcontainer');
                    let lableValue = nodeList[i]["nodeTitle"];
                    if (nodeList[i]["logo"] && nodeList[i]["logo"] !== '') {
                        lableValue = '<img src="' + iconList[nodeList[i]["logo"]] + '" width="30px"/><p style="font-size:0.8px;white-space: normal;"> <strong>' + nodeList[i]["nodeTitle"] + '</strong></p>';
                    }
                    obj.setAttribute('label', lableValue);
                    obj.setAttribute('inputParam', JSON.stringify(nodeList[i]["inputParam"]));
                    obj.setAttribute('outputParam', JSON.stringify(nodeList[i]["outputParam"]));
                    obj.setAttribute('description', nodeList[i]["description"]);
                    obj.setAttribute('nodeType', nodeList[i]["nodeType"]);
                    vertexList[nodeList[i]["nodeID"]] = graph.insertVertex(parent, nodeList[i]["nodeID"], obj, nodeList[i]["xaxis"], nodeList[i]["yaxis"], nodeList[i]["nodeShape"]["width"], nodeList[i]["nodeShape"]["height"], nodeList[i]["nodeShape"]["style"]);
                }
                for (let j = 0; j < edgeList.length; j++) {
                    graph.insertEdge(parent, edgeList[j]["edgeID"], "", vertexList[edgeList[j]["inboundNodeId"]], vertexList[edgeList[j]["outboundNodeId"]], edgeList[j]["style"]);
                }
            } finally {
                graph.getModel().endUpdate();
                graphData = graph;
            }
            let keyHandler = new mxKeyHandler(graph);
            keyHandler.bindKey(46, (evt) => {
                if (graph.isEnabled()) {
                    let currentSelectedCell = graph.getSelectionCell();
                    if (currentSelectedCell) {
                        if (currentSelectedCell.getAttribute("nodeType") !== "start" && currentSelectedCell.getAttribute("nodeType") !== "end") {
                            let msg = "Are you sure to delete this node?";
                            msg = (currentSelectedCell["edge"] ? "Are you sure to delete this connector?" : msg);
                            if (window.confirm(msg)) {
                                if (currentSelectedCell["edge"]) {
                                    this.updateInputOutputParam(currentSelectedCell.source, 1);
                                } else {
                                    this.updateInputOutputParam(currentSelectedCell, 1);
                                }
                                graph.removeCells();
                                this.loadObjectProperty(null);
                            }
                        }
                    }
                }
            });
        }
    }
    render() {
        return <>
            {this.state.showAlertMessage ? <AlertPopup parentCallback={this.handleChildComponentCallback} message={this.state.showAlertMessage} reloadWindow={this.state.reloadWindow} /> : ""}
            <div className="card card-custom card-stretch gutter-b">
                <div className="card-header border-0 pt-5">

                    <h1 className="font-size-sm-100 font-weight-boldest text-dark-75 mt-2">
                        Process Model
                    </h1>
                </div>
                <div className="card-body pt-3 pb-2">
                    <div className="row">
                        <div className="col-md-1 col-sm-2 col-xs-12">
                            <h2 className="leftToolBar">Tool Box</h2>
                            <div className="leftToolBarContainer">
                                <ToolBox parentCallback={this.createNewNode} />
                            </div>
                        </div>
                        <div className="col-md-7 col-sm-6 col-xs-12">
                            <div className="graph-container" ref={this.setDivGraphRef} id="divGraph"></div>
                            <hr />

                            <div className="form-group row">
                                <label htmlFor="processModelName" className="col-sm-2 col-form-label">Name <span className="requiredfield">*</span></label>
                                <div className="col-sm-10">
                                    <input type="text" maxLength="100" value={this.state.processModelName} className="form-control" name="processModelName" id="processModelName" placeholder="Process Model Name" onChange={this.onChangeHandler} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="processModelDescription" className="col-sm-2 col-form-label">Description</label>
                                <div className="col-sm-10">
                                    <textarea className="form-control" rows="2" maxLength="300" name="processModelDescription" id="processModelDescription" placeholder="Description" value={this.state.processModelDescription} onChange={this.onChangeHandler} ></textarea>
                                </div>
                            </div>
                            <fieldset className="form-group">
                                <div className="row">
                                    <legend className="col-form-label col-sm-2 pt-0">Status <span className="requiredfield">*</span></legend>
                                    <div className="col-sm-10">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="processModelStatus" id="processModelStatus1" value="active" checked={this.state.processModelStatus === "active" ? 'checked' : ''} onChange={this.onChangeHandler} />
                                            <label className="form-check-label" htmlFor="processModelStatus1">
                                                Active
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="processModelStatus" id="processModelStatus2" value="inactive" checked={this.state.processModelStatus === "inactive" ? 'checked' : ''} onChange={this.onChangeHandler} />
                                            <label className="form-check-label" htmlFor="processModelStatus2">
                                                Inactive
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <hr />
                            <p align="center">
                                <button type="button" className="btn btn-dark" onClick={this.saveProcessModel} disabled={!this.state.processModelSaveLoading ? "" : "disabled"}>{this.state.processModelSaveLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : ''}  Save Data Model</button>
                            </p>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <NodeObjectProperty parentCallback={this.handleChildComponentCallback} nodeProperty={this.state.nodeProperty} lastStateChildData={lastStateChildData[this.state.nodeProperty.nodeID] ? lastStateChildData[this.state.nodeProperty.nodeID] : {}} />
                        </div>
                    </div>
                </div>
            </div>
        </>;
    }
}
