import React, {useEffect, useMemo, useState} from 'react';
import {Modal, Button} from 'react-bootstrap';
import IsLoadingHOC from '../../../layout/components/HOC/IsLoadingHOC';
import {connect, useSelector} from 'react-redux';
import {Formik, ErrorMessage} from 'formik';
import {getReportData, getReportDetails} from '../_redux/action';
import DatePicker from 'react-datepicker';
import * as Yup from 'yup';
import ErrorBoundary from '../../../_helpers/ErrorBoundary';
import {FormHelperText} from '@material-ui/core';
import {toast} from 'react-toastify';
import moment from 'moment';

const ModelForm = ({
  setLoading,
  modelVisible,
  id,
  dialogClose,
  getReportData,
  getReportDetails,
}) => {
  //const [open, dialogOpen, dialogClose] = useDialogHook();
  const [initialvalues, setInit] = useState({});
  const [toggleForm, setToggetValue] = useState(false);
  const [customSchema, setCustomSchema] = useState({});
  const [resDownload, setResDownload] = useState(null);
  const {accessToken} = useSelector((state) => state.auth);
  const [details, setDetails] = useState({
    name: '',
    inputCriteria: [],
  });
  const [html, setHtml] = useState();
  useEffect(() => {
    setResDownload(null);
    setLoading(true);
    getReportDetails(id)
      .then((res) => {
        setLoading(false);
        setDetails(res);
        customSchemaCreate();
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, [id]);

  const saveForm = async (values) => {
    setLoading(true);
    try {
      setResDownload(null);
      const data = await getReportData(id, values);
      setLoading(false);
      if (data.httpCode === 200) {
        if ((values?.outputType || '') === 'csv') {
          setResDownload(data?.data);
        } else {
          setHtml(data?.data);
          var myWindow = window.open(
            '',
            '',
            'width=1000,height=800,fullscreen=yes',
          );
          myWindow.document.write(data?.data);
        }
      } else {
        if (data?.errors) {
          if (Array.isArray(data.errors)) {
            data.errors.map((item) => {
              toast.error(item?.message);
            });
          }
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const customSchemaCreate = () => {
    const Obj = {};
    // console.log('details', details.inputCriteria);
    const initVal = {};
    if (details?.inputCriteria?.length > 0) {
      details.inputCriteria.forEach((item) => {
        initVal[item.inputName] = '';
        if (item.required) {
          if (item.inputType === 'datepicker') {
            Obj[item.inputName] = Yup.date().required('Required');
          } else {
            Obj[item.inputName] = Yup.string().required('Required');
          }
        }
      });
    }

    setInit(initVal);
    const FormSchema = Yup.object(Obj);
    setCustomSchema(FormSchema);
  };

  const optionHtml = (inputOptions) => {
    const itemHtml = [];
    for (let k in inputOptions) {
      itemHtml.push(
        <option key={k} value={k}>
          {inputOptions[k]}
        </option>,
      );
    }
    return itemHtml;
  };

  return (
    <Modal
      // className="container-fluid"
      size="xl"
      show={modelVisible}
      onHide={() => {
        dialogClose();
        setToggetValue(false);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title">
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {details.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {toggleForm ? (
          <ErrorBoundary>
            <button
              onClick={() => setToggetValue(false)}
              className={`btn btn-primary font-weight-bold mr-2`}>
              Back
            </button>
            <div dangerouslySetInnerHTML={{__html: html}} />
          </ErrorBoundary>
        ) : (
          <ErrorBoundary>
            <Formik
              initialValues={initialvalues}
              enableReinitialize
              onSubmit={(values, {resetForm}) => {
                saveForm(values);
              }}
              validationSchema={customSchema}
              onReset={() => {}}>
              {({
                values,
                handleReset,
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                errors,
              }) => (
                <>
                  <div className="card card-custom">
                    <div className="form">
                      <div className="card-body">
                        {resDownload && (
                          <div className="form-group row">
                            <div class="col-12 text-center text-success">
                              Report Generation process has been started. Your
                              report Filename is <b>{resDownload}</b>. For
                              checking the status or download please{' '}
                              <a
                                className="btn-link"
                                href="/requestedreports/list"
                                title="Requested(SQS) Report">
                                Click here
                              </a>
                              .
                            </div>
                          </div>
                        )}
                        {details.inputCriteria.map((item) => {
                          if (item.inputType === 'text') {
                            return (
                              <div
                                className="form-group row"
                                key={item.inputName}>
                                <label className="col-lg-3 col-form-label text-lg-left">
                                  {item?.displayName}:
                                </label>
                                <div className="col-lg-9 col-xl-4">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name={item.inputName}
                                    value={values[item.inputName]}
                                  />

                                  <FormHelperText>
                                    <ErrorMessage
                                      name={item.inputName}
                                      render={(msg) => (
                                        <span className="text-danger">
                                          {msg}
                                        </span>
                                      )}
                                    />
                                  </FormHelperText>
                                </div>
                              </div>
                            );
                          }

                          if (item.inputType === 'datepicker') {
                            return (
                              <div
                                className="form-group row"
                                key={item.inputName}>
                                <label className="col-lg-3 col-form-label text-lg-left">
                                  {item.displayName} :
                                </label>
                                <div className="col-lg-9 col-xl-4">
                                  <DatePicker
                                    // showTimeSelect
                                    dateFormat="yyyy-MM-dd"
                                    className={`form-control form-control-lg form-control-solid`}
                                    autocomplete="off"
                                    selected={
                                      values[item.inputName]
                                        ? new Date(values[item.inputName])
                                        : ''
                                    }
                                    name={item.inputName}
                                    onChange={(value) => {
                                      handleChange({
                                        target: {
                                          name: item.inputName,
                                          //  value: value.toISOString().split('T')[0],
                                          value:
                                            moment(value).format('YYYY-MM-DD'),
                                        },
                                      });
                                    }} //only when value has changed
                                  />
                                  <FormHelperText>
                                    <ErrorMessage
                                      name={item.inputName}
                                      render={(msg) => (
                                        <span className="text-danger">
                                          {msg}
                                        </span>
                                      )}
                                    />
                                  </FormHelperText>
                                </div>
                              </div>
                            );
                          }

                          if (item.inputType === 'dropdown') {
                            return (
                              <div
                                className="form-group row"
                                key={item.inputName}>
                                <label className="col-lg-3 col-form-label text-lg-left">
                                  {item.displayName} :
                                </label>
                                <div className="col-lg-9 col-xl-4">
                                  <select
                                    multiple={item.selectMultiple}
                                    className="form-control form-control-lg form-control-solid"
                                    name={item.inputName}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values[item.inputName]}>
                                    <option value="">
                                      Please Select {item.displayName}
                                    </option>
                                    <>{optionHtml(item.inputOptions)}</>
                                  </select>
                                  <FormHelperText>
                                    <ErrorMessage
                                      name={item.inputName}
                                      render={(msg) => (
                                        <span className="text-danger">
                                          {msg}
                                        </span>
                                      )}
                                    />
                                  </FormHelperText>
                                </div>
                              </div>
                            );
                          }

                          return true;
                        })}
                      </div>

                      <div className="card-footer">
                        <div className="row">
                          <div className="col-lg-3"></div>
                          <div className="col-lg-9">
                            <button
                              type="submit"
                              onClick={handleSubmit}
                              className={`btn btn-primary font-weight-bold mr-2`}>
                              <i className="fa fa-eye" /> Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Formik>
          </ErrorBoundary>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            dialogClose();
            setToggetValue(false);
          }}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default connect(null, {getReportData, getReportDetails})(
  IsLoadingHOC(ModelForm),
);
