import React, {createContext, useContext, useState, useCallback} from 'react';
import {isEqual, isFunction} from 'lodash';
import {initialFilter} from './RoleUIHelpers';

const RoleUIContext = createContext();

export function useRoleUIContext() {
  return useContext(RoleUIContext);
}

export const RoleUIConsumer = RoleUIContext.Consumer;

export function RoleUIProvider({roleUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initRole = {
    id: undefined,
    title: '',
    actionType: '',
    points: '',
    maxAttempt: '',
    enabled: '',
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initRole,
    newRoleButtonClick: roleUIEvents.newRoleButtonClick,
    openEditRoleDialog: roleUIEvents.openEditRoleDialog,
    openDeleteRoleDialog: roleUIEvents.openDeleteRoleDialog,
    // openDeleteCustomersDialog: customersUIEvents.openDeleteCustomersDialog,
    // openFetchCustomersDialog: customersUIEvents.openFetchCustomersDialog,
    // openUpdateCustomersStatusDialog:
    //   customersUIEvents.openUpdateCustomersStatusDialog,
  };

  return (
    <RoleUIContext.Provider value={value}>{children}</RoleUIContext.Provider>
  );
}
