import {
  SAVE_INBOX_LIST,
  LOADING,
  INBOX_FOR_EDIT,
  UPDATE_INBOX,
  INBOX_DELETE,
  INBOX_MESSAGE_UPDATE,
  UPDATE_STATUS,
} from './type';

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  metaData: {limit: 10, currentPageNo: 0, totalRecs: 10, nextPageNo: 1},
  helpdeskForEdit: undefined,
  lastError: null,
};
const HelpDeskReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_INBOX_LIST: {
      const {content, metaData} = action.payload;
      return {
        ...state,
        entities: content ? content : null,
        totalCount: metaData?.totalRecs,
        metaData: metaData,
      };
    }
    case LOADING: {
      return {...state, listLoading: action.payload};
    }
    case INBOX_FOR_EDIT: {
      return {...state, helpdeskForEdit: action.payload};
    }

    case INBOX_DELETE: {
      const entities = state.entities.filter(
        (item) => item.id != action.payload,
      );
      return {...state, entities: entities, totalCount: state.totalCount - 1};
    }

    case INBOX_MESSAGE_UPDATE: {
      const replies = state.helpdeskForEdit?.replies || [];
      replies.push(action.payload);
      return {
        ...state,
        helpdeskForEdit: {
          ...state.helpdeskForEdit,
          replies: replies,
        },
      };
    }

    case UPDATE_INBOX: {
      let entities = state.entities.map((entity) => {
        if (entity.id === action.payload.id) {
          return action.payload;
        }
        return entity;
      });
      return {...state, entities: entities};
    }

    case UPDATE_STATUS: {
      return {
        ...state,
        helpdeskForEdit: {
          ...state.helpdeskForEdit,
          ticketDetails: action.payload,
        },
      };
    }
    case 'CLEAR_ALL': {
      return initialState;
    }
    default:
      return state;
  }
};

export default HelpDeskReducer;
