import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import IsLoadingHOC from '../../../layout/components/HOC/IsLoadingHOC';
import {Row, Col, Button} from 'react-bootstrap';
import {getReportEarnBurnAction} from '../_redux/action';
import {Card, CardBody} from '../../controls';
const EarnBarn = ({setLoading}) => {
  const [data, setData] = useState({});
  let curYear = new Date().getFullYear();
  let curMon = new Date().getMonth();
  curMon = curMon >= 1 ? curMon - 1 : curMon;
  const monthList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const yaerList = [curYear];
  const [filter, setFilter] = useState({year: curYear, month: curMon});
  const {activationEnd, activationStart} = useSelector(
    (state) => state.auth.tenantConfig,
  );
  if (activationStart) {
    for (let y = curYear; y < new Date(activationStart).getFullYear(); y--) {
      yaerList.push(y);
    }
  }
  useEffect(() => {
    setLoading(true);
    getReportEarnBurnAction({
      year: filter.year,
      month: filter.month,
      tag: 'EARN_BURN',
    })
      .then((res) => {
        setLoading(false);
        setData(res);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [filter]);
  const downloadFile = ({data, fileName, fileType}) => {
    const blob = new Blob([data], {type: fileType});
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = async () => {
    // Headers for each column
    let headers = [
      'Points Earned, Points Redeemed, Points Outstanding, Points Burned, Burn Rate, Month, Points Expired',
    ];
    try {
      setLoading(true);
      const data = await getReportEarnBurnAction({
        year: filter.year,
        month: filter.month,
        tag: 'EARN_BURN',
      });
      console.log('.....', data);
      setLoading(false);
      let dataCsv = [];
      dataCsv.push([
        data?.pointsEarned || 0,
        data?.pointsRedeemed || 0,
        data?.pointsOutstanding || 0,
        data?.pointsBurned || 0,
        data?.burnRate?.toFixed(2) || 0,
        data?.month || '-',
        data?.pointsExpired || 0,
      ]);
      downloadFile({
        data: [...headers, ...dataCsv].join('\n'),
        fileName: 'EarnBurn.csv',
        fileType: 'text/csv',
      });
    } catch (err) {
      setLoading(false);
    }
  };
  return (
    <>
      <Row>
        <Col>
          <div className={`card card-custom gutter-b`}>
            {/* begin::Header */}
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  Please Select Month
                </span>
              </h3>
              <div className="d-flex  pl-5 flex-row">
                <div className="mr-3">
                  <select
                    onChange={(e) =>
                      setFilter({...filter, year: parseInt(e.target.value)})
                    }
                    value={filter.years}
                    className="form-control"
                    id="year">
                    {yaerList.map((item, i) => {
                      return (
                        <option value={i} key={`year-${i}`}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="mr-3">
                  <select
                    onChange={(e) =>
                      setFilter({...filter, month: parseInt(e.target.value)})
                    }
                    value={filter.month}
                    className="form-control"
                    id="month">
                    {monthList.map((item, i) => {
                      return (
                        <option value={i} key={`month-${i}`}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="mr-3">
                  <Button type="submit" variant="primary" onClick={exportToCsv}>
                    Export to CSV
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {Object.keys(data)?.length > 3 && (
        <>
          <Row md={3} lg={3} sm={3} xs={1}>
            <Col>
              <Card style={{backgroundColor: '#A8AAC0', height: '125px'}}>
                <CardBody>
                  <div className="font-weight-bold" style={{fontSize: '16px'}}>
                    Points Earned
                  </div>
                  <div className="font-weight-bolder">
                    <span style={{fontSize: '22px'}}>
                      {data?.pointsEarned || 0}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card style={{backgroundColor: '#A8AAC0', height: '125px'}}>
                <CardBody>
                  <div className="font-weight-bold" style={{fontSize: '16px'}}>
                    Points Redeemed
                  </div>
                  <div className="font-weight-bolder">
                    <span style={{fontSize: '22px'}}>
                      {data?.pointsRedeemed || 0}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card style={{backgroundColor: '#A8AAC0', height: '125px'}}>
                <CardBody>
                  <div className="font-weight-bold" style={{fontSize: '16px'}}>
                    Points Outstanding
                  </div>
                  <div className="font-weight-bolder">
                    <span style={{fontSize: '22px'}}>
                      {data?.pointsOutstanding || 0}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row md={4} lg={4} sm={3} xs={1}>
            <Col>
              <Card style={{backgroundColor: '#C8C7D6', height: '125px'}}>
                <CardBody>
                  <div className="font-weight-bold" style={{fontSize: '16px'}}>
                    Points Burned
                  </div>
                  <div className="font-weight-bolder">
                    <span style={{fontSize: '22px'}}>
                      {data?.pointsBurned || 0}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card style={{backgroundColor: '#C8C7D6', height: '125px'}}>
                <CardBody>
                  <div className="font-weight-bold" style={{fontSize: '16px'}}>
                    Burn Rate
                  </div>
                  <div className="font-weight-bolder">
                    <span style={{fontSize: '22px'}}>
                      {data?.burnRate?.toFixed(2) || 0}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card style={{backgroundColor: '#C8C7D6', height: '125px'}}>
                <CardBody>
                  <div className="font-weight-bold" style={{fontSize: '16px'}}>
                    Month
                  </div>
                  <div className="font-weight-bolder">
                    <span style={{fontSize: '22px'}}>{data?.month || '-'}</span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card style={{backgroundColor: '#C8C7D6', height: '125px'}}>
                <CardBody>
                  <div className="font-weight-bold" style={{fontSize: '16px'}}>
                    Points Expired
                  </div>
                  <div className="font-weight-bolder">
                    <span style={{fontSize: '22px'}}>
                      {data?.pointsExpired || 0}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default IsLoadingHOC(EarnBarn);
