/* eslint-disable no-restricted-imports */
import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import ErrorBoundary from '../../../../_metronic/_helpers/ErrorBoundary';
import {getHistoryList} from '../_redux/action';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';

const HistoryComponent = ({show, setShow, id, getHistoryList, setLoading}) => {
  // Specs UI Context
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [currentPage, setcurrentPage] = useState(0);
  useEffect(() => {
    getHistory(page, pagePerSize);
  }, [pagePerSize, page, id]);

  const options = paginationFactory({
    page: page,
    totalSize: totalCount,
    sizePerPage: pagePerSize,
    showTotal: pagePerSize,
    alwaysShowAllBtns: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,

    sizePerPageList: [
      {text: '5', value: 5},
      {text: '10', value: 10},
      {text: '25', value: 25},
      {text: '50', value: 50},
      {text: '100', value: 100},
    ],

    onSizePerPageChange: (sizePerPage, page) => {
      setPagePerSize(sizePerPage);
      // console.log(page)
      setList([]);
      setPage(currentPage);
    },
    onPageChange: (page, sizePerPage) => {
      // console.log(page,sizePerPage)

      setLoading(true);

      setPage(page);
      setList([]);
      // getHistoryList(page, sizePerPage);
    },
  });

  const getHistory = (page, sizePerPage) => {
    setLoading(true);
    getHistoryList(id, page, sizePerPage)
      .then((res) => {
        setLoading(false);
        setTotalCount(res?.metaData?.totalRecs);
        // console.log(res)
        setList(res?.content);
        // console.log(res?.content)
        setPage(page);
        setPagePerSize(sizePerPage);
        setcurrentPage(res?.metaData?.currentPageNo);
      })
      .catch((error) => {
        setLoading(false);
        console.log('error', error);
      });
  };
  const handleTableChange = (type, {page, sizePerPage}) => {
    setPagePerSize(+sizePerPage);

    setPage(+page);
  };
  const columns = [
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'status',
      text: 'Status',
    },
    {
      dataField: 'updatedBy',
      text: 'Updated By',
    },
    {
      text: 'Amount',
      formatter: (cellContent, Row) => {
        return (
          <>
            {Row?.document?.qualifyingAmount ? '$' : ''}
            {Row?.document?.qualifyingAmount || '-'}
          </>
        );
      },
    },
    {
      dataField: 'updatedUserEmail',
      text: 'Updated User Email',
    },
    {
      dataField: 'updatedAt',
      text: 'Updated At',
      formatter: (cellContent) => {
        return <>{moment(cellContent).format('YYYY-MM-DD HH:mm:ss')}</>;
      },
    },
    // {
    //   dataField: 'submissionDate',
    //   text: 'Submission Date',

    //   formatter: (cellContent) => {
    //     return <>{moment(cellContent).format('YYYY-MM-DD HH:mm:ss')}</>;
    //   },
    // },
  ];

  return (
    <Modal
      size="xl"
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Receipt History
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ErrorBoundary>
          <BootstrapTable
            onTableChange={handleTableChange}
            keyField="status"
            data={list}
            bootstrap4
            remote
            columns={columns}
            pagination={options}
            bordered={false}
          />
        </ErrorBoundary>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setShow(false)}
            className="btn btn-light btn-elevate">
            Cancel
          </button>
          <> </>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default connect(null, {getHistoryList})(IsLoadingHOC(HistoryComponent));
