import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {useDispatch, connect} from 'react-redux';

import Swal from 'sweetalert2';
import {useSubheader} from '../../../_metronic/layout';
import AddRole from './pages/AddRole';
import {RoleList} from './pages/RoleList';
import {RoleUIProvider} from './RoleUIContext';
import {IsLoadingHOC} from '../../../_metronic/layout/components/HOC/IsLoadingHOC';
import {deleteRoleById} from './_redux/action';
// import {RoleEditDialog} from "./component/Role-edit-dialog/RoleEditDialog";im
import './styles.css';

const RolePage = ({history, setLoading, deleteRoleById}) => {
  const suhbeader = useSubheader();
  const dispatch = useDispatch();
  suhbeader.setTitle('Role');

  const roleUIEvents = {
    newRoleButtonClick: () => {
      history.push('/role/new');
    },
    openEditRoleDialog: (id, name) => {
      history.push({
        pathname: `/role/${id}/edit`,
        state: name,
      });
    },
    openDeleteRoleDialog: (id) => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          deleteRoleById(id)
            .then((res) => {
              setLoading(false);
              // console.log('rest', res);
              Swal.fire('Deleted!', 'Role has been deleted.', 'success');
            })
            .catch((error) => {
              setLoading(false);
              console.log(error);
            });
        }
      });
    },
    // openDeleteCustomersDialog: () => {
    //   history.push(`/e-commerce/customers/deleteCustomers`);
    // },
    // openFetchCustomersDialog: () => {
    //   history.push(`/e-commerce/customers/fetch`);
    // },
    // openUpdateCustomersStatusDialog: () => {
    //   history.push('/e-commerce/customers/updateStatus');
    // },
  };
  return (
    <RoleUIProvider roleUIEvents={roleUIEvents}>
      <Switch>
        <Redirect from="/role" exact={true} to="/role/list" />
        <Route path="/role/list" component={RoleList} />
        <Route path="/role/new" component={AddRole} />
        <Route path="/role/:id/edit" component={AddRole} />
      </Switch>
    </RoleUIProvider>
  );
};

export default connect(null, {deleteRoleById})(IsLoadingHOC(RolePage));
