import React, { Component } from "react";
import FileManagerModel from "../modules/FileManager/index";
import fmService from "../modules/FileManager/FileManager.Service";
export class TestingPanel extends Component {
    constructor(props) {
        super(props);
        this.state = { showFM: false, filepath: "", showTextFM: false };

    }
    showPopup = () => {
        let val = this.state.showFM;
        this.setState(Object.assign({}, this.state, { showFM: (val ? false : true) }));
    }
    showPopupFM = () => {
        let val = this.state.showTextFM;
        this.setState(Object.assign({}, this.state, { showTextFM: (val ? false : true) }));
    }
    selectedFile = (param) => {
       // console.log("Selected File Path", param);
        let { path } = param;
        this.setState(Object.assign({}, this.state, { filepath: fmService.getPathWithoutTenentID(path) }));
    }
    getStateFromChild = () => {
        this.setState(Object.assign({}, this.state, { showFM: false, showTextFM: false }));
    }
    elechange = (e) => {
        this.setState(Object.assign({}, this.state, { filepath: e.target.value }));
    }
    componentDidMount() {
    }
    render() {
        return <div className="card card-custom card-stretch gutter-b">
            <div className="card-header border-0 pt-5">
                <h5 className="font-size-sm-100 font-weight-boldest text-dark-75 mt-2">
                    File Manager
                </h5>
            </div>
            <div className="card-body pt-3 pb-2">
                <div className="row" >
                    <button type="button" onClick={this.showPopup} className="btn btn-dark" >Click to view File Manager</button>
                    {this.state.showFM ? <FileManagerModel parentCallback={this.getStateFromChild} /> : ""}
                </div>
            </div>

            <div className="card-header border-0 pt-5">
                <h5 className="font-size-sm-100 font-weight-boldest text-dark-75 mt-2">
                    File Manager with input field
                </h5>
            </div>
            <div className="card-body pt-3 pb-2">
                <div className="row" >
                    <p align="center" style={{ width: "50%" }}>
                        <input type="text" onFocus={this.showPopupFM} onChange={this.elechange} className="form-control" placeholder="File Name" value={this.state.filepath} />
                        {this.state.showTextFM ? <FileManagerModel parentCallback={this.getStateFromChild} getSelectedFilesOnParent={this.selectedFile} /> : ""}
                    </p>
                </div>
            </div>
        </div>;
    }
}

