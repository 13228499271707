import React, {useState, useEffect, useCallback, useRef, Fragment} from 'react';
import moment from 'moment';
// import {useAuth} from '../context';
import {connect, useSelector} from 'react-redux';
import {logout} from '../../app/modules/Auth/_redux/authCrud';

const SessionTimeout = ({logout}) => {
  const [events, setEvents] = useState(['click', 'load', 'scroll']);
  const [second, setSecond] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const {accessToken} = useSelector((state) => state.auth);
  // const {
  //   authState: {isAuthenticated},
  //   logout,
  // } = useAuth();

  let timeStamp;
  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();

  // start inactive check
  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem('lastTimeStamp');
      //  console.log('alert');
      warningInactive(storedTimeStamp);
    }, 3600000);
  };

  // 3600000

  // warning timer
  let warningInactive = (timeString) => {
    clearTimeout(startTimerInterval.current);

    // warningInactiveInterval.current = setInterval(() => {
    //   const maxTime = 2;
    //   const popTime = 1;

    //   const diff = moment.duration(moment().diff(moment(timeString)));
    //   const minPast = diff.minutes();
    //   const leftSecond = 10 - diff.seconds();

    //   if (minPast === popTime) {
    //     setSecond(leftSecond);
    //     setOpen(true);
    //   }

    // if (minPast === maxTime) {
    //  clearInterval(warningInactiveInterval.current);
    //  setOpen(false);
    sessionStorage.removeItem('lastTimeStamp');

    logout();
    //   }
    // }, 1000);
  };

  // reset interval timer
  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    if (accessToken) {
      timeStamp = moment();
      sessionStorage.setItem('lastTimeStamp', timeStamp);
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem('lastTimeStamp');
    }
    timeChecker();
    setOpen(false);
  }, [accessToken]);

  // handle close popup
  const handleClose = () => {
    setOpen(false);

    resetTimer();
  };

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
      //   resetTimer();
    };
  }, [resetTimer, events, timeChecker]);

  if (!isOpen) {
    return null;
  }

  // change fragment to modal and handleclose func to close
  return <Fragment />;
};

export default connect(null, {logout})(SessionTimeout);
