// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, {useEffect, useState, useMemo} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';

import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
//import * as actions from '../../../_redux/customers/customersActions';
import {getReceiptSummaryList} from '../_redux/action';
import {connect} from 'react-redux';
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from '../../../../_metronic/_helpers';
import * as uiHelpers from '../ReceiptSummaryUIHelpers';
import {Pagination} from '../../../../_metronic/_partials/controls';
// import {entities} from '../_mock';
import {useReceiptSummaryUIContext} from '../ReceiptSummaryUIContext';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';

const ReceiptSummaryTable = ({getReceiptSummaryList, setLoading}) => {
  // ReceiptSummary UI Context
  const [data, setData] = useState([]);

  // Getting curret state of ReceiptSummary list from store (Redux)
  const {campaignId} = useSelector(
    (state) => ({
      campaignId: state.auth.campaignId,
    }),
    shallowEqual,
  );
  //const {entities} = currentState;
  // console.log('entities, data', entities);
  const {SearchBar} = Search;
  useEffect(() => {
    setLoading(true);
    getReceiptSummaryList()
      .then((res) => {
        setLoading(false);
        // console.log('newData', res);

        if (res) {
          let newData = [];
          Object.keys(res).forEach((key) => {
            newData.push({
              name: key,
              ...res[key],
              total:
                (isNaN(res[key]['NEW']) ? 0 : res[key]['NEW']) +
                (isNaN(res[key]['DUPLICATE']) ? 0 : res[key]['DUPLICATE']) +
                (isNaN(res[key]['PENDING']) ? 0 : res[key]['PENDING']) +
                (isNaN(res[key]['AMBIGUOUS']) ? 0 : res[key]['AMBIGUOUS']),
            });
          });
          setData(newData);
        }

        // setData(res);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }, [campaignId]);

  // Table columns
  const columns = [
    {
      dataField: 'name',
      text: 'Program',
      sort: true,
      footer: 'Total',
    },
    {
      dataField: 'NEW',
      text: 'NEW',
      sort: true,
      formatter: (cellContent) => {
        return <span>{cellContent ? cellContent : 0}</span>;
      },
      footer: (columnData) =>
        columnData.reduce((acc, item) => acc + (isNaN(item) ? 0 : item), 0),
    },
    {
      dataField: 'NEW_oldReceipt',
      text: 'OldestReceipt',
      sort: true,
      formatter: (cellContent) => {
        return (
          <span>
            {cellContent ? new Date(cellContent).toLocaleDateString() : ''}
          </span>
        );
      },
      footer: '',
    },
    {
      dataField: 'DUPLICATE',
      text: 'DUPLICATE',
      sort: true,
      formatter: (cellContent) => {
        return <span>{cellContent ? cellContent : 0}</span>;
      },
      footer: (columnData) =>
        columnData.reduce((acc, item) => acc + (isNaN(item) ? 0 : item), 0),
    },
    {
      dataField: 'DUPLICATE_oldReceipt',
      text: 'OldestReceipt',
      sort: true,
      formatter: (cellContent) => {
        return (
          <span>
            {cellContent ? new Date(cellContent).toLocaleDateString() : ''}
          </span>
        );
      },
      footer: '',
    },
    {
      dataField: 'PENDING',
      text: 'PENDING',
      sort: true,
      formatter: (cellContent) => {
        return <span>{cellContent ? cellContent : 0}</span>;
      },
      footer: (columnData) =>
        columnData.reduce((acc, item) => acc + (isNaN(item) ? 0 : item), 0),
    },
    {
      dataField: 'PENDING_oldReceipt',
      text: 'OldestReceipt',
      sort: true,
      formatter: (cellContent) => {
        return (
          <span>
            {cellContent ? new Date(cellContent).toLocaleDateString() : ''}
          </span>
        );
      },
      footer: '',
    },
    {
      dataField: 'AMBIGUOUS',
      text: 'AMBIGUOUS',
      sort: true,
      formatter: (cellContent) => {
        return <span>{cellContent ? cellContent : 0}</span>;
      },
      footer: (columnData) =>
        columnData.reduce((acc, item) => acc + (isNaN(item) ? 0 : item), 0),
    },
    {
      dataField: 'AMBIGUOUS_oldReceipt',
      text: 'OldestReceipt',
      sort: true,
      formatter: (cellContent) => {
        return (
          <span>
            {cellContent ? new Date(cellContent).toLocaleDateString() : ''}
          </span>
        );
      },
      footer: '',
    },
    {
      dataField: 'total',
      text: 'Total',
      sort: true,
      formatter: (cellContent) => {
        return <span>{cellContent ? cellContent : 0}</span>;
      },
      // formatter: (cellContent, row) => {
      //   return (
      //     (isNaN(row['NEW']) ? 0 : row['NEW']) +
      //     (isNaN(row['DUPLICATE']) ? 0 : row['DUPLICATE']) +
      //     (isNaN(row['PENDING']) ? 0 : row['PENDING']) +
      //     (isNaN(row['AMBIGUOUS']) ? 0 : row['AMBIGUOUS'])
      //   );
      // },
      footer: (columnData) =>
        columnData.reduce((acc, item) => acc + (isNaN(item) ? 0 : item), 0),
    },
    // {
    //   dataField: 'NEW',
    //   text: 'NEW',
    //   sort: true
    // },
  ];

  return (
    <ToolkitProvider
      keyField="id"
      data={data === null ? [] : data}
      columns={columns}
      search>
      {(props) => (
        <div>
          <SearchBar {...props.searchProps} />
          {/* <small className="form-text text-muted">
            <b>Search</b> in all fields
          </small> */}
          <hr />
          <BootstrapTable
            {...props.baseProps}
            wrapperClasses="table-responsive react-bootstarp-table-custom custome-receipt-summary-header"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
            // remote
            keyField="name"
            data={data === null ? [] : data}
            columns={columns}
            defaultSorted={uiHelpers.defaultSorted}>
            <PleaseWaitMessage entities={data} />
            <NoRecordsFoundMessage entities={data} />
          </BootstrapTable>
        </div>
      )}
    </ToolkitProvider>
  );
};

export default connect(null, {getReceiptSummaryList})(
  IsLoadingHOC(ReceiptSummaryTable),
);
