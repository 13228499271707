import {combineReducers} from 'redux';

const loading = (state = false, action) => {
  switch (action.type) {
    case 'LOADING': {
      return action.payload || false;
    }
    case 'SET_USERINFO':
      return false;
    default:
      return state;
  }
};

const detail = (state = {}, action) => {
  switch (action.type) {
    case 'INIT_TAB': {
      return {};
    }
    case 'SET_USERINFO':
      return action.data;
    case 'INIT_USERINFO':
      return {};
    case 'SET_USERINFO_NOTES':
      return {...state, notes: action.data.notes};
    default:
      return state;
  }
};

const initialState = {
  entities: [],
  meta: {limit: 10, currentPageNo: 0, totalRecs: 10, nextPageNo: 1},
  loading: false,
};

const pointHistory = (state = {...initialState}, action) => {
  switch (action.type) {
    case 'INIT_POINT_HISTORY': {
      return {...state, loading: true};
    }
    case 'SET_POINT_HISTORY':
      return {
        entities: action.entities,
        meta: action.meta,
        loading: false,
      };
    default:
      return state;
  }
};

const pointRedeemed = (state = {...initialState}, action) => {
  switch (action.type) {
    case 'INIT_POINT_REDEEMED': {
      return {...state, loading: true};
    }
    case 'SET_POINT_REDEEMED':
      return {
        entities: action.entities,
        meta: action.meta,
        loading: false,
      };
    default:
      return state;
  }
};

const purchaseHistory = (state = {...initialState}, action) => {
  switch (action.type) {
    case 'INIT_PURCHASE_HISTORY': {
      return {...state, loading: true};
    }
    case 'SET_PURCHASE_HISTORY':
      return {
        entities: action.entities,
        meta: action.meta,
        loading: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  loading,
  detail,
  pointHistory,
  pointRedeemed,
  purchaseHistory,
});
