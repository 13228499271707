// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, {useEffect, useMemo, useState} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import {shallowEqual, useSelector, useDispatch} from 'react-redux';
//import * as actions from '../../../_redux/customers/customersActions';
import {getRewardSummaryList} from '../_redux/action';
import {connect} from 'react-redux';
import filterFactory from 'react-bootstrap-table2-filter';
import filterFun from '../../../../_metronic/_helpers/FilterHelper';
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  headerSortingClasses,
  getHandlerTableChange,
} from '../../../../_metronic/_helpers';
import * as uiHelpers from '../RewardSummaryUIHelpers';
import {Pagination} from '../../../../_metronic/_partials/controls';
// import {entities} from '../_mock';
import {useRewardSummaryUIContext} from '../RewardSummaryUIContext';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';

const RewardSummaryTable = ({getRewardSummaryList, setLoading, campaignId}) => {
  // RewardSummary UI Context
  // Getting curret state of RewardSummary list from store (Redux)
  const dispatch = useDispatch();
  const {currentState} = useSelector(
    (state) => ({
      currentState: state.rewardSummary,
      // campaignId: state.auth.campaignId,
    }),
    shallowEqual,
  );
  const {totalCount, tokenAvailable, entities, listLoading} = currentState;
  // const {entities} = currentState;
  const rewardsummaryUIContext = useRewardSummaryUIContext();
  const rewardsummaryUIProps = useMemo(() => {
    return {
      ids: rewardsummaryUIContext.ids,
      setIds: rewardsummaryUIContext.setIds,
      queryParams: rewardsummaryUIContext.queryParams,
      setQueryParams: rewardsummaryUIContext.setQueryParams,
      openEditAdminUserDialog: rewardsummaryUIContext.openEditAdminUserDialog,
      openDeleteAdminUserDialog:
        rewardsummaryUIContext.openDeleteAdminUserDialog,
    };
  }, [rewardsummaryUIContext]);

  useEffect(() => {
    //  console.log('working fine', campaignId, rewardsummaryUIProps.queryParams);
    if (campaignId) {
      setLoading(true);
      rewardsummaryUIProps.queryParams.campaignId = campaignId;
      getRewardSummaryList(rewardsummaryUIProps.queryParams)
        .then((res) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    } else {
      dispatch({type: 'CLEAR_ALL', payload: {}});
    }
  }, [campaignId, rewardsummaryUIProps.queryParams]);

  // Table columns
  let columns = [
    {
      dataField: 'email',
      text: 'Email',
      headerSortingClasses,
      filter: filterFun(),
    },
    {
      dataField: 'token',
      text: 'Reward Code',
      headerSortingClasses,
      filter: filterFun(),
    },
    {
      dataField: 'isClaimed',
      text: 'Redeemed',
      formatter: (data, row) => {
        return row.isClaimed ||
          (row.claimedTime && !row.hasOwnProperty('isClaimed'))
          ? 'TRUE'
          : 'FALSE';
      },
      headerSortingClasses,
    },
    {
      dataField: 'sentAt',
      text: 'Sent Date',
      formatter: (data, row) => {
        return row.createdAt
          ? new Date(row.createdAt).toLocaleDateString()
          : 'NA';
      },
      headerSortingClasses,
    },
    {
      dataField: 'redemptionDate',
      text: 'Redemption Date',
      formatter: (data, row) => {
        return row.claimedTime
          ? new Date(row.claimedTime).toLocaleDateString()
          : 'NA';
      },
      headerSortingClasses,
    },
    {
      dataField: 'amount',
      text: 'Amount',
      formatter: (data, row) => {
        return row.rewardMasterInfo ? row.rewardMasterInfo.redeemPrice : '';
      },
      headerSortingClasses,
    },
    {
      dataField: 'currency',
      text: 'Currency',
      formatter: (data, row) => {
        return row.rewardMasterInfo ? row.rewardMasterInfo.currency : '';
      },
      headerSortingClasses,
    },
    {
      dataField: 'rewardId',
      text: 'RewardId',
      // formatter: (data, row) => {
      //   return row.rewardMasterInfo ? row.rewardMasterInfo.brandId : '';
      // },
      headerSortingClasses,
    },
    {
      dataField: 'rewardId',
      text: 'Neocurrency’s RewardId',
      formatter: (data, row) => {
        return row.rewardMasterInfo ? row.rewardMasterInfo.brandId : '';
      },
      headerSortingClasses,
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
      headerSortingClasses,
    },
    {
      dataField: 'rewardType',
      text: 'RewardType',
      headerSortingClasses,
    },
  ];

  if (!tokenAvailable) {
    columns = columns.filter((item) => item.dataField !== 'rewardCode');
  }

  const paginationOptions = {
    custom: true,
    totalSize: totalCount || 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: rewardsummaryUIProps.queryParams.limit,
    page: rewardsummaryUIProps.queryParams.page,
  };

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({paginationProps, paginationTableProps}) => {
          return (
            <Pagination
              // isLoading={listLoading}
              paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="rewardCode"
                data={
                  entities === null || isEmpty(entities)
                    ? []
                    : entities.map((item) => {
                        item.redeemed = item.redeemed ? 'Yes' : 'No';

                        return item;
                      })
                }
                columns={columns}
                filter={filterFactory()}
                onTableChange={getHandlerTableChange(
                  rewardsummaryUIProps.setQueryParams,
                )}
                defaultSorted={uiHelpers.defaultSorted}
                {...paginationTableProps}>
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default connect(null, {getRewardSummaryList})(
  IsLoadingHOC(RewardSummaryTable),
);
