import React, {useState, useEffect} from 'react';
import {Spinner} from 'react-bootstrap';
import {getRequest} from '../../../../../_metronic/_helpers/APIRequestHelpers';
import {Tabs, Tab} from 'react-bootstrap/';
import './NodeProperties.css';
import {toast} from 'react-toastify';
export default function DBNodeProperties(props) {
  let operatorList = [
    {key: 'eq', value: 'Equal To'},
    {key: 'ne', value: 'Not Equal to'},
    {key: 'gt', value: 'Greater Than'},
    {key: 'lt', value: 'Less Than'},
    {key: 'in', value: 'In'},
    {key: 'nin', value: 'Not In'},
  ];
  let nodeProperties = props.nodeProperty;
  let lastStateChildData = props.lastStateChildData;
  let columns = nodeProperties.inputParam['column']
    ? nodeProperties.inputParam.column
    : [];
  let [dbColumnList, setDBColumnList] = useState(columns);
  let [activeTabs, setaActiveTabs] = useState('inputTab');
  let [selectedColumn, setSelectedColumn] = useState(
    dbColumnList.filter((val) => val.selected === true),
  );
  let [collectionList, setCollectionList] = useState(
    lastStateChildData['collectionList']
      ? lastStateChildData['collectionList']
      : [],
  );
  let [conditionList, setConditionList] = useState(
    nodeProperties.inputParam['whereQuery']
      ? nodeProperties.inputParam['whereQuery'].and
      : [],
  );
  let collection_name = React.createRef();
  let [collectionVariable, setCollectionVariable] = useState(columns);
  if (
    nodeProperties.inputParam.collection_name === '' &&
    collectionVariable.length > 0
  ) {
    setCollectionVariable([]);
  }
  useEffect(() => {
    if (collectionList.length === 0) {
      getRequest(
        '/s1/automation/get-db-entities?node=db',
        (response, error) => {
          if (error) {
            alert(error);
            setCollectionList([]);
          } else if (response) {
            setCollectionList(response.data.data);
            let tempdata = [];
            tempdata[nodeProperties.nodeID] = {
              collectionList: response.data.data,
            };
            props.parentCallback(tempdata, 2);
          }
        },
      );
    }
  }, []);
  let loadCollectionVariable = (event) => {
    let collection_name = event.target.value;
    if (collection_name && collection_name !== '') {
      nodeProperties.inputParam['collection_name'] = collection_name;
      let selectedCollection = collectionList.find(
        (element) => element.collection_name === collection_name,
      );
      let vList = selectedCollection.visible_fields.map((v) => {
        return {...v, selected: false};
      });
      nodeProperties.inputParam['column'] = vList;
      setCollectionVariable(vList);
    } else {
      nodeProperties.inputParam['column'] = [];
      nodeProperties.inputParam['collection_name'] = '';
      setCollectionVariable([]);
    }
    props.parentCallback(nodeProperties, 1);
  };
  let addRemoveVariable = (type) => {
    let vlist = [...conditionList];
    if (type === 'add') {
      vlist.push({
        field: '',
        op: '',
        value: '',
      });
      setConditionList(vlist);
    } else {
      vlist.pop();
      setConditionList(vlist);
    }
  };
  let prepareOutput = (event) => {
    event.preventDefault();
    let tempArr = dbColumnList.filter((val) => val.selected === true);
    let cList = conditionList.filter(
      (val) =>
        val.field === '' ||
        val.op === '' ||
        (val.value === '' && val.op != 'eq' && val.op != 'nq'),
    );
    if (tempArr.length === 0) {
      toast.error('Please select at least one column');
    } else if (cList.length > 0) {
      toast.error('Invaild Column/Operator or value in conditions');
    } else {
      setSelectedColumn(JSON.parse(JSON.stringify(tempArr)));
      nodeProperties.inputParam.column = dbColumnList;
      nodeProperties.inputParam.whereQuery.and = conditionList;
      let outPutVariables = {[nodeProperties.nodeID]: []};
      for (let i in tempArr) {
        outPutVariables[nodeProperties.nodeID].push({
          key: tempArr[i].name,
          value: '',
          type: 'db',
        });
      }
      nodeProperties.outputParam.variables = Object.assign(
        {},
        nodeProperties.inputParam.variables,
        outPutVariables,
      );
      setaActiveTabs('outputTab');
      props.parentCallback(nodeProperties, 1);
    }
  };
  let columnSelected = (ind) => {
    let tempColumnList = [...columns];
    tempColumnList[ind]['selected'] = tempColumnList[ind].selected
      ? false
      : true;
    setDBColumnList(tempColumnList);
  };
  const changeHandler = (ind, value, key) => {
    let vlist = [...conditionList];
    vlist[ind][key] = value;
    setConditionList(vlist);
  };
  let counter = 1;
  return (
    <>
      <h2 className="leftToolBar">
        Object Properties
        <br />
        <span>DB Node</span>
      </h2>
      <div className="leftToolBarContainer">
        <Tabs
          activeKey={activeTabs}
          transition={false}
          id="noanim-tab-example"
          onSelect={(key) => {
            setaActiveTabs(key);
          }}>
          <Tab eventKey={'inputTab'} title="Input">
            <table className="table table-striped" id="dbNodeTable">
              <tbody>
                <tr>
                  <td width="35%">
                    <strong>
                      {' '}
                      Collection <span className="requiredfield">*</span>{' '}
                    </strong>
                  </td>
                  <td>
                    {collectionList.length === 0 ? (
                      <Spinner animation="border" size="sm" variant="primary" />
                    ) : (
                      <select
                        className="form-select customselect"
                        id="collection_name"
                        ref={collection_name}
                        onChange={loadCollectionVariable}
                        value={nodeProperties.inputParam.collection_name}>
                        <option value="">Select Collection</option>
                        {collectionList.map((val, ind) => {
                          return (
                            <option
                              key={'tem' + ind}
                              value={val.collection_name}>
                              {val.collection_name}
                            </option>
                          );
                        })}
                      </select>
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} style={{padding: 0}} align="center">
                    <strong>Conditions</strong>
                    <table width="100%">
                      <thead>
                        <tr>
                          <th>Column</th>
                          <th>Operator</th>
                          <th>Values</th>
                        </tr>
                      </thead>
                      <tbody>
                        {conditionList.map((v, i) => (
                          <tr key={`row-cond-${i}`}>
                            <td>
                              <select
                                className="form-select customselect"
                                value={v.field}
                                onChange={(e) =>
                                  changeHandler(i, e.target.value, 'field')
                                }>
                                <option value="">--Select--</option>
                                {collectionVariable.map((val, ind) => {
                                  return (
                                    <option
                                      key={`cond-${i}-${ind}`}
                                      value={val.name}>
                                      {val.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </td>
                            <td>
                              <select
                                className="form-select customselect"
                                value={v.op}
                                onChange={(e) =>
                                  changeHandler(i, e.target.value, 'op')
                                }>
                                <option value="">--Select--</option>
                                {operatorList.map((val, ind) => {
                                  return (
                                    <option
                                      key={`op-${i}-${ind}`}
                                      value={val.key}>
                                      {val.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </td>
                            <td>
                              <input
                                style={{height: '27px'}}
                                className="md-textarea form-control"
                                type={'text'}
                                value={v.value}
                                onChange={(e) =>
                                  changeHandler(i, e.target.value, 'value')
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-6">
                          <button
                            type="button"
                            title="Add Condition"
                            className="btn btn-success custombtn"
                            onClick={(e) => {
                              addRemoveVariable('add');
                            }}>
                            {' '}
                            +{' '}
                          </button>
                        </div>
                        <div className="col-md-6" style={{textAlign: 'right'}}>
                          <button
                            type="button"
                            title="Remove Condition"
                            className="btn btn-danger custombtn"
                            onClick={(e) => {
                              addRemoveVariable('remove');
                            }}
                            disabled={
                              conditionList.length < 1 ? 'disabled' : false
                            }>
                            {' '}
                            -{' '}
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong> Column</strong>
                  </td>
                  <td>
                    <div className="container">
                      {collectionVariable.map((val, ind) => {
                        return (
                          <div className="form-check" key={ind}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={val.selected}
                              id={'key' + ind}
                              onChange={() => columnSelected(ind)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={'key' + ind}>
                              {val.name}
                            </label>
                          </div>
                        );
                      })}
                      {collectionVariable.length > 0 ? (
                        <p>
                          <button
                            type="button"
                            className="custombtn btn btn-dark"
                            title="Prepare Output"
                            onClick={prepareOutput}>
                            Prepare Output
                          </button>
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Tab>
          <Tab
            eventKey={'outputTab'}
            title="Output"
            disabled={selectedColumn.length > 0 ? false : 'disabled'}>
            <h5>Selected Column</h5>
            {selectedColumn.map((val, ind) => {
              if (val.selected) {
                return (
                  <div className="form-check" key={ind}>
                    <label
                      className="form-check-label"
                      key={'outputlable' + ind}>
                      {counter++} {val.name}
                    </label>
                  </div>
                );
              } else {
                return '';
              }
            })}
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
